import React, { Component } from 'react';
import { Form, Message, Segment } from 'semantic-ui-react';
import Placement from 'apps/placement';

class PlacementOverview extends Component {
  render() {
    const {
      abtest,
      abtesttype,
      app,
      appId,
      defaultNames,
      errors,
      loading,
      onAddName,
      placement,
      set,
    } = this.props;

    const isNewPlacement = placement.id === '__new__';
    const hasError = errors.length > 0;

    return (
      <Segment basic loading={loading} textAlign="left" className="pt-0">
        <Form error={hasError}>
          <Form.Group inline widths="equal">
            <Form.Checkbox
              checked={placement.active}
              label="Active"
              name="active"
              onClick={set}
              toggle
              value={!placement.active}
            />
          </Form.Group>
          {!abtest ? (
            <Form.Group inline widths="equal">
              <Form.Input
                fluid
                label="App Name"
                name="app_name"
                placeholder="App Name"
                readOnly
                value={placement.app_name}
              />
              <Form.Input
                fluid
                label="App Platform"
                name="app_platform"
                placeholder="App Platform"
                readOnly
                value={app.platform}
              />
            </Form.Group>
          ) : null}

          <Form.Group inline widths="equal">
            <Form.Input
              fluid
              label="Helium App ID"
              name="helium_app_id"
              placeholder=""
              readOnly
              value={appId}
            />
            <Form.Input
              data-testid="ad_placement_type"
              fluid
              label="Ad Placement Type"
              name="type"
              placeholder="Select Type"
              readOnly
              value={Placement.Constants.AD_TYPE_LABELS[placement.type]}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              allowAdditions
              disabled={abtesttype === 'B' || !isNewPlacement}
              error={hasError}
              fluid
              label="Ad Placement Name"
              name="name"
              onAddItem={onAddName}
              onChange={set}
              options={defaultNames}
              placeholder="Your Placement"
              search
              selection
              value={placement.name}
            />
          </Form.Group>
          <Message error fluid="true" header="Invalid Name" content={errors} />
        </Form>
      </Segment>
    );
  }
}

export default PlacementOverview;
