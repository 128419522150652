import Api from 'core/framework/api';
import Helpers from './helpers';

const getAll = appId => Api.get(`${Api.HELIUM_API}/apps/${appId}/adgroups`, null, { token: true });

const create = (appId, adgroup) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/adgroups`, Helpers.toApiAdGroup(adgroup), {
    token: true,
    method: 'POST'
  });

const update = (appId, adgroup) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/adgroups/${adgroup.id}`, adgroup, {
    token: true,
    method: 'POST'
  });

const _delete = (appId, adgroupId) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/adgroups/${adgroupId}`, null, { token: true });

const AdgroupApi = {
  getAll,
  create,
  update,
  _delete
};

export default AdgroupApi;
