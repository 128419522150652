import React from 'react';
import { Form, Header, Select, TextArea, Input, Popup, Message } from 'semantic-ui-react';
import CommonHelpers from '../../common/helpers';

const RewardedCallbackSettings = (props) => {
  const { appId, placement, set, errors = [] } = props;
  const { enabled, url, method, body, max_retries } = placement.rewarded_callback || {};
  const isPost = method === 'POST';
  const methodOptions = [
    { key: 'GET', text: 'GET', value: 'GET' },
    { key: 'POST', text: 'POST', value: 'POST' },
  ];

  const setField = (name, value) => {
    set(appId, placement.id, name, value);
  };

  const urlErrors = errors.filter((e) => e.field === 'url');
  const bodyErrors = errors.filter((e) => e.field === 'body');
  const otherErrors = errors.filter((e) => e.field === 'method' || e.field === 'max_retries');

  return (
    <div>
      <Header as="h4" content="Rewarded Callbacks" />
      <Form error={errors.length > 0}>
        <Form.Checkbox
          label={enabled ? 'Enabled' : 'Disabled'}
          name="rewarded_callback_status"
          value={enabled}
          checked={enabled}
          onChange={(e) => setField('enabled', !enabled)}
          toggle
        />
        <div className={enabled ? 'visible' : 'hidden'}>
          <Form.Group>
            <Form.Field
              control={Select}
              label="Method"
              options={methodOptions}
              placeholder="Method"
              value={method}
              onChange={(e, ctx) => setField('method', ctx.value)}
            />
            <Form.Field
              control={Input}
              label="Max Retries"
              value={max_retries}
              step="1"
              min={0}
              max={10}
              type="number"
              onChange={(e) => setField('max_retries', e.target.value)}
              onWheel={CommonHelpers.numberInputOnWheelPreventChange}
            />
          </Form.Group>
          {otherErrors.map((e) => (
            <Message error content={e.message} />
          ))}
          <Form.Field
            control={isPost ? Input : TextArea}
            label="URL"
            placeholder="https://example.com"
            value={url}
            onChange={(e) => setField('url', e.target.value)}
          />
          {urlErrors.map((e) => (
            <Message error content={e.message} />
          ))}
          <Form.Field
            control={TextArea}
            label="Body"
            placeholder=""
            className={isPost ? 'visible' : 'hidden'}
            value={body}
            onChange={(e) => setField('body', e.target.value)}
          />
          {bodyErrors.map((e) => (
            <Message error content={e.message} />
          ))}
          <Popup
            wide="very"
            mouseLeaveDelay={300}
            trigger={
              <span className="hint">
                See a list of <i>Supported Macros</i>
              </span>
            }
          >
            <Popup.Header>Callback Macros</Popup.Header>
            <Popup.Content>
              <ul>
                <li>
                  <b>%%TRANSACTION_ID%%</b> A unique impression ID associated with the rewarded
                  event <i>(required)</i>
                </li>
                <li>
                  <b>%%SERVER_TIMESTAMP%%</b> A unix timestamp set by the Helium server when the
                  event is processed <i>(required)</i>
                </li>
                <li>
                  <b>%%USER_ID%%</b> A publisher specific user ID associated <i>(required)</i>
                </li>
                <li>
                  <b>%%VERIFICATION%%</b> A SHA256 hash encrypted with your rewarded secret key by
                  the Helium server <i>(required)</i>
                </li>
                <li>
                  <b>%%SDK_TIMESTAMP%%</b> A unix timestamp set by the Helium SDK when the event is
                  processed
                </li>
                <li>
                  <b>%%CPM_PRICE%%</b> The CPM price of the ad unit that was served
                </li>
                <li>
                  <b>%%AD_REVENUE%%</b> The actual ad revenue value from the impression. CPM_PRICE /
                  1000.
                </li>
                <li>
                  <b>%%CUSTOM_DATA%%</b> A key value JSON payload provided via the SDK that contains
                  custom data.
                </li>
              </ul>
            </Popup.Content>
          </Popup>
        </div>
      </Form>
    </div>
  );
};

export default RewardedCallbackSettings;
