import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import MetricsTooltip from './MetricsTooltip';
import Helpers from '../helpers';
import MetricsConstants from '../constants';

import '../css/metrics.css';

const MetricsBarGraph = ({
  colorMap,
  graph_data,
  isAdvancedView,
  pageView,
  placementNames,
  selectedView,
}) => {
  return (
    <ResponsiveContainer>
      <BarChart data={graph_data} margin={{ left: 0, top: 5, right: 0, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dt" tickFormatter={Helpers.xAxisFormatter} />
        <YAxis
          tickCount={5}
          tickFormatter={(tick) => Helpers.yAxisFormatter({ view: selectedView, tick })}
        />
        <Tooltip
          content={
            <MetricsTooltip
              colorMap={colorMap}
              isAdvanced={isAdvancedView}
              pageView={pageView}
              selectedView={selectedView}
            />
          }
          wrapperStyle={{ outline: 'none' }}
        />
        {pageView === 'appOverview' && (
          <Bar
            dataKey={selectedView}
            name={MetricsConstants.FIELD_LABELS[selectedView]}
            fill={MetricsConstants.COMMON_COLORS.basic}
          />
        )}

        {pageView === 'singlePlacement' && (
          <Bar
            dataKey={`bidding_${selectedView}`}
            name="Bidding"
            stackId="dt"
            fill={MetricsConstants.COMMON_COLORS.bidding}
          />
        )}
        {pageView === 'singlePlacement' && (
          <Bar
            dataKey={`waterfall_${selectedView}`}
            name="Waterfall"
            stackId="dt"
            fill={MetricsConstants.COMMON_COLORS.waterfall}
          />
        )}

        {(pageView === 'placementOverview' || pageView === 'allApps') && !isAdvancedView && (
          <Bar
            dataKey={`totals.${selectedView}`}
            name={MetricsConstants.FIELD_LABELS[selectedView]}
            fill="#84c342"
          />
        )}
        {pageView === 'placementOverview' &&
          isAdvancedView &&
          placementNames.map((placement) => {
            return (
              <Bar
                dataKey={`placement_metrics.${placement}.${selectedView}`}
                stackId={`dt`}
                name={placement}
                fill={colorMap[placement]}
              />
            );
          })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MetricsBarGraph;
