/**
 * @namespace App/Api
 */

import Api from 'core/framework/api';
import Constants from './constants';
import Helpers from './helpers';

/**
 * App object in API response format,
 * not all fields are shown here
 * @typedef {Object} APP
 * @property {string} id - app id stirng
 * @property {string} company_name - publisher name
 * @property {string} name - official app name
 * @property {string} nickname - app nickname from chartboost dashboard
 * @property {string} bundle - app bunbdle id
 * @property {string} url - app store url
 * @property {string} platform - either "iOS" or "android"
 * @property {string} signature - chartboost signature credential
 * @property {APP_CREDENTIALS} credentials - app creds and network states
 * @property {string[]} blocked_adomains - list of blocked domains
 * @property {number[]} placements - list of placement ids associated with app
 * @property {LINE_ITEMS[]} line_items - list of line items
 * @property {number[]} adgroups - list of adgroup ids associated with app
 */

/**
 * @inner
 * @name _toApiApp
 * @description converts UI_APP object back to
 * api-compliant APP object for saving app changes
 * @param {UI_APP} app - app object from UI (react state)
 * @returns {APP} - app object in api format
 * @memberof App/Api
 */

const _toApiApp = (app) =>
  Constants.APP_FIELDS.reduce(
    (normalized, field) => ({ ...normalized, [field]: app[field], advertiser_appid: app.id }),
    {}
  );

/**
 * @function getOne
 * @description fetches an app's details from API
 * @param {string} appId - app id string
 * @returns {APP} - app object in api format
 * @memberof App/Api
 */

const getOne = (appId) => Api.get(`${Api.HELIUM_API}/apps/${appId}`, null, { token: true });

/**
 * @function getAll
 * @description fetches all apps for a publisher
 * @returns {APP[]} - list of apps with details
 * @memberof App/Api
 */

const getAll = () => Api.get(`${Api.HELIUM_API}/apps`, null, { token: true });

/**
 * @function getAllTimeline
 * @description fetches all apps for a publisher along with metric data
 * @returns {APP} - totals dictionary and list of daily metric data
 * @memberof App/Api
 */

const getAllTimeline = (date_min, date_max) =>
  Api.post(
    `${Api.HELIUM_API}/analytics/apps_timeline`,
    {
      date_min,
      date_max,
    },
    { token: true, method: 'POST' }
  );

/**
 * @function update
 * @description saves app details to API
 * @param {UI_APP} app - an app object from UI
 * @returns {APP} - updated app details
 * @memberof App/Api
 */

const update = (app) =>
  Api.post(`${Api.HELIUM_API}/apps/${app.id}`, _toApiApp(app), { token: true });

/**
 * @function saveCredentials
 * @description saves app credentials to API
 * @param {APP_CREDENTIALS} app - an app credentials object (with network_states)
 * @returns {APP_CREDENTIALS} - updated app details
 * @memberof App/Api
 */

const saveCredentials = (appId, credentials) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/credentials`, Helpers.toApiCredentials(credentials), {
    token: true,
  });

/**
 * @function deleteCredential
 * @description Api request to delete an app's credential
 * @param {string} appId - app id
 * @param {NETWORK_STATE} networkState - network name and type
 * @returns {NETWORK_STATE[]} - updated network states
 * @memberof App/Api
 */

const deleteCredential = (appId, networkState) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/credentials`, networkState, { token: true });

const AppApi = {
  getOne,
  getAll,
  getAllTimeline,
  update,
  saveCredentials,
  deleteCredential,
};

export default AppApi;
