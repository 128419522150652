import Constants from '../../../network/constants';

const networkKeywordFilters = Object.keys(Constants.NETWORK_LABELS).map((n) => `helium_${n}`);

const formatKeyword = (keyword) => {
  const updates = {
    Adcolony: 'AdColony',
    admob: 'AdMob',
    Admob: 'AdMob',
    Amazon_aps: 'Amazon APS',
    Facebook: 'Meta Audience Network',
    Google_googlebidding: 'Google Bidding',
    Hyprmx: 'HyprMX',
    Inmobi: 'InMobi',
    Ironsource: 'ironSource',
    Unity: 'Unity Ads',
    'SDK Version': 'Helium SDK Version',
  };
  Object.keys(updates).forEach((key) => {
    if (keyword && keyword.includes(key)) {
      keyword = keyword.replace(key, updates[key]);
    }
  });
  return keyword;
};

const keywordsToOptions = (keywordMap) => {
  if (keywordMap === undefined) return [];
  const keywordList = [];
  for (const [keyword, details] of Object.entries(keywordMap)) {
    const keywordDetails = details.text ? details.text : keyword;
    const updatedText = formatKeyword(keywordDetails);
    keywordList.push({
      key: keyword,
      text: updatedText,
      value: keyword,
    });
  }
  return keywordList;
};

const createKeywordDisplayMap = (reservedKeywords) => {
  const reserveKeywordDisplayMap = {};
  for (const key in reservedKeywords) {
    const reservedKeywordText = reservedKeywords[key].text;
    reserveKeywordDisplayMap[key] = formatKeyword(reservedKeywordText);
  }
  return reserveKeywordDisplayMap;
};

const filterKeywords = (keywords, network_id) => {
  // Use a set to easily remove the current network from the blocklist
  const notAllowedSet = new Set(networkKeywordFilters);
  notAllowedSet.delete(`helium_${network_id}`);

  // Convert to array to utilize find method in following step
  const notAllowed = Array.from(notAllowedSet);

  return Object.keys(keywords)
    .filter((key) => !notAllowed.find((notAllowedSubstring) => key.includes(notAllowedSubstring)))
    .reduce((obj, key) => {
      obj[key] = keywords[key];
      return obj;
    }, {});
};

const getRule = (id, lineItem, isRuleNegated) => {
  let rule = {};
  if (lineItem && lineItem.targeting_rules) {
    rule = lineItem.targeting_rules.find((rule) => rule.uuid === id);
  }
  rule.is_negated = isRuleNegated;
  return rule;
};

const getKeywordType = (keyword, keywordOptions) => {
  if (keywordOptions !== undefined) {
    return keywordOptions[keyword] && keywordOptions[keyword].keyword_type;
  }
};

const processPredicate = (lineItemState) => {
  const {
    addingPredicateId,
    editingPredicateId,
    selectedRuleId,
    inputType,
    keyword,
    operation,
    value,
  } = lineItemState;
  const lineItemId = lineItemState.id;
  const addId = addingPredicateId || 'newRule';
  const ruleId = inputType === 'add' ? addId : selectedRuleId;
  const selectedKeyword = lineItemState.keywords[keyword];
  const isReservedKeyword = selectedKeyword.uuid === undefined;

  const baseKeyword = {
    is_reserved: isReservedKeyword,
    keyword_type: selectedKeyword.keyword_type,
    key: keyword,
  };

  if (!isReservedKeyword) {
    baseKeyword.uuid = selectedKeyword.uuid;
  }

  const predicate = {
    keyword: baseKeyword,
    operation: operation,
    value: value.trim(),
  };

  const rule = {
    predicates: [predicate],
  };

  return {
    lineItemId,
    predicate,
    predicateId: editingPredicateId,
    submitType: inputType,
    rule,
    ruleId,
  };
};

const Processors = {
  createKeywordDisplayMap,
  formatKeyword,
  getKeywordType,
  getRule,
  keywordsToOptions,
  processPredicate,
  filterKeywords,
};

export default Processors;
