import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import MetricsTooltip from './MetricsTooltip';
import MetricsConstants from '../constants';
import Helpers from '../helpers';

const MetricsLineGraph = ({ ...props }) => {
  const { data, selectedView, pageView, placementNames, isAdvancedView, colorMap } = props;
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dt" tickFormatter={Helpers.xAxisFormatter} />
        <YAxis
          tickCount={5}
          domain={[0, 1]}
          tickFormatter={(tick) => Helpers.yAxisFormatter({ view: selectedView, tick })}
        />
        <Tooltip
          content={
            <MetricsTooltip
              selectedView={selectedView}
              pageView={pageView}
              colorMap={colorMap}
              isAdvanced={isAdvancedView}
            />
          }
          wrapperStyle={{ outline: 'none' }}
        />
        {pageView === 'appOverview' && (
          <Line
            type="monotone"
            dataKey={selectedView}
            stroke={MetricsConstants.COMMON_COLORS.basic}
            activeDot={{ r: 8 }}
          />
        )}
        {(pageView === 'placementOverview' || pageView === 'allApps') && !isAdvancedView && (
          <Line
            type="monotone"
            dataKey={`totals.${selectedView}`}
            stroke={MetricsConstants.COMMON_COLORS.basic}
            activeDot={{ r: 8 }}
          />
        )}
        {pageView === 'placementOverview' &&
          isAdvancedView &&
          placementNames.map((placement) => {
            return (
              <Line
                type="monotone"
                dataKey={`placement_metrics.${placement}.${selectedView}`}
                stroke={colorMap[placement]}
                name={placement}
                dot={false}
              />
            );
          })}

        {pageView === 'singlePlacement' && (
          <Line
            type="monotone"
            dataKey={`bidding_${selectedView}`}
            stroke={MetricsConstants.COMMON_COLORS.bidding}
            dot={false}
          />
        )}
        {pageView === 'singlePlacement' && (
          <Line
            type="monotone"
            dataKey={`waterfall_${selectedView}`}
            stroke={MetricsConstants.COMMON_COLORS.waterfall}
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MetricsLineGraph;
