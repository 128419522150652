import React, { Component } from 'react';
import { Form, Header } from 'semantic-ui-react';

import Placement from 'apps/placement';

class CreativeTypes extends Component {
  getOptions = (placement) => {
    if (placement.type === 'rewarded' || placement.type === 'rewarded_interstitial') {
      return Placement.Constants.REWARDED_CREATIVE_TYPES;
    } else if (placement.type === 'interstitial') {
      return Placement.Constants.INTERSTITIAL_CREATIVE_TYPES;
    } else {
      return Placement.Constants.BANNER_CREATIVE_TYPES;
    }
  };

  render() {
    const { set, placement } = this.props;
    const options = this.getOptions(placement);
    const disabled = placement.allow_all_ct;
    const isBanner =
      placement && (placement.type === 'banner' || placement.type === 'adaptive_banner');

    return (
      <div>
        <Header as="h4" content="Creative Types" />
        <Form>
          <Form.Group inline={false}>
            <Form.Checkbox
              checked={isBanner ? false : placement.allow_all_ct}
              label="Allow All Creative Types"
              name="allow_all_ct"
              disabled={isBanner}
              onClick={set}
              toggle
              value={isBanner ? false : !placement.allow_all_ct}
            />
          </Form.Group>
          <Form.Group width="equal">
            <ul className="creative-types-checkbox-group">
              {options &&
                options.map((o) => (
                  <Form.Checkbox
                    key={o.key}
                    checked={placement[o.key] || disabled}
                    label={o.text}
                    disabled={disabled || isBanner}
                    name={o.key}
                    onChange={set}
                    className="pb-10"
                    value={!placement[o.key]}
                  />
                ))}
            </ul>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default CreativeTypes;
