import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const TestAccountWarning = ({ color = 'red' }) => (
  <span data-testid="test-account-warning">
    <Popup
      trigger={<Icon name="warning sign" color={color} />}
      content={'Not supported on Production, Test Accounts only'}
      position="top center"
      wide
    />
  </span>
);

export default TestAccountWarning;
