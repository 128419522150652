import Actions from 'core/framework/actions';
import { LOADED, LOADING, UNLOADED } from 'core/app/constants/readyStates';

import Helpers from './helpers';
import PlacementActions from './actions';
import Network from '../network';
import AppHelpers from '../app/helpers';
import Constants from './constants';

const updateState = (state, app) => {
  return {
    ...state,
    entities: AppHelpers.addOrReplaceApp(state.entities, app),
    placementChanged: true,
  };
};

const createPlacement = (state, { heliumApp, type }) => {
  const app = AppHelpers.getApp(state.entities, heliumApp.id);
  const placement = Helpers.create(app, type);
  return { ...state, newPlacement: placement };
};

const discardPlacement = (state) => ({
  ...state,
  newPlacement: false,
});

const removeFloor = (state, { appId, placementId, country }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  placement.country_floors = placement.country_floors.filter(
    (floor) => floor.country_code !== country
  );
  return updateState(state, app);
};

const enableBannerRefresh = (state, { appId, placementId }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  if (
    (placement.type === 'banner' || placement.type === 'adaptive_banner') &&
    !placement.auto_refresh_rate
  ) {
    placement.auto_refresh_rate = Constants.BANNER_DEFAULT_REFRESH_RATE;
  }
  return updateState(state, app);
};

const disableBannerRefresh = (state, { appId, placementId }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  if (
    (placement.type === 'banner' || placement.type === 'adaptive_banner') &&
    placement.auto_refresh_rate !== 0
  ) {
    placement.auto_refresh_rate = 0;
  }
  return updateState(state, app);
};

const setBannerRefresh = (state, { appId, placementId, value }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  if (
    (placement.type === 'banner' || placement.type === 'adaptive_banner') &&
    placement.auto_refresh_rate !== 0
  ) {
    placement.auto_refresh_rate = value;
  }
  return updateState(state, app);
};

const setQueueSize = (state, { appId, placementId, value }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  placement.queue_size = value;
  return updateState(state, app);
};

const activateFloor = (state, { appId, placementId, country, floor }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  placement.country_floors = placement.country_floors || [];
  placement.country_floors = placement.country_floors
    .reduce(
      (acc, cur) => (country !== cur.country_code ? [...acc, cur] : acc),
      [{ country_code: country, floor: floor }]
    )
    .sort((a, b) => (a.country_code > b.country_code ? 1 : -1));
  return updateState(state, app);
};

const setPlacements = (state, { appId, response }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const { placements } = response;
  if (app !== undefined) {
    app.placements = Helpers.toUIPlacements(placements);
  }
  return {
    ...state,
    entities: AppHelpers.addOrReplaceApp(state.entities, app),
    readyState: LOADED,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
  };
};

const setPlacement = (state, { appId, response }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  if (app !== undefined) {
    app.placements = Helpers.toUIPlacements([response]);
  }
  return {
    ...state,
    entities: AppHelpers.addOrReplaceApp(state.entities, app),
    readyState: LOADED,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
  };
};

const removeDeletedPlacement = (state, { appId, placementId }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  app.placements = app.placements.filter((p) => p.id !== placementId);
  return {
    ...state,
    entities: AppHelpers.addOrReplaceApp(state.entities, app),
    readyState: LOADED,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
  };
};

const setPlacementMetrics = (state, { response }) => {
  const { bidding, mediation, calculated_totals, graph_data } = response;
  return {
    ...state,
    readyState: LOADED,
    placementBiddingMetrics: bidding,
    placementMediationMetrics: mediation,
    placementCalculatedTotalsMetrics: calculated_totals,
    placementGraphDataMetrics: graph_data,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
    metricGraphData: graph_data,
    metricsCalculatedTotals: calculated_totals,
  };
};

const setBTestPlacementMetrics = (state, { response }) => {
  const { bidding, mediation, calculated_totals, graph_data } = response;
  return {
    ...state,
    readyState: LOADED,
    placementBTestBiddingMetrics: bidding,
    placementBTestMediationMetrics: mediation,
    placementBTestCalculatedTotalsMetrics: calculated_totals,
    placementBTestGraphDataMetrics: graph_data,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
    metricBTestGraphData: graph_data,
    metricsBTestCalculatedTotals: calculated_totals,
  };
};

const setPlacementOverviewMetrics = (state, { response }) => {
  const { data } = response;
  return {
    ...state,
    readyState: LOADED,
    placementOverviewMetrics: data,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
  };
};

const setAppOverviewTimelineMetrics = (state, { response }) => {
  const { data } = response;
  return {
    ...state,
    readyState: LOADED,
    appOverviewTimelineMetrics: data,
    placementChanged: false,
    placementsReadyState: LOADED,
    placementCredState: LOADED,
  };
};

const setCompanyPlacements = (state, { response }) => ({
  ...state,
  company: {
    ...state.company,
    placementsState: LOADED,
    placements: response.placements || [],
  },
});

const setCompanyPlacementsNames = (state, { response }) => {
  const placementNames =
    (response.placement_names &&
      response.placement_names.map((placementName) => ({
        name: placementName,
        value: placementName,
      }))) ||
    [];
  return {
    ...state,
    company: {
      ...state.company,
      placementsState: LOADED,
      placementNames,
    },
  };
};

const setField = (state, { appId, placementId, name, value }) => {
  let errors = [];
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  if (name === 'name') errors = Helpers.validatePlacementName(value, Helpers.getExistingNames(app));

  placement[name] = value;

  if (name === 'allow_all_ct' && value) Helpers.enableAllCT(placement);

  if (['type', 'interactive_ct', 'static_ct', 'video_ct'].indexOf(name) > -1)
    Helpers.shouldEnableAllCTFlag(placement);

  if (name === 'type') Helpers.updatePlacementType(placement, app);

  return updateState({ ...state, placementErrors: errors }, app);
};

// TODO: Add AdGroup To new placement !
const addAdgroup = (state, { appId, placementId, agId }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  if (Array.isArray(placement.adgroups)) {
    placement.adgroups.push(agId);
  } else {
    placement.adgroups = [agId];
  }
  return updateState(state, app);
};

const onABTestSuccess = (state, payload) => {
  const { appId, placementId, response } = payload;
  const { id } = response;
  const redirect = `/apps/${appId}/placements-split/${placementId}/${id}`;
  return {
    ...state,
    adgroupsState: UNLOADED,
    placementsReadyState: UNLOADED,
    redirectToAB: redirect,
  };
};

const setABCohort = (state, { appId, placementId, value }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const target = Helpers.get(app, placementId, state);

  const bothEnabled = (A) => A.proportion === 0.5;
  const onlyAEnabled = (A) => A.proportion === 1;
  const onlyBEnabled = (A) => A.proportion === 0;

  if (value === 0 && (bothEnabled(target) || onlyAEnabled(target))) {
    target.proportion = 0;
  } else if (value === 1 && onlyBEnabled(target)) {
    target.proportion = 1;
  }

  return updateState(state, app);
};

/********************************
 *    Placement Credentials
 ********************************/

const removePlacementCredential = (state, { appId, placementId, networkId }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  delete placement.credentials[networkId];
  return updateState(state, app);
};

const addPlacementCredential = (state, { appId, placementId, credentials }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  placement.credentials[credentials.id] = Network.Helpers.toApiCredentials(credentials);
  placement.credentials[credentials.id].active = true;
  return updateState(state, app);
};

const handleSavePlacementCredentials = (state, { appId, placementId, response }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  // Update placement credentials with the response
  if (response) {
    placement.credentials = response;
  }
  return updateState({ ...state, placementCredState: LOADED }, app);
};

const togglePlacementCredential = (state, { appId, placementId, networkId, value }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  placement.credentials[networkId].active = value;
  return updateState(state, app);
};

const updatePlacementBiddingLineItems = (state, payload) => {
  const { appId, placementId, response } = payload;
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);

  // for each crdentials, add in the targeting rules from credentials
  Object.keys(response).forEach((network_credential) => {
    if (response[network_credential]) {
      placement.credentials[network_credential] = response[network_credential];
    }
  });
  updateState(
    {
      ...state,
      placementsReadyState: LOADED,
      placementLineItemState: LOADED,
      placementsState: LOADED,
      placementCredState: LOADED,
    },
    app
  );
};

/*******************************
 *    Rewarded Callback
 *******************************/

const setRewardedCallback = (state, { appId, placementId, name, value }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const placement = Helpers.get(app, placementId, state);
  placement.rewarded_callback[name] = value;
  const errors = Helpers.validateRewardedCallback(placement);
  return updateState({ ...state, callbackErrors: errors }, app);
};

const apply = (state, type, payload) => {
  switch (type) {
    case Actions.started(PlacementActions.SAVE_PLACEMENT):
      return { ...state, readyState: LOADING, placementsReadyState: LOADING };

    case Actions.failure(PlacementActions.SAVE_PLACEMENT):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.SAVE_PLACEMENT):
      return { ...state, placementsReadyState: UNLOADED };

    case Actions.started(PlacementActions.FETCH_PLACEMENTS):
      return { ...state, readyState: LOADING, placementsReadyState: LOADING };

    case Actions.failure(PlacementActions.FETCH_PLACEMENTS):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.FETCH_PLACEMENTS):
      return setPlacements(state, payload);

    case Actions.started(PlacementActions.FETCH_PLACEMENT):
      return { ...state, readyState: LOADING, placementsReadyState: LOADING };

    case Actions.failure(PlacementActions.FETCH_PLACEMENT):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.FETCH_PLACEMENT):
      return setPlacement(state, payload);

    case Actions.started(PlacementActions.FETCH_PLACEMENT_METRICS):
      return {
        ...state,
        readyState: LOADING,
        placementsReadyState: LOADING,
        placementBiddingMetrics: [],
        placementMediationMetrics: [],
      };

    case Actions.failure(PlacementActions.FETCH_PLACEMENT_METRICS):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.FETCH_PLACEMENT_METRICS):
      return setPlacementMetrics(state, payload);

    case Actions.started(PlacementActions.FETCH_B_TEST_PLACEMENT_METRICS):
      return {
        ...state,
        readyState: LOADING,
        placementsReadyState: LOADING,
        placementBTestBiddingMetrics: [],
        placementBTestMediationMetrics: [],
      };

    case Actions.failure(PlacementActions.FETCH_B_TEST_PLACEMENT_METRICS):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.FETCH_B_TEST_PLACEMENT_METRICS):
      return setBTestPlacementMetrics(state, payload);

    case Actions.started(PlacementActions.FETCH_PLACEMENT_OVERVIEW_METRICS):
      return {
        ...state,
        readyState: LOADING,
        placementsReadyState: LOADING,
        placementOverviewMetrics: [],
      };

    case Actions.failure(PlacementActions.FETCH_PLACEMENT_OVERVIEW_METRICS):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.FETCH_PLACEMENT_OVERVIEW_METRICS):
      return setPlacementOverviewMetrics(state, payload);

    case Actions.started(PlacementActions.FETCH_APP_OVERVIEW_TIMELINE_METRICS):
      return {
        ...state,
        readyState: LOADING,
        placementsReadyState: LOADING,
        appOverviewTimelineMetrics: {},
      };

    case Actions.failure(PlacementActions.FETCH_APP_OVERVIEW_TIMELINE_METRICS):
      return { ...state, readyState: LOADED, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.FETCH_APP_OVERVIEW_TIMELINE_METRICS):
      return setAppOverviewTimelineMetrics(state, payload);

    case Actions.started(PlacementActions.FETCH_COMPANY_PLACEMENTS):
      return { ...state, placementsState: LOADING };

    case Actions.failure(PlacementActions.FETCH_COMPANY_PLACEMENTS):
      return { ...state, placementsState: LOADED };

    case Actions.success(PlacementActions.FETCH_COMPANY_PLACEMENTS):
      return setCompanyPlacements(state, payload);

    case Actions.started(PlacementActions.FETCH_COMPANY_PLACEMENT_NAMES):
      return { ...state, placementsState: LOADING };

    case Actions.failure(PlacementActions.FETCH_COMPANY_PLACEMENT_NAMES):
      return { ...state, placementsState: LOADED };

    case Actions.success(PlacementActions.FETCH_COMPANY_PLACEMENT_NAMES):
      return setCompanyPlacementsNames(state, payload);

    case Actions.started(PlacementActions.SAVE_NEW_PLACEMENT):
      return { ...state, placementsReadyState: LOADING, newPlacementId: false };

    case Actions.failure(PlacementActions.SAVE_NEW_PLACEMENT):
      return { ...state, placementsReadyState: LOADED };

    case Actions.success(PlacementActions.SAVE_NEW_PLACEMENT):
      return { ...state, placementsReadyState: UNLOADED, newPlacementId: payload.response.id };

    case Actions.started(PlacementActions.REMOVE_PLACEMENT):
      return { ...state, placementsReadyState: LOADING };

    case Actions.failure(PlacementActions.REMOVE_PLACEMENT):
      return { ...state, placementsReadyState: LOADED };

    case Actions.started(PlacementActions.FETCH_PLACEMENT_BIDDING_LINE_ITEMS):
      return { ...state, placementLineItemState: LOADING };

    case Actions.failure(PlacementActions.FETCH_PLACEMENT_BIDDING_LINE_ITEMS):
      return { ...state, placementsReadyState: LOADED, placementLineItemState: LOADED };

    case Actions.success(PlacementActions.FETCH_PLACEMENT_BIDDING_LINE_ITEMS):
      return updatePlacementBiddingLineItems(state, payload);

    case Actions.success(PlacementActions.REMOVE_PLACEMENT):
      return removeDeletedPlacement(state, payload);

    case PlacementActions.SET_PLACEMENT_FIELD:
      return setField({ ...state, madeChanges: true }, payload);

    case PlacementActions.SET_FLOOR:
      return activateFloor({ ...state, madeChanges: true }, payload);

    case PlacementActions.REMOVE_FLOOR:
      return removeFloor({ ...state, madeChanges: true }, payload);

    case PlacementActions.CREATE_PLACEMENT:
      return createPlacement(state, payload);

    case PlacementActions.DISCARD_PLACEMENT:
      return discardPlacement(state, payload);

    case PlacementActions.ADD_PLACEMENT_CREDENTIAL:
      return addPlacementCredential(state, payload);

    case PlacementActions.TOGGLE_PLACEMENT_CREDENTIAL:
      return togglePlacementCredential(state, payload);

    case PlacementActions.REMOVE_PLACEMENT_CREDENTIAL:
      return removePlacementCredential(state, payload);

    case PlacementActions.ADD_ADGROUP:
      return addAdgroup(state, payload);

    case PlacementActions.ENABLE_BANNER_REFRESH:
      return enableBannerRefresh(state, payload);

    case PlacementActions.DISABLE_BANNER_REFRESH:
      return disableBannerRefresh(state, payload);

    case PlacementActions.SET_BANNER_REFRESH:
      return setBannerRefresh(state, payload);

    case PlacementActions.SET_PLACEMENT_QUEUE_SIZE:
      return setQueueSize(state, payload);

    case PlacementActions.SET_REWARDED_CALLBACK:
      return setRewardedCallback(state, payload);

    case Actions.success(PlacementActions.SAVE_DELETED_PLACEMENT_CREDENTIALS):
    case Actions.failure(PlacementActions.SAVE_DELETED_PLACEMENT_CREDENTIALS):
    case Actions.failure(PlacementActions.SAVE_PLACEMENT_CREDENTIALS):
    case Actions.success(PlacementActions.SAVE_PLACEMENT_CREDENTIALS):
      return handleSavePlacementCredentials(state, payload);

    case Actions.started(PlacementActions.SAVE_DELETED_PLACEMENT_CREDENTIALS):
    case Actions.started(PlacementActions.SAVE_PLACEMENT_CREDENTIALS):
      return { ...state, placementCredState: LOADING };

    case Actions.started(PlacementActions.CREATE_AB_TEST):
      return { ...state, placementsReadyState: LOADING };

    case Actions.success(PlacementActions.CREATE_AB_TEST):
      return onABTestSuccess(state, payload);

    case Actions.failure(PlacementActions.CREATE_AB_TEST):
      return { ...state, placementsReadyState: LOADED };

    case PlacementActions.CLEAR_REDIRECT:
      return { ...state, redirectToAB: false };

    case PlacementActions.SET_LOCAL_PROPORTION:
      return setABCohort(state, payload);

    default:
      return state;
  }
};

const PlacementReducers = {
  apply,
};

export default PlacementReducers;
