import React, { Component } from 'react';
import { Popup, Icon, Form } from 'semantic-ui-react';
import CommonHelpers from '../../common/helpers';

// views
import { SectionHeader } from 'core/app/views';
import Countries from 'apps/app/views/Countries';

// models
import Placement from 'apps/placement';

class Bidfloors extends Component {
  render() {
    const { abtest, set, setFloor, removeFloor, appId, placement } = this.props;

    return (
      <Form>
        <SectionHeader
          content="eCPM Floor"
          as="h4"
          tooltip={Placement.Constants.TOOLTIPS['floor_active']}
        />
        <Form.Group inline widths="equal">
          <Form.Checkbox
            checked={placement.floor_active}
            label="Enabled"
            name="floor_active"
            onClick={set}
            value={!placement.floor_active}
          />
          <Form.Input
            disabled={!placement.floor_active}
            fluid
            placeholder="0.99"
            iconPosition="left"
            type="number"
            min="0"
            step="0.01"
            icon="dollar"
            label="Global Floor"
            name="global_floor"
            onChange={set}
            value={placement.global_floor}
            className="global-floor"
            onWheel={CommonHelpers.numberInputOnWheelPreventChange}
          />
          <Popup
            trigger={<Icon name="question circle" className="tooltip" />}
            content={Placement.Constants.TOOLTIPS['global_ecpm']}
            position="top center"
            wide
          />
        </Form.Group>
        <div style={{ display: placement.floor_active ? 'block' : 'none' }}>
          <Countries
            abtest={abtest}
            add={(c, v) => setFloor(appId, placement.id, c, v)}
            remove={(c) => removeFloor(appId, placement.id, c)}
            noContentMsg="No country floors set"
            existing={placement.country_floors}
            title="Country Floors"
            type="ecpm"
          />
        </div>
      </Form>
    );
  }
}

export default Bidfloors;
