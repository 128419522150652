import App from './app';
import Placement from './placement';
import Chartboost from './chartboost';
import Network from './network';
import AdGroup from './adgroup';
import LineItem from './lineitem';
import { UNLOADED } from 'core/app/constants/readyStates';

const initState = {
  ...App.Reducer.initState(),
  network: Network.Reducer.initState(),
  chartboost: Chartboost.Reducer.initState(),
  company: {
    placementsState: UNLOADED,
    lineItemsState: UNLOADED,
    placements: [],
    lineItems: []
  }
};

export default (state = initState, { payload, type }) => {
  state = App.Reducer.apply(state, type, payload);
  state = Chartboost.Reducer.apply(state, type, payload);
  state = Network.Reducer.apply(state, type, payload);
  state = Placement.Reducer.apply(state, type, payload);
  state = AdGroup.Reducer.apply(state, type, payload);
  state = LineItem.Reducer.apply(state, type, payload);

  return state;
};
