import AppActions from 'core/app/actions';

const initState = {
  dimensions: [window.innerHeight, window.innerWidth],
  messages: [],
  navbarVisible: false,
  online: navigator.onLine,
  bundleLoading: false
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AppActions.APP_ONLINE:
      return { ...state, online: payload.status };

    case AppActions.APP_RESIZE:
      return { ...state, dimensions: payload.dimensions };

    case AppActions.TOGGLE_NAVBAR:
      return {
        ...state,
        navbarVisible: payload.visible !== undefined ? payload.visible : !state.navbarVisible
      };

    case AppActions.SHOW_MESSAGE:
      return { ...state, messages: [...state.messages, payload.message] };

    case AppActions.DISMISS_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(msg => msg.index !== payload.index)
      };

    case AppActions.REGISTER_BUNDLE:
      return {
        ...state,
        bundleLoading: false
      };

    case AppActions.REGISTER_BUNDLE_STARTED:
      return {
        ...state,
        bundleLoading: true
      };

    default:
      return state;
  }
};

export default reducer;
