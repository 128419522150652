import React from 'react';
import { Message, Icon, Segment } from 'semantic-ui-react';

export const Unauthorized = () => (
  <Segment basic>
    <Message icon negative>
      <Icon name="user x" />
      <Message.Content>
        <Message.Header>Unauthorized User</Message.Header>
        It looks like you are not authorized to use the Helium dashboard.
        <a href="//dashboard.chartboost.com"> Back to main dashboard </a>
      </Message.Content>
    </Message>
  </Segment>
);

export default Unauthorized;
