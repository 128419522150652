import React, { useState } from 'react';
import { getAuthToken } from 'core/framework/localStorage';

const GoogleRevokeCredentials = (props) => {
  const { appId, network } = props;
  const [state, setState] = useState({ message: '' });

  const handleClick = () => {
    const BODY = {
      app_id: appId,
    };
    const revokeRoute = network === 'admob' ? 'google-login-revoke' : 'google-bidding-login-revoke';

    let POST_ROUTE = `${process.env.REACT_APP_HELIUM_API}/helium/${revokeRoute}`;
    // if (window.location.host === 'helium.chartboost.com') {
    //   POST_ROUTE = `https://helium-api.chartboost.com/helium/${revokeRoute}`;
    // }
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
      },
      body: JSON.stringify(BODY),
    };

    fetch(POST_ROUTE, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || (data && data.error) || response.status;
          setState({ message: error.toString() });
          return Promise.reject(error);
        }
        setState({ message: data.message });
        setTimeout(() => (window.location = `/apps/${appId}/`), 2000);
      })
      .catch((error) => {
        setState({ message: error.toString() });
        console.error('There was an error!', error);
        setTimeout(() => (window.location = `/apps/${appId}/`), 2000);
      });
  };

  return (
    <div className="google-revoke-container">
      <a onClick={handleClick}>Revoke Google Login</a>
      <div>
        <span>{state.message}</span>
      </div>
    </div>
  );
};

export default GoogleRevokeCredentials;
