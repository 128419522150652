import React from 'react';
import { Header, Checkbox } from 'semantic-ui-react';

const AskPriceEnabled = ({ isAskPriceEnabled, isShown, setAskPriceEnabled }) => {
  // SG-1655 Only display ask price toggle if company is Zynga
  if (!isShown) return <></>;

  return (
    <div className="ask-price-container">
      <Header as="h4" content="Ask Price " />
      <Checkbox
        label={isAskPriceEnabled ? 'Enabled' : 'Disabled'}
        checked={isAskPriceEnabled}
        onClick={() => setAskPriceEnabled(!isAskPriceEnabled)}
        toggle
      />
    </div>
  );
};

export default AskPriceEnabled;
