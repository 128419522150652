import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import Form from './Form';
import Credential from './Credential';
import { EmptyRow } from 'core/app/views';

import App from 'apps/app';
import Placement from 'apps/placement';
import AppHelpers from '../../app/helpers';
import Helpers from '../helpers';
import Store from '../store';
import UserStore from 'core/user/store';

const defaultState = {
  instance: {
    id: '',
    active: true,
    fields: [],
  },
};

class Credentials extends Component {
  constructor(props) {
    super(props);
    this.state = { ...defaultState };
  }

  componentDidUpdate(prevProps) {
    const previousAppId = prevProps?.appId;
    const currentAppId = this.props?.appId;
    if (previousAppId !== currentAppId) {
      this.setState({ ...defaultState });
    }
  }

  Items = (existing, appId) => {
    if (existing.length === 0) {
      return <EmptyRow message="No App Credentials" colSpan="5" />;
    }
    return existing.map((entry) => {
      if (entry.id === 'admob' || entry.id === 'google_googlebidding') {
        entry = Helpers.hideGoogleSSO(entry);
      }
      return (
        <Credential
          appId={appId}
          key={String(entry.id)}
          removeSource={this.removeInstance.bind(this, entry.id)}
          setSource={this.toggleInstance.bind(this, entry.id)}
          onlySource={existing.length === 1}
          source={entry}
          abtest={this.props.abtest === true}
          editCredentials={this.editCredentials.bind(this)}
          type={this.props.type}
          networkType={this.props.networkType}
          placement={this.props.placement}
        />
      );
    });
  };

  selectNetwork(e, event) {
    const { app, type, networkSchemas } = this.props;
    const networkId = event.value;
    const schema = networkSchemas[networkId];
    this.setState({
      instance: {
        id: networkId,
        fields:
          type === 'app_credentials'
            ? Helpers.createAppCredentials(app, networkId, schema)
            : Helpers.createPlacementCredentials(app, networkId, schema),
      },
    });
  }

  addInstance() {
    const network = this.state.instance.id;
    const { create, app, placement = null, networkType, networkSchemas } = this.props;
    if (placement) {
      let placementFields = networkSchemas[network].placement_credentials || [];
      let values = this.state.instance.fields.filter((f) =>
        placementFields.find((pf) => pf.name === f.name)
      );
      create(app.id, placement.id, { id: network, fields: values });
    } else {
      create(app.id, this.state.instance, networkType);
    }
    this.setState(defaultState);
  }

  editCredentials(source) {
    const network = source.id;
    const {
      app,
      create,
      editAppCredentials,
      editPlacementCredentials,
      networkSchemas,
      networkType,
      placement = null,
    } = this.props;
    if (placement) {
      let placementFields = networkSchemas[network].placement_credentials || [];
      let values = source.fields.filter((f) => placementFields.find((pf) => pf.name === f.name));
      delete values[0].label;
      values[0].type = 'string';
      // Update credentials for new placements
      if (placement.id === '__new__') {
        create(app.id, placement.id, { id: network, fields: values });
      } else {
        editPlacementCredentials(app.id, placement.id, { id: network, fields: values });
      }
    }
    // TODO: we should check what changes occur within app/placement/both credentials and make the appropiate api calls
    editAppCredentials(app.id, source, networkType);
  }

  removeInstance(networkId) {
    const { app, placement = null, networkType } = this.props;
    if (placement) {
      this.props.delete(app.id, placement.id, networkId);
    } else {
      this.props.delete(app.id, networkId, networkType);
    }
  }

  editInstanceField(e, { name, value }) {
    const fields = this.state.instance.fields;
    const field = fields.find((f) => f.name === name);
    field.value = value;
    this.setState({
      instance: {
        ...this.state.instance,
        fields: fields,
      },
    });
  }

  toggleInstance(networkId, e, { value }) {
    const { app, placement = null, edit, networkType } = this.props;
    if (placement) {
      edit(app.id, placement.id, networkId, value === 'true');
    } else {
      edit(app.id, networkId, networkType, value === 'true');
    }
  }

  isValid() {
    const { fields, id } = this.state.instance;
    // no selected networks
    if (fields.length === 0) return false;
    // not all fields are filled
    for (const f of fields) {
      // facebook system user access token is optional; only app id is required
      if (id === 'facebook' && f.name === 'applicationid' && f.value) {
        return true;
      }
      if (f.name == 'price_points') {
        // check for amazon aps csv line items
        if (f?.value === undefined || f?.value?.length === 0) {
          return false;
        }
      } else if (f?.value === undefined || f?.value.trim() === '') return false;
    }
    // all fields are filled
    return true;
  }

  toUICredentials(creds) {
    let list = [];
    const { type, app, networkType } = this.props;
    for (const networkId of Object.keys(creds)) {
      let isActive = false;
      let networkState = AppHelpers.getNetworkState(app, networkId, networkType);
      let fieldNames = Object.keys(creds[networkId]).filter((n) => n !== 'active');
      if (networkState) {
        isActive = type === 'app_credentials' ? networkState.active : creds[networkId].active;
        list.push({
          id: networkId,
          key: networkId,
          name: Helpers.getName(networkId),
          active: isActive,
          fields: fieldNames.map((f) => ({
            label: Helpers.getFieldName(networkId, f),
            value: creds[networkId][f],
            name: f,
          })),
        });
      }
    }
    return list;
  }

  getExistingCredentials() {
    const { type, app, placement } = this.props;
    const appCreds = AppHelpers.getAppCredentials(app);
    const plcCreds = placement && placement.credentials ? placement.credentials : {};
    let creds = {};
    if (type === 'placement_credentials') {
      // combine app and placement credentials values
      _.forIn(plcCreds, (values, network) => {
        creds[network] = {
          ...appCreds[network],
          ...values,
        };
      });
    } else {
      creds = appCreds;
    }
    return this.toUICredentials(creds);
  }

  getActionText = (type) =>
    type === 'app_credentials' ? 'add your app credentials from' : 'add your placement info from';

  render() {
    const {
      abtest,
      appId,
      disabled,
      options,
      networkType,
      type,
      placement,
      isBetaTestAccount,
      isTestAccount,
    } = this.props;
    const existing = this.getExistingCredentials();
    const selected = this.state.instance;

    const formProps = {
      abtest,
      action: this.getActionText(type),
      add: this.addInstance.bind(this),
      appId,
      appPlatform: this.props?.app?.platform,
      disabled: disabled,
      edit: this.editInstanceField.bind(this),
      fields: selected.fields || [],
      isBetaTestAccount,
      isTestAccount,
      isValid: this.isValid(),
      network: selected.id,
      networkType,
      options,
      placement,
      select: this.selectNetwork.bind(this),
      type,
    };

    let credentials = existing ? this.Items(existing, appId) : [];

    return (
      <Table singleLine selectable structured unstackable fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="network-table-active">Active</Table.HeaderCell>
            <Table.HeaderCell className="network-table-network">Network</Table.HeaderCell>
            <Table.HeaderCell className="network-table-credentials">Credentials</Table.HeaderCell>
            <Table.HeaderCell className="network-table-edit">Edit</Table.HeaderCell>
            <Table.HeaderCell className="network-table-remove">Remove</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{credentials}</Table.Body>
        <Form {...formProps} />
      </Table>
    );
  }
}

const props = (state, ownProps) => {
  return {
    networkSchemas: Store.getSchema(state),
    networkType: ownProps.networkType || 'bidding',
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
    isTestAccount: UserStore.isTestAccount(state),
  };
};

const dispatches = {
  ...App.Actions,
  editAppCredentials: App.Actions.editAppCredentials,
  editPlacementCredentials: Placement.Actions.editPlacementCredentials,
};

export default connect(props, dispatches)(Credentials);
