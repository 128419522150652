import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import logo from 'core/app/images/logo.png';

class SidebarLegacy extends Component {
  constructor(props) {
    super(props);

    this.menuProps = {
      vertical: true,
      vertical: true,
    };

    const appItems = (selectedApp) => [
      {
        key: 'apps',
        to: '/apps',
        name: 'Overview',
        regex: [
          /\/apps$/,
          // eslint-disable-next-line
          /\/apps\/[0-9a-zA-Z]+[^\/]$/,
        ],
      },
      {
        key: 'networks',
        to: `/apps/${selectedApp || 'unselected'}/networks`,
        name: 'Networks',
        regex: [/\/apps\/[0-9a-zA-Z]+\/networks$/],
      },
      {
        key: 'placements',
        to: `/apps/${selectedApp || 'unselected'}/placements`,
        name: 'Placements',
        regex: [/\/apps\/[0-9a-zA-Z]+\/placements.*$/, /\/apps\/[0-9a-zA-Z]+\/createPlacement.*$/],
      },
      {
        key: 'adgroups',
        to: `/apps/${selectedApp || 'unselected'}/adgroups`,
        name: 'Ad Groups',
        regex: [/\/apps\/[0-9a-zA-Z]+\/adgroups.*$/, /\/apps\/[0-9a-zA-Z]+\/createAdGroup.*$/],
      },
    ];

    const analyticsItems = [
      {
        key: 'performance',
        to: '/analytics/performance',
        name: 'Performance',
        regex: [/\/analytics\/performance.*$/],
      },
      {
        key: 'bidding',
        to: '/analytics/bidding',
        name: 'Bidding',
        regex: [/\/analytics\/bidding.*$/],
      },
      {
        key: 'reports',
        to: '/analytics/reports',
        name: 'Reports',
        regex: [/\/analytics\/reports.*$/],
      },
      {
        key: 'abtest',
        to: '/analytics/abtest',
        name: 'A/B Tests',
        regex: [/\/analytics\/abtest.*$/],
      },
    ];

    const settingsItems = [
      {
        key: 'general',
        to: '/settings',
        name: 'General Settings',
        regex: [/\/settings$/],
      },
      {
        key: 'adomain-settings',
        to: '/settings/adomain-settings',
        name: 'Adomain Settings',
        regex: [/\/settings\/adomain-settings$/],
      },
      {
        key: 'adomain-reports',
        to: '/settings/adomain-reports',
        name: 'Adomain Reports',
        regex: [/\/settings\/adomain-reports$/],
      },
      {
        key: 'lineitem-import',
        to: '/settings/lineitem-import',
        name: 'Import Line Items',
        regex: [/\/settings\/lineitem-import$/],
      },
      {
        key: 'keywords-management',
        to: '/settings/keywords-management',
        name: 'Keywords',
        regex: [/\/settings\/keywords-management$/],
      },
    ];

    const resourceItems = [
      {
        key: 'docs',
        to: 'https://docs.chartboost.com/en/mediation/get-started/',
        name: 'Documentation',
        isExternal: true,
      },
      {
        key: 'api',
        to: '/resources/api',
        name: 'API Explorer',
        regex: [/\/resources\/api.*$/],
      },
    ];

    const actionItems = [{ key: 'logout', name: 'Log out', action: this.props.logout }];

    this.topLevelItems = [
      {
        name: 'Apps',
        key: 'apps',
        icon: 'mobile alternate',
        children: appItems(this.props.selectedApp),
      },
      { name: 'Analytics', key: 'analytics', icon: 'chart bar', children: analyticsItems },
      { name: 'Tools', key: 'settings', icon: 'wrench', children: settingsItems },
      { name: 'Resources', key: 'resources', icon: 'list', children: resourceItems },
    ];

    this.userActions = [{ name: 'Actions', key: 'user', icon: 'user', children: actionItems }];
  }

  isActive = (link) => {
    const path = window.location.pathname;
    if (Array.isArray(link.regex)) {
      for (let reg of link.regex) {
        if (reg.test(path)) {
          return true;
        }
      }
    }
    return false;
  };

  renderChildren = (child) =>
    child.isExternal ? (
      <Menu.Item key={child.key} href={child.to} target="_blank">
        {child.name}
      </Menu.Item>
    ) : (
      <Menu.Item key={child.key} as={Link} to={child.to} active={this.isActive(child)}>
        {child.name}
      </Menu.Item>
    );

  renderActionChildren = (child) => (
    <Menu.Item key={child.key} onClick={child.action}>
      {child.name}
    </Menu.Item>
  );

  renderTopLevel = (item) => (
    <Menu.Item key={item.key}>
      <Menu.Header>
        {' '}
        <Icon name={item.icon} /> {item.name}{' '}
      </Menu.Header>
      {item.children.map(this.renderChildren)}
    </Menu.Item>
  );

  renderAction = (item) => (
    <Menu.Item key={item.key}>
      <Menu.Header>
        {' '}
        <Icon name={item.icon} /> {item.name}{' '}
      </Menu.Header>
      {item.children.map(this.renderActionChildren)}
    </Menu.Item>
  );

  backToChartboost = (e) => {
    window.location.href = '//dashboard.chartboost.com';
  };

  render = () => (
    <div className="SideColumn">
      <div className="Header">
        <div>
          <img src={logo} className="Header-logo" alt="logo" />
          <span className="Header-name">Helium</span>
        </div>
      </div>
      <Menu {...this.menuProps} className="Sidebar">
        {this.topLevelItems.map(this.renderTopLevel)}
        {this.userActions.map(this.renderAction)}
      </Menu>
    </div>
  );
}

export default SidebarLegacy;
