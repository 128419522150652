import Api from 'core/framework/api';
import Helpers from './helpers';

const getAll = (appId) =>
  Api.get(`${Api.HELIUM_API}/apps/${appId}/placements`, null, { token: true });

const getCompanyPlacements = () => Api.get(`${Api.HELIUM_API}/placements`, null, { token: true });

const getCompanyPlacementNames = () =>
  Api.get(`${Api.HELIUM_API}/placement-names`, null, { token: true });

const getOne = (appId, placementId) =>
  Api.get(`${Api.HELIUM_API}/apps/${appId}/placements/${placementId}`, null, { token: true });

const save = (adPlacement, appId) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/placements`, Helpers.prepareAdPlacement(adPlacement), {
    token: true,
    method: 'POST',
  });

const update = (adPlacement, appId) =>
  Api.post(
    `${Api.HELIUM_API}/apps/${appId}/placements/${adPlacement.id}`,
    Helpers.prepareAdPlacement(adPlacement),
    { token: true, method: 'POST' }
  );

const savePlacementCredentials = (appId, placementId, credentials) =>
  Api.post(
    `${Api.HELIUM_API}/apps/${appId}/placements/${placementId}/credentials`,
    Helpers.toApiCredentials(credentials),
    {
      token: true,
      method: 'POST',
    }
  );

const deletePlacementCredentials = (appId, placementId, credentials) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/placements/${placementId}/credentials`, credentials, {
    token: true,
    method: 'POST',
  });

const _delete = (appId, placementId) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/placements/${placementId}`, null, { token: true });

const createABTest = (appId, placementId) =>
  Api.post(
    `${Api.HELIUM_API}/apps/${appId}/placements/${placementId}/abtest`,
    {},
    { token: true, method: 'POST' }
  );

const getPlacementMetrics = (appId, placementName, date_min, date_max) =>
  Api.post(
    `${Api.HELIUM_API}/analytics/placement_network_timeline`,
    {
      filters: {
        app_id: appId,
        placement_name: placementName,
      },
      date_min,
      date_max,
    },
    { token: true, method: 'POST' }
  );

const getBTestPlacementMetrics = (appId, placementName, date_min, date_max) =>
  Api.post(
    `${Api.HELIUM_API}/analytics/placement_network_timeline`,
    {
      filters: {
        app_id: appId,
        placement_name: placementName,
      },
      date_min,
      date_max,
    },
    { token: true, method: 'POST' }
  );

const getPlacementOverviewMetrics = (appId, date_min, date_max) =>
  Api.post(
    `${Api.HELIUM_API}/analytics/placements_overview_timeline`,
    {
      filters: {
        app_id: appId,
      },
      date_min,
      date_max,
    },
    { token: true, method: 'POST' }
  );

const getAppOverviewTimelineMetrics = (appId, date_min, date_max) =>
  Api.post(
    `${Api.HELIUM_API}/analytics/app_overview_timeline`,
    {
      filters: {
        app_id: appId,
      },
      date_min,
      date_max,
    },
    { token: true, method: 'POST' }
  );

const getPlacementBiddingLineItems = (appId, placementId) =>
  Api.get(`${Api.HELIUM_API}/apps/${appId}/placements/${placementId}/credentials`, null, {
    token: true,
  });

const PlacementApi = {
  getAll,
  getOne,
  save,
  update,
  savePlacementCredentials,
  deletePlacementCredentials,
  _delete,
  getCompanyPlacements,
  getCompanyPlacementNames,
  createABTest,
  getPlacementMetrics,
  getBTestPlacementMetrics,
  getPlacementOverviewMetrics,
  getAppOverviewTimelineMetrics,
  getPlacementBiddingLineItems,
};

export default PlacementApi;
