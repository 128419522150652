import Api from 'core/framework/api';

export const getBlockedAdomains = () =>
  Api.get(`${Api.HELIUM_API}/blocked-adomains`, null, { token: true });

export const updateDomainBlocking = (appId, domains) =>
  Api.post(
    `${Api.HELIUM_API}/apps/${appId}/blocked-adomains`,
    { adomains: domains },
    { token: true }
  );

export const removeDomainBlocking = appId =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/blocked-adomains`, null, { token: true });

export const getAdomainReports = () =>
  Api.get(`${Api.HELIUM_API}/reports/adomain`, null, { token: true });

export const postAdomainReport = report =>
  Api.post(`${Api.HELIUM_API}/reports/adomain`, report, { token: true, method: 'POST' });

export const deleteAdomainReport = reportId =>
  Api.del(`${Api.HELIUM_API}/reports/${reportId}`, null, { token: true, method: 'DELETE' });

export const getCompany = () => Api.get(`${Api.HELIUM_API}/company`, null, { token: true });

export const resetRewardedSecret = () =>
  Api.post(`${Api.HELIUM_API}/company/regenerate-rewarded-secret`, null, {
    token: true,
    method: 'POST'
  });

export const getReservedKeywords = () =>
  Api.get(`${Api.HELIUM_API}/public/reserved-keywords`, null, { token: true });

export const getCustomKeywords = () =>
  Api.get(`${Api.HELIUM_API}/custom-keywords`, null, { token: true });

export const postKeyword = keyword =>
  Api.post(`${Api.HELIUM_API}/keywords`, keyword, { token: true, method: 'POST' });

export const delKeyword = keywordId =>
  Api.del(`${Api.HELIUM_API}/keywords/${keywordId}`, null, { token: true, method: 'DELETE' });

export const putKeyword = (keywordId, key) =>
  Api.put(`${Api.HELIUM_API}/keywords/${keywordId}`, { key }, { token: true, method: 'PUT' });
