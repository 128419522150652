import BUNDLES from 'core/app/constants/bundles.json';
import { LOADING, UNLOADED, LOADED } from 'core/app/constants/readyStates';
import Utils from '../framework/utils';

const activeRoles = ['admin', 'bidder', 'studio'];
const ZYNGA_COMPANY_ID = '4f26e639cd1cb2f13e00000e';

const getState = (state) => state.user;

const getFeatures = (state) => getState(state).features;
const getLoading = (state) => getState(state).loading;
const getLoggedIn = (state) => getState(state).isLoggedIn;
const getLoginError = (state) => getState(state).loginError;
const getReadyState = (state) => getState(state).readyState;
const getRoles = (state) => getState(state).roles;
const getSettings = (state) => getState(state).settings;
const getIsFetching = (state) => getReadyState(state) === LOADING;
const getShouldFetch = (state) => getReadyState(state) === UNLOADED;
const getHasAcceptedTerms = (state) => getState(state).acceptedTerms;
const getUserID = (state) => getState(state).user_id;
const isBetaTestAccount = (state) => getState(state).isBetaTestAccount;
const isTestAccount = (state) => getState(state).isTestAccount;
const isZynga = (state) => getState(state).company_id === ZYNGA_COMPANY_ID;

const getShouldFetchTerms = (state) =>
  getLoggedIn(state) && !state.user.wasShownTerms && getReadyState(state) === LOADED;

const getBundles = () => [...BUNDLES];

const getHasActiveRole = (state) => getRoles(state).some((role) => activeRoles.includes(role));

/*
const isV2 = state => {
  const companyId = Utils.getCookie('company_id') || false;
  const isFlagged = companyId ? V2_PUBS.indexOf(companyId) > -1 : false;
  // return false;  //uncomment this to see nonv2 publisher prespective
  return isFlagged || isAdmin(state);
}*/

const isV2 = (state) => true;

const UserStore = {
  getBundles,
  getFeatures,
  getHasAcceptedTerms,
  getHasActiveRole,
  getIsFetching,
  getLoading,
  getLoggedIn,
  getLoginError,
  getReadyState,
  getRoles,
  getSettings,
  getShouldFetch,
  getShouldFetchTerms,
  getUserID,
  isBetaTestAccount,
  isTestAccount,
  isV2,
  isZynga,
};

export default UserStore;
