import React, { Component } from 'react';
import { Form, Segment, Label, Popup } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

//Views
import PlacementsManager from './PlacementsManager.jsx';
import LineItemsManager from './LineItemsManager.jsx';
import Countries from 'apps/app/views/Countries.jsx';

//Models
import Placement from 'apps/placement';
import Utils from 'core/framework/utils';

import '../css/adgroups.css';

class AdGroupForm extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldFetchLineItems) {
      this.props.fetchLineItems(this.props.appId);
    }
  }

  renderForm() {
    const { app, appId, adgroup, placement } = this.props;
    const { addCountry, removeCountry, shouldTransition } = this.props;

    const defaultAdgroupIDs = [
      app.default_interstitial_adgroup,
      app.default_rewarded_adgroup,
      app.default_rewarded_interstitial_adgroup,
    ];

    const disableCountrySelect = defaultAdgroupIDs.includes(adgroup.id);

    const set = Utils.handleFormEvent(this.props.setField, appId, adgroup.id);

    if (shouldTransition) {
      return <Redirect to={`/apps/${app.id}/adgroups`} />;
    }

    const NameField = {
      autoComplete: 'off',
      placeholder: 'Ad Group Name',
      fluid: true,
      label: 'Name',
      name: 'name',
      value: adgroup.name,
      onChange: set,
    };

    const AdTypeField = {
      placeholder: 'Select Type',
      options: Placement.Constants.AD_TYPES,
      disabled: placement !== false,
      fluid: true,
      label: 'Type',
      name: 'type',
      value: adgroup.type,
      onChange: set,
    };

    const inclusionTypeProps = {
      disabled: disableCountrySelect,
      fluid: true,
      label: 'Country list',
      name: 'inclusion_type',
      onChange: set,
      selection: true,
      value: adgroup.inclusion_type,
      options: [
        {
          key: 'SOME',
          value: 'SOME',
          text: 'Include specified countries',
        },
        {
          key: 'EXCLUDE',
          value: 'EXCLUDE',
          text: 'Exclude specified countries',
        },
        {
          key: 'ALL',
          value: 'ALL',
          text: 'Include all countries',
        },
      ],
    };

    const CountriesProps = {
      noContentMsg: 'No countries set',
      title: 'Countries',
      type: 'adgroup',
      remove: (ctr) => removeCountry(appId, adgroup.id, ctr),
      add: (ctr) => addCountry(appId, adgroup.id, ctr),
      existing: adgroup.countries,
      disabled: adgroup.inclusion_type === 'ALL',
    };

    const PlacementsProps = {
      app: app,
      adgroup: adgroup,
      addFunction: this.props.addPlacement,
      removeFunction: this.props.removePlacement,
    };

    return (
      <div className="content-padding">
        <Segment textAlign="left" loading={this.props.loading} className="smooth-table mt-25">
          <Label attached="top" size="large">
            Ad Group
          </Label>
          <Form className="AdGroupForm">
            <Form.Group inline widths="equal">
              <Form.Input {...NameField} />
              <Form.Select {...AdTypeField} />
              <Form.Dropdown {...inclusionTypeProps} />
            </Form.Group>
            <Countries {...CountriesProps} />
          </Form>
        </Segment>

        <Segment className="smooth-table mt-25" loading={this.props.loading}>
          <Label attached="top" size="large">
            Line Items
          </Label>
          <LineItemsManager app={app} adgroup={adgroup} isAutoCpm={false} />
        </Segment>

        <Segment className="smooth-table mt-25" loading={this.props.loading}>
          <Label attached="top" size="large">
            Placements
          </Label>
          <PlacementsManager {...PlacementsProps} />
        </Segment>
      </div>
    );
  }
}

export default AdGroupForm;
