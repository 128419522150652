import React, { useEffect, useState } from 'react';

import { Button, Form, Icon, Modal, Segment, Image } from 'semantic-ui-react';
import TargetingRulesContainer from '../targetingRules/components/TargetingRulesContainer';
import PredicateInput from '../targetingRules/components/PredicateInput';
import Helpers from '../targetingRules/helpers';
import Constants from '../targetingRules/constants/targetingRules';
import Network from '../../network';
import CommonHelpers from '../../common/helpers';

const EditLineItemModal = (props) => {
  const {
    addPredicate,
    appId,
    createRule,
    deleteRule,
    deletePredicate,
    editPredicate,
    editLineItem,
    item,
    loading,
    negateRule,
    setEditShown,
    closePopup,
    isBetaTestAccount,
  } = props;
  const { network_id } = item;
  const [modalOpen, setModal] = useState(false);
  const [lineItemState, setLineItemState] = useState({
    ...item,
    ...Constants.INITIAL_TARGETING_RULES_STATE,
    cpm: item.cpm,
    auto_cpm: false,
    first_look: item.first_look,
    name: item.name,
    partner_placement: item.partner_placement,
    errors: [],
    keywords: {},
    keywordOptions: [],
  });

  const setKeywords = (customWords, reservedWords) => {
    const filteredReservedWords = Helpers.Processors.filterKeywords(reservedWords, network_id);
    const combinedKeywords = { ...customWords, ...filteredReservedWords };
    const options = Helpers.Processors.keywordsToOptions(combinedKeywords);
    setLineItemState({
      ...lineItemState,
      keywords: combinedKeywords,
      keywordOptions: options,
    });
  };

  const toggleAddPredicate = (isAdding, uuid) => {
    if (isAdding) {
      setLineItemState({
        ...lineItemState,
        isAddingPredicate: true,
        inputType: 'add',
        addingPredicateId: uuid,
      });
    } else {
      handleResetState();
    }
  };

  const toggleEditPredicate = (isEditing, ruleId, predicate) => {
    if (isEditing) {
      setLineItemState({
        ...lineItemState,
        isEditingPredicate: true,
        editingPredicateId: predicate.uuid,
        selectedRuleId: ruleId,
        inputType: 'edit',
        keyword: predicate.keyword.key,
        keywordType: predicate.keyword.keyword_type,
        operation: predicate.operation,
        value: predicate.value,
      });
    } else {
      handleResetState();
    }
  };

  const partnerDetails = item.partner_details != null ? item.partner_details : null;

  const handleClose = () => {
    handleResetState();
    setModal(false);
    setEditShown(false);
    closePopup();
  };

  const handleResetState = () => {
    setLineItemState({
      ...lineItemState,
      ...Constants.INITIAL_TARGETING_RULES_STATE,
      errors: [],
    });
  };

  const handleSubmit = () => {
    editLineItem(appId, lineItemState);
    handleClose();
  };

  const handleSubmitPredicate = () => {
    const errors = Helpers.Validators.validateKeywordType(
      lineItemState.value,
      lineItemState.keywordType
    );

    if (errors.length > 0) {
      setLineItemState((prevState) => ({
        ...prevState,
        errors,
      }));
    }

    if (errors.length === 0) {
      const { lineItemId, predicate, predicateId, rule, ruleId, submitType } =
        Helpers.Processors.processPredicate(lineItemState);
      if (submitType === 'add') {
        if (ruleId === 'newRule') {
          createRule({ appId, lineItemId, rule });
        } else {
          addPredicate({ appId, lineItemId, ruleId, predicate });
        }
      } else if (submitType === 'edit') {
        editPredicate({ appId, lineItemId, ruleId, predicateId, predicate });
      }

      handleResetState();
    }
  };

  const handleDeleteRule = (ruleID) => {
    deleteRule(appId, item.id, ruleID);
  };

  const handleDeletePredicate = (ruleID, predicateId) => {
    deletePredicate(appId, item.id, ruleID, predicateId);
  };

  const handleNegateRule = (ruleID, isNegated) => {
    const rule = Helpers.Processors.getRule(ruleID, item, isNegated);
    negateRule(appId, item.id, ruleID, rule);
  };

  const setter = (_, e) => {
    let value = e.value;
    if (e.name === 'first_look') value = !value;
    if (e.name === 'cpm') {
      value = parseFloat(value);
    }
    if (e.name === 'mintegral_unit_id') {
      setLineItemState({
        ...lineItemState,
        partner_details: {
          [e.name]: value,
        },
      });
    } else if (e.name === 'keyword') {
      const keywordType = Helpers.Processors.getKeywordType(value, lineItemState.keywords);
      setLineItemState({
        ...lineItemState,
        keyword: value,
        keywordType,
        operation: '',
        value: '',
        errors: [],
      });
    } else {
      setLineItemState({
        ...lineItemState,
        [e.name]: value,
        errors: [],
      });
    }
  };

  const isLineItemFieldsValid = (itemField) => {
    const { cpm, network_id, name, partner_placement, partner_details } = itemField;
    let isValid = false;
    if (name?.trim() !== '' && partner_placement?.trim() !== '' && cpm !== '' && !isNaN(cpm)) {
      isValid = true;
    }
    if (network_id === 'mintegral') {
      if (
        partner_details?.mintegral_unit_id !== undefined &&
        partner_details?.mintegral_unit_id?.trim() === ''
      ) {
        return false;
      }
    }
    return isValid;
  };

  const isModified = () => {
    let result = false;
    Object.keys(item).forEach((key) => {
      if (key === 'partner_details') {
        // Compare local line item state to line item values
        if (item[key].mintegral_unit_id !== lineItemState[key].mintegral_unit_id) {
          result = true;
        }
      } else if (item[key] !== lineItemState[key]) {
        result = true;
      }
    });
    return result;
  };

  const areFieldsValid = isLineItemFieldsValid(lineItemState);
  const shouldDisableSubmit = !areFieldsValid || !isModified();

  useEffect(() => {
    setLineItemState({
      ...lineItemState,
      item,
    });
    if (partnerDetails != null) {
      setLineItemState({
        ...lineItemState,
        partner_details: {
          mintegral_unit_id: item.partner_details.mintegral_unit_id,
        },
      });
    }
  }, [item.id]);

  return (
    <Modal
      centered={true}
      className="hl-modal LineItemModal"
      closeIcon
      closeOnDimmerClick={false}
      onClose={handleClose}
      open={modalOpen}
      size="fullscreen"
      trigger={
        <Button
          className="editLineItemButton"
          onClick={() => {
            setModal(true);
            setEditShown(true);
          }}
        >
          Edit Line Item
        </Button>
      }
    >
      <Modal.Header content="Update Line Item" color="green" />
      <Modal.Content>
        <Segment>
          <div className="lineItem edit container">
            <Form className="lineItem network">
              <Form.Field label="Network" />
              <Image {...Network.Helpers.getLogo(network_id)} />
              <span>{Network.Helpers.getName(network_id)}</span>
            </Form>
            <Form className="lineItem create name">
              <Form.Input
                autoComplete="off"
                id="name"
                label="Name"
                name="name"
                onChange={setter}
                placeholder="Name"
                required={true}
                value={lineItemState.name}
              />
            </Form>
            <Form className="lineItem edit placement">
              <Form.Input
                autoComplete="off"
                id="partner_placement"
                label="Partner Placement Name"
                name="partner_placement"
                onChange={setter}
                placeholder="Partner Placement Name"
                required={true}
                value={lineItemState.partner_placement}
              />
            </Form>
            {partnerDetails && (
              <Form className="lineItem edit placement">
                <Form.Input
                  autoComplete="off"
                  id="mintegral_unit_id"
                  label="Unit ID"
                  name="mintegral_unit_id"
                  onChange={setter}
                  placeholder="Unit ID"
                  required={true}
                  value={lineItemState.partner_details.mintegral_unit_id}
                />
              </Form>
            )}
            {isBetaTestAccount && (
              <Form className="lineItem edit firstlook">
                <Form.Checkbox
                  id="first_look"
                  label="First Look"
                  name="first_look"
                  onChange={setter}
                  checked={lineItemState.first_look}
                  value={lineItemState.first_look}
                />
              </Form>
            )}
            <Form className="lineItem create cpm">
              <Form.Input
                id="cpm"
                icon="dollar"
                iconPosition="left"
                label="CPM"
                min="0"
                name="cpm"
                onChange={setter}
                placeholder="0.99"
                step="0.01"
                type="number"
                required={true}
                value={lineItemState.cpm}
                onWheel={CommonHelpers.numberInputOnWheelPreventChange}
              />
            </Form>
          </div>
        </Segment>
        <TargetingRulesContainer
          addingPredicateId={lineItemState.addingPredicateId}
          editingPredicateId={lineItemState.editingPredicateId}
          handleDeletePredicate={handleDeletePredicate}
          handleDeleteRule={handleDeleteRule}
          handleNegateRule={handleNegateRule}
          isAddingPredicate={lineItemState.isAddingPredicate}
          isEditingPredicate={lineItemState.isEditingPredicate}
          isLoading={loading}
          keyword={lineItemState.keyword}
          lineItem={item}
          operation={lineItemState.operation}
          selectedRuleId={lineItemState.selectedRuleId}
          setKeywords={setKeywords}
          setter={setter}
          toggleAddPredicate={toggleAddPredicate}
          toggleEditPredicate={toggleEditPredicate}
          value={lineItemState.value}
        />
      </Modal.Content>
      <Modal.Actions>
        {(lineItemState.isAddingPredicate || lineItemState.isEditingPredicate) && (
          <PredicateInput
            errors={lineItemState.errors}
            handleSubmitPredicate={handleSubmitPredicate}
            inputType={lineItemState.inputType}
            keyword={lineItemState.keyword}
            keywordOptions={lineItemState.keywordOptions}
            keywordType={lineItemState.keywordType}
            operation={lineItemState.operation}
            setter={setter}
            toggleAddPredicate={toggleAddPredicate}
            toggleEditPredicate={toggleEditPredicate}
            value={lineItemState.value}
          />
        )}
        {!lineItemState.isAddingPredicate && !lineItemState.isEditingPredicate && (
          <div>
            <Button onClick={handleSubmit} disabled={shouldDisableSubmit}>
              <Icon name="add" /> Submit Changes
            </Button>

            <Button onClick={handleClose}>
              <Icon name="remove" /> Cancel
            </Button>
          </div>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default EditLineItemModal;
