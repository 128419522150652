import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ManageNetworks from './network/views/ManageNetworks';
import ManagePlacements from './placement/views/ManagePlacements';
import CreatePlacement from './placement/views/CreatePlacement';
import EditPlacement from './placement/views/EditPlacement';
import SplitPlacements from './placement/views/SplitPlacements';
import ManageAdGroups from './adgroup/views/ManageAdGroups';
import CreateAdGroup from './adgroup/views/CreateAdGroup';
import EditAdGroup from './adgroup/views/EditAdGroup';
import ImportApp from './chartboost/views/ImportApp';
import Overview from './app/views/Overview';
import EditApp from './app/views/EditApp';

import './app/css/main.css';

const AppsRoutes = () => (
  <div className="Helium">
    <Switch>
      <Route exact path="/apps" component={Overview} />
      <Route exact path="/apps/new" component={ImportApp} />
      <Route exact path="/apps/:id" component={EditApp} />
      <Route exact path="/apps/:id/networks" component={ManageNetworks} />
      <Route exact path="/apps/:id/placements" component={ManagePlacements} />
      <Route exact path="/apps/:id/placements/:pid" component={EditPlacement} />
      <Route exact path="/apps/:id/placements-split/:pid/:pidb" component={SplitPlacements} />
      <Route exact path="/apps/:id/createPlacement/:type" component={CreatePlacement} />
      <Route exact path="/apps/:id/adgroups" component={ManageAdGroups} />
      <Route exact path="/apps/:id/adgroups/:agid" component={EditAdGroup} />
      <Route exact path="/apps/:id/createAdGroup" component={CreateAdGroup} />
      <Route exact path="/apps/:id/createAdGroup/:pid" component={CreateAdGroup} />
    </Switch>
  </div>
);

export default AppsRoutes;
