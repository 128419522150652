import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// views
import { Button, Segment } from 'semantic-ui-react';
import { AppLayout, DualHeader, PageHeader, RouteLeavingGuard } from 'core/app/views';

import AdGroupForm from './AdGroupForm';

// modules
import App from 'apps/app';
import AdGroup from 'apps/adgroup';
import LineItem from 'apps/lineitem';
import User from 'core/user';
import { setTitle } from 'utils/helpers';

class EditAdgroup extends AdGroupForm {
  constructor(props) {
    super(props);
    this.state = { saved: false };
  }

  componentDidMount() {
    this.props.fetchAdGroups(this.props.appId);
    this.props.fetchLineItems(this.props.appId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.appId !== 'unselected') {
      if (prevProps.shouldFetchLineItems === false && this.props.shouldFetchLineItems === true) {
        this.props.fetchLineItems(this.props.appId);
        this.setState({
          saved: false,
        });
      }
      if (prevProps.shouldFetchAdGroups === false && this.props.shouldFetchAdGroups === true) {
        this.props.fetchAdGroups(this.props.appId);
        this.setState({
          saved: false,
        });
      }
    }
    setTitle(this.props?.app?.nickname || this.props?.app?.name);
  }

  handleSave() {
    const { appId, adgroup, save } = this.props;
    save(appId, adgroup);
    this.setState({
      saved: true,
    });
  }

  render() {
    const { app, adgroup, madeChanges } = this.props;
    const isDirty = madeChanges && !this.state.saved;

    const context = {
      app: app,
      adgroups: true,
      adgroup: adgroup,
    };

    return (
      <AppLayout bundle="apps" context={context}>
        <RouteLeavingGuard when={isDirty} shouldBlockNavigation={() => true} />
        <Segment padded textAlign="left">
          <DualHeader>
            <PageHeader content="Edit Ad Group" img={true} className="pt-10 pb-10" />
            <Button
              content="Save Ad Group"
              onClick={this.handleSave.bind(this)}
              className="positive"
            />
          </DualHeader>
          {this.renderForm()}
        </Segment>
      </AppLayout>
    );
  }
}

const props = (state, ownProps) => {
  const appId = ownProps.match.params.id;
  const agid = Number(ownProps.match.params.agid);
  const app = App.Store.getApp(state, appId) || {};
  const adgroups = AdGroup.Store.getAll(state, appId) || [];
  const adgroup = AdGroup.Helpers.find(adgroups, agid) || {};
  return {
    appId: appId,
    app: app,
    adgroups: adgroups,
    adgroup: adgroup,
    isBetaTestAccount: User.Store.isBetaTestAccount(state),
    loading: AdGroup.Store.getLoading(state),
    madeChanges: App.Store.hasMadeChanges(state),
    shouldFetchLineItems: LineItem.Store.shouldFetch(state),
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    shouldTransition: false,
  };
};

const dispatches = {
  fetchAdGroups: AdGroup.Actions.fetchAll,
  fetchLineItems: LineItem.Actions.fetchAll,
  setField: AdGroup.Actions.setField,
  addCountry: AdGroup.Actions.addCountry,
  removeCountry: AdGroup.Actions.removeCountry,
  addPlacement: AdGroup.Actions.addPlacement,
  removePlacement: AdGroup.Actions.removePlacement,
  save: AdGroup.Actions.update,
};

export default withRouter(connect(props, dispatches)(EditAdgroup));
