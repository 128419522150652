import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import Placement from '../../placement';
import Constants from '../../placement/constants';

class PlacementsManager extends Component {
  Item = (placement) => (
    <Table.Row>
      <Table.Cell>
        <Link to={`/apps/${this.props.appId}/placements/${placement.id}`}>{placement.name}</Link>
      </Table.Cell>
      <Table.Cell>{Constants.AD_TYPE_LABELS[placement.type]}</Table.Cell>
      <Table.Cell textAlign="right">
        <Icon onClick={() => this.remove(placement.id)} name="delete" className="unlink" />
      </Table.Cell>
    </Table.Row>
  );

  Option = ({ id, name }) => {
    const props = {
      key: id,
      text: name,
      value: id,
      onClick: () => this.add(id),
    };
    return <Dropdown.Item {...props} />;
  };

  NoContent = () => (
    <Table.Row>
      <Table.Cell colSpan={3} className="center-align">
        No placements associated with ad group
      </Table.Cell>
    </Table.Row>
  );

  add = (id) => {
    const { adgroup, appId, addFunction } = this.props;
    addFunction(appId, adgroup.id, id);
  };

  remove = (id) => {
    const { adgroup, appId, removeFunction } = this.props;
    removeFunction(appId, adgroup.id, id);
  };

  getExistingPlacements = (existing) =>
    existing.length > 0 ? existing.map(this.Item) : this.NoContent();

  render() {
    const { options, existing, type } = this.props;
    const placements = this.getExistingPlacements(existing);
    const _options = options.map(this.Option);

    const dropdownProps = {
      width: 4,
      labeled: true,
      button: true,
      icon: 'angle down',
      className: `icon select-placement positive ${type}`,
      placeholder: `${Constants.AD_TYPE_LABELS[type]} Placements`,
    };

    return (
      <Table className="PlacementsManager" unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body Style={{ padding: '40px' }}>{placements}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell textAlign="right" colSpan={3}>
              <Dropdown {...dropdownProps} scrolling upward>
                <Dropdown.Menu>{_options}</Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

const props = (state, ownProps) => {
  const { app, adgroup } = ownProps;

  const placements = app.placements || [];
  const valid = Placement.Helpers.filter(placements, adgroup.type);
  let existing = Placement.Helpers.get(app, adgroup.placements) || [];
  existing = Array.isArray(existing) ? existing : [existing];
  const options =
    existing.length > 0 ? valid.filter((v) => existing.find((e) => e.id !== v.id)) : valid;

  return {
    appId: app.id,
    adgroup: adgroup,
    type: adgroup.type,
    existing: existing,
    options: options,
  };
};

const dispatches = {};

export default connect(props, dispatches)(PlacementsManager);
