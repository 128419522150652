import Papa from 'papaparse';
import Constants from './constants';

const getSizes = (placement) => {
  if (placement?.type === 'interstitial') {
    return [...Constants.AD_SIZES.INTERSTITIAL];
  } else if (placement?.type === 'banner') {
    return [...Constants.AD_SIZES.BANNER];
  } else if (placement?.type === 'rewarded') {
    return [...Constants.AD_SIZES.REWARDED];
  } else if (placement?.type === 'adaptive_banner') {
    return [...Constants.AD_SIZES.ADAPTIVE_BANNER];
  }
  return [];
};

const parse = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      transformHeader: (header) => {
        const formatted = Constants.INDEXED_HEADERS[header];
        return formatted || header;
      },
      complete: ({ data }) => resolve(data),
      error: (error) => reject(error),
    });
  });
};

const processPricePoints = async (file) => {
  const sanitize = ({ cpm, price_point, encoded, create_date }) => ({
    cpm,
    price_point,
    encoded,
    create_date,
  });

  if (file != null) {
    const rows = await parse(file);
    const sanitized = [];
    for (const row of rows) {
      const sanitizedRow = sanitize(row);
      if (sanitizedRow.encoded) {
        sanitized.push(sanitize(row));
      }
    }
    return sanitized;
  }
};

const toAmazonApsApiCredentials = (credentials) => {
  if (credentials) {
    credentials.ad_slot_name = '';
  }
};

const Helpers = {
  getSizes,
  processPricePoints,
  toAmazonApsApiCredentials,
};

export default Helpers;
