import { useAuth0 } from '@auth0/auth0-react';
import { deleteAuthToken, setAuthToken } from 'core/framework/localStorage';

const AuthZeroClient = () => {
  const { loginWithRedirect, isAuthenticated, user, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const getToken = async () => {
    // Retrieve Auth0 Access token and save it to local storage
    const accessToken = await getAccessTokenSilently();
    setAuthToken(accessToken);
    // redirect user to Apps overview
    window.location = `/apps`;
    return;
  };

  const forceLogout = () => {
    deleteAuthToken();
    return logout({
      logoutParams: {
        returnTo: window.location.origin + '/login',
      },
    });
  };

  const signup = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  return {
    forceLogout,
    getAccessTokenSilently,
    getToken,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    signup,
    user,
  };
};

export default AuthZeroClient;
