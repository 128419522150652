import React from 'react';
import { Segment } from 'semantic-ui-react';
import { PageHeader } from 'core/app/views';

export const TermsOfService = () => (
  <Segment padded="very" className="terms">
    <PageHeader
      img={true}
      content="Unified Auction Service Addendum to Chartboost Terms and Conditions (“Addendum”)"
    />
    <Segment basic>
      <h4>Last updated: May 1, 2019</h4>
      <p>
        This Addendum is incorporated into the Terms and Conditions between Chartboost and You which
        are currently available at https://answers.chartboost.com/en-us/articles/200780239 (the
        “Terms and Conditions”). This Addendum is with respect to Online Services involving the
        applications that are using Chartboost’s Unified Auction Service (the “Unified Auction
        Service”). Capitalized terms used but not defined in this Addendum have the meaning
        attributed to them in the Terms and Conditions.
      </p>
      <p>
        By accessing or using the Unified Auction Service, You are agreeing to the Terms and
        Conditions including this Addendum.
      </p>
    </Segment>

    <Segment basic>
      <h4>1. UNIFIED AUCTION SYSTEM PARTICIPATION</h4>
      <ul>
        <li>
          1.1 Authorization. Subject to You installing the appropriate SDK made available by
          Chartboost and providing the information required under Section 1.2 below, Chartboost will
          designate Your as an Authorized Publisher of the Unified Auction Service. As used in this
          Addendum, “Unified Auction” means the programmatic auction platform that enables all
          Chartboost’s Demand Partners to simultaneously compete in real time using a 1st-price
          bidding auction.
        </li>
        <li>
          1.2 Demand Partners Configuration:You will be required to provide Chartboost with the
          necessary credentials from third party bidders that are participating in auctions in the
          Unified Auction Service (“Third Party SDK Demand Partners”) before using the Unified
          Auction Service. You represent and warrant that such data is accurate and true, and You
          understand and acknowledge that Chartboost requires this information to authorize the
          Third Party SDK Demand Partners to access the Unified Auction Service. Under no
          circumstances shall Chartboost have any liability, in any manner, for any errors arising
          from or related to such third party credentials or other data provided by You.
        </li>
        <li>
          1.3 Unified Auction Dynamics
          <ul>
            <li>
              1.3.1. After You enter the Unified Auction Service, all the approved Demand Partners
              will be eligible to receive Bid Requests from You. “Demand Partners” are Third Party
              SDK Demand Partners and programmatic-based demand partners that buy Inventory via the
              Unified Auction Service for the placement of ads on a publisher’s mobile application
              (such publisher, a “Publisher”). “Bid Requests” means the server-to-server call from
              Chartboost to each one of the approved Demand Partners in order for them to
              participate in the Unified Auction. "Bid" means the price offer for a specific Bid
              Request. “Inventory” means elements of an application or website that a Publisher
              designates for placement of Ads.
            </li>
            <li>
              1.3.2. The winning Bid will be chosen through the Unified Auction Service. The auction
              winner will bethe Demand Partner offering the highest price for a specific Ad
              impression (the “Winning Bid” or “First Price”). Such Demand Partner shall pay the
              applicable specified Publisher for the Winning Bid. The auction will close with the
              highest price Bid.
            </li>
            <li>
              1.3.3. In cases where the winning Bid comes from a Third Party SDK Demand Partner, the
              Third party SDK Demand Partner will be responsible for rendering the appropriate Ad
              via its SDK.
            </li>
          </ul>
        </li>
      </ul>
    </Segment>

    <Segment basic>
      <h4> 2. 1. PAYMENTS </h4>
      2.1 Payments: Demand Partners have the following billing options:
      <ul>
        <li>
          (i) Direct Pay Option: The Demand Partner pays the specified Publisher directly for the
          Inventory purchased{' '}
        </li>
        <li>
          (ii) Chartboost Pay Option: Chartboost will invoice the Demand Partner for Inventory
          purchased from all Publishers hereunder. Chartboost will pay the Publisher under the
          standard payment terms as set forth in the Terms and Conditions.{' '}
        </li>
      </ul>
      <p>
        For all Inventory purchased under the Direct Pay Option, the Demand Partner is solely
        responsible for all applicable payments, based on the contractual agreement between the
        Publisher and the Demand Partner. Chartboost shall have no responsibility or liability with
        respect to any discrepancy between the analytics of the Demand Partner and the Unified
        Auction Service.
      </p>
    </Segment>

    <Segment basic>
      <h4> 3. 1. DATA </h4>
      <ul>
        <li>
          3.1 Chartboost Data. All data transmitted by Chartboost to You, all data derived by
          Chartboost in connection with its performance under the Agreement, and all data collected
          independently by Chartboost outside of this Agreement shall be referred to as “Chartboost
          Data.” Chartboost Data shall exclude Performance Data and Your Data. As between Chartboost
          and You, Chartboost shall own and retain all right, title and interest in and to all
          Chartboost Data except for the rights granted in this Addendum. You shall not use, share,
          or resell any Chartboost Data without Chartboost’s written consent or otherwise provide
          Chartboost Data to any person or entity except as needed to perform Your obligations
          hereunder. You shall not use Chartboost Data to augment, modify, design, or improve any
          auction or bidding tools for You or any third party. Notwithstanding the foregoing, You
          will have the perpetual, worldwide, non-exclusive, royalty-free right to use Chartboost
          Data anonymously and in the aggregate with comparable data from other sources for
          reporting, planning, promotional and development purposes, so long as third parties cannot
          attribute the data to Chartboost or any Advertiser and no individual or entity can be
          identified. For the avoidance of doubt, promotional purposes do not include any statements
          comparing Chartboost’s products and services to Your, or any third party’s, products and
          services, whether or not Chartboost is explicitly mentioned; such use is prohibited.
        </li>
        <li>
          3.2 Performance Data.You and Chartboost each may collect, use and store any and all data,
          exclusive of Chartboost Data and Your Data, obtained by such party as a result of the Ads
          displayed to the applicable End User in response to a request for an Ad (collectively,
          “Performance Data”). For clarity, Performance Data includes click through rates,
          conversion rates, advertising responses, auction clearing prices, impressions and similar
          data exchanged by Chartboost and You, as the buying and selling entities for Ads
          hereunder. You may only use Performance Data to audit the results of the Unified Auction,
          no other usage or activity is allowed.
        </li>
        <li>
          3.3 Your Data.“Your Data” means any data, other than Chartboost Data and Performance Data,
          that is provided by You to Chartboost in a Bid Request or otherwise regarding any end user
          of an application (each an “End User”), Publisher or Inventory, including without
          limitation any unique identifying information associated with any End User (including,
          without limitation, any advertising identifier such as IDFA, hardware devices or operating
          system identifiers, session-based browsing behavior, user agent, segments, and http header
          information). As between Chartboost and You, You shall own and retain all right, title and
          interest in and to Your Data. Notwithstanding the foregoing, Chartboost will have the
          perpetual, worldwide, non-exclusive, royalty-free right to use and store Your Data for
          reporting, planning, promotional and development purposes, so long as third parties cannot
          attribute the data to You.
        </li>
        <li>
          3.4 Additional Terms Regarding Demand Partners. In the case of Third Party SDK Demand
          Partners, where You have a contractual agreement with the Demand Partner, those terms
          shall not be modified by this Addendum and Chartboost is not responsible or liable for any
          consequences of the advertisements served by the Third Party SDK Demand Partner. Also,
          Chartboost shall not be responsible for any actions or inactions by any Demand Partner
          with respect to Performance Data or Publisher Data.
        </li>
      </ul>
    </Segment>

    <Segment basic>
      <h4> 4. PRIVACY </h4>
      <p>
        Your agree to comply with all applicable laws and self-regulatory guidelines relating to the
        collection and use of information from end users, including any applicable requirement to
        obtain consent from End Users. You are also required to use the SDK flags that Chartboost
        offers to properly categorize the Inventory that is available on the Unified Auction
        Service. These flags include:
      </p>
      <ul>
        <li>
          <b>COPPA</b>
          <ul>
            <li>
              By sending setSubjectToCoppa (YES), You indicate that You want Your content treated as
              child-directed for purposes of COPPA. Chartboost will take steps to disable
              interest-based advertising for such ad requests.
            </li>
            <li>
              By sending setSubjectToCoppa (NO), You indicate that You do not want Your content
              treated as child-directed for purposes of COPPA. You represent and warrant that Your
              applications and services are not directed towards children and that You will not
              provide to Chartboost any information from a user under the age of 13.
            </li>
          </ul>
        </li>
        <li>
          <b>GDPR (EU General Data Protection Regulation)</b>
          <ul>
            <li>
              By sending setSubjectToGDPR (YES), You indicate that this End User is a “data subject”
              under the GDPR.
            </li>
            <li>
              By sending setSubjectToGDPR (NO), You indicate that this End User is not a “data
              subject” under the GDPR.
            </li>
          </ul>
        </li>
        <li>
          <b>User Consent</b>
          <ul>
            <li>
              By sending setUserHasGivenConsent (YES), You indicate that this End User has given
              consent to share personal data for behavioral targeted advertising.{' '}
            </li>
            <li>
              By sending setUserHasGivenConsent (NO), You indicate that this End User has not given
              consent to use its personal data for behavioral targeted advertising, so only
              contextual advertising is allowed.
            </li>
          </ul>
        </li>
      </ul>
    </Segment>
  </Segment>
);

export default TermsOfService;
