import React from 'react';

const GoogleLoginContainer = (props) => {
  const { appId, networkAppId, isEdit, network } = props;
  let redirect_uri = '';

  if (window.location.host === 'local.chartboost.com:3000') {
    redirect_uri = 'http%3A//local.chartboost.com:3000/google_code';
  } else {
    redirect_uri = `https%3A//${window.location.host}/google_code`;
  }

  const SCOPES = {
    adSense: 'https%3A//www.googleapis.com/auth/adsense.readonly',
    admobReport: 'https%3A//www.googleapis.com/auth/admob.report',
    dfp: 'https%3A//www.googleapis.com/auth/dfp',
    googleBidding: 'https%3A//www.googleapis.com/auth/admob.googlebidding.readwrite',
    admob_readonly: 'https://www.googleapis.com/auth/admob.readonly',
    admob_monetization: 'https://www.googleapis.com/auth/admob.monetization',
  };

  const ALL_SCOPES = Object.values(SCOPES).join('%20');

  const HELIUM_CLIENT_ID =
    '539386036942-36njljvq83505t2ugatg7tsb45iiorl2.apps.googleusercontent.com';

  const link = `https://accounts.google.com/o/oauth2/v2/auth?scope=${ALL_SCOPES}&access_type=offline&prompt=consent&state=${network}-${appId}-${networkAppId}&include_granted_scopes=true&response_type=code&redirect_uri=${redirect_uri}&client_id=${HELIUM_CLIENT_ID}`;
  const isGoogleSSOReady = networkAppId && networkAppId.length;

  return (
    <div className={'google-login-button-container'}>
      <a href={isGoogleSSOReady ? link : 'javascript:void(0)'}>
        <img
          src="/img/google-signin.png"
          alt="googlesignin"
          href={link}
          className={
            isEdit
              ? 'google-login-button-edit'
              : `google-login-button${!isGoogleSSOReady ? '-disabled' : ''}`
          }
        />
      </a>
    </div>
  );
};

export default GoogleLoginContainer;
