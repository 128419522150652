import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Button, Dropdown, Icon } from 'semantic-ui-react';

import WarningPopup from 'core/app/views/WarningPopup';

import '../css/adgroup-editor.css';

const AddAdgroupDropdown = props => {
  const {
    adgroupOptions,
    app,
    currentAdgroupInfo,
    placementId,
    saveOnExit,
    updateAdgroupPlacement,
    updateAdgroupPlacementLocal,
  } = props;
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = adgroup => {
    const adgroupId = adgroup.id;
    const appId = adgroup.app_id;
    props.addAdgroup(appId, placementId, adgroupId);
  };

  const saveAndNavigate = () => {
    if (saveOnExit) {
      saveOnExit();
    }
    setRedirect(true);
  };

  const handleDelete = () => {
    const updatedPlacements = [...currentAdgroupInfo.placements].filter(id => id !== placementId);
    currentAdgroupInfo.placements = updatedPlacements;
    if (placementId === '__new__') {
      // updates the local adgroup + placement connection for when creating placement when using existing adgroups
      updateAdgroupPlacementLocal(app.id, currentAdgroupInfo, placementId)
    } else {
      updateAdgroupPlacement(app.id, currentAdgroupInfo, placementId)
    }
  };

  const ModalButtonProps = {
    className: 'adgroup',
    content: 'Add',
    floated: 'right',
    icon: 'plus',
    labelPosition: 'left',
    size: 'tiny'
  };

  if (redirect) {
    return <Redirect to={`/apps/${app.id}/createAdGroup/${placementId}`} />;
  }

  // TODO: incorporate @babel/plugin-proposal-optional-chaining throughout project
  const name = currentAdgroupInfo && currentAdgroupInfo.name ? currentAdgroupInfo.name : '';
  const disableDelete = name === '';
  return (
    <div className="adgroup-btn-container">
      <Dropdown
        className="adgroup dropdown"
        floating
        icon={null}
        labeled
        noResultsMessage="No results found"
        trigger={<Button {...ModalButtonProps} />}
      >
        <Dropdown.Menu>
          {adgroupOptions.map(option => {
            return (
              <Dropdown.Item key={option.id} onClick={() => handleSubmit(option)}>
                {option.name}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Item
            key="create-new"
            className="createnewAGlink"
            onClick={() => saveAndNavigate()}
          >
            <Icon name="plus" /> Create a new Ad Group
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className="adgroupDelete">
        <WarningPopup
          action={handleDelete}
          actionName="Disable"
          disabled={disableDelete}
          iconName="cancel"
          name={name}
          modalText={
            <React.Fragment>
              <span>Remove this adgroup from this placement</span>
              <p>
                <strong>{name}</strong>
              </p>
            </React.Fragment>
          }
          size="tiny"
        />
      </div>
    </div>
  );
};

export default AddAdgroupDropdown;
