const filterPlacementFields = (fields) => {
  return fields.filter((field) => field.key === 'ad_unit_id_field');
};

const adUnitToOptions = (adUnits) => {
  let formatedAdUnits = [];
  if (adUnits && adUnits?.length) {
    formatedAdUnits = adUnits?.map((adUnit) => {
      adUnit.key = adUnit.ad_unit_id;
      adUnit.value = adUnit.ad_unit_id;
      adUnit.text = adUnit.ad_unit_id;
      adUnit.name = adUnit.ad_unit_id;
      return adUnit;
    });
  }
  return formatedAdUnits;
};

const combineAdUnits = (apiResponse, currentAdUnits) => {
  const generatedAdUnitID = apiResponse?.ad_unit_id;
  let message = `Created: ${generatedAdUnitID}`;
  if (apiResponse && currentAdUnits.find((adUnit) => generatedAdUnitID === adUnit?.ad_unit_id)) {
    message = `Ad Unit exists: ${generatedAdUnitID}`;
    return { adUnits: currentAdUnits, message };
  } else if (apiResponse) {
    return { adUnits: [apiResponse, ...currentAdUnits], message };
  } else {
    return { adUnits: [...currentAdUnits], message: 'Error Generating Ad Unit' };
  }
};

const Helpers = {
  filterPlacementFields,
  adUnitToOptions,
  combineAdUnits,
};

export default Helpers;
