import React, { Component } from 'react';
import { Header, Checkbox } from 'semantic-ui-react';

class ABTestToggle extends Component {
  render() {
    const { disabled, onClick } = this.props;

    const handleClick = () => {
      if (!disabled) {
        onClick();
      }
    };

    return (
      <div>
        <Header as="h4" content="A/B Test" />
        <Checkbox
          label="A/B Test this placement"
          checked={disabled}
          disabled={disabled}
          onClick={handleClick}
          toggle
        />
      </div>
    );
  }
}

export default ABTestToggle;
