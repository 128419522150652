import React from 'react';
import { Table } from 'semantic-ui-react';

import AdGroup from './AdGroup';
import { EmptyRow } from 'core/app/views';

const AdGroups = ({ adGroups, appId, defaultIds, removeAdgroup, override = false }) => {
  const isDefaultId = (adGroupId, defaultIds) => defaultIds.includes(adGroupId);

  return (
    <Table singleLine fixed selectable structured unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={8}>Name</Table.HeaderCell>
          <Table.HeaderCell width={4} textAlign="center">
            Type
          </Table.HeaderCell>
          <Table.HeaderCell width={4} textAlign="center">
            Edit
          </Table.HeaderCell>
          <Table.HeaderCell width={4} textAlign="center">
            Delete
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {adGroups.length === 0 ? (
          <EmptyRow message={`No ad groups`} colSpan="4" />
        ) : (
          adGroups.map((adGroup, index) => (
            <AdGroup
              adGroup={adGroup}
              appId={appId}
              disabled={adGroups.length <= 1 || isDefaultId(adGroup.id, defaultIds)}
              key={index}
              override={override}
              removeAdgroup={removeAdgroup}
            />
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default AdGroups;
