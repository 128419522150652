import _ from 'lodash';
import { LOADED, UNLOADED, LOADING } from 'core/app/constants/readyStates';
import Utils from 'core/framework/utils';
import Actions from 'core/framework/actions';
import AppHelpers from '../app/helpers';
import AppReducer from '../app/reducer';
import ChartboostActions from './actions';

const initState = () => ({
  apps: [],
  searchTerm: '',
  state: UNLOADED,
  importState: UNLOADED,
  imported: null,
  pagination: {
    total: 0,
    active: 0,
  },
});

const updateState = (state, updates) => ({
  ...state,
  chartboost: {
    ...state.chartboost,
    ...updates,
  },
});

/* Helper functions */

const normalizeChartboostApp = (app, index) => {
  // TODO: hide amazon apps to non test accounts
  let platform = 'iOS';
  if (app?.type_android === 'amazon') {
    platform = 'Amazon';
  } else if (app.type === 2) {
    platform = 'Android';
  }
  return {
    key: app._id.$oid,
    value: app._id.$oid,
    text: `${app.nickname} (${app._id.$oid})`,
    name: app.nickname,
    icon: app.icon,
    platform: platform,
    keywords:
      app.nickname.toLowerCase() +
      (app.name ? app.name.toLowerCase() : '') +
      app._id.$oid.toLowerCase(),
    page: AppHelpers.assignPageNumber(index, 8),
  };
};

const updateSearchPage = (app, index) => ({
  ...app,
  page: AppHelpers.assignPageNumber(index, 8),
});

const normalizeChartboostApps = (apps) =>
  Utils.isNonZeroArray(apps)
    ? _.sortBy(apps, [(app) => app.nickname]).map(normalizeChartboostApp)
    : [];

const searchApps = (state, { value }) => {
  let unfiltered = state.chartboost.unfiltered;
  let filtered = unfiltered.filter((a) => Utils.matchString(a.keywords, value));
  if (value === '') filtered = unfiltered;
  const cleansedApps = filtered.map(updateSearchPage);

  return updateState(state, {
    apps: cleansedApps,
    unfiltered: unfiltered,
    searchTerm: value,
    pagination: AppHelpers.getPagination(cleansedApps),
  });
};

const setApps = (state, apps) => {
  const _apps = normalizeChartboostApps(apps);
  const _copy = normalizeChartboostApps(apps);
  return updateState(state, {
    apps: _apps,
    unfiltered: _copy,
    pagination: AppHelpers.getPagination(_copy),
    state: LOADED,
    importState: LOADED,
  });
};

const apply = (state, type, payload) => {
  switch (type) {
    /* FETCH_CHARTBOOST_APPS */

    case Actions.started(ChartboostActions.FETCH_CHARTBOOST_APPS):
      return updateState(state, { state: LOADING, importState: LOADING, searchTerm: '' });

    case Actions.failure(ChartboostActions.FETCH_CHARTBOOST_APPS):
      return updateState(state, { state: LOADED, importState: LOADED });

    case Actions.success(ChartboostActions.FETCH_CHARTBOOST_APPS):
      return setApps(state, payload.response.apps);

    /* IMPORT_CHARTBOOST_APP */

    case Actions.started(ChartboostActions.IMPORT_CHARTBOOST_APP):
      return updateState(state, { importState: LOADING });

    case Actions.failure(ChartboostActions.IMPORT_CHARTBOOST_APP):
      return updateState(state, { importState: LOADED });

    case Actions.success(ChartboostActions.IMPORT_CHARTBOOST_APP):
      return AppReducer.setImportedApp(state, payload);

    /* LOCAL ACTIONS */

    case ChartboostActions.RESET_IMPORT:
      return AppReducer.clearImportedApp(state);

    case ChartboostActions.SET_CHARTBOOST_PAGE:
      let pag = state.chartboost.pagination;
      return updateState(state, { pagination: { total: pag.total, active: payload.value } });

    case ChartboostActions.SEARCH_CHARTBOOST_APPS:
      return searchApps(state, payload);

    case ChartboostActions.CLEAR_CHARTBOOST_SEARCH:
      let clearedApps = state.chartboost.unfiltered;
      return updateState(state, { searchTerm: '', apps: clearedApps });

    default:
      return state;
  }
};

const Chartboost = {
  initState,
  updateState,
  apply,
};

export default Chartboost;
