import React from 'react';
import { Label } from 'semantic-ui-react';

import NetworkConstants from '../../network/constants';

const PartnerPlacementDetails = ({ network, partnerDetails, partnerPlacementName }) => {
  let extraField;

  if (partnerDetails) {
    const fieldName = NetworkConstants.PARTNER_DETAIL_MAP[network];
    const fieldLabel = NetworkConstants.NETWORK_FIELDS[fieldName];
    const fieldValue = partnerDetails[fieldName];
    if (fieldValue) {
      extraField = (
        <Label className="lineItem-createTable-partner-details">
          {fieldLabel}: {fieldValue}
        </Label>
      );
    }
  }

  return (
    <span>
      {partnerPlacementName} {extraField}
    </span>
  );
};

export default PartnerPlacementDetails;
