import React from 'react';
import { Link } from 'react-router-dom';
import { Label, Checkbox, Button, Card } from 'semantic-ui-react';
import AppCard from './AppCard';

class HeliumCard extends AppCard {
  constructor(props) {
    super(props);
    this.type = 'helium-card';
  }

  renderHeader = (name) => (
    <Card.Header>
      <Link to={`/apps/${this.props.app.key}`}>{name}</Link>
    </Card.Header>
  );

  renderMeta = (app) => {
    const platform = this.props.app.platform;
    let platformIcon = 'amazon';
    if (platform === 'iOS') {
      platformIcon = 'apple';
    } else if (platform === 'Android') {
      platformIcon = 'android';
    }
    return (
      <Card.Meta>
        <div className="helium-app-details">
          <div className="appid">
            <span>{app.key}</span>
          </div>
          <div className="bundle">
            <span>{this.props.app.advertiser_bundle}</span>
          </div>
          <Label
            icon={platformIcon}
            floated="left"
            content={<span class={`placement-label-name-${platform}`}>{platform}</span>}
            className={`os ${platformIcon}`}
          />
        </div>
      </Card.Meta>
    );
  };

  renderExtra = () => {
    const app = this.props.app;
    const action = (event, { name, value }) => {
      const val = value === 'true' ? true : false;
      this.props.toggleFunction(app, name, val);
    };
    return (
      <Card.Content extra>
        <Button as={Link} to={`/apps/${app.id}`} icon="pencil" floated="left" basic />
        <Checkbox
          data-testid="app-active-checkbox"
          checked={app.active}
          name="active"
          onClick={action}
          toggle
          value={String(!app.active)}
          className="helium-cb"
          floated="right"
        />
      </Card.Content>
    );
  };
}

export default HeliumCard;
