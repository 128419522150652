/**
 * @namespace LineItems/Actions
 */
import Actions from 'core/framework/actions';
import Api from './api';

/* Actions */

const terms = {
  CLEAR_SELECTED_LINE_ITEM: 'CLEAR_SELECTED_LINE_ITEM',
  CREATE_LINE_ITEM: 'LINEITEMS.CREATE',
  CREATE_LOCAL_LINE_ITEM: 'CREATE_LOCAL_LINE_ITEM',
  DELETE_LINE_ITEM: 'LINEITEM.DELETE',
  EDIT_LINE_ITEM: 'LINEITEM.EDIT',
  FETCH_COMPANY_LINE_ITEMS: 'LINEITEMS.COMPANY.FETCH',
  FETCH_COMPANY_LINE_ITEM_NAMES: 'COMPANY.LINEITEM.NAMES.FETCH',
  FETCH_LINE_ITEMS: 'LINEITEMS.FETCH',
  SET_LINE_ITEM_FIELD: 'LINEITEM.FIELD.SET',
  CREATE_RULE: 'LINEITEM.CREATE.RULE',
  DELETE_RULE: 'LINEITEM.DELETE.RULE',
  NEGATE_RULE: 'LINEITEM.NEGATE.RULE',
  ADD_PREDICATE: 'LINEITEM.ADD.PREDICATE',
  DELETE_PREDICATE: 'LINEITEM.DELETE.PREDICATE',
  EDIT_PREDICATE: 'LINEITEM.EDIT.PREDICATE',
};

const fetchAll = Actions.create({
  type: terms.FETCH_LINE_ITEMS,
  args: ['appId'],
  awaits: Api.getAll,
  message: ['fetch-failure', 'lineitems'],
});

const fetchCompanyLineItems = Actions.create({
  type: terms.FETCH_COMPANY_LINE_ITEMS,
  args: [],
  awaits: Api.getCompanyLineItems,
  message: ['fetch-failure', 'lineitems'],
});

const fetchCompanyLineItemNames = Actions.create({
  type: terms.FETCH_COMPANY_LINE_ITEM_NAMES,
  args: [],
  awaits: Api.getCompanyLineItemNames,
  message: ['fetch-failure', 'lineitems'],
});

const createLineItem = Actions.create({
  type: terms.CREATE_LINE_ITEM,
  args: ['appId', 'item'],
  awaits: Api.create,
  message: ['save', 'lineitems'],
});

const setField = Actions.create({
  type: terms.SET_LINE_ITEM_FIELD,
  args: ['name', 'value'],
});

const createLocal = Actions.create({
  type: terms.CREATE_LOCAL_LINE_ITEM,
  args: [],
});

const deleteLineItem = Actions.create({
  args: ['appId', 'itemId'],
  awaits: Api.deleteLineItem,
  message: ['delete', 'line item'],
  type: terms.DELETE_LINE_ITEM,
});

const clearSelectedLineItem = Actions.create({
  type: terms.CLEAR_SELECTED_LINE_ITEM,
  args: [],
});

const editLineItem = Actions.create({
  type: terms.EDIT_LINE_ITEM,
  args: ['appId', 'item'],
  awaits: Api.update,
  message: ['edit', 'lineitem'],
});

const createRule = Actions.create({
  type: terms.CREATE_RULE,
  args: ['appId', 'lineItemId', 'rule'],
  awaits: Api.createRule,
  message: ['save', 'targetingRule'],
});

const deleteRule = Actions.create({
  type: terms.DELETE_RULE,
  args: ['appId', 'lineItemId', 'ruleId'],
  awaits: Api.deleteRule,
  message: ['delete', 'Targeting Rule'],
});

const negateRule = Actions.create({
  type: terms.NEGATE_RULE,
  args: ['appId', 'lineItemId', 'ruleId', 'rule'],
  awaits: Api.negateRule,
  message: ['save', 'Targeting Rule'],
});

export const addPredicate =
  ({ appId, lineItemId, ruleId, predicate }) =>
  async (dispatch) => {
    dispatch({ type: Actions.started(terms.ADD_PREDICATE) });
    try {
      const payload = await Api.addPredicate({ appId, lineItemId, ruleId, predicate });
      dispatch({
        type: Actions.success(terms.ADD_PREDICATE),
        payload,
        message: ['save-success', 'Predicate'],
      });
    } catch (e) {
      const assignedErrorSubstring = 'is already assigned to Predicate';
      const isPredicateAlreadyAssigned =
        e && e.message && e.message.includes(assignedErrorSubstring);
      dispatch({
        type: Actions.failure(terms.ADD_PREDICATE),
        payload: {},
        error: true,
        meta: {
          messages: {
            failure: [
              'save-failure',
              `${isPredicateAlreadyAssigned ? `: Predicate must be unique` : ''}`,
            ],
          },
        },
      });
    }
  };

const deletePredicate = Actions.create({
  type: terms.DELETE_PREDICATE,
  args: ['appId', 'lineItemId', 'ruleId', 'predicateId'],
  awaits: Api.deletePredicate,
  message: ['delete', 'Predicate'],
});

const editPredicate = Actions.create({
  type: terms.EDIT_PREDICATE,
  args: ['appId', 'lineItemId', 'ruleId', 'predicateId', 'predicate'],
  awaits: Api.editPredicate,
  message: ['save', 'Predicate'],
});

const LineItemActions = {
  ...terms,
  clearSelectedLineItem,
  createLineItem,
  createLocal,
  deleteLineItem,
  editLineItem,
  fetchAll,
  fetchCompanyLineItems,
  fetchCompanyLineItemNames,
  setField,
  createRule,
  deleteRule,
  negateRule,
  addPredicate,
  deletePredicate,
  editPredicate,
};

export default LineItemActions;
