import React, { Component } from 'react';
import { connect } from 'react-redux';

//views
import Placements from './Placements';
import AppSelector from 'apps/app/views/AppSelector';
import AdTypeDropdown from '../../common/AdTypeDropdown';
import { Segment, Label } from 'semantic-ui-react';
import { PageHeader, AppLayout, DualHeader } from 'core/app/views';
import MetricsGraphContainer from '../../common/metrics/views/MetricsGraphContainer';

// models
import App from 'apps/app';
import Placement from 'apps/placement';
import AdGroup from 'apps/adgroup';
import LineItem from 'apps/lineitem';
import User from '../../../core/user';
import Network from 'apps/network';

import DateFilter from 'analytics/common/views/DateFilter';
import Format from 'analytics/common/format';
import CommonHelpers from 'analytics/common/helpers';
import { setTitle } from 'utils/helpers';

class ManagePlacements extends Component {
  componentDidMount() {
    if (this.props.appId !== 'unselected') {
      this.props.fetchApp(this.props.appId);
      this.props.fetchPlacements(this.props.appId);
      this.props.selectApp(this.props.appId);
      const { max, min } = this.props.getDateFilter || {};
      this.props.getPlacementOverviewMetrics(this.props.appId, min, max);
      const platform = this.props.app?.platform;
      if (platform) {
        this.props.getSupportedPlacementTypes(platform.toLowerCase());
      }
    }
  }

  componentDidUpdate(prevProps) {
    const appChanged = this.props.appId !== prevProps.appId;
    let curDateFilter = this.props.getDateFilter;
    const platform = this.props.app?.platform;
    if (prevProps.appId !== 'unselected' && this.props.appId !== 'unselected') {
      const appName = this.props?.app?.nickname || this.props?.app?.name;
      setTitle(appName);

      if (
        (prevProps.shouldFetchPlacements === false &&
          this.props.shouldFetchPlacements === true &&
          prevProps.loading === false &&
          this.props.loading === false &&
          !appChanged) ||
        appChanged
      ) {
        this.props.fetchPlacements(this.props.appId);
      }
      if (
        (prevProps.shouldFetchAdGroups === false &&
          this.props.shouldFetchAdGroups === true &&
          this.props.isAdgroupsLoading === false) ||
        appChanged
      ) {
        this.props.fetchAdGroups(this.props.appId);
      }
      if (appChanged) {
        this.props.fetchApp(this.props.appId);
        this.props.getPlacementOverviewMetrics(
          this.props.appId,
          curDateFilter.min,
          curDateFilter.max
        );
      }

      let lastDateFilter = prevProps.getDateFilter;
      if (curDateFilter.min !== lastDateFilter.min || curDateFilter.max !== lastDateFilter.max) {
        this.props.getPlacementOverviewMetrics(
          this.props.appId,
          curDateFilter.min,
          curDateFilter.max
        );
      }
      if (platform !== prevProps.app.platform && platform) {
        this.props.getSupportedPlacementTypes(platform.toLowerCase());
      }
    }
  }

  render = () => {
    const {
      all,
      app,
      appId,
      loading,
      removePlacement,
      togglePlacement,
      getDateFilter,
      setDateFilter,
      supportedPlacementTypes,
    } = this.props;
    const { min, max, type } = getDateFilter || {};
    const context = {
      app: app,
      placements: true,
    };

    const updateDateFilter = (start, end, selectedKey) => {
      const min = Format.date(start || CommonHelpers.timePeriod.week);
      const max = Format.date(end || CommonHelpers.timePeriod.today);
      setDateFilter(app, { min, max, type: selectedKey });
    };

    return (
      <AppLayout bundle="apps" context={context}>
        <Segment padded textAlign="left">
          <DualHeader>
            <PageHeader content="Manage Placements" className="pt-10 pb-10" img={true} />
            <AppSelector id={appId} suffix="placements" />
          </DualHeader>
          <div className="content-padding manage-tab">
            <div className="metrics-filter">
              <DateFilter
                date_key={type}
                date_from={min}
                date_to={max}
                onSelect={(_e) => null}
                onApply={(_e, endDate, startDate, selectedKey) =>
                  updateDateFilter(startDate, endDate, selectedKey)
                }
              />
            </div>
            <MetricsGraphContainer
              overviewMetrics={this.props.overviewMetrics}
              pageView="placementOverview"
            />
            <Segment padded loading={loading} className="smooth-table">
              <Label attached="top" size="large">
                <AdTypeDropdown
                  appId={app.id}
                  supportedPlacementTypes={supportedPlacementTypes}
                  dropdownType="placement"
                />
              </Label>
              <Placements
                adPlacements={all}
                appId={appId}
                togglePlacement={togglePlacement}
                removePlacement={removePlacement}
                placementsOverview={this.props.overviewMetrics?.table_metrics}
              />
            </Segment>
          </div>
        </Segment>
      </AppLayout>
    );
  };
}

const props = (state, ownProps) => {
  const appId = ownProps.match.params.id;
  const app = App.Store.getApp(state, appId) || {};
  return {
    shouldFetchPlacements: Placement.Store.getShouldFetchPlacements(state),
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state, appId),
    shouldFetchLineItems: LineItem.Store.shouldFetch(state),
    isAdgroupsLoading: AdGroup.Store.getLoading(state),
    all: Placement.Store.getAll(state, appId),
    loading: Placement.Store.getLoading(state),
    overviewMetrics: Placement.Store.getOverviewMetrics(state),
    getDateFilter: App.Store.getDateFilter(state),
    appId: appId,
    app: app,
    isBetaTestAccount: User.Store.isBetaTestAccount(state),
    supportedPlacementTypes: Network.Store.getSupportedPlacementTypes(state),
  };
};

const dispatches = {
  togglePlacement: Placement.Actions.togglePlacement,
  fetchPlacements: Placement.Actions.fetchAll,
  fetchAdGroups: AdGroup.Actions.fetchAll,
  fetchLineItems: LineItem.Actions.fetchAll,
  fetchApp: App.Actions.fetchApp,
  removePlacement: Placement.Actions.removePlacement,
  getPlacementOverviewMetrics: Placement.Actions.fetchOverviewMetrics,
  setDateFilter: App.Actions.setDateFilter,
  selectApp: App.Actions.selectApp,
  getSupportedPlacementTypes: Network.Actions.fetchSupportedPlacementTypes,
};

export default connect(props, dispatches)(ManagePlacements);
