import React from 'react';
import { Header, Checkbox, Form } from 'semantic-ui-react';
import CommonHelpers from '../../common/helpers';

const PrioritizeBidding = ({
  isPrioritizeBidding,
  prioritizeBiddingCPM,
  setIsPrioritizeBidding,
  setPrioritizeBiddingCPM,
}) => {
  const handleCpmInput = (value) => {
    const validateNumber = (value) => {
      const numberRegex = /^(?:\d{1,6}(?:\.\d{1,2})?|999999(?:\.00?)?)$/;
      return numberRegex.test(value);
    };
    if (validateNumber(value)) {
      if (value === '0.0') {
        setPrioritizeBiddingCPM(value);
      } else {
        const trimmedValue = parseFloat(value, 10).toString();
        setPrioritizeBiddingCPM(trimmedValue);
      }
    } else if (value.length === 0) {
      setPrioritizeBiddingCPM('0');
    }
  };

  return (
    <div className="prioritize-bidding-container">
      <Header as="h4" content="Prioritize Bidding" />
      <div className="prioritize-bidding-actions">
        <Checkbox
          label={isPrioritizeBidding ? 'Enabled' : 'Disabled'}
          checked={isPrioritizeBidding}
          onClick={() => setIsPrioritizeBidding(!isPrioritizeBidding)}
          toggle
        />
        <Form.Input
          disabled={!isPrioritizeBidding}
          fluid
          placeholder="0"
          iconPosition="left"
          type="number"
          min="0"
          step="0.01"
          icon="dollar"
          label={
            <span className={`prioritize-bidding-cpm-label ${!isPrioritizeBidding && 'disabled'}`}>
              Prioritize Bidding CPM
            </span>
          }
          name="bidding_prioritization_cpm"
          onChange={(_e, { value }) => handleCpmInput(value)}
          value={prioritizeBiddingCPM}
          onWheel={CommonHelpers.numberInputOnWheelPreventChange}
        />
      </div>
    </div>
  );
};

export default PrioritizeBidding;
