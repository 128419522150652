import Api from 'core/framework/api';
import Helpers from './helpers';

const getAll = (appId) =>
  Api.get(`${Api.HELIUM_API}/apps/${appId}/line-items`, null, { token: true });

const getCompanyLineItems = () => Api.get(`${Api.HELIUM_API}/line-items`, null, { token: true });

const getCompanyLineItemNames = () =>
  Api.get(`${Api.HELIUM_API}/line-item-names`, null, { token: true });

const create = (appId, item) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/line-items`, Helpers.toApiLineItem(item), {
    token: true,
    method: 'POST',
  });

const update = (appId, item) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/line-items/${item.id}`, Helpers.toApiLineItem(item), {
    token: true,
    method: 'POST',
  });

const _delete = (appId, itemId) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/adgroups/${itemId}`, null, { token: true });

const deleteLineItem = (appId, lineItemId) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}`, null, {
    token: true,
    method: 'DELETE',
  });

const createRule = ({ appId, lineItemId, rule }) =>
  Api.post(`${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}/rules`, rule, {
    token: true,
    method: 'POST',
  });

const deleteRule = (appId, lineItemId, ruleId) =>
  Api.del(`${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}/rules/${ruleId}`, null, {
    token: true,
    method: 'DELETE',
  });

const negateRule = (appId, lineItemId, ruleId, rule) =>
  Api.put(`${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}/rules/${ruleId}`, rule, {
    token: true,
    method: 'PUT',
  });

const addPredicate = ({ appId, lineItemId, ruleId, predicate }) =>
  Api.post(
    `${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}/rules/${ruleId}/predicates`,
    predicate,
    {
      token: true,
      method: 'POST',
    }
  );

const deletePredicate = (appId, lineItemId, ruleId, predicateId) =>
  Api.del(
    `${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}/rules/${ruleId}/predicates/${predicateId}`,
    null,
    {
      token: true,
      method: 'DELETE',
    }
  );

const editPredicate = ({ appId, lineItemId, ruleId, predicateId, predicate }) =>
  Api.put(
    `${Api.HELIUM_API}/apps/${appId}/line-items/${lineItemId}/rules/${ruleId}/predicates/${predicateId}`,
    predicate,
    {
      token: true,
      method: 'PUT',
    }
  );

const LineItemsApi = {
  _delete,
  create,
  deleteLineItem,
  deleteRule,
  getAll,
  getCompanyLineItems,
  getCompanyLineItemNames,
  update,
  createRule,
  negateRule,
  addPredicate,
  deletePredicate,
  editPredicate,
};

export default LineItemsApi;
