import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { COUNTRIES, TOOLTIPS } from 'core/app/constants/helium';
import { SectionHeader } from 'core/app/views';
import Country from './Country';

import CommonHelpers from '../../common/helpers';

import '../css/countries.css';

const MY_COUNTRIES = COUNTRIES.filter((c) => c.key !== 'other');

class Countries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: null,
      value: '',
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  clearState = () => {
    this.setState({
      country: null,
      value: '',
    });
  };

  addCountry = () => {
    const { country, value } = this.state;
    this.props.add(country, value);
    this.clearState();
  };

  isInvalid = (type) => {
    const { country, value } = this.state;
    if (type === 'ecpm') {
      return !country || !value;
    }
    return !country;
  };

  getCustomFields = (type) => {
    let fields = [];
    if (type === 'ecpm') {
      fields.push(this.EcpmValueField());
    }
    return fields;
  };

  EcpmValueField = () => (
    <Form.Input
      {...{
        key: 'ecpm',
        onChange: this.handleChange,
        hidden: true,
        placeholder: 'eCPM Floor',
        iconPosition: 'left',
        name: 'value',
        value: this.state.value,
        type: 'number',
        icon: 'dollar',
        step: '0.01',
        width: 5,
        min: '0',
        fluid: true,
        onWheel: CommonHelpers.numberInputOnWheelPreventChange,
      }}
    />
  );

  AdGroupItems = (countries) =>
    countries.map((c) => <Country key={c} remove={this.props.remove} code={c} value="" />);

  FloorItems = (countries) =>
    countries.map((c) => (
      <Country
        key={c.country_code}
        remove={this.props.remove}
        code={c.country_code}
        value={c.floor}
      />
    ));

  Items = (countries) =>
    this.props.type === 'ecpm' ? this.FloorItems(countries) : this.AdGroupItems(countries);

  NoContent = (msg) => <span>{msg}</span>;

  render() {
    const { type, noContentMsg, existing = [], title, disabled } = this.props;

    const items = existing.length === 0 ? this.NoContent(noContentMsg) : this.Items(existing);

    const headerProps = {
      className: 'country-floors-header',
      tooltip: TOOLTIPS['country_floor'],
      content: title,
      as: 'h4',
    };

    const selectCountryField = {
      onChange: this.handleChange,
      placeholder: 'Select Country',
      value: this.state.country,
      options: MY_COUNTRIES,
      name: 'country',
      selection: true,
      search: true,
      width: 6,
      fluid: true,
    };

    const customFields = this.getCustomFields(type);

    const addButtonField = {
      onClick: this.addCountry,
      disabled: this.isInvalid(type),
      positive: true,
      icon: 'plus',
      width: 1,
      className: 'center-align',
    };

    const cellWidth = customFields.length === 1 ? 'nine' : 'twelve';

    return (
      <div className="country-floors-container" style={{ display: disabled ? 'none' : 'block' }}>
        <SectionHeader {...headerProps} />
        <div className="country-cards"> {items} </div>
        <div className="country-cards-footer">
          <Form.Group className="countries-form">
            {!this.props.abtest ? <div className={`${cellWidth} wide field`} /> : null}
            <Form.Select {...selectCountryField} />
            {customFields}
            <Form.Button {...addButtonField} />
          </Form.Group>
        </div>
      </div>
    );
  }
}

export default Countries;
