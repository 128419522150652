/**
 * @namespace Core/framework/utils
 */

/**
 * @function exists
 * @param any val
 * @description checks if val is undefined
 * @returns {boolean} true if val is not undefined
 * @memberof Core/framework/utils
 */

const exists = val => typeof val !== 'undefined';

/**
 * @function isObject
 * @param any val
 * @description checks if val is strictly a json object
 * that is not null or array
 * @returns {boolean} true if val is Object {}
 * @memberof Core/framework/utils
 */

const isObject = val => typeof val === 'object' && val !== null && Array.isArray(val) === false;

/**
 * @function hasKey
 * @param any val
 * @param string key
 * @description checks if val is Object {} and has the specified key
 * @returns {boolean} true if val is Object {} and has key
 * @memberof Core/framework/utils
 */

const hasKey = (val, key) => exists(val) && isObject(val) && exists(val[key]);

/**
 * @function get
 * @param any val
 * @param string key
 * @param any def - false
 * @description if val is Object {} and has the specified key
 * it returns the value in the specified key , otherwise it returns
 * the default value def
 * @returns {any} value of key if found , otherwise def
 * @memberof Core/framework/utils
 */

const get = (val, key, def = false) => (hasKey(val, key) ? val[key] : def);

/**
 * @function getArray
 * @param any val
 * @param string key
 * @param any def - false
 * @description if val is Object {} and has the specified key
 * and the value in key is an Array , the array is returned
 * otherwise the default value def is returned
 * @returns {Array} value of key if found , otherwise def
 * @memberof Core/framework/utils
 */

const getArray = (val, key, def = false) =>
  get(val, key, false) && Array.isArray(val[key]) ? val[key] : def;

/**
 * @function isString
 * @param any val
 * @description checks if val is string
 * @returns {boolean} true if val is string
 * @memberof Core/framework/utils
 */

const isString = val => typeof val === 'string';

/**
 * @function isNumber
 * @param any val
 * @description checks if val is number
 * @returns {boolean} true if val is number
 * @memberof Core/framework/utils
 */

const isNumber = val => !isNaN(val);

/**
 * @function isNonZeroArray
 * @param any val
 * @description checks if val is non-empty array
 * @returns {boolean} true if val is non-empty array
 * @memberof Core/framework/utils
 */

const isNonZeroArray = val => Array.isArray(val) && val.length > 0;

/**
 * @function matchString
 * @param string keywords
 * @param string test
 * @description checks if test is a substring of keywords
 * @returns {boolean} true if test is substring
 * @memberof Core/framework/utils
 */

const matchString = (keywords, test) => keywords.indexOf(test.toLowerCase()) >= 0;

/**
 * @function isEmpty
 * @param any val
 * @description checks if val is non-empty string , array , or Object
 * @returns {boolean} true if val is non-empty
 * @memberof Core/framework/utils
 */

const isEmpty = val => {
  if (isObject(val)) {
    return Object.keys(val).length === 0;
  }
  if (Array.isArray(val) || isString(val)) {
    return val.length === 0;
  }
  return true;
};

/**
 * @function toOptions
 * @param array[] items
 * @param string idField - id
 * @param string textField - name
 * @param string valueField - false
 * @description converts an array of objects into options for the
 * select component , sets the option text to the value in the object's key
 * that is specified by textField , sets the option value to the object's key
 * that is specified by valueField
 * @returns {boolean} true if val is non-empty
 * @memberof Core/framework/utils
 */

const toOptions = (
  items,
  idField = 'id',
  textField = 'name',
  valueField = false,
  descriptionField = false,
  icon = null,
) =>
  isNonZeroArray(items)
    ? items.map(i => {
        const option = { key: i[idField], text: i[textField] };
        if (valueField) option.value = i[valueField];
        if (descriptionField) option.description = i[descriptionField];
        if (icon) option.icon = i[icon];
        return option;
      })
    : [];

/**
 * @function clone
 * @param object entry
 * @description clones an object returning a new identical copy
 * @returns {object}
 * @memberof Core/framework/utils
 */

const clone = entry =>
  Object.keys(entry).reduce((parsed, key) => ({ ...parsed, [key]: entry[key] }), {});

/**
 * @function handleFormEvent
 * @param function callback
 * @param any args
 * @description returns a function that applies callback
 * to form field's name and value
 * @returns {object}
 * @memberof Core/framework/utils
 */

const handleFormEvent = (callback, ...args) => (event, { name, value }) =>
  callback(...args, name, value);

/**
 * @function hash
 * @param array values - array of values (any)
 * @param string[] keys - array of strings
 * @description merges the keys array and values array into a JSON dictionary of
 * key/value pairs, used mainly to create action payloads for reducers
 * @returns {object}
 * @memberof Core/framework/utils
 */

const hash = (values, keys = []) =>
  keys.reduce((previous, key, i) => ({ ...previous, [key]: values[i] }), {});

/**
 * @function toDictionary
 * @param array[] list - array of values (any)
 * @param string key - optional string key to use for finding the keys
 * @description takes an array of objects and converts it to a dictionary
 * @returns {object}
 * @memberof Core/framework/utils
 */

const toDictionary = (list, key) =>
  list.reduce(
    (acc, cur) => ({
      ...acc,
      [key ? cur[key] : cur]: cur
    }),
    {}
  );

/**
 * @function getCookie
 * @param string name
 * @description gets cookie's value if set
 * @memberof Core/framework/utils
 */

const getCookie = name => {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

/**
 * @function setCookie
 * @param string name
 * @param string cookieName
 * @description sets given cookieName's value
 * @memberof Core/framework/utils
 */
const setCookie = (name, cookieName) => {
  const DOMAIN = process.env.REACT_APP_DOMAIN || '.chartboost.com' ;
  document.cookie = `${name}=${cookieName};path=/;domain=${DOMAIN}`;
  return;
};

/**
 * @function isValidJSONString
 * @param string json
 * @description returns true if string can be 
 * parsed to JSON without errors
 * @memberof Core/Framework/utils
 */

const isValidJSONString = (str, allow_macros) => {
  if (allow_macros){
    const macro_regex = /%%[A-Z_]+%%/ig;
    str = str.replace(macro_regex, '0');
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const Utils = {
  exists,
  isObject,
  hasKey,
  get,
  getArray,
  isString,
  isNumber,
  isNonZeroArray,
  matchString,
  isEmpty,
  toOptions,
  clone,
  hash,
  handleFormEvent,
  toDictionary,
  getCookie,
  setCookie,
  isValidJSONString,
};

export default Utils;
