import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Image, Modal, Button, Icon, List } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import balloon from '../images/balloon.png';

import User from 'core/user';

import '../css/welcome.css';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  accept = () => {
    this.props.acceptTerms();
    this.setState({ loading: true });
  };

  render() {
    return (
      <Modal defaultOpen closeOnDimmerClick={false} centered={false} className="hl-modal welcome">
        <Modal.Header>Helium by Chartboost</Modal.Header>
        <Modal.Content image>
          <Image wrapped size="medium" src={balloon} />
          <Modal.Description>
            <h2> Welcome to the Chartboost Helium Dashboard </h2>
            <List>
              <List.Item>
                <List.Icon name="check" />
                <List.Content>
                  Elevate Performance and Transparency with Unified Auction
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="check" />
                <List.Content>The most transparent in-app unified auction technology</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="check" />
                <List.Content>Easy SDK Integration</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="check" />
                <List.Content>Diverse High-Quality Demand</List.Content>
              </List.Item>
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className="right-align">
          <div className="fs-12 pb-10">
            <span>
              By clicking proceed you agree to the{' '}
              <Link to="/terms-of-service" target="_blank">
                Unified Auction Service Addendum.
              </Link>
            </span>
          </div>
          <Button loading={this.state.loading} positive onClick={this.accept}>
            Proceed <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  acceptTerms: User.Actions.acceptTerms
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Welcome)
);
