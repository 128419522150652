import React from 'react';
import { Message, Transition } from 'semantic-ui-react';

const FORMATS = {
  error: {
    color: 'red',
    error: true,
    icon: 'warning sign'
  },
  info: {
    color: 'blue',
    icon: 'info circle',
    info: true
  },
  success: {
    color: 'green',
    icon: 'check circle',
    success: true
  },
  warning: {
    color: 'yellow',
    icon: 'warning circle',
    warning: true
  }
};

const Messages = ({ dismissMessage, messages }) => {
  if (messages.length) {
    return (
      <div className="Messages">
        {messages.map(({ content, header, index, format }) => (
          <Transition animation="bounce" key={index} transitionOnMount>
            <Message
              key={index}
              content={content}
              header={header}
              onDismiss={() => dismissMessage(index)}
              {...FORMATS[format]}
            />
          </Transition>
        ))}
      </div>
    );
  }
  return null;
};

export default Messages;
