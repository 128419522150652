import React, { Component } from 'react';
import { Form, Header } from 'semantic-ui-react';
import CommonHelpers from '../../common/helpers';

class BannerRefresh extends Component {
  render() {
    const { placement, enable, disable, set, min, max } = this.props;
    const isBanner =
      placement && (placement.type === 'banner' || placement.type === 'adaptive_banner');
    const value = placement.auto_refresh_rate;

    if (!isBanner) {
      return null;
    }

    const isEnabled = value > 0;
    const myLabel = isEnabled ? 'Enabled' : 'Disabled';

    const toggle = () => {
      if (isEnabled) {
        disable();
      } else {
        enable();
      }
    };

    let error;

    if (isEnabled && (value < min || value > max)) {
      error = {
        content: `value must be between ${min} and ${max}`,
        pointing: 'above',
      };
    }

    const validateBannerRefresh = (e) => {
      const sanitized = String(e.target.value).trim();
      const isValid = /^[1-9][0-9]*$/.test(sanitized);
      if (isValid) {
        set(e.target.value);
      }
    };

    return (
      <div>
        <Header as="h4" content="Banner Automatic Refresh" />
        <Form>
          <Form.Field>
            <Form.Checkbox
              label={myLabel}
              name="banner_auto_refresh_status"
              value={true}
              checked={isEnabled}
              onChange={toggle}
              toggle
            />
          </Form.Field>
          <Form.Field disabled={!isEnabled} className="banner-refresh-counter">
            Refresh Rate
            <Form.Input
              disabled={!isEnabled}
              placeholder="30"
              step="1"
              min={min}
              max={max}
              type="number"
              name="banner_auto_refresh_seconds"
              onChange={validateBannerRefresh}
              value={placement.auto_refresh_rate}
              className="banner-refresh-input"
              error={error}
              onWheel={CommonHelpers.numberInputOnWheelPreventChange}
            />
            Seconds
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default BannerRefresh;
