const DEFAULT_PERIODS = [
  { key: 'custom', label: '' },
  { key: 'today', label: 'Today', offset: 1 },
  { key: 'yesterday', label: 'Yesterday', offset: 1 },
  { key: 'last-7-days', label: 'Last 7 days', offset: 6 },
  { key: 'last-14-days', label: 'Last 14 days', offset: 13 },
  { key: 'last-30-days', label: 'Last 30 days', offset: 29 },
  { key: 'last-90-days', label: 'Last 90 days', offset: 89 },
];

const ANALYTICS_LABEL_MAP = {
  earnings: 'Estimated Earnings',
  ecpm: 'eCPM',
  requests: 'Auctions',
};

const SHOW_COLUMN_ORDER = {
  // All Performance MenuType
  All: [
    'dt',
    'app_id',
    'helium_placement',
    'partner_placement',
    'demand_partner',
    'country',
    'earnings',
    'impressions',
    'requests',
    'fill_rate',
    'ecpm',
  ],
  Bidding: [
    'dt',
    'app_id',
    'helium_placement',
    'partner_placement',
    'demand_partner',
    'country',
    'earnings',
    'requests',
    'valid_bids',
    'winning_bids',
    'impressions',
    'fill_rate',
    'ecpm',
    'bid_rate',
    'win_rate',
    'survival_rate',
  ],
  Waterfall: [
    'dt',
    'app_id',
    'helium_placement',
    'partner_placement',
    'demand_partner',
    'country',
    'earnings',
    'impressions',
    'requests',
    'responses',
    'fill_rate',
    'ecpm',
  ],
  // Fallback in case MenuType cannot be determined
  AllColumns: [
    'dt',
    'app_id',
    'helium_placement',
    'partner_placement',
    'demand_partner',
    'country',
    'placement_type',
    'ad_requests',
    'bid_range',
    'bid_rate',
    'earnings',
    'earnings_perc',
    'ecpm',
    'fill_rate',
    'impressions',
    'load_successes',
    'num_bids',
    'num_bids_perc',
    'requests',
    'responses',
    'survival_rate',
    'valid_bids',
    'win_rate',
    'winning_bids',
    'wins',
  ],
};

const ANALYTICS_LABELS = {
  ad_requests: 'Ad Requests',
  app_id: 'App',
  bid_range: 'Price Range',
  bid_rate: 'Bid Rate',
  country: 'Country',
  demand_partner: 'Demand Partner',
  dt: 'Date',
  earnings: 'Estimated Earnings',
  earnings_perc: 'Earnings Percentage',
  ecpm: 'eCPM',
  fill_rate: 'Fill Rate',
  helium_placement: 'Placement',
  impressions: 'Impressions',
  load_successes: 'Load Successes',
  num_bids: 'Impressions Shown',
  num_bids_perc: 'Impressions Percentage',
  partner_placement: 'Partner Placement',
  placement_type: 'Placement Type',
  requests: 'Auctions',
  responses: 'Responses',
  survival_rate: 'Impression Show Rate',
  valid_bids: 'Valid Bids',
  win_rate: ' Win Rate',
  winning_bids: 'Winning Bids',
  wins: 'Wins',
};

const NETWORK_TYPE_OPTIONS = [
  {
    key: 'all',
    value: 'all',
    text: 'All',
  },
  {
    key: 'bidding',
    value: 'bidding',
    text: 'Bidding',
  },
  {
    key: 'mediation',
    value: 'mediation',
    text: 'Mediation',
  },
];

const Constants = {
  ANALYTICS_LABELS,
  ANALYTICS_LABEL_MAP,
  DEFAULT_PERIODS,
  NETWORK_TYPE_OPTIONS,
  SHOW_COLUMN_ORDER,
};

export default Constants;
