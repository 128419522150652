import React, { Component } from 'react';
import { COUNTRIES } from 'core/app/constants/helium';
import { Flag, Label, Icon } from 'semantic-ui-react';

class Country extends Component {
  getCountry = code => {
    let country = COUNTRIES.find(c => c.value === code);
    return country || { flag: '', text: '' };
  };

  render() {
    let { code, value } = this.props;
    const country = this.getCountry(code);
    const remove = this.props.remove.bind(this, code);
    return (
      <Label image className="country-floor-label" key={country.flag}>
        <Flag name={country.flag} />
        {country.text}
        <span>{value}</span>
        <Icon name="delete" onClick={remove} />
      </Label>
    );
  }
}

export default Country;
