import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import logo from 'core/app/images/logo.png';

const Sidebar = ({ logout, selectedApp }) => {
  const [activeItem, setActiveItem] = useState('overview');
  useEffect(() => {
    switch (window.location.pathname) {
      case '/apps':
        setActiveItem('overview');
        break;
      case `/apps/${selectedApp}`:
        setActiveItem('overview');
        break;
      case `/apps/${selectedApp || 'unselected'}/networks`:
        setActiveItem('networks');
        break;
      case `/apps/${selectedApp || 'unselected'}/placements`:
        setActiveItem('placements');
        break;
      case `/apps/${selectedApp || 'unselected'}/adgroups`:
        setActiveItem('adgroups');
        break;
      case '/analytics/performance':
        setActiveItem('performance');
        break;
      case '/analytics/reports':
        setActiveItem('reports');
        break;
      case '/analytics/abtest':
        setActiveItem('abtests');
        break;
      case '/settings':
        setActiveItem('general');
        break;
      case '/settings/adomain-settings':
        setActiveItem('adomain-settings');
        break;
      case '/settings/adomain-reports':
        setActiveItem('adomain-reports');
        break;
      case '/settings/lineitem-import':
        setActiveItem('lineitem-import');
        break;
      case '/settings/keywords-management':
        setActiveItem('keywords-management');
        break;
      case '/resources/api':
        setActiveItem('api');
        break;
      default:
        break;
    }
  }, [selectedApp, window.location.pathname]);

  return (
    <div className="SideColumn">
      <div className="Header">
        <div>
          <img src={logo} className="Header-logo" alt="logo" />
          <span className="Header-name">Helium</span>
        </div>
      </div>
      <Menu vertical className="Sidebar">
        {/* Apps */}
        <Menu.Item>
          <Menu.Header>
            <Icon name={'mobile alternate'} /> {'Apps'}
          </Menu.Header>
          <Menu.Item key={'overview'} as={Link} to={'/apps'} active={activeItem === 'overview'}>
            Overview
          </Menu.Item>
          <Menu.Item
            key={'netoworks'}
            as={Link}
            to={`/apps/${selectedApp || 'unselected'}/networks`}
            active={activeItem === 'networks'}
          >
            Networks
          </Menu.Item>
          <Menu.Item
            key={'placements'}
            as={Link}
            to={`/apps/${selectedApp || 'unselected'}/placements`}
            active={activeItem === 'placements'}
          >
            Placements
          </Menu.Item>
          <Menu.Item
            key={'agroup'}
            as={Link}
            to={`/apps/${selectedApp || 'unselected'}/adgroups`}
            active={activeItem === 'adgroups'}
          >
            Ad Groups
          </Menu.Item>
        </Menu.Item>

        {/* Analytics */}
        <Menu.Item>
          <Menu.Header>
            <Icon name={'chart bar'} /> {'Analytics'}
          </Menu.Header>
          <Menu.Item
            key={'performance'}
            as={Link}
            to={'/analytics/performance'}
            active={activeItem === 'performance'}
          >
            Performance
          </Menu.Item>
          <Menu.Item
            key={'reports'}
            as={Link}
            to={'/analytics/reports'}
            active={activeItem === 'reports'}
          >
            Reports
          </Menu.Item>
          <Menu.Item
            key={'abtests'}
            as={Link}
            to={'/analytics/abtest'}
            active={activeItem === 'abtests'}
          >
            A/B Tests
          </Menu.Item>
        </Menu.Item>

        {/* Tools */}
        <Menu.Item>
          <Menu.Header>
            <Icon name={'wrench'} /> {'Tools'}
          </Menu.Header>
          <Menu.Item key={'general'} as={Link} to={'/settings'} active={activeItem === 'general'}>
            General Settings
          </Menu.Item>
          <Menu.Item
            key={'adomain-settings'}
            as={Link}
            to={'/settings/adomain-settings'}
            active={activeItem === 'adomain-settings'}
          >
            Adomain Settings
          </Menu.Item>
          <Menu.Item
            key={'adomain-reports'}
            as={Link}
            to={'/settings/adomain-reports'}
            active={activeItem === 'adomain-reports'}
          >
            Adomain Reports
          </Menu.Item>
          <Menu.Item
            key={'lineitem-import'}
            as={Link}
            to={'/settings/lineitem-import'}
            active={activeItem === 'lineitem-import'}
          >
            Import Line Items
          </Menu.Item>
          <Menu.Item
            key={'keywords-management'}
            as={Link}
            to={'/settings/keywords-management'}
            active={activeItem === 'keywords-management'}
          >
            Keywords
          </Menu.Item>
        </Menu.Item>

        {/* Resources */}
        <Menu.Item>
          <Menu.Header>
            <Icon name={'list'} /> {'Resources'}
          </Menu.Header>
          <Menu.Item
            key={'docs'}
            as={'a'}
            target={'_blank'}
            href={'https://docs.chartboost.com/en/mediation/get-started/'}
          >
            Documentation
          </Menu.Item>
          <Menu.Item key={'api'} as={Link} to={'/resources/api'} active={activeItem === 'api'}>
            API Explorer
          </Menu.Item>
        </Menu.Item>

        {/* Actions */}
        <Menu.Item>
          <Menu.Header>
            <Icon name={'user'} /> {'Actions'}
          </Menu.Header>
          <Menu.Item key={'logout'} onClick={logout}>
            Log out
          </Menu.Item>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;
