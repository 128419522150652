import { LOADING, UNLOADED } from 'core/app/constants/readyStates';

const getNew = (state) => state.apps.newLineItem;
const getSelected = (state) => state.apps.selectedLineItem;
const getLoading = (state) => state.apps.lineItemsState === LOADING;
const shouldFetch = (state) =>
  state.apps.lineItemsState === UNLOADED && state.apps.lineItemsState !== LOADING;
const shouldFetchCompanyLineItems = (state) => state.apps.company.lineItemsState === UNLOADED;
const getCompanyLineItems = (state) => state.apps.company.lineItems;
const getCompanyLineItemNames = (state) => state.apps.company.lineItemNames;

const Store = {
  getNew,
  getSelected,
  getLoading,
  shouldFetch,
  shouldFetchCompanyLineItems,
  getCompanyLineItemNames,
  getCompanyLineItems,
};

export default Store;
