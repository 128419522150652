import AppStore from '../app/store';
import Constants from './constants';
import { LOADING, UNLOADED } from 'core/app/constants/readyStates';

/**
 * Placement level credentials contains network names as keys and credentials as values,
 *
 * @typedef {Object} PLACEMENT_CREDENTIALS
 * @example
 *
 * {
 *   chartboost_creds: {
 *     app_id: "5c37c51476f3910c3cf92534",
 *     app_signature: "e29012de354dca5b190faac05f01820586e40814"
 *   },
 *   facebook_creds: {
 *     applicationid: "428939561173260",
 *   }
 * }
 */

const getState = (state, id) => {
  const app = AppStore.getApp(state, id) || {};
  return app.placements || [];
};

const getAll = (state, id) => getState(state, id).filter(({ id }) => typeof id !== 'undefined');

const getAllAppPlacements = (state, appId) => {
  return getState(state, appId) || [];
};

const getAllABTestedPlacements = (state, appId) => {
  const placements = getState(state, appId) || [];
  return placements.filter((p) => p.has_abtest && p.ab_type === 'A');
};

const getByType = (state, appId, type) => {
  const all = getAll(state, appId);
  return all.filter((a) => a.type === type);
};

const getOne = (state, id, pid) => getState(state, id).find(({ id }) => id === parseInt(pid, 10));

const getLoading = (state) => state.apps.placementsReadyState === LOADING;

const getShouldFetchPlacements = (state) =>
  state.apps.placementsReadyState === UNLOADED && state.apps.placementsReadyState !== LOADING;

const getShouldFetchCompanyPlacements = (state) => state.apps.company.placementsState === UNLOADED;

const getCompanyPlacements = (state) => state.apps.company.placements;

const getCompanyPlacementsNames = (state) => state.apps.company.placementNames;

const getDefaults = (state, names = []) =>
  Constants.DEFAULT_PLACEMENTS.filter(({ value }) => !names.includes(value));

const getNewPlacement = (state) => state.apps.newPlacement;

const getCredsLoading = (state) => state.apps.placementCredState === LOADING;

const getNewPlacementId = (state) => state.apps.newPlacementId;

const getShouldRedirectToAB = (state) => state.apps.redirectToAB;

const hasMadeChanges = (state) => state.apps.placementChanged;

const getMediationMetrics = (state) => state.apps.placementMediationMetrics;

const getBTestMediationMetrics = (state) => state.apps.placementBTestMediationMetrics;

const getBiddingMetrics = (state) => state.apps.placementBiddingMetrics;

const getBTestBiddingMetrics = (state) => state.apps.placementBTestBiddingMetrics;

const getCalculatedTotalsMetrics = (state) => state.apps.placementCalculatedTotalsMetrics;

const getGraphDataMetrics = (state) => state.apps.placementGraphDataMetrics;

const getOverviewMetrics = (state) => state.apps.placementOverviewMetrics;

const getTimelineMetrics = (state) => state.apps.appOverviewTimelineMetrics;

const getMetricsGraphData = (state) => state.apps.metricGraphData;

const shouldFetchPlacementLineItems = (state) => state.apps.placementLineItemState === UNLOADED;

const Store = {
  getAll,
  getOne,
  getByType,
  getLoading,
  getDefaults,
  getNewPlacement,
  getNewPlacementId,
  getCredsLoading,
  getShouldFetchPlacements,
  getShouldFetchCompanyPlacements,
  getCompanyPlacements,
  getCompanyPlacementsNames,
  getShouldRedirectToAB,
  hasMadeChanges,
  getMediationMetrics,
  getBTestMediationMetrics,
  getBiddingMetrics,
  getBTestBiddingMetrics,
  getCalculatedTotalsMetrics,
  getGraphDataMetrics,
  getOverviewMetrics,
  getTimelineMetrics,
  getMetricsGraphData,
  getAllABTestedPlacements,
  getAllAppPlacements,
  shouldFetchPlacementLineItems,
};

export default Store;
