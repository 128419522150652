import React, { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import Constants from '../constants/targetingRules';

const DeleteRuleButton = ({ handleDeleteRule, rule, shouldDisableActions }) => {
  const [open, setOpen] = useState(false);

  const triggerButton = (
    <Button
      disabled={shouldDisableActions}
      fitted
      icon="close"
      onClick={() => setOpen(prevOpen => !prevOpen)}
    />
  );

  const handleDeleteClick = () => {
    handleDeleteRule(rule.uuid);
    setOpen(false);
  };

  const DeleteContent = (
    <div>
      <div className="targeting-rules-description">{Constants.STRINGS.DELETE_DESCRIPTION}</div>
      <div className="flex space-between width-100">
        <Button onClick={handleDeleteClick} color="red" disabled={shouldDisableActions}>
          Delete
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </div>
    </div>
  );

  if (rule && rule.uuid !== undefined) {
    return (
      <Popup
        content={DeleteContent}
        on="click"
        onClose={() => setOpen(false)}
        open={open}
        positionFixed
        position="top right"
        trigger={triggerButton}
      />
    );
  }
  return null;
};

export default DeleteRuleButton;
