import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import TestAccountWarning from 'apps/network/views/TestAccountWarning';

// Helpers for formatting dropdown options from API
const formatTextAdtype = (option) => {
  const replacedUnderscore = option.split('_');
  const upperCaseWord = replacedUnderscore.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return upperCaseWord.join(' ');
};

const formatOptionsDropdown = (options) => {
  return [...options].map((option) => {
    option.name = option.option;
    option.value = option.option;
    option.text = (
      <span>
        {formatTextAdtype(option.option)} {!option.supported && <TestAccountWarning />}
      </span>
    );
    return option;
  });
};

const typeMap = {
  placement: {
    create: 'createPlacement',
    placeholder: 'New Placement',
    dataTestId: 'createPlacementDropdown',
  },
  adgroup: {
    create: 'createAdGroup',
    placeholder: 'New Ad Group',
    dataTestId: 'createAdGroupDropdown',
  },
};

const AdTypeDropdown = (props) => {
  const { appId, supportedPlacementTypes, dropdownType } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // disable button for initial load
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonDisabled(appId === 'unselected' || appId === undefined);
    }, 5000);

    return () => clearTimeout(timer);
  }, [appId]);

  const formattedOptions = formatOptionsDropdown(supportedPlacementTypes || []);
  const placementOptions = [...formattedOptions].map((at) => ({
    ...at,
    as: Link,
    to: `/apps/${appId}/${typeMap[dropdownType].create}/${at.name}`,
  }));

  const dropdownProps = {
    button: true,
    className: 'greenlink',
    disabled: isButtonDisabled,
    forceSelection: false,
    icon: 'plus',
    labeled: true,
    options: placementOptions,
    selectOnBlur: false,
    text: typeMap[dropdownType].placeholder,
  };

  return <Dropdown {...dropdownProps} data-testid={typeMap[dropdownType].dataTestId} />;
};

export default AdTypeDropdown;
