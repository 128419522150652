import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Segment } from 'semantic-ui-react';
import { AppLayout, DualHeader, PageHeader } from 'core/app/views';

// Views
import AppSelector from 'apps/app/views/AppSelector';
import Credentials from 'apps/network/views/Credentials';

// Modules
import App from 'apps/app';
import Network from 'apps/network';
import Store from 'apps/network/store';
import User from 'core/user';
import { setTitle } from 'utils/helpers';

class ManageNetworks extends Component {
  componentDidMount() {
    if (this.props.appId !== 'unselected') {
      this.props.fetchApp(this.props.appId);
      this.props.selectApp(this.props.appId);
      const currentPlatform = this.props.app?.platform?.toLowerCase();
      if (currentPlatform) {
        this.props.getSupportedAppPartners(currentPlatform, 'bidding');
        this.props.getSupportedAppPartners(currentPlatform, 'waterfall');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const appChanged = this.props.appId !== prevProps.appId;
    const isPlatformUpdated =
      this.props.app?.platform !== prevProps.app?.platform &&
      this.props.app?.platform !== undefined;

    if (isPlatformUpdated) {
      const currentPlatform = this.props.app.platform.toLowerCase();
      this.props.getSupportedAppPartners(currentPlatform, 'bidding');
      this.props.getSupportedAppPartners(currentPlatform, 'waterfall');
    }
    if (this.props.appId !== 'unselected') {
      if (appChanged) {
        this.props.fetchApp(this.props.appId);
      }
    }
    setTitle(this.props?.app?.nickname || this.props?.app?.name);
  }

  render() {
    const { app, appId, appPlatform, biddingOptions, mediationOptions, isV2 } = this.props;
    const disableButton = appId === 'unselected' || appId === 'undefined';
    const context = {
      app: app,
      extra: {
        url: `/apps/${appId}/networks`,
        label: 'Manage Networks',
      },
    };

    const biddingProps = {
      app: app,
      create: this.props.create,
      delete: this.props.delete,
      disabled: disableButton,
      edit: this.props.edit,
      networkType: 'bidding',
      options: biddingOptions,
      type: 'app_credentials',
    };

    const mediationProps = {
      app: app,
      create: this.props.create,
      delete: this.props.delete,
      disabled: disableButton,
      edit: this.props.edit,
      networkType: 'mediation',
      options: mediationOptions,
      type: 'app_credentials',
    };

    return (
      <AppLayout bundle="apps" context={context}>
        <Segment padded textAlign="left">
          <DualHeader>
            <PageHeader content="Manage Networks" className="pt-10 pb-10" img={true} />
            <AppSelector id={appId} suffix="networks" />
          </DualHeader>
          <div className="content-padding">
            <Segment padded textAlign="left" className="smooth-table mt-10">
              <Label attached="top" size="large">
                Bidding Networks
              </Label>
              <Credentials {...biddingProps} appId={appId} appPlatform={appPlatform} />
            </Segment>

            <Segment
              padded
              textAlign="left"
              className="smooth-table mt-25"
              style={{ display: isV2 ? 'block' : 'none' }}
            >
              <Label attached="top" size="large">
                Mediation Networks
              </Label>
              <Credentials {...mediationProps} appId={appId} appPlatform={appPlatform} />
            </Segment>
          </div>
        </Segment>
      </AppLayout>
    );
  }
}

const props = (state, ownProps) => {
  const appId = ownProps.match.params.id;
  const app = App.Store.getApp(state, appId) || {};
  const biddingOptions = Store.getUnusedNetworks(state, app, 'bidding');
  const mediationOptions = Store.getUnusedNetworks(state, app, 'mediation');
  const appPlatform = App.Store.getAppPlatform(state, appId);

  return {
    appId: appId,
    app: app,
    appPlatform,
    biddingOptions: biddingOptions,
    mediationOptions: mediationOptions,
    isV2: User.Store.isV2(state),
    isTestAccount: User.Store.isTestAccount(state),
  };
};

const dispatches = {
  ...App.Actions,
  create: App.Actions.createAppCredentials,
  edit: App.Actions.toggleAppCredentials,
  delete: App.Actions.deleteAppCredentials,
  getSupportedAppPartners: Network.Actions.fetchSupportedAppPartners,
};

export default connect(props, dispatches)(ManageNetworks);
