import React from 'react';
import { Loader as SemanticLoader } from 'semantic-ui-react';

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return <SemanticLoader active />;
  }
  return null;
};

export default Loader;
