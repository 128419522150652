import React from 'react';

const PLACEMENT_FIELDS = [
  'active',
  'adgroups',
  'allow_all_ct',
  'app_id',
  'app_name',
  'app_nickname',
  'ask_price_enabled',
  'bidding_prioritization_cpm',
  'country_floors',
  'credentials',
  'floor_active',
  'global_floor',
  'id',
  'interactive_ct',
  'name',
  'prioritize_bidding',
  'proportion',
  'queue_size',
  'static_ct',
  'type',
  'video_ct',
];

const ERRORS = {
  BAD_CHARACTERS: 'Only Letters [A-Z], Numbers [0-9], Underscores "_" and dashes "-" are allowed',
  MAX_LENGTH: 'Maximum of 50 characters exceeded',
  NAME_EXISTS: 'This name is already used in another placement',
  CALLBACK_URL_REQUIRED: 'URL is a required property',
  CALLBACK_URL_INVALID: 'URL must begin with https://',
  CALLBACK_RETRY_RANGE: 'Retry must be a value between 0 and 10',
  CALLBACK_METHOD_INVALID: 'Method must be either GET or POST',
  CALLBACK_BODY_REQUIRED: 'Body is a required property',
  CALLBACK_BODY_INVALID: 'The JSON is invalid',
  CALLBACK_MACROS_MISSING: 'The following macros are required:',
};

const AD_TYPES = [
  { key: 'banner', text: 'Banner', value: 'banner' },
  { key: 'interstitial', text: 'Interstitial', value: 'interstitial' },
  { key: 'rewarded', text: 'Rewarded', value: 'rewarded' },
  { key: 'adaptive_banner', text: 'Adaptive Banner', value: 'adaptive_banner' },
  { key: 'rewarded_interstitial', text: 'Rewarded Interstitial', value: 'rewarded_interstitial' },
];

const AD_TYPE_LABELS = {
  banner: 'Banner',
  interstitial: 'Interstitial',
  rewarded: 'Rewarded',
  adaptive_banner: 'Adaptive Banner',
  rewarded_interstitial: 'Rewarded Interstitial',
};

// SG-1456: Show all placement types for all pubs as of 10/26/2023
const AD_TYPES_OLD = [...AD_TYPES];

const DEFAULT_PLACEMENTS = [
  { key: 'startup', text: 'startup', value: 'startup' },
  { key: 'homeScreen', text: 'homeScreen', value: 'homeScreen' },
  { key: 'mainMenu', text: 'mainMenu', value: 'mainMenu' },
  { key: 'gameScreen', text: 'gameScreen', value: 'gameScreen' },
  { key: 'levelStart', text: 'levelStart', value: 'levelStart' },
  { key: 'levelComplete', text: 'levelComplete', value: 'levelComplete' },
  { key: 'gameOver', text: 'gameOver', value: 'gameOver' },
];

const BANNER_CREATIVE_TYPES = [{ key: 'static_ct', text: 'Static', value: 'static_ct' }];
const BANNER_DEFAULT_REFRESH_RATE = 30;
const BANNER_MAX_REFRESH_RATE = 240;
const BANNER_MIN_REFRESH_RATE = 10;

const INTERSTITIAL_CREATIVE_TYPES = [
  { key: 'interactive_ct', text: 'Interactive', value: 'interactive_ct' },
  { key: 'video_ct', text: 'Video', value: 'video_ct' },
  { key: 'static_ct', text: 'Static', value: 'static_ct' },
];

const REWARDED_CREATIVE_TYPES = [
  { key: 'interactive_ct', text: 'Interactive', value: 'interactive_ct' },
  { key: 'video_ct', text: 'Video', value: 'video_ct' },
];

const REQUIRED_CALLBACK_MACROS = [
  '%%TRANSACTION_ID%%',
  '%%SERVER_TIMESTAMP%%',
  '%%USER_ID%%',
  '%%VERIFICATION%%',
];

const TOOLTIPS = {
  floor_active: (
    <ul>
      <li>Establish the minimum eCPM that Helium needs to serve ads at.</li>
      <li>
        <b>Note:</b> setting higher eCPM floor will increase the risk of lowering the fill rate.{' '}
      </li>
    </ul>
  ),
  country_floor: (
    <ul>
      <li>
        Country eCPM floor represents the minimum eCPM that Helium needs to serve ads at for a given
        country.
      </li>
      <li>Country level eCPM floor takes precedent over the global eCPM floor.</li>
    </ul>
  ),
  global_ecpm:
    'Establish the minimum eCPM that Helium needs to serve ads at for all the countries.',
};

const AB_TEST_COLORS = {
  a: 'blue',
  b: 'green',
};

const Constants = {
  AB_TEST_COLORS,
  AD_TYPES,
  AD_TYPES_OLD,
  AD_TYPE_LABELS,
  BANNER_CREATIVE_TYPES,
  DEFAULT_PLACEMENTS,
  INTERSTITIAL_CREATIVE_TYPES,
  PLACEMENT_FIELDS,
  REWARDED_CREATIVE_TYPES,
  BANNER_DEFAULT_REFRESH_RATE,
  BANNER_MAX_REFRESH_RATE,
  BANNER_MIN_REFRESH_RATE,
  REQUIRED_CALLBACK_MACROS,
  ERRORS,
  TOOLTIPS,
};

export default Constants;
