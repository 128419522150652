import React, { Component } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import Constants from 'apps/app/constants';

class QueueSize extends Component {
  render() {
    const { placement, set, app } = this.props;
    const { default_queue_size } = app || {};

    // Hide queue size for banner and adaptive_banner placements
    const placementType = placement?.type;
    if (placementType === 'banner' || placementType === 'adaptive_banner') {
      return null;
    }

    const setPlacementQueueSize = (_e, { value }) => {
      set(value);
    };

    return (
      <div>
        <Header as="h4" content="Queue Size" />
        <Dropdown
          data-testid="placement-queue-size-dropdown"
          placeholder={
            <span style={{ color: 'black' }}>
              App Queue Size{default_queue_size ? ` (${default_queue_size})` : ''}
            </span>
          }
          name="default_queue_size"
          options={Constants.PLACEMENT_QUEUE_SIZE_OPTIONS}
          value={placement?.queue_size || null}
          onChange={setPlacementQueueSize}
        />
      </div>
    );
  }
}

export default QueueSize;
