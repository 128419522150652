import React from 'react';
import { Form } from 'semantic-ui-react';

import CommonHelpers from '../../common/helpers';

interface LineItemCPMFieldProps {
  cpm: string;
  setter: () => void;
}

const LineItemCPMField: React.FunctionComponent<LineItemCPMFieldProps> = ({ cpm, setter }) => (
  <Form.Field>
    <Form.Input
      icon="dollar"
      iconPosition="left"
      min="0"
      name="cpm"
      label="CPM"
      onChange={setter}
      placeholder="0.99"
      type="number"
      required={true}
      value={cpm}
      onWheel={CommonHelpers.numberInputOnWheelPreventChange}
    />
  </Form.Field>
);

export default LineItemCPMField;
