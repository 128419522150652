import React, { Component } from 'react';
import { Form, Dropdown, Button, Input, Table, Label } from 'semantic-ui-react';
import TestAccountWarning from './TestAccountWarning';
import Helpers from '../helpers';
import AdmobForm from '../admob/views/AdmobForm';
import AmazonApsForm from '../amazon_aps/views/AmazonApsForm';
import GoogleBiddingForm from '../google_bidding/views/GoogleBiddingForm';
import GoogleBiddingPlacementForm from '../google_bidding/views/GoogleBiddingPlacementForm';

class CredentialsForm extends Component {
  Network = (networkId) => {
    const { networkType, select } = this.props;
    const id = `${Helpers.getName(networkId)} network option ${networkType}`;
    let isTestAccountWarningShown = false;
    if (networkType === 'mediation') {
      isTestAccountWarningShown = this.props.options.waterfall_supported[networkId];
    } else if (networkType === 'bidding') {
      isTestAccountWarningShown = this.props.options.bidding_supported[networkId];
    }

    const props = {
      key: networkId,
      text: (
        <span>
          {Helpers.getName(networkId)} {!isTestAccountWarningShown && <TestAccountWarning />}
        </span>
      ),
      id,
      value: networkId,
      image: Helpers.getLogo(networkId),
      onClick: select,
    };
    return <Dropdown.Item {...props} />;
  };

  Field = ({ name, type, value, read_only }) => {
    const { edit, network } = this.props;
    let label;
    if (!name) {
      return '';
    } else {
      label = Helpers.getFieldName(network, name);
    }
    const props = {
      autoComplete: 'off',
      fluid: true,
      key: name,
      labelPosition: 'left',
      name: name,
      id: name,
      onChange: edit,
      placeholder: '',
      type: type,
      value: value || '',
    };
    const inputProps = read_only ? { disabled: true } : {};
    return (
      <Form.Field key={`${props.key}_field`}>
        <Input {...props}>
          <Label pointing="right" id={`${name} label`}>
            {label}
          </Label>
          <input {...inputProps} />
          {Helpers.getTooltip(network, name)}
        </Input>
      </Form.Field>
    );
  };

  Button = (valid, network) => {
    const shouldHideAdd =
      !valid &&
      (this.props.type === 'app_credentials' ||
        (network !== 'bidmachine' && this.props.type === 'placement_credentials'));
    if (shouldHideAdd) return <span />;
    const id = `add network button ${this.props.networkType}`;
    return (
      <span>
        <Button icon="plus" onClick={this.props.add} positive id={id} />
      </span>
    );
  };

  render() {
    const {
      action,
      appId,
      appPlatform,
      disabled,
      fields,
      isValid,
      network,
      networkType,
      options,
      placement,
      edit,
      isBetaTestAccount,
      isTestAccount,
    } = this.props;

    let isTestAccountWarningShown = true;
    if (networkType === 'mediation' && network !== '') {
      isTestAccountWarningShown = this.props.options.waterfall_supported[network];
    } else if (networkType === 'bidding' && network !== '') {
      isTestAccountWarningShown = this.props.options.bidding_supported[network];
    }

    const dropdownProps = {
      button: true,
      className: 'icon positive select-network',
      disabled: disabled,
      fluid: true,
      icon: 'angle down',
      id: `select network dropdown ${networkType}`,
      labeled: true,
      placeholder: (
        <span>
          {!isTestAccountWarningShown && <TestAccountWarning color="white" />}{' '}
          {`${Helpers.getName(network)}` || 'Select network'}
        </span>
      ),
      isBetaTestAccount: isBetaTestAccount,
      isTestAccount: isTestAccount,
    };
    // filter supported bidding or mediation options from unused networks
    const filteredSupported = options.unused.filter((option) => {
      if (networkType === 'bidding') {
        return options.bidding_supported[option] !== undefined;
      } else if (networkType === 'mediation') {
        return options.waterfall_supported[option] !== undefined;
      }
    });

    const unUsedOptions = filteredSupported || [];
    const _networks = unUsedOptions ? unUsedOptions.map(this.Network) : [];

    const _fields = fields ? fields.map(this.Field) : [];
    const _button = this.Button(isValid, network);

    if (!unUsedOptions || !unUsedOptions.length) return null;

    let CurrentForm = null;
    switch (network) {
      case 'admob':
        CurrentForm = <AdmobForm fields={_fields} appId={appId} />;
        break;
      case 'google_googlebidding':
        CurrentForm = placement ? (
          <GoogleBiddingPlacementForm fields={_fields} placement={placement} edit={edit} />
        ) : (
          <GoogleBiddingForm fields={_fields} appId={appId} />
        );
        break;
      case 'amazon_aps':
        CurrentForm = placement ? (
          <AmazonApsForm
            fields={fields}
            placement={placement}
            edit={edit}
            isBetaTestAccount={isBetaTestAccount}
          />
        ) : (
          <Form>{_fields}</Form>
        );
        break;
      default:
        CurrentForm = <Form>{_fields}</Form>;
        break;
    }

    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={8} style={{ height: '100%' }}>
            {CurrentForm}
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <Dropdown {...dropdownProps}>
                <Dropdown.Menu>
                  <Dropdown.Header icon="key" content={action} id={`${networkType} dropdown`} />
                  <Dropdown.Divider />
                  {_networks}
                </Dropdown.Menu>
              </Dropdown>
              {_button}
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }
}

export default CredentialsForm;
