import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const BASE_LINKS = {
  home: [{ url: '/apps', content: 'Overview' }],
  apps: [{ url: '/apps', content: 'Overview' }],
  analytics: [{ url: '/analytics', content: 'Analytics' }],
  settings: [{ url: '/settings', content: 'General Settings' }],
  resources: [
    // { url: '/resources/sdk', content: 'SDK Guide' },
    // { url: '/resources/docs', content: 'Dashboard Guide' },
    // { url: '/resources/faq', content: 'FAQ' },
    { url: '/resources/api', content: 'API Explorer' }, // disable for non test accounts
  ],
};

const DisabledLink = (link, index, override) => (
  <Breadcrumb.Section
    key={index}
    onClick={() => override(link.url)}
    active={link.url === window.location.pathname}
  >
    {link.content}
  </Breadcrumb.Section>
);

const EnabledLink = (link, index, history) => {
  const allUrlParts = link.url.split('/');
  const docsAvailableSuffixes = [
    '/ab-test',
    '/import-app',
    '/manage-adgroup',
    '/manage-adomain',
    '/manage-networks',
    '/manage-placement',
    '/monitor-performance',
    '/api-explorer-guide',
    '/dashboard-video-guide',
  ];
  const hasMatchingBreadcrumb = docsAvailableSuffixes.some(
    (suffix) => link.url + suffix === window.location.pathname
  );
  const isActive = link.url === window.location.pathname || hasMatchingBreadcrumb;

  const isNotSelected =
    allUrlParts.indexOf('undefined') > -1 || allUrlParts.indexOf('unselected') > -1;

  const handleClick = () => {
    // disables clicking on analytics breadcrumb
    if (link.content === 'Analytics') {
      return;
    }
    if (!isNotSelected) {
      history.push(link.url);
    }
  };

  return (
    <Breadcrumb.Section
      active={isActive || isNotSelected}
      key={index}
      link={link.url !== window.location.pathname}
      onClick={handleClick}
    >
      {link.content}
    </Breadcrumb.Section>
  );
};

const getAppLink = (app) => ({
  url: `/apps/${app.id}`,
  content: app.name,
});

const getPlacementsLink = (app) => ({
  url: `/apps/${app?.id}/placements`,
  content: 'Placements',
});

const getPlacementLink = (app, placement) =>
  placement.id === '__new__'
    ? { url: `/apps/${app.id}/placements/new`, content: 'New Placement' }
    : { url: `/apps/${app.id}/placements/${placement.id}`, content: placement.name };

const getAdGroupsLink = (app) => ({
  url: `/apps/${app.id}/adgroups`,
  content: 'Ad Groups',
});

const getAdGroupLink = (app, adgroup) => ({
  url: `/apps/${app.id}/adgroups/${adgroup.id}`,
  content: adgroup.name,
});

const getExtraLink = (extra) => ({
  url: extra.url,
  content: extra.label,
});

const getLinks = (bundle, context, isBetaTestAccount) => {
  if (isBetaTestAccount) {
    if (BASE_LINKS.resources.length === 3) {
      BASE_LINKS.resources.push({ url: '/resources/api', content: 'API Explorer' });
    }
  }
  const links = [...BASE_LINKS[bundle]];
  const { app, placement, adgroup, adgroups, placements, extra } = context;
  if (app) links.push(getAppLink(app));
  if (adgroups) links.push(getAdGroupsLink(app));
  if (adgroup) links.push(getAdGroupLink(app, adgroup));
  if (placements) links.push(getPlacementsLink(app));
  if (placement) links.push(getPlacementLink(app, placement));
  if (extra) links.push(getExtraLink(extra));
  return links;
};

const Breadcrumbs = ({
  override = false,
  bundle = 'home',
  context,
  history,
  isBetaTestAccount,
}) => {
  const links = getLinks(bundle, context, isBetaTestAccount).map((item, index) =>
    override === false ? EnabledLink(item, index, history) : DisabledLink(item, index, override)
  );

  return (
    <div className="breadcrumbs">
      <Breadcrumb icon="right angle" sections={links} />
    </div>
  );
};

export default withRouter(Breadcrumbs);
