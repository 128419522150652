import { applyMiddleware, compose, legacy_createStore } from 'redux';
import { default as thunk } from 'redux-thunk';
import messaging from './messaging';
import { createReducer, injectReducer } from './reducer';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

const store = legacy_createStore(
  createReducer(),
  {},
  composeEnhancers(applyMiddleware(messaging, thunk))
);

store.reducers = {};
store.injectReducer = injectReducer(store);

export default store;
