import React from 'react';
import { Icon } from 'semantic-ui-react';

import MetricsConstants from '../constants';
import MetricHelpers from '../helpers';

const ToolTipDetails = ({ metric, colorMap, pageView, selectedView, isAdvanced }) => {
  let metricValue = '';
  let metricName = '';

  let legendColor = colorMap[metric?.name] || '#84c342';

  if (pageView === 'singlePlacement') {
    let metricKey = metric && metric.dataKey;
    metricValue = metric?.payload[metricKey];
    metricName = metric?.name;
    if (metricName !== 'Waterfall' || metricName !== 'Bidding') {
      metricName = MetricsConstants.FIELD_LABELS[metric?.name];
    }
    if (metricName === 'Waterfall') {
      legendColor = MetricsConstants.COMMON_COLORS.waterfall;
    }
  }

  if (pageView === 'placementOverview' && isAdvanced) {
    metricName = metric?.name;
    metricValue = metric?.value;
  }

  metricValue = MetricHelpers.formatValue({ value: metricValue, selectedView });

  if (metricValue === '$0.00' || metricValue === '0' || metricValue === '0.00%') {
    return null;
  }

  return (
    <div className="metrics-tooltip-details">
      <div>
        <span style={{ color: legendColor }}>
          <Icon name="square" size="mini" />
        </span>
        <b>{metricName}</b>
      </div>{' '}
      {metricValue}
    </div>
  );
};

export default ToolTipDetails;
