import { LOADED, UNLOADED } from 'core/app/constants/readyStates';

//CHARTBOOST
const getState = state => state.apps.chartboost;
const getImported = state => getState(state).imported;
const getApps = state => getState(state).apps;
const getPagination = state => getState(state).pagination;
const getSearchTerm = state => getState(state).searchTerm;
const getLoading = state => getState(state).importState !== LOADED;
const getShouldFetchApps = state => getState(state).state === UNLOADED;

const Store = {
  getImported,
  getApps,
  getPagination,
  getSearchTerm,
  getLoading,
  getShouldFetchApps
};

export default Store;
