import React, { useEffect, useState } from 'react';
import Loader from '../../../core/app/views/Loader';
import Messages from '../../../core/app/views/Messages';
import { getAuthToken } from 'core/framework/localStorage';

const GoogleLoading = (props) => {
  const { search } = props.location;
  const [state, setState] = useState({ message: '', format: '' });

  const getSearchParams = (url) => {
    const params = url.substring(1).split('&');
    const searchMapping = params.reduce((mapping, param) => {
      const [key, value] = param.split('=');
      mapping[key] = value;
      return mapping;
    }, {});
    return searchMapping;
  };

  const { state: app_ids, code: auth_code } = getSearchParams(search);
  const network = app_ids.split('-')[0];
  const app_id = app_ids.split('-')[1];
  const networkAppId = app_ids.replace(`${network}-${app_id}-`, '');
  const ssoLoginRoute = network === 'admob' ? 'google-login' : 'google-bidding-login';

  let redirect_uri = '';
  if (window.location.host === 'local.chartboost.com:3000') {
    redirect_uri = 'http%3A//local.chartboost.com:3000/google_code';
  } else {
    redirect_uri = `https%3A//${window.location.host}/google_code`;
  }
  useEffect(() => {
    const BODY = {
      auth_code: auth_code,
      app_id: app_id,
      redirect_uri: redirect_uri,
    };

    if (network === 'admob') {
      BODY.admob_app_id = networkAppId;
    } else if (network === 'gbid') {
      BODY.google_googlebidding_app_id = networkAppId;
    }

    let POST_ROUTE = `${process.env.REACT_APP_HELIUM_API}/helium/${ssoLoginRoute}`;
    // if (window.location.host === 'helium.chartboost.com') {
    //   POST_ROUTE = `https://helium-api.chartboost.com/helium/${ssoLoginRoute}`;
    // }

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
      },
      body: JSON.stringify(BODY),
    };

    fetch(POST_ROUTE, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || (data && data.error) || response.status;
          setState({ message: error.toString(), format: 'error' });
          return Promise.reject(error);
        }
        setState({ message: data.message, format: 'success' });
        setTimeout(() => props.history.push(`/apps/${app_id}/`), 2000);
      })
      .catch((error) => {
        setState({ message: error.toString(), format: 'warning' });
        console.error('There was an error!', error);
        setTimeout(() => props.history.push(`/apps/${app_id}/`), 2000);
      });
  }, []);

  return (
    <div>
      <Messages
        messages={[{ content: state.message, header: 'Login via Google', format: state.format }]}
        dismissMessage={() => {}}
      />
      <Loader isLoading={true} className="google-login-loading" />
    </div>
  );
};

export default GoogleLoading;
