import React, { Component } from 'react';
import { connect } from 'react-redux';

// Views
import { Pagination, Segment, Input, Card, Image, Icon, Label, Header } from 'semantic-ui-react';
import { PageHeader, AppLayout } from 'core/app/views';
import ChartboostAppCard from './ChartboostAppCard';
import cblogo from 'apps/network/images/chartboost.svg';

//Models
import Chartboost from 'apps/chartboost';
import App from 'apps/app';
import { setTitle } from 'utils/helpers';

import '../css/chartboost.css';

class AppImport extends Component {
  componentDidMount() {
    this.props.fetchApps();
    setTitle('Helium Dashboard - Import Apps');
  }

  componentDidUpdate() {
    const { appId, resetLastImport } = this.props;
    if (appId) {
      resetLastImport();
      this.props.history.push(`/apps/${appId}`);
    }
  }

  filterApps(term) {
    this.props.searchApps(term);
  }

  handlePageChange(e, p) {
    this.props.setPage(p.activePage);
  }

  clearSearch() {
    this.props.clearSearch();
  }

  render() {
    const { apps, pagination, importApp, loading, importedApps, searchTerm } = this.props;
    const total = apps.length;
    const cbapps = apps.filter(({ page }) => {
      return page === pagination.active;
    });

    let searchLabel;
    if (searchTerm !== '') {
      searchLabel = (
        <Label className="filter-label">
          <Icon name="close" onClick={(e) => this.clearSearch()} />
          {searchTerm}{' '}
          <Label.Detail>
            <Icon name="filter" />
          </Label.Detail>
        </Label>
      );
    }

    return (
      <AppLayout bundle="apps" context={{ extra: { url: '/apps/new', label: 'Import App' } }}>
        <Segment textAlign="left" attached="bottom">
          <Segment basic>
            <PageHeader content="Import Apps" img={true} />
            <Segment basic loading={loading}>
              <div className="from-cb">
                <div className="mb-10">Import your Chartboost apps into Helium</div>
                <Input
                  fluid
                  value={searchTerm}
                  icon="search"
                  placeholder="Search..."
                  onChange={(e) => this.filterApps(e.target.value)}
                />
              </div>
              <Header as="h3" className="chartboost-header center-align">
                <Image src={cblogo} /> Chartboost Apps
              </Header>
              <div className="search-results-labels">
                <Label>
                  <Icon name="search" />
                  Results <Label.Detail>{total}</Label.Detail>
                </Label>
                {searchLabel}
              </div>
              <Card.Group centered itemsPerRow={4}>
                {cbapps.map((app) => (
                  <ChartboostAppCard
                    key={app.key}
                    importFunction={importApp}
                    importedApps={importedApps}
                    app={app}
                  />
                ))}
              </Card.Group>
            </Segment>
            <div className="center-align">
              <Pagination
                boundaryRange={0}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                activePage={pagination.active}
                totalPages={pagination.total}
                onPageChange={this.handlePageChange.bind(this)}
              />
            </div>
          </Segment>
        </Segment>
      </AppLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  appId: Chartboost.Store.getImported(state),
  apps: Chartboost.Store.getApps(state),
  loading: Chartboost.Store.getLoading(state),
  shouldFetchApps: Chartboost.Store.getShouldFetchApps(state),
  pagination: Chartboost.Store.getPagination(state),
  importedApps: App.Store.getImportedApps(state),
  searchTerm: Chartboost.Store.getSearchTerm(state),
});

const mapDispatchToProps = {
  ...Chartboost.Actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppImport);
