import React from 'react';

import { Checkbox, Icon, Input } from 'semantic-ui-react';

import AppIcon from './AppIcon';

const AppDetailCard = ({ ...props }) => {
  const { appId, heliumApp, toggleActive } = props;
  let url = heliumApp?.url || '';
  if (heliumApp.platform === 'Amazon') {
    let asin = url.split('asin=');
    if (asin[1] !== undefined) {
      url = `https://www.amazon.com/dp/${asin[1]}`;
    }
  }
  return (
    <div style={{ display: 'flex', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100px' }}>
        <AppIcon
          appId={appId}
          appIcon={heliumApp.icon}
          platform={heliumApp.platform}
          floated="left"
        />
        <Checkbox
          toggle
          name="active"
          onClick={toggleActive}
          value={String(!heliumApp.active)}
          checked={heliumApp.active}
        />
        Active
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
        <div style={{ minWidth: '250px', paddingRight: '10px', width: '100%', maxWidth: '350px' }}>
          <div>
            <div>
              <b>Helium App ID</b>
            </div>
            <Input value={heliumApp.id} fluid readOnly />
          </div>
          <div>
            <div>
              <b>App Bundle</b>
            </div>
            <Input value={heliumApp.bundle} fluid readOnly />
          </div>
        </div>
        <div style={{ minWidth: '320px', width: '100%' }}>
          <div>
            <div>
              <b>Helium Signature</b>
            </div>
            <Input value={heliumApp.signature} fluid readOnly />
          </div>
          <div>
            <div>
              <b>App Store URL</b>
            </div>
            <Input
              value={url}
              fluid
              readOnly
              iconPosition="left"
              icon={
                url !== '' && (
                  <Icon
                    data-testid="app-platform-url-icon"
                    link
                    name="external alternate"
                    onClick={() => window.open(url, '_blank').focus()}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDetailCard;
