/**
 * @namespace Network/Helpers
 */

import React from 'react';
import _ from 'lodash';
import { Popup, Icon } from 'semantic-ui-react';

import Constants from './constants';
import App from '../app';

/**
 *  @typedef {Object} NETWORK_CREDENTIALS
 *  @description network credentials format expected by the API
 *  @example
 *  {
 *    "app_id": "123456",
 *    "app_signature": "abc1234"
 *  }
 */

/**
 * @function toApiCredentials
 * @param {UI_CREDENTIALS} uiCredentials - network credentials from UI component fields
 * @description converts UICredentials back to api-compliant network credentials for saving
 * @returns {NETWORK_CREDENTIALS} - array of network credentials
 * @memberof Network/Helpers
 */

const toApiCredentials = (uiCredentials) => {
  const normalized = {};
  for (const field of uiCredentials.fields) {
    normalized[field.name] = typeof field.value === 'string' ? field.value.trim() : field.value;
  }
  return normalized;
};

/**
 * @function filter
 * @param {NETWORK_SCHEMA} networks
 * @description filters networks schemas by bidding / mediation
 * @returns {NETWORK_SCHEMA} - filtered schemas
 * @memberof Network/Helpers
 */

const filter = (networks, type) => {
  const filtered = {};
  if (networks) {
    _.forIn(networks, (schema, network) => {
      if (schema.type.includes(type)) {
        filtered[network] = schema;
      }
    });
  }
  return filtered;
};

const getDeprecatedNetworks = (networkType) =>
  networkType === 'bidding'
    ? Constants.DEPRECATED_BIDDING_NETWORKS
    : Constants.DEPRECATED_MEDIATION_NETWORKS;

/**
 * @function createAppCredentials
 * @param {UI_APP} app - app to create placements for
 * @param {string} networkId - example "chartboost"
 * @param {NETWORK_SCHEMA} schema - network's credentials schema
 * @description returns UI fields attributes for new
 * network credentials to be added to an app
 * @returns {Array} - field attributes for credentials form
 * @memberof Network/Helpers
 */

const createAppCredentials = (app, networkId, schema) => {
  return schema.app_credentials.map((field) => {
    field.value = '';
    if (networkId === 'chartboost') {
      switch (field.name) {
        case 'app_id':
          field.value = app.id;
          field.read_only = true;
          break;
        case 'app_signature':
          field.value = app.signature;
          field.read_only = true;
          break;
        default:
          break;
      }
    }
    if (networkId === 'admob') {
      field.google_sso = false;
    }
    if (networkId === 'bidmachine') {
      switch (field.name) {
        case 'source_id':
          field.read_only = false;
          break;
        default:
          break;
      }
    }
    if (networkId === 'amazon_aps') {
      switch (field.name) {
        case 'application_id':
          field.read_only = false;
          break;
        default:
          break;
      }
    }
    return field;
  });
};

/**
 * @function createPlacementCredentials
 * @param {UI_APP} app - app to create placements for
 * @param {string} networkId - example "chartboost"
 * @param {NETWORK_SCHEMA} schema - network's credentials schema
 * @description returns UI fields attributes for new network
 * credentials to be added to a placement
 * @returns {Array} - field attributes for credentials form
 * @memberof Network/Helpers
 */

const createPlacementCredentials = (app, networkId, schema) => {
  const appFields = schema.app_credentials.map((f) => f);
  const placementFields = schema.placement_credentials.map((f) => {
    // clear placement credentials upon selecting network
    delete f.value;
    return f;
  });
  // combine app and placement credential fields
  let fields = appFields.concat(placementFields);
  // prefill app credential fields with values
  let credentials = App.Helpers.getAppCredentials(app)[networkId];
  for (const field of appFields) {
    const val = credentials[field.name];
    field.read_only = true;
    field.value = val;
  }
  return fields;
};

/***********************************
            UI Helpers
************************************/

const getLogo = (network) => {
  const imgSrc = Constants.NETWORK_LOGOS[network] || Constants.NETWORK_LOGOS['default'];
  return {
    avatar: true,
    src: imgSrc,
  };
};

const getName = (network) =>
  network && network.length > 0 ? Constants.NETWORK_LABELS[network] : '';

const getFieldName = (network, credential) => {
  const simple = Constants.NETWORK_FIELDS[credential];
  const prefixed = Constants.NETWORK_FIELDS[`${network}_${credential}`];
  if (credential.startsWith(network) && simple) {
    return simple;
  }

  if (prefixed) {
    return prefixed;
  }

  return credential;
};

const getTooltip = (partner, name) => {
  const tooltipText = Constants.TOOLTIPS[`${partner}_${name}`];
  let tooltip = <i className="tooltip-placeholder" />;
  if (tooltipText)
    tooltip = (
      <Popup
        trigger={<Icon name="question circle" className="tooltip" />}
        content={tooltipText}
        position="top center"
        wide
      />
    );
  return tooltip;
};

const addLongTextPopover = (originalString, offSet) => {
  const tooltipText = originalString;
  let tooltip = <i className="tooltip-placeholder" />;
  if (tooltipText)
    tooltip = (
      <Popup
        trigger={
          <span className="tooltip popovertext">
            {originalString.slice(0, offSet)}
            <Icon name="ellipsis horizontal" className="tooltip popovertext ellipsis" />
          </span>
        }
        content={tooltipText}
        position="top center"
        wide="very"
        pinned="true"
      />
    );
  return tooltip;
};

const hideGoogleSSO = (network_credentials) => {
  const { fields } = network_credentials;
  // checks to see google_sso key is in credentials
  if (fields.some((field) => field.name === 'google_sso')) {
    const google_sso_index = fields.findIndex((obj) => obj.name === 'google_sso');
    network_credentials.fields = network_credentials.fields.map((field) => {
      // hides network credentials based on google_sso values
      if (fields[google_sso_index].value === true) {
        if (
          ['api_key', 'client_id', 'client_secret', 'google_sso', 'refresh_token'].includes(
            field.name
          )
        ) {
          field.hidden = true;
        }
      } else {
        if (['google_sso'].includes(field.name)) {
          field.hidden = true;
        }
      }
      return field;
    });
  }
  return network_credentials;
};

const shouldDisplayCredentialField = (source, field, isPlacement, placement) => {
  if (source?.id === 'google_googlebidding' && isPlacement && field?.name !== 'ad_unit_id') {
    return false;
  } else if (source?.id === 'amazon_aps' && isPlacement && field?.name === 'ad_slot_name') {
    return false;
  } else if (
    source?.id === 'amazon_aps' &&
    placement === undefined &&
    field?.name !== 'application_id'
  ) {
    return false;
  } else if (field?.hidden === true || field?.name === 'google_sso') {
    return false;
  }
  return true;
};

const hasGoogleSSOField = (source) => {
  return (
    Array.isArray(source?.fields) && source.fields.some((field) => field.name === 'google_sso')
  );
};

const shoudDisplayRevokeGoogleSSO = (source) => {
  return source.fields.some((field) => field.name === 'google_sso' && field.value === true);
};

const shoudDisplayLoginGoogleSSO = (source) => {
  return source.fields.some((field) => field.name === 'google_sso' && field.value === false);
};

const addBiddingMetrics = (metrics, bidders) => {
  return bidders.map((bidder) => {
    const { id } = bidder;
    if (metrics && metrics[id]) {
      const {
        earnings,
        attempts,
        bids,
        wins,
        impressions,
        ecpm,
        bid_rate,
        win_rate,
        show_rate,
        clicks,
        ctr,
      } = metrics[id];

      return {
        ...bidder,
        earnings,
        attempts,
        bids,
        wins,
        impressions,
        ecpm: ecpm,
        bidRate: bid_rate,
        winRate: win_rate,
        showRate: show_rate,
        clicks: clicks,
        ctr: ctr,
      };
    }
    return bidder;
  });
};

const filterAmazonAppPublishers = (networkType, options, isTestAccount) => {
  let avaiablePartners = [...options];
  const amazonCombinations = isTestAccount
    ? Constants.AMAZON_AVAILABLE_PARTNERS_TEST_ACCOUNT
    : Constants.AMAZON_AVAILABLE_PARTNERS;

  if (networkType === 'bidding') {
    avaiablePartners = avaiablePartners.filter(
      (biddingPartner) => amazonCombinations[biddingPartner]?.bidding?.isNetworkCredDisplayed
    );
  }
  if (networkType === 'mediation') {
    avaiablePartners = avaiablePartners.filter(
      (mediationPartner) => amazonCombinations[mediationPartner]?.mediation?.isNetworkCredDisplayed
    );
  }
  return avaiablePartners;
};

const filterAmazonAppMediationItems = (options, type, isTestAccount) => {
  let avaiablePartners = [...options];
  const amazonCombinations = isTestAccount
    ? Constants.AMAZON_AVAILABLE_PARTNERS_TEST_ACCOUNT
    : Constants.AMAZON_AVAILABLE_PARTNERS;

  avaiablePartners = avaiablePartners.filter(
    (mediationPartner) => amazonCombinations[mediationPartner.name]?.mediation[type]
  );
  return avaiablePartners;
};

const formatSupportedPartners = (data) => {
  let partners = [];
  let partnersSupported = [];
  let partnerSupportedMap = {};
  data &&
    data.forEach((partner) => {
      if (partner) {
        partners = [...partners, partner.option];
      }
      partnersSupported = [...partnersSupported, partner];
      partnerSupportedMap[partner.option] = partner.supported;
    });
  return {
    partners,
    partnersSupported,
    partnerSupportedMap,
  };
};

const Helpers = {
  addLongTextPopover,
  createAppCredentials,
  createPlacementCredentials,
  filter,
  filterAmazonAppMediationItems,
  filterAmazonAppPublishers,
  formatSupportedPartners,
  getFieldName,
  getLogo,
  getName,
  getTooltip,
  hideGoogleSSO,
  toApiCredentials,
  getDeprecatedNetworks,
  addBiddingMetrics,
  shouldDisplayCredentialField,
  hasGoogleSSOField,
  shoudDisplayRevokeGoogleSSO,
  shoudDisplayLoginGoogleSSO,
};

export default Helpers;
