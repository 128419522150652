import React, { Component } from 'react';
import { connect } from 'react-redux';

// views
import { Button, Checkbox, Input, Image, Icon, Popup, Table } from 'semantic-ui-react';
import LineItemModal from 'apps/lineitem/views/LineItemModal';
import EditLineItemModal from '../../lineitem/views/EditLineItemModal';
import RateIcon from './components/RateIcon';
import Constants from 'apps/network/constants';
import '../css/lineitem-manager.css';

// modules
import AdGroup from 'apps/adgroup';
import App from 'apps/app';
import LineItem from '../../lineitem';
import Network from '../../network';
import User from 'core/user';

import AdGroupHelpers from '../helpers';
import { formatDollars, formatPercent, formatValue, sortArrayByDirection } from 'utils/helpers';

const DEFAULT_STATE = {
  column: null,
  data: [],
  direction: null,
  currentCPM: {},
  popupId: '',
  initialValue: '',
  isEditShown: false,
  isSubmitError: false,
  isSubmitting: false,
  selectedRow: '',
  submitingId: '',
};

class LineItemsManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_STATE,
    };
    this.changeSort = this.changeSort.bind(this);
    this.changeCPM = this.changeCPM.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.toggleEditShown = this.toggleEditShown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props?.existing?.length > 0) {
      this.setState((state) => {
        const lineItems = AdGroupHelpers.addMediationMetricsLineItem(
          this.props.mediationMetrics,
          this.props.existing
        );

        const currentCPM = AdGroupHelpers.makeCpmMap(lineItems);
        return {
          ...state,
          data: lineItems,
          originalCPM: currentCPM,
          currentCPM,
        };
      });
    }

    this.props.createLocal();
    if (this.props.shouldFetch) {
      if (this.props.app?.id !== undefined) {
        this.props.fetch(this.props.app.id);
      } else {
        const urlParams = window.location.pathname.split('/');
        const urlAppId = urlParams[2];
        this.props.fetch(urlAppId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const lineItems = AdGroupHelpers.addMediationMetricsLineItem(
      this.props.mediationMetrics,
      this.props.existing
    );

    const currentCPM = AdGroupHelpers.makeCpmMap(lineItems);

    const isMetricsChanged =
      prevProps.mediationMetrics?.length !== undefined &&
      this.props.mediationMetrics.length !== prevProps.mediationMetrics.length;

    const isAdgroupChanged = prevProps?.adgroup?.name !== this.props?.adgroup?.name;

    const isLineItemsChanged = AdGroupHelpers.isLineItemsChanged(
      prevProps.existing,
      this.props.existing
    );

    if (isMetricsChanged || isAdgroupChanged || isLineItemsChanged) {
      const isSorted = this.state.column !== null && this.state.direction !== null;
      let sortedData = [];
      if (isSorted) {
        sortedData = sortArrayByDirection(lineItems, this.state.column, this.state.direction);
      }

      this.setState((prevState) => {
        return {
          ...DEFAULT_STATE,
          data: isSorted ? sortedData : lineItems,
          originalCPM: currentCPM,
          popupId: prevState.popupId,
          isEditShown: prevState.isEditShown,
          currentCPM,
          column: prevState.column,
          direction: prevState.direction,
        };
      });
    }
    if (prevProps.shouldFetch === false && this.props.shouldFetch === true) {
      this.props.fetch(this.props.app.id);
    }
  }

  changeCPM(value, id) {
    this.setState((prevState) => {
      const copy = { ...prevState.currentCPM };
      if (AdGroupHelpers.validateNumber(value)) {
        copy[id] = value;
      }
      return {
        ...prevState,
        currentCPM: copy,
        isSubmitError: false,
      };
    });
  }

  changeSort(column) {
    if (this.state.column === column) {
      this.setState((state) => {
        const newDirection = state.direction === 'ascending' ? 'descending' : 'ascending';
        const sortedData = sortArrayByDirection(state.data, column, newDirection);
        return {
          ...state,
          data: sortedData,
          direction: newDirection,
        };
      });
    } else {
      this.setState((state) => {
        const newDirection = 'descending';
        const sortedData = sortArrayByDirection(state.data, column, newDirection);
        return {
          column: column,
          data: sortedData,
          direction: newDirection,
        };
      });
    }
  }

  togglePopup(id) {
    this.setState((state) => ({ ...state, popupId: id, selectedRow: id }));
  }

  toggleEditShown(bool) {
    this.setState((state) => ({ ...state, isEditShown: bool }));
  }

  async handleSubmit(e, lineitem, appId, editLineItem) {
    const lineItemId = lineitem.id;
    if (e.keyCode === 13 || e.keyCode === 9 || e.type === 'blur' || e.type === 'click') {
      if (this.state.originalCPM[lineItemId] !== this.state.currentCPM[lineItemId]) {
        const baseLineItem = {
          ...lineitem,
          auto_cpm: false,
          cpm: this.state.currentCPM[lineItemId],
          id: lineItemId,
          name: lineitem.name,
          network_id: lineitem.network_id,
          partner_placement: lineitem.partner_placement,
          type: lineitem.type,
        };

        this.setState((state) => ({
          ...state,
          isSubmitting: true,
          submitingId: lineItemId,
        }));
        try {
          await editLineItem(appId, baseLineItem);
          this.setState((state) => ({
            ...state,
            isSubmitting: false,
            isSubmitSuccess: true,
            isSubmitError: false,
          }));
        } catch (e) {
          this.setState((state) => ({
            ...state,
            isSubmitting: false,
            isSubmitError: true,
          }));
        }
      } else {
        this.setState((state) => ({ ...state, isSubmitting: false, submitingId: '' }));
      }
    }
  }

  async handleToggleEnable(e, lineitem, appId, editLineItem, isEnabled) {
    const toggledLineItem = {
      ...lineitem,
      enabled: isEnabled,
    };
    try {
      await editLineItem(appId, toggledLineItem);
      this.setState((state) => ({
        ...state,
        isSubmitting: false,
        isSubmitSuccess: true,
        isSubmitError: false,
      }));
    } catch (e) {
      this.setState((state) => ({
        ...state,
        isSubmitting: false,
        isSubmitError: true,
      }));
    }
  }

  render() {
    const {
      add,
      adgroup,
      app,
      autosave,
      clear,
      compact,
      create,
      deleteLineItem,
      loading,
      networkOptions,
      createRule,
      newItem,
      deletePredicate,
      options,
      negateRule,
      editPredicate,
      addPredicate,
      deleteRule,
      editLineItem,
      remove,
      save,
      selected,
      set,
      isBetaTestAccount,
      isTestAccount,
      getSupportedPlacementPartners,
      supportedPlacementPartners,
    } = this.props;

    const { id: adGroupId, type } = adgroup;
    const { platform } = app;
    const appId = app.id;

    const addFunc = (ids) => {
      add(app.id, adgroup.id, ids);
      if (autosave) save(app.id, adgroup);
    };

    const delFunc = (id) => {
      deleteLineItem(app.id, id);
    };

    const displaySort = (isSorted) =>
      isSorted ? (
        <Icon name={`sort ${this.state.direction}`} />
      ) : (
        <Icon name="sort" style={{ color: 'rgba(0,0,0,0.3' }} />
      );

    const formatHoverContent = (networkName, placementInfo) => {
      return (
        <>
          <div>
            <b>{networkName}</b>
          </div>
          <div>{placementInfo}</div>
        </>
      );
    };

    return (
      <>
        <Table className="LineItemsManager" sortable structured unstackable>
          <div className="table-contents">
            <Table.Header style={{ width: '100%' }}>
              <Table.Row style={{ width: '100%' }}>
                <Table.HeaderCell
                  className="activecolumn"
                  onClick={() => this.changeSort('enabled')}
                >
                  Active {displaySort(this.state.column === 'enabled')}
                </Table.HeaderCell>
                {isBetaTestAccount && (
                  <Table.HeaderCell
                    className="firstlookcolumn"
                    onClick={() => this.changeSort('first_look')}
                  >
                    First Look {displaySort(this.state.column === 'first_look')}
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell
                  onClick={() => this.changeSort('network_id')}
                  className="networkcolumn noselect"
                >
                  Network {displaySort(this.state.column === 'network_id')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('name')}
                  className="namecolumn noselect"
                >
                  Name {displaySort(this.state.column === 'name')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('cpm')}
                  className="ratecolumn noselect"
                >
                  Rate {displaySort(this.state.column === 'cpm')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('earnings')}
                  className="earningscolumn noselect"
                >
                  Earnings {displaySort(this.state.column === 'earnings')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('attempts')}
                  className="attemptscolumn noselect"
                >
                  Attempts {displaySort(this.state.column === 'attempts')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('wins')}
                  className="winscolumn noselect"
                >
                  Fills {displaySort(this.state.column === 'wins')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('impressions')}
                  className="impressionscolumn noselect"
                >
                  Impressions {displaySort(this.state.column === 'impressions')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('ecpm')}
                  className="ecpmcolumn noselect"
                >
                  eCPM {displaySort(this.state.column === 'ecpm')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('winRate')}
                  className="winratecolumn noselect"
                >
                  Fill Rate {displaySort(this.state.column === 'winRate')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('showRate')}
                  className="showratecolumn noselect"
                >
                  Show Rate {displaySort(this.state.column === 'showRate')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('clicks')}
                  className="clickscolumn noselect"
                >
                  Clicks {displaySort(this.state.column === 'clicks')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  onClick={() => this.changeSort('ctr')}
                  className="ctrcolumn noselect"
                >
                  CTR {displaySort(this.state.column === 'ctr')}
                </Table.HeaderCell>
                <Table.HeaderCell className="morecolumn"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body
              style={{
                width: '100%',
              }}
            >
              {this.state.data.map((lineitem) => {
                const {
                  id,
                  name,
                  network_id,
                  earnings,
                  enabled,
                  attempts,
                  wins,
                  impressions,
                  ecpm,
                  winRate,
                  showRate,
                  partner_placement,
                  clicks,
                  ctr,
                  first_look,
                } = lineitem;

                const removeFunc = () => {
                  remove(app.id, adGroupId, id);
                  if (autosave) save(app.id, adgroup);
                  this.togglePopup('', '');
                };

                const isDisabled = enabled !== true;
                const disabledClass = ' disabled-lineitem';

                return (
                  <Table.Row
                    key={id}
                    className={`lineitemrow ${id === this.state.selectedRow ? 'selected' : ''}`}
                  >
                    <Table.Cell textAlign="center" className="activecolumn">
                      <Checkbox
                        toggle
                        checked={enabled}
                        onClick={(e) =>
                          this.handleToggleEnable(e, lineitem, appId, editLineItem, !enabled)
                        }
                      />
                    </Table.Cell>
                    {isBetaTestAccount && (
                      <Table.Cell
                        textAlign="center"
                        className={`firstlookcolumn${isDisabled ? disabledClass : ''}`}
                      >
                        <Checkbox disabled checked={first_look} />
                      </Table.Cell>
                    )}
                    <Table.Cell textAlign="center" className="networkcolumn">
                      <Popup
                        content={formatHoverContent(
                          Constants.NETWORK_LABELS[network_id],
                          partner_placement
                        )}
                        trigger={
                          <Image
                            {...Network.Helpers.getLogo(network_id)}
                            style={{ margin: 'auto' }}
                          />
                        }
                        flowing
                      />
                    </Table.Cell>
                    <Table.Cell className={`namecolumn${isDisabled ? disabledClass : ''}`}>
                      {name}
                    </Table.Cell>
                    <Table.Cell className="ratecolmnn">
                      <Input
                        value={this.state.currentCPM[id]}
                        iconPosition="left"
                        placeholder
                        disabled={isDisabled}
                        error={this.state.isSubmitError && this.state.submitingId === id}
                        className="cpminput"
                        onChange={(e) => this.changeCPM(e.target.value, id)}
                        onKeyDown={(e) => this.handleSubmit(e, lineitem, appId, editLineItem)}
                        onBlur={(e) => this.handleSubmit(e, lineitem, appId, editLineItem)}
                      >
                        <Icon name="dollar" className="inputDollarIcon" />
                        <input />
                        <RateIcon
                          isSubmitting={this.state.isSubmitting}
                          isSubmitSuccess={this.state.isSubmitSuccess}
                          isSubmitError={this.state.isSubmitError}
                          submitingId={this.state.submitingId}
                          lineItemId={id}
                          handleIconClick={(e) =>
                            this.handleSubmit(e, lineitem, appId, editLineItem)
                          }
                        />
                      </Input>
                    </Table.Cell>
                    <Table.Cell className={`earningscolumn${isDisabled ? disabledClass : ''}`}>
                      {earnings !== undefined ? formatDollars(earnings) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`attemptscolumn${isDisabled ? disabledClass : ''}`}>
                      {attempts !== undefined ? new Intl.NumberFormat().format(attempts) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`winscolumn${isDisabled ? disabledClass : ''}`}>
                      {wins !== undefined ? new Intl.NumberFormat().format(wins) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`impressionscolumn${isDisabled ? disabledClass : ''}`}>
                      {impressions !== undefined
                        ? new Intl.NumberFormat().format(impressions)
                        : '-'}
                    </Table.Cell>
                    <Table.Cell className={`ecpmcolumn${isDisabled ? disabledClass : ''}`}>
                      {ecpm !== undefined ? formatDollars(ecpm) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`winratecolumn${isDisabled ? disabledClass : ''}`}>
                      {winRate !== undefined ? formatPercent(winRate) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`showratecolumn${isDisabled ? disabledClass : ''}`}>
                      {showRate !== undefined ? formatPercent(showRate) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`clickscolumn${isDisabled ? disabledClass : ''}`}>
                      {clicks !== undefined ? formatValue(clicks) : '-'}
                    </Table.Cell>
                    <Table.Cell className={`ctrcolumn${isDisabled ? disabledClass : ''}`}>
                      {ctr !== undefined ? formatPercent(ctr) : '-'}
                    </Table.Cell>
                    <Table.Cell className="morecolumn">
                      <Popup
                        on="click"
                        open={this.state.popupId === id}
                        pinned
                        position="left"
                        onClose={() => {
                          if (this.state.isEditShown === false) {
                            this.togglePopup('');
                          }
                        }}
                        trigger={
                          <Button
                            icon="ellipsis vertical"
                            className="editlineitempopup"
                            onClick={() => this.togglePopup(id)}
                          />
                        }
                      >
                        <div style={{ width: '125px' }}>
                          <EditLineItemModal
                            appId={app.id}
                            addPredicate={addPredicate}
                            deletePredicate={deletePredicate}
                            editPredicate={editPredicate}
                            createRule={createRule}
                            deleteRule={deleteRule}
                            negateRule={negateRule}
                            editLineItem={editLineItem}
                            item={lineitem}
                            loading={loading}
                            closePopup={() => this.togglePopup('')}
                            setEditShown={(bool) => this.toggleEditShown(bool)}
                            isBetaTestAccount={isBetaTestAccount}
                            isTestAccount={isTestAccount}
                          />
                          <Button className="removeLineItemButton" onClick={() => removeFunc()}>
                            Remove Line Item
                          </Button>
                        </div>
                      </Popup>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              {this.state.data.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={isBetaTestAccount ? 8 : 7} className="center-align">
                    No line-items associated with ad group
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </div>
        </Table>
        <div className="lineitem-table-footer">
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={isBetaTestAccount ? 8 : 7}>
                <LineItemModal
                  add={addFunc}
                  clear={clear}
                  compact={compact}
                  create={(item) => create(app.id, item)}
                  deleteLineItem={delFunc}
                  item={newItem}
                  items={options}
                  loading={loading}
                  networks={networkOptions}
                  selected={selected}
                  set={set}
                  type={type}
                  isBetaTestAccount={isBetaTestAccount}
                  isTestAccount={isTestAccount}
                  platform={platform}
                  getSupportedPlacementPartners={getSupportedPlacementPartners}
                  supportedPlacementPartners={supportedPlacementPartners}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </div>
      </>
    );
  }
}

const props = (state, ownProps) => {
  const { app, adgroup } = ownProps;
  const items = LineItem.Helpers.getActive(app.line_items || []);
  const existing = LineItem.Helpers.get(items, adgroup.line_items || []);
  const options = items.filter((i) => !existing.find((e) => e.id === i.id));
  let networkOptions = App.Helpers.getActiveNetworks(app, 'mediation');

  return {
    adgroup: adgroup,
    app: app,
    existing: existing,
    loading: LineItem.Store.getLoading(state),
    networkOptions: networkOptions,
    newItem: LineItem.Store.getNew(state),
    options: options,
    selected: LineItem.Store.getSelected(state),
    shouldFetch: LineItem.Store.shouldFetch(state),
    isBetaTestAccount: User.Store.isBetaTestAccount(state),
    isTestAccount: User.Store.isTestAccount(state),
    supportedPlacementPartners: Network.Store.getSupportedPlacementPartners(state),
  };
};

const dispatches = {
  add: AdGroup.Actions.addLineItem,
  clear: LineItem.Actions.clearSelectedLineItem,
  create: LineItem.Actions.createLineItem,
  createLocal: LineItem.Actions.createLocal,
  deleteLineItem: LineItem.Actions.deleteLineItem,
  editLineItem: LineItem.Actions.editLineItem,
  fetch: LineItem.Actions.fetchAll,
  remove: AdGroup.Actions.removeLineItem,
  save: AdGroup.Actions.update,
  set: LineItem.Actions.setField,
  createRule: LineItem.Actions.createRule,
  deleteRule: LineItem.Actions.deleteRule,
  negateRule: LineItem.Actions.negateRule,
  addPredicate: LineItem.Actions.addPredicate,
  deletePredicate: LineItem.Actions.deletePredicate,
  editPredicate: LineItem.Actions.editPredicate,
  getSupportedPlacementPartners: Network.Actions.fetchSupportedPlacementPartners,
};

export default connect(props, dispatches)(LineItemsManager);
