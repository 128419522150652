import React from 'react';
import DatePicker from 'react-datepicker';
import { Icon } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';

const DateRange = ({ label, value, onChange, name, minDate, endDate, type }) => {
  // SG-1871: force minDate to be one year from today
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  return (
    <div className="daterange-container">
      <div>
        <label>{label}</label>
      </div>
      <div className="mt-10">
        <DatePicker
          className="daterange"
          endDate={endDate}
          maxDate={new Date()} // Cant select max date in the future
          minDate={type === 'end' ? minDate : oneYearAgo}
          name={name}
          onChange={onChange}
          selected={value}
          selectsEnd={type === 'end'}
          selectsStart={type === 'start'}
          startDate={minDate}
        />
        <Icon name="calendar alternate outline" size="large" />
      </div>
    </div>
  );
};

export default DateRange;
