import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

// views
import { Dropdown, Icon } from 'semantic-ui-react';
import AppIcon from 'apps/app/views/AppIcon';

// modules
import Utils from 'core/framework/utils';
import Store from 'apps/app/store';
import Helpers from 'apps/app/helpers';
import Actions from 'apps/app/actions';

import 'apps/app/css/selector.css';

class AppSelector extends Component {
  select = (cls, e) => {
    const appId = e.value;
    this.props.selectApp(appId);
  };

  redirect = (url) => <Redirect push to={url} />;

  renderUI = () => {
    const { apps, selected } = this.props;

    const addPlatformIcon = (apps) => {
      return apps.map((app) => {
        let appPlatform = 'apple';
        if (app.description === 'Android') {
          appPlatform = 'android';
        } else if (app.description === 'Amazon') {
          appPlatform = 'amazon';
        }
        app.description = <Icon name={appPlatform} />;
        app.icon = null; // removes unnecessary extra semantic ui field
        return app;
      });
    };

    const options = addPlatformIcon(apps);
    return (
      <div className="AppSelectorContainer">
        <div className="AppSelector">
          <AppIcon platform={selected.platform} appId={selected.id} appIcon={selected.icon} />
          <Dropdown
            button
            className="icon"
            icon="dropdown"
            iconPosition="right"
            labeled
            onChange={this.select}
            options={options}
            placeholder="Select app"
            value={selected.id}
          />
        </div>
      </div>
    );
  };

  render = () => (this.props.shouldRedirect ? this.redirect(this.props.redirect) : this.renderUI());
}

// props

const props = (state, ownProps) => {
  const options = Utils.toOptions(Store.getApps(state), 'id', 'name', 'id', 'platform', 'icon');
  const selectedAppId = Store.getSelected(state);
  const urlAppId = ownProps.id;
  let selected = {};
  let redirect = '';
  let shouldRedirect = false;
  if (urlAppId) {
    selected = Store.getApp(state, urlAppId) || {};
    if (selectedAppId && urlAppId !== selectedAppId) {
      redirect = `/apps/${selectedAppId}/${ownProps.suffix}`;
      shouldRedirect = true;
    }
  }

  return {
    apps: Helpers.addImages(options),
    selected: selected,
    redirect: redirect,
    shouldRedirect,
  };
};

const dispatches = {
  selectApp: Actions.selectApp,
};

export default connect(props, dispatches)(AppSelector);
