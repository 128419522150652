import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDollars, formatPercent, formatValue } from 'utils/helpers';

const Placement = ({ calculated_totals, pageView }) => {
  const {
    attempts,
    earnings,
    ecpm,
    fill_rate,
    fills,
    impressions,
    requests,
    show_rate,
    win_rate,
    wins,
    clicks,
    ctr,
  } = calculated_totals;
  const isSinglePlacementView = pageView === 'singlePlacement';
  return (
    <Table.Row className="placementrow">
      <Table.Cell>
        {earnings !== undefined && earnings > 0 ? formatDollars(earnings) : '-'}
      </Table.Cell>
      <Table.Cell>
        {!isSinglePlacementView &&
          (requests !== undefined && requests > 0 ? formatValue(requests) : '-')}
        {isSinglePlacementView &&
          (attempts !== undefined && attempts > 0 ? formatValue(attempts) : '-')}
      </Table.Cell>
      <Table.Cell>
        {!isSinglePlacementView && (fills !== undefined && fills > 0 ? formatValue(fills) : '-')}
        {isSinglePlacementView && (wins !== undefined && wins > 0 ? formatValue(wins) : '-')}
      </Table.Cell>
      <Table.Cell>
        {impressions !== undefined && impressions > 0 ? formatValue(impressions) : '-'}
      </Table.Cell>
      <Table.Cell>{ecpm !== undefined && ecpm > 0 ? formatDollars(ecpm) : '-'}</Table.Cell>
      <Table.Cell>
        {!isSinglePlacementView &&
          (fill_rate !== undefined && fill_rate > 0 ? formatPercent(fill_rate) : '-')}
        {isSinglePlacementView &&
          (win_rate !== undefined && win_rate > 0 ? formatPercent(win_rate) : '-')}
      </Table.Cell>
      <Table.Cell>
        {show_rate !== undefined && show_rate > 0 ? formatPercent(show_rate) : '-'}
      </Table.Cell>
      <Table.Cell>{clicks !== undefined && clicks > 0 ? formatValue(clicks) : '-'}</Table.Cell>
      <Table.Cell>{ctr !== undefined && ctr > 0 ? formatPercent(ctr) : '-'}</Table.Cell>
    </Table.Row>
  );
};

export default Placement;
