/**
 * Defines placement actions, helpers, reducer , etc
 * @namespace Placement
 */

import Actions from './actions';
import Api from './api';
import Helpers from './helpers';
import Store from './store';
import Reducer from './reducer';
import Constants from './constants';

const Placement = {
  Actions,
  Api,
  Helpers,
  Store,
  Reducer,
  Constants
};

export default Placement;
