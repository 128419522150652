/**
 * Defines Core framework modules and utils
 * @namespace Core
 */

import { combineReducers } from 'redux';

import app from 'core/app/reducer';
import user from 'core/user/reducer';
import settings from '../../settings/reducer';

import { reducer as apps } from 'apps/';

export const createReducer = (reducers = {}) =>
  combineReducers({ app, user, apps, settings, ...reducers });

export const injectReducer = store => (name, reducer) => {
  if (store.reducers[name]) {
    return;
  }
  const reducers = { ...store.reducers, [name]: reducer };
  store.replaceReducer(createReducer(reducers));
  store.reducers = reducers; // eslint-disable-line no-param-reassign
};
