import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';
import { AmazonLogo, AppleLogo, AndroidLogo } from '../assets';

export const PLATFORM_LOGO_MAP = {
  Android: AndroidLogo,
  Amazon: AmazonLogo,
  iOS: AppleLogo,
};

const AppIcon = ({ appIcon, size = 'tiny', platform, floated = 'right' }) => {
  if (platform === undefined) {
    return null;
  }
  let fallbackLogo = PLATFORM_LOGO_MAP[platform];

  const imageUrl =
    appIcon !== null && appIcon !== undefined && appIcon !== '' ? appIcon : fallbackLogo;

  const [imgSrc, setImgSrc] = useState(imageUrl);
  const onError = () => {
    setImgSrc(fallbackLogo);
  };

  return (
    <Image
      className="rounded AppIcon"
      size={size}
      src={imgSrc}
      floated={floated}
      onError={onError}
    />
  );
};

export default AppIcon;
