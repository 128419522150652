import Actions from 'core/framework/actions';
import Api from './api';

/* Actions */

const terms = {
  FETCH_CHARTBOOST_APPS: 'CHARTBOOST.APPS.FETCH',
  SEARCH_CHARTBOOST_APPS: 'CHARTBOOST.APPS.SEARCH',
  IMPORT_CHARTBOOST_APP: 'CHARTBOOST.APP.IMPORT',
  CLEAR_CHARTBOOST_SEARCH: 'CHARTBOOST.CLEAR.SEARCH',
  SET_CHARTBOOST_PAGE: 'CHARTBOOST.PAGE.SET',
  RESET_IMPORT: 'HELIUM.APP.RESET'
};

/* Async Definitions */

const fetchApps = Actions.create({
  type: terms.FETCH_CHARTBOOST_APPS,
  args: [],
  awaits: Api.getApps,
  message: ['fetch-failure', 'Chartboost apps']
});

const importApp = Actions.create({
  type: terms.IMPORT_CHARTBOOST_APP,
  args: ['chartboostId'],
  awaits: Api.importApp,
  message: ['import', 'Chartboost app']
});

const resetLastImport = Actions.create({
  type: terms.RESET_IMPORT
});

const searchApps = Actions.create({
  type: terms.SEARCH_CHARTBOOST_APPS,
  args: ['value']
});

const setPage = Actions.create({
  type: terms.SET_CHARTBOOST_PAGE,
  args: ['value']
});

const clearSearch = Actions.create({
  type: terms.CLEAR_CHARTBOOST_SEARCH
});

/* exports */

const ChartboostActions = {
  ...terms,
  fetchApps,
  importApp,
  resetLastImport,
  searchApps,
  setPage,
  clearSearch
};

export default ChartboostActions;
