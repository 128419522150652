/**
 * @namespace Placement/Helpers
 */

import Constants from './constants';
import Utils from 'core/framework/utils';
import AmazonApsHelpers from 'apps/network/amazon_aps/helpers';

/* default placement creator */

const create = (app, adType) => {
  const placement = {
    id: '__new__',
    active: true,
    allow_all_ct: true,
    interactive_ct: true,
    video_ct: true,
    static_ct: true,
    app_name: app.name,
    app_nickname: app.nickname,
    country_floors: [],
    credentials: {
      chartboost: {
        active: true,
        app_id: app.id,
        app_signature: app.signature,
        ad_location: app.ad_location,
      },
    },
    floor_active: false,
    prioritize_bidding: false,
    global_floor: 0,
    name: null,
    type: adType,
    adgroups: [],
    auto_refresh_rate: 0,
    queue_size: null,
    ask_price_enabled: false,
    bidding_prioritization_cpm: 0,
  };

  if (adType === 'rewarded' || adType === 'rewarded_interstitial') {
    placement.rewarded_callback = createRewardedCallback();
  }
  //  SG-1493 : Hide Chartboost as bidding network, not supported for rewarded interstitial, supported for adaptive banners
  if (adType === 'rewarded_interstitial') {
    delete placement.credentials.chartboost;
  }
  // SG-1602: Banner and Adaptive Banners do not support queue size
  if (adType === 'banner' || adType === 'adaptive_banner') {
    delete placement.queue_size;
  }

  return placement;
};

const createRewardedCallback = () => ({
  enabled: false,
  method: 'GET',
  url: '',
  max_retries: 2,
  body: null,
});

const isBPlacement = (placement) => placement.has_abtest && placement.ab_type === 'B';

const isAPlacement = (placement) => placement.has_abtest && placement.ab_type === 'A';

const isRewarded = (placement) =>
  placement.type === 'rewarded' || placement.type === 'rewarded_interstitial';

const toUIPlacements = (response) => {
  if (Utils.isNonZeroArray(response)) {
    return response.map((placement) => {
      if (!placement.credentials) {
        placement.credentials = {};
      }
      if (isRewarded(placement)) {
        if (!placement.rewarded_callback) {
          placement.rewarded_callback = createRewardedCallback();
        }
      }
      if (isBPlacement(placement)) {
        placement.name = placement.name.replace('::B', '_B');
      }
      if (isAPlacement(placement) && typeof placement.proportion === 'undefined') {
        placement.proportion = 0;
      }
      return placement;
    });
  }
  return response;
};

const _getOne = (app, placementId, state) => {
  if (placementId === '__new__') return state.newPlacement;
  if (app.placements) {
    const plc = app.placements.filter((placement) => placement.id === placementId);
    if (plc.length > 0) return plc[0];
  }
  return false;
};

const _getSome = (app, placementIds) => placementIds.map((id) => _getOne(app, id));

/*************************************************************
 *  finds one or more placements in app
 *  @placementIds: can be either one id, or an array of ids
 *************************************************************/

const get = (app, placementIds, state = {}) =>
  Array.isArray(placementIds) ? _getSome(app, placementIds) : _getOne(app, placementIds, state);

const prepareAdPlacement = (adPlacement) => {
  let plc = Constants.PLACEMENT_FIELDS.reduce(
    (prepared, field) => ({ ...prepared, [field]: adPlacement[field] }),
    {}
  );
  // If Chartboost credentials are there, set ad location field to placement name
  if (plc.credentials?.chartboost) {
    if (
      adPlacement.type !== 'rewarded_interstitial' &&
      plc.credentials &&
      !plc.credentials.chartboost.ad_location
    ) {
      plc.credentials.chartboost.ad_location = plc.name;
    }
  }

  if (plc.id === '__new__') delete plc['id'];

  if (isBPlacement(adPlacement)) {
    delete plc['name']; // keep default "Aname::B"
  }

  if (adPlacement.type === 'banner' || adPlacement.type === 'adaptive_banner') {
    plc['auto_refresh_rate'] = adPlacement['auto_refresh_rate'];
  }

  if (adPlacement.type === 'rewarded' || adPlacement.type === 'rewarded_interstitial') {
    plc['rewarded_callback'] = adPlacement['rewarded_callback'];
  }

  if (adPlacement.type === 'rewarded_interstitial') {
    delete plc.credentials.chartboost;
  }
  return plc;
};

const prepareCredentials = (credentials, schemas) => {
  let creds = {};
  for (let network in credentials) {
    let schema = schemas[network].placement_credentials;
    creds[network] = schema.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: credentials[network][cur.name] }),
      {}
    );
    creds[network].active = credentials[network].active || false;
  }
  return creds;
};

const makePlacementOptionRow = (name) => ({
  key: name.toLowerCase().split(' ').join('_'),
  value: name,
  text: name,
});

/**
 * checks if new placement name includes any
 * forbidden characters or longer than 50 chars
 * @function validatePlacementName()
 * @param {PLACEMENT} placement
 * @memberof Placement/Helpers
 */

const validatePlacementName = (name, existingNames) => {
  const errors = [];
  const existing = existingNames || [];

  if (name) {
    let regexp = /^[a-zA-Z0-9-_]+$/;
    if (name.search(regexp) === -1) {
      errors.push(Constants.ERRORS.BAD_CHARACTERS);
    }
    if (name.length >= 50) {
      errors.push(Constants.ERRORS.MAX_LENGTH);
    }
    if (existing.includes(name)) {
      errors.push(Constants.ERRORS.NAME_EXISTS);
    }
  }
  return errors;
};

const validateRewardedCallback = (placement) => {
  let errors = [];
  const callback = placement.rewarded_callback;
  const hasCallback = (p) =>
    p.type === 'rewarded' || p.type === 'rewarded_interstitial'
      ? p.rewarded_callback && p.rewarded_callback.enabled
      : false;
  const isValidURL = (c) => c.url.startsWith('https://');
  const isValidRetry = (c) => c.max_retries >= 0 && c.max_retries <= 10;
  const isValidMethod = (c) => c.method === 'GET' || c.method === 'POST';
  const getMissingMacros = (value) =>
    Constants.REQUIRED_CALLBACK_MACROS.filter((m) => value.indexOf(m) === -1);
  const addError = (field, message) => errors.push({ field, message });

  if (!hasCallback(placement)) {
    return [];
  }
  if (!callback.url) {
    addError('url', Constants.ERRORS.CALLBACK_URL_REQUIRED);
    return errors;
  }
  if (!isValidURL(callback)) {
    addError('url', Constants.ERRORS.CALLBACK_URL_INVALID);
    return errors;
  }
  if (!isValidRetry(callback)) {
    addError('max_retries', Constants.ERRORS.CALLBACK_RETRY_RANGE);
    return errors;
  }
  if (!isValidMethod(callback)) {
    addError('method', Constants.ERRORS.CALLBACK_METHOD_INVALID);
    return errors;
  }
  if (callback.method === 'GET') {
    let missing = getMissingMacros(callback.url);
    if (missing.length > 0) {
      addError('url', `${Constants.ERRORS.CALLBACK_MACROS_MISSING} ${missing.join(', ')}`);
      return errors;
    }
  }
  if (callback.method === 'POST') {
    if (!callback.body) {
      addError('body', Constants.ERRORS.CALLBACK_BODY_REQUIRED);
      return errors;
    }
    if (!Utils.isValidJSONString(callback.body, true)) {
      addError('body', Constants.ERRORS.CALLBACK_BODY_INVALID);
      return errors;
    }
    let missing_macros_url = getMissingMacros(callback.url);
    let missing = missing_macros_url.filter((m) => callback.body.indexOf(m) === -1);
    if (missing.length > 0) {
      addError('body', `${Constants.ERRORS.CALLBACK_MACROS_MISSING} ${missing.join(', ')}`);
      return errors;
    }
  }
  return errors;
};

/**
 * sets all the placement's creative types to true
 * {interactive_ct: true, video_ct: true} .. etc
 * @function enableAllCT()
 * @param {PLACEMENT} placement
 * @memberof Placement/Helpers
 */

const enableAllCT = (placement) => {
  const ctList =
    placement.type === 'rewarded' || placement.type === 'rewarded_interstitial'
      ? Constants.REWARDED_CREATIVE_TYPES
      : Constants.INTERSTITIAL_CREATIVE_TYPES;

  placement.allow_all_ct = true;
  for (let ct of ctList) {
    placement[ct.key] = true;
  }
};

/**
 * checks if all individual creative types
 * are set to true and sets allow_all_ct to true
 * @function shouldEnableAllCTFlag()
 * @param {PLACEMENT} placement
 * @returns {boolean}
 * @memberof Placement/Helpers
 */

const shouldEnableAllCTFlag = (placement) => {
  const ctList =
    placement.type === 'rewarded' || placement.type === 'rewarded_interstitial'
      ? Constants.REWARDED_CREATIVE_TYPES
      : Constants.INTERSTITIAL_CREATIVE_TYPES;

  for (let ct of ctList) {
    if (placement[ct.key] === false) {
      placement.allow_all_ct = false;
      return false;
    }
  }

  placement.allow_all_ct = true;
  return true;
};

/**
 * Ensures that placement has fields specific to the type.
 * Sets default if field is not found.
 * @param {PLACEMENT} placement
 * @returns
 */

const updatePlacementType = (placement, app) => {
  if (placement.type === 'banner' || placement.type === 'adaptive_banner') {
    if (typeof placement.auto_refresh_rate === 'undefined') {
      placement.auto_refresh_rate = Constants.BANNER_DEFAULT_REFRESH_RATE;
    }
  } else {
    placement['auto_refresh_rate'] = 0;
  }
  if (placement.type === 'rewarded' || placement.type === 'rewarded_interstitial') {
    if (typeof placement.rewarded_callback === 'undefined') {
      placement.rewarded_callback = createRewardedCallback();
    }
  } else {
    delete placement['rewarded_callback'];
  }
  // Set CB creds if not rewarded interstitial, remove if it is
  if (placement.type !== 'rewarded_interstitial') {
    if (placement.credentials.chartboost === undefined) {
      placement.credentials.chartboost = {
        active: true,
        app_id: app.id,
        app_signature: app.signature,
        ad_location: app.ad_location,
      };
    }
  } else if (placement.type === 'rewarded_interstitial') {
    delete placement.credentials.chartboost;
  }
};

const getExistingNames = (app, placementId) =>
  app.placements
    ? app.placements.filter(({ id }) => id !== placementId).map(({ name }) => name)
    : [];

/***************************************************************
 * getCombinedCredentials(placement, app)
 * combines placement and app credentials for display purposes
 ***************************************************************/

const filter = (placements, type) => placements.filter((p) => p.type === type);

/**
 * Retrives Placement details by ID and key
 * @function findPlacementDetail()
 * @param {array} placement
 * @param {int} id
 * @param {string} key
 * @returns {any}
 * @memberof Placement/Helpers
 */

const findPlacementDetail = (placements, id, key) => {
  const foundPlacement = placements.filter((placement) => placement.id === id)[0];
  return foundPlacement !== undefined ? foundPlacement[key] : null;
};

/**
 * @function getABTestedPlacements
 * @param {PLACEMENTS[]} all - all company placements
 * @param {UI_APP} app
 * returns a list of all placements that have
 * an AB Test setup in an app
 */
const getABTestedPlacements = (all, app) => {
  const placements = app.placements.map((p) => (isNaN(p) ? p : all.find((P) => P.id === p)));
  return placements.filter((p) => p.has_abtest && p.ab_type === 'A');
};

const trimStrings = (networkCredentials) => {
  let keys = Object.keys(networkCredentials);
  keys.forEach((key) => {
    let value = networkCredentials[key];
    networkCredentials[key] = typeof value === 'string' ? value.trim() : value;
  });
};

const toApiCredentials = (allCredentials) => {
  const networks = Object.keys({ ...allCredentials });
  const credentialCopy = { ...allCredentials };
  networks.forEach((network) => {
    let networkCredentials = credentialCopy[network];
    switch (network) {
      case 'amazon_aps':
        AmazonApsHelpers.toAmazonApsApiCredentials(networkCredentials);
        trimStrings(networkCredentials);
        break;
      default:
        trimStrings(networkCredentials);
        break;
    }
  });
  return credentialCopy;
};

const combineNonBAndOverview = (nonBtestPlacements, placementsOverview = {}) => {
  return nonBtestPlacements.map((placement) => {
    if (placement.name in placementsOverview) {
      return { ...placement, ...placementsOverview[placement.name] };
    }
    return { ...placement };
  });
};

/**
 * @function combineABTestData
 * modifies the nonBtestPlacementsAndMetrics array with B Test data added to A test data
 */
const combineABTestData = (nonBtestPlacementsAndMetrics, placementsOverview = {}) => {
  for (const placementA of nonBtestPlacementsAndMetrics) {
    // If it is an A/B Test placement and data exists in the placements overview,
    // combine the metrics together. Default to zero to prevent errors or NaN.
    if (placementA.has_abtest && placementsOverview[`${placementA.name}::B`]) {
      const {
        earnings = 0,
        fills = 0,
        impressions = 0,
        requests = 0,
        clicks = 0,
      } = placementsOverview[`${placementA.name}::B`] || {};
      // SG-1525: if placement is AB test and is an A test with no metrics, set metrics to B test
      placementA.earnings = isNaN(placementA.earnings) ? earnings : placementA.earnings + earnings;
      placementA.fills = isNaN(placementA.fills) ? fills : placementA.fills + fills;
      placementA.impressions = isNaN(placementA.impressions)
        ? impressions
        : placementA.impressions + impressions;
      placementA.requests = isNaN(placementA.requests) ? requests : placementA.requests + requests;
      placementA.clicks = isNaN(placementA.clicks) ? clicks : placementA.clicks + clicks;

      // calculated metric values
      const calculatedShowRate =
        placementA.requests !== 0 ? placementA.impressions / placementA.requests : undefined;

      const calculatedFillRate =
        placementA.requests !== 0 ? placementA.fills / placementA.requests : undefined;

      const calculatedECPM =
        placementA.impressions !== 0
          ? (placementA.earnings / placementA.impressions) * 1000
          : undefined;

      const calculatedCTR =
        placementA.impressions !== 0 ? placementA.clicks / placementA.impressions : undefined;

      placementA.ecpm = calculatedECPM;
      placementA.fill_rate = calculatedFillRate;
      placementA.show_rate = calculatedShowRate;
      placementA.ctr = calculatedCTR;
    }
  }
  return nonBtestPlacementsAndMetrics;
};

// Remove after product support matrix implementation
const filterBidderNetworks = ({ placement, options }) => {
  let filteredOptions = [...options];
  if (placement?.type === 'adaptive_banner') {
    filteredOptions = filteredOptions.filter(
      (option) => option !== 'verve' && option !== 'mobilefuse' && option !== 'vungle' // SG-1405 Hides Vungle bidding for adaptive banners for all app
    );
  }
  // remove CB for rewarded interstitial
  if (placement?.type === 'rewarded_interstitial') {
    filteredOptions = filteredOptions.filter((option) => option !== 'chartboost');
  }
  return filteredOptions;
};

const Helpers = {
  _getOne,
  _getSome,
  create,
  enableAllCT,
  filter,
  findPlacementDetail,
  get,
  getABTestedPlacements,
  getExistingNames,
  isAPlacement,
  isBPlacement,
  makePlacementOptionRow,
  prepareAdPlacement,
  prepareCredentials,
  shouldEnableAllCTFlag,
  toApiCredentials,
  toUIPlacements,
  validatePlacementName,
  updatePlacementType,
  validateRewardedCallback,
  combineNonBAndOverview,
  combineABTestData,
  filterBidderNetworks,
};

export default Helpers;
