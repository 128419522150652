/**
 * Defines Actions, Api, Helpers, Reducer, and Store for network credentials
 * @namespace Network
 */

import Actions from './actions';
import Api from './api';
import Helpers from './helpers';
import Store from './store';
import Reducer from './reducer';
import Constants from './constants';

const Network = {
  Actions,
  Api,
  Helpers,
  Store,
  Reducer,
  Constants
};

export default Network;
