/**
 * @namespace Core/framework/api
 */

import 'whatwg-fetch';
import querystring from 'querystring';
import { getAuthToken } from 'core/framework/localStorage';

class CustomApiError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CustomApiError';
  }
}

/**
 * @function parseCustomError
 * @param {object} body - Response body from api error
 * @description throws an error with message from api
 * @memberof Core/framework/api
 */

const parseCustomError = (body) => {
  if (body && typeof body.message === 'string') {
    throw new CustomApiError(body.message);
  } else if (body && typeof body.reason === 'string') {
    throw new CustomApiError(body.reason);
  } else if (body && typeof body.description === 'string') {
    throw new CustomApiError(body.description);
  } else {
    throw new Error('Unknown Error');
  }
};

/**
 * @function parseResponse
 * @param string res - xmlHttpResponse from fetch()
 * @description converts response text to json if content-type is not text/html
 * @returns object - json response from api
 * @memberof Core/framework/api
 */

const parseResponse = (res) => {
  if (res.ok) {
    switch (res.headers.get('Content-Type')) {
      case 'text/html':
        return res.text();
      default:
        return res.json();
    }
  }
  if (res.status === 400) {
    if (res.headers.get('Content-Type') === 'application/json') {
      return res.json().then(parseCustomError);
    }
  }
  const err = new Error(res.statusText);
  err.response = res;
  throw err;
};

/**
 * @function xhr
 * @description performs xhr requests
 * @memberof Core/framework/api
 */

const xhr = (url, data, options = {}) => {
  const { credentials = 'include', encode, method, multipart, token } = options;
  let request = { credentials, method: method || (data ? 'POST' : 'GET') };
  let headers = {
    'Accept-Encoding': 'gzip',
  };

  if (token) {
    headers.Authorization = `Bearer ${getAuthToken()}`;
  }

  if (data) {
    const body = encode ? querystring.stringify(data) : JSON.stringify(data);
    const type = encode ? 'application/x-www-form-urlencoded' : 'application/json';
    headers = { ...headers, Accept: 'application/json', 'Content-Type': type };
    request = { ...request, body, headers };
  } else {
    request = { ...request, headers };
  }
  if (multipart) {
    request.body = data;
    delete request.headers['Content-Type'];
  }
  return fetch(url, request).then(parseResponse);
};

/**
 * @function get
 * @description performs a get requests
 * @memberof Core/framework/api
 */

const get = xhr;

/**
 * @function post
 * @description performs a post request
 * @memberof Core/framework/api
 */

const post = xhr;

/**
 * @function patch
 * @description performs a patch requests
 * @memberof Core/framework/api
 */

const patch = (url, data, options) => xhr(url, data, { ...options, method: 'PATCH' });

/**
 * @function put
 * @description performs a put requests
 * @memberof Core/framework/api
 */

const put = (url, data, options) => xhr(url, data, { ...options, method: 'PUT' });

/**
 * @function del
 * @description performs a del requests
 * @memberof Core/framework/api
 */

const del = (url, data, options) => xhr(url, data, { ...options, method: 'DELETE' });

const useMockServer = process.env.REACT_APP_USE_MOCK_SERVER;

const Api = {
  DASH_API: process.env.REACT_APP_DASH_API,
  HELIUM_API:
    useMockServer === '1'
      ? process.env.REACT_APP_MOCK_SERVER
      : // Add helium prefix to all routes for api-gateway test
        process.env.REACT_APP_HELIUM_API + '/helium',
  parseResponse,
  patch,
  xhr,
  post,
  get,
  del,
  put,
};

export default Api;
