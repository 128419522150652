import React, { useState } from 'react';
import { Label, Tab, Segment } from 'semantic-ui-react';
import LineItemsManager from 'apps/adgroup/views/LineItemsManager';

import AddAdgroupDropdown from './AddAdgroupDropdown';
import '../css/adgroup-editor.css';

const AdGroupEditor = ({
  abtest,
  addAdgroup,
  adgroups,
  app,
  catchallIds,
  compact,
  placement,
  mediationMetrics,
  saveOnExit,
  updateAdgroupPlacement,
  updateAdgroupPlacementLocal,
}) => {
  const placementId = placement && placement.id;
  const type = placement && placement.type;
  const currentAdgroups = [...adgroups] || [];
  const [currentAdgroupIdex, toggleAdgroupId] = useState(0);
  const allAdgroups = app.adgroups || [];
  const currentAdgroupIds = currentAdgroups.map((adgroup) => adgroup.id);
  const allAdgroupIds = allAdgroups.map((adgroup) => adgroup.id);
  const difference = allAdgroupIds.filter((x) => !currentAdgroupIds.includes(x));
  const adgroupOptions = difference
    .map((optionId) => {
      for (let adgroup of allAdgroups) {
        if (adgroup.id === optionId && type === adgroup.type) {
          return adgroup;
        }
      }
      return null;
    })
    .filter((option) => option !== null);

  let noAdGroupsSelected = false;

  if (currentAdgroups.length === 0) {
    noAdGroupsSelected = true;
  }
  const currentAdgroupInfo = currentAdgroups[currentAdgroupIdex];

  const handleAdgroupChange = (adgroupdata) => {
    toggleAdgroupId(adgroupdata.activeIndex - 1);
  };

  currentAdgroups.push({
    name: (
      <AddAdgroupDropdown
        addAdgroup={addAdgroup}
        adgroupOptions={adgroupOptions}
        app={app}
        catchallIds={catchallIds}
        currentAdgroupInfo={currentAdgroupInfo}
        placementId={placementId}
        saveOnExit={saveOnExit}
        updateAdgroupPlacement={updateAdgroupPlacement}
        updateAdgroupPlacementLocal={updateAdgroupPlacementLocal}
      />
    ),
  });

  if (currentAdgroups.length) {
    currentAdgroups.unshift({
      name: <div className="waterfallHeader">Ad Groups</div>,
      active: false,
    });
  }

  const panes = currentAdgroups.map((adgroup) => {
    if (noAdGroupsSelected) {
      return {
        menuItem: adgroup.name,
        render: () => (
          <Tab.Pane>
            <div className="NoAdGroupSelected">No Ad Group Selected</div>
          </Tab.Pane>
        ),
      };
    }
    return {
      menuItem: adgroup.name,
      render: () => (
        <Tab.Pane>
          <Segment className="smooth-table mt-25">
            <Label attached="top">
              <span className="fs-15">Line Items</span>
            </Label>
            <LineItemsManager
              mediationMetrics={mediationMetrics}
              abtest={abtest}
              adgroup={adgroup}
              app={app}
              autosave={true}
              compact={compact}
            />
          </Segment>
        </Tab.Pane>
      ),
    };
  });

  return (
    <Segment className="AdGroupEditor">
      <Tab
        defaultActiveIndex={currentAdgroups.length ? 1 : null}
        menu={{ fluid: true, vertical: true }}
        menuPosition="left"
        onTabChange={(e, data) => handleAdgroupChange(data)}
        panes={panes}
      />
    </Segment>
  );
};

export default AdGroupEditor;
