// 3rd Party
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppLayout } from 'core/app/views';

// views
import PlacementForm from './PlacementForm';

// modules
import AdGroup from 'apps/adgroup';
import App from 'apps/app';
import Network from 'apps/network';
import Placement from 'apps/placement';
import UserStore from 'core/user/store';

class CreatePlacement extends PlacementForm {
  constructor(props) {
    super(props);
    this.state = { isNewPlacement: true };
  }

  componentDidMount() {
    if (this.props.app !== undefined && !this.props.placement) {
      this.props.createPlacement(this.props.app, this.props.type);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.app !== undefined && prevProps?.placement === false) {
      this.props.createPlacement(this.props.app, this.props.type);
      // Fetch for supported placement partners bidding
      const platform = this.props.app.platform && this.props.app.platform.toLowerCase();
      const placementType = this.props.placement.type;
      if (placementType) {
        this.props.getSupportedPlacementPartners(platform, 'bidding', placementType);
      }
    }
  }

  componentWillUnmount() {
    this.props.discardPlacement();
  }

  render() {
    const { app, placement } = this.props;

    return (
      <AppLayout bundle="apps" context={{ app: app, placement: placement, placements: true }}>
        {this.renderForm()}
      </AppLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: appId, type } = ownProps.match.params;
  const app = App.Store.getApp(state, appId);
  const placement = Placement.Store.getNewPlacement(state);
  const existingNames = app !== undefined ? Placement.Helpers.getExistingNames(app) : [];
  const adgroups = placement.adgroups
    ? placement.adgroups.map((agid) => AdGroup.Store.getOne(state, appId, agid) || {})
    : [];

  return {
    adgroups,
    app,
    type,
    credsLoading: false,
    defaultNames: Placement.Store.getDefaults(state, existingNames),
    existingNames,
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
    isTestAccount: UserStore.isTestAccount(state),
    isZynga: UserStore.isZynga(state),
    isV2: UserStore.isV2(state),
    loading: Placement.Store.getLoading(state),
    networkOptions: Network.Store.getUnusedNetworksForPlacement(
      state,
      app,
      placement || { credentials: [] }
    ),
    placement,
    shouldTransition: Placement.Store.getShouldFetchPlacements(state),
    callbackErrors: App.Store.getCallbackErrors(state),
    placementErrors: App.Store.getPlacementErrors(state),
  };
};

const mapDispatchToProps = {
  addAdgroup: Placement.Actions.addAdgroup,
  createABTest: Placement.Actions.createABTest,
  createPlacement: Placement.Actions.createPlacement,
  createPlacementCredentials: Placement.Actions.createPlacementCredentials,
  deletePlacementCredentials: Placement.Actions.deletePlacementCredentials,
  discardPlacement: Placement.Actions.discardPlacement,
  editPlacement: Placement.Actions.editPlacement,
  removeFloor: Placement.Actions.removeFloor,
  save: Placement.Actions.saveNewPlacement,
  setFloor: Placement.Actions.setFloor,
  togglePlacementCredentials: Placement.Actions.togglePlacementCredentials,
  updateAdgroupPlacementLocal: AdGroup.Actions.updateAdgroupPlacementLocal,
  enableBannerRefresh: Placement.Actions.enableBannerRefresh,
  disableBannerRefresh: Placement.Actions.disableBannerRefresh,
  setBannerRefresh: Placement.Actions.setBannerRefresh,
  setQueueSize: Placement.Actions.setQueueSize,
  setRewardedCallback: Placement.Actions.setRewardedCallback,
  getSupportedPlacementPartners: Network.Actions.fetchSupportedPlacementPartners,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePlacement));
