import React, { Component } from 'react';
import { Button, Segment, Grid, Tab } from 'semantic-ui-react';
import { PageHeader, DualHeader } from 'core/app/views';
import { Redirect } from 'react-router-dom';

// models
import Placement from 'apps/placement';
import Utils from 'core/framework/utils';

// views
import Bidfloors from 'apps/placement/views/Bidfloors';
import CreativeTypes from 'apps/placement/views/CreativeTypes';
import Credentials from 'apps/network/views/Credentials';
import CredentialsPlacements from 'apps/network/views/CredentialsPlacements';
import PlacementOverview from 'apps/placement/views/PlacementOverview';
import BannerRefresh from 'apps/placement/views/BannerRefresh';
import QueueSize from './QueueSize';
import RewardedCallbackSettings from 'apps/placement/views/RewardedCallbackSettings';
import DateFilter from 'analytics/common/views/DateFilter';
import MetricsGraphContainer from '../../common/metrics/views/MetricsGraphContainer';
import AdGroupEditor from './AdGroupEditor';
import AskPriceEnabled from './AskPriceEnabled';
import PrioritizeBidding from './PrioritizeBidding';

import Format from 'analytics/common/format';
import CommonHelpers from 'analytics/common/helpers';

import '../css/placements.css';

class PlacementForm extends Component {
  handleAddition() {
    let { placement, defaultNames } = this.props;
    let name = placement ? placement.name : null;
    defaultNames = defaultNames || [];
    let isCustomName = name && !defaultNames.find(({ value }) => value === name);
    if (isCustomName) {
      defaultNames.push(Placement.Helpers.makePlacementOptionRow(name));
    }
  }

  renderEmpty = () => <div />;

  renderForm = () => {
    const {
      abtest,
      abtesttype,
      app,
      credsLoading,
      editPlacement,
      loading,
      placement,
      shouldTransition,
      shouldRedirectToAB,
      enableBannerRefresh,
      disableBannerRefresh,
      setBannerRefresh,
      setRewardedCallback,
      callbackErrors,
      placementErrors,
      placementName,
      getPlacementMetrics,
      getDateFilter,
      setDateFilter,
      addAdgroup,
      adgroups,
      save,
      isBetaTestAccount,
      isTestAccount,
      isZynga,
      updateAdgroupPlacement,
      mediationMetrics,
      updateAdgroupPlacementLocal,
      networkOptions,
      setQueueSize,
      shouldFetchPlacementLineItems,
    } = this.props;
    const { min, max, type } = getDateFilter || {};
    if (shouldTransition) {
      return <Redirect to={`/apps/${app.id}`} />;
    }

    if (shouldRedirectToAB) {
      return <Redirect to={shouldRedirectToAB} />;
    }

    if (!placement) {
      return this.renderEmpty();
    }

    this.handleAddition();

    const shouldDisableSave =
      placementErrors.length > 0 || callbackErrors.length > 0 || !placement.name;
    const isAtLeastOneBidder =
      placement && placement.credentials && Object.keys(placement.credentials).length > 0;
    const isAtLeastOneAdgroup = placement && placement.adgroups && placement.adgroups.length > 0;
    const set = Utils.handleFormEvent(editPlacement, app?.id, placement?.id);
    const isBanner = placement.type === 'banner' || placement.type === 'adaptive_banner';
    const isRewarded = placement.type === 'rewarded' || placement.type === 'rewarded_interstitial';

    const handleSave = (placement, appId) => {
      this.props.save(placement, appId);
      this.setState({
        saved: true,
      });
    };

    const saveButtonProps = {
      className: 'positive',
      content: 'Save Placement',
      // Allows publisher to create waterfall only or bidding only placements
      disabled: !(isAtLeastOneBidder || isAtLeastOneAdgroup) || shouldDisableSave,
      onClick: () => handleSave(placement, app.id),
    };

    const updateDateFilter = (start, end, selectedKey) => {
      const min = Format.date(start || CommonHelpers.timePeriod.week);
      const max = Format.date(end || CommonHelpers.timePeriod.today);
      setDateFilter(app, { min, max, type: selectedKey });
      getPlacementMetrics(app.id, placementName, min, max);
    };

    const panes = [
      {
        menuItem: 'Settings',
        render: () => (
          <Tab.Pane>
            <PageHeader content="General Settings" />
            <PlacementOverview
              abtest={abtest}
              abtesttype={abtesttype}
              app={app}
              appId={app.id}
              defaultNames={this.props.defaultNames}
              errors={placementErrors}
              loading={false}
              onAddName={this.handleAddition.bind(this)}
              placement={placement}
              set={set}
              isBetaTestAccount={isBetaTestAccount}
            />
            <PageHeader content="Advanced Settings" />
            <Segment basic>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="4">
                    <CreativeTypes placement={placement} set={set} />
                  </Grid.Column>
                  <Grid.Column width="4" className={isBanner ? 'visible' : 'hidden'}>
                    <BannerRefresh
                      placement={placement}
                      enable={() => enableBannerRefresh(app.id, placement.id)}
                      disable={() => disableBannerRefresh(app.id, placement.id)}
                      set={(val) => setBannerRefresh(app.id, placement.id, val)}
                      max={Placement.Constants.BANNER_MAX_REFRESH_RATE}
                      min={Placement.Constants.BANNER_MIN_REFRESH_RATE}
                    />
                  </Grid.Column>
                  <Grid.Column width="4" className={isRewarded ? 'visible' : 'hidden'}>
                    <RewardedCallbackSettings
                      appId={app.id}
                      placement={placement}
                      set={setRewardedCallback}
                      errors={callbackErrors}
                    />
                  </Grid.Column>
                  <Grid.Column width="4">
                    <QueueSize
                      app={app}
                      placement={placement}
                      set={(val) => setQueueSize(app.id, placement.id, val)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            {this.state?.isNewPlacement === true && (
              <Segment padded loading={credsLoading} textAlign="left" basic>
                <PageHeader content="Bidding" />
                <Credentials
                  app={app}
                  create={this.props.createPlacementCredentials}
                  delete={this.props.deletePlacementCredentials}
                  edit={this.props.togglePlacementCredentials}
                  options={networkOptions}
                  placement={placement}
                  type="placement_credentials"
                />
                <PageHeader content="Waterfall" />
                <AdGroupEditor
                  addAdgroup={addAdgroup}
                  adgroups={adgroups}
                  app={app}
                  compact={false}
                  isBetaTestAccount={isBetaTestAccount}
                  mediationMetrics={mediationMetrics}
                  placement={placement}
                  saveOnExit={() => save(placement, app.id)}
                  updateAdgroupPlacement={updateAdgroupPlacement}
                  updateAdgroupPlacementLocal={updateAdgroupPlacementLocal}
                />
              </Segment>
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Bid Floors',
        render: () => (
          <Tab.Pane>
            <AskPriceEnabled
              isAskPriceEnabled={placement?.ask_price_enabled}
              isShown={isZynga || isTestAccount}
              setAskPriceEnabled={(val) =>
                editPlacement(app.id, placement.id, 'ask_price_enabled', val)
              }
            />
            <PrioritizeBidding
              isPrioritizeBidding={placement?.prioritize_bidding}
              prioritizeBiddingCPM={placement?.bidding_prioritization_cpm}
              setIsPrioritizeBidding={(val) => {
                editPlacement(app.id, placement.id, 'prioritize_bidding', val);
              }}
              setPrioritizeBiddingCPM={(val) =>
                editPlacement(app.id, placement.id, 'bidding_prioritization_cpm', val)
              }
            />
            <Bidfloors
              abtest={abtest}
              appId={app.id}
              placement={placement}
              removeFloor={this.props.removeFloor}
              set={set}
              setFloor={this.props.setFloor}
            />
          </Tab.Pane>
        ),
      },
    ];

    const metricsPane = {
      menuItem: 'Overview',
      render: () => (
        <Tab.Pane>
          <div className="metrics-filter">
            <DateFilter
              date_key={type}
              date_from={min}
              date_to={max}
              onSelect={(_e) => null}
              onApply={(_e, endDate, startDate, selectedKey) =>
                updateDateFilter(startDate, endDate, selectedKey)
              }
            />
          </div>
          <MetricsGraphContainer pageView="singlePlacement" />
          <PageHeader content="Bidding" />
          <Segment padded loading={credsLoading} textAlign="left" basic>
            <CredentialsPlacements
              app={app}
              loading={loading}
              create={this.props.createPlacementCredentials}
              delete={this.props.deletePlacementCredentials}
              edit={this.props.togglePlacementCredentials}
              options={networkOptions}
              placement={placement}
              shouldFetchPlacementLineItems={shouldFetchPlacementLineItems}
              type="placement_credentials"
            />
          </Segment>
          <div>
            <PageHeader content="Waterfall" />
            <AdGroupEditor
              addAdgroup={addAdgroup}
              adgroups={adgroups}
              app={app}
              compact={false}
              isBetaTestAccount={isBetaTestAccount}
              mediationMetrics={mediationMetrics}
              placement={placement}
              saveOnExit={() => save(placement, app.id)}
              updateAdgroupPlacement={updateAdgroupPlacement}
            />
          </div>
        </Tab.Pane>
      ),
    };

    if (!this.state?.isNewPlacement) {
      panes.unshift(metricsPane);
    }

    return (
      <Segment attached="bottom">
        <Segment basic loading={loading}>
          <DualHeader>
            <PageHeader content={placement.name || 'New Placement'} />
            <Button {...saveButtonProps} />
          </DualHeader>

          <Tab panes={panes} />
        </Segment>
      </Segment>
    );
  };
}

export default PlacementForm;
