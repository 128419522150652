import * as React from "react";
import { Icon, Loader } from "semantic-ui-react";

interface RateIconProps {
  handleIconClick: any;
  isSubmitError: boolean;
  isSubmitSuccess: boolean;
  isSubmitting: boolean;
  lineItemId: string;
  submitingId: string;
}

const RateIcon: React.FunctionComponent<RateIconProps> = ({
  handleIconClick,
  isSubmitError,
  isSubmitSuccess,
  isSubmitting,
  lineItemId,
  submitingId,
}) => {
  const isRateSubmitting = submitingId === lineItemId && isSubmitting;
  const isRateChanged =
    isSubmitSuccess && !isSubmitError && submitingId === lineItemId;

  return (
    <div
      className={`input-status${isRateSubmitting ||
        (isSubmitSuccess && submitingId === lineItemId)
        ? " visible"
        : ""
        } `}
    >
      {isRateSubmitting ? (
        <Loader active size="tiny" className="input-loader" />
      ) : (
        <button className="input-check-button">
          <Icon
            name="check"
            className="input-check"
            style={{
              color: `${isRateChanged ? "green" : ""}`,
            }}
            onClick={(_e: React.MouseEvent<HTMLButtonElement>) =>
              handleIconClick()
            }
          />
        </button>
      )}
    </div>
  );
};

export default RateIcon;
