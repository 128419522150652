import countries from './country_codes';
import countries_50 from './country_codes_50';
import countries_2L from './country_codes_2L';

export const NEW_ENTITY_ID = '__new__';

export const TOOLTIPS = {
  top_demand_sources_performance: 'Metrics based on how each ad network performed for your apps',
  top_app_performance: 'How each of your apps performed',
  bid_rate: 'The percentage of valid bids over the ad requests',
  win_rate: 'The percentage of winning bids over valid bids',
  impression_show_rate: 'The percentage of shown impressions over winning bids',
  fill_rate: 'The percentage of impressions over requests',
  analytics_timezone: 'Data is in Pacific Time Zone UTC-08:00',
};

export const TOTALS_KEY_MAP = ['today', 'yesterday', 'last-7-days', 'last-30-days', 'last-90-days'];

export const COUNTRIES = countries;

export const COUNTRIES_50 = countries_50;

export const COUNTRIES_2L = countries_2L;

export const COUNTRY_MAP = countries.reduce((acc, cur) => {
  acc[cur.value] = cur;
  return acc;
}, {});
