import Actions from 'core/framework/actions';
import Api from './api';

/* Actions */

const terms = {
  FETCH_ADGROUPS: 'ADGROUP.FETCH',
  SELECT_ADGROUP: 'ADGROUP.SELECT',
  CREATE_ADGROUP: 'ADGROUP.CREATE',
  CREATE_LOCAL_ADGROUP: 'ADGROUP.CREATE.LOCAL',
  CLEAR_ADGROUP_TRANSITION: 'ADGROUP.CLEAR.TRANSITION',
  SET_ADGROUP_FIELD: 'ADGROUP.SET.FIELD',
  ADD_ADGROUP_COUNTRY: 'ADGROUP.ADD.COUNTRY',
  REMOVE_ADGROUP_COUNTRY: 'ADGROUP.REMOVE.COUNTRY',
  ADD_ADGROUP_PLACEMENT: 'ADGROUP.ADD.PLACEMENT',
  REMOVE_ADGROUP_PLACEMENT: 'ADGROUP.REMOVE.PLACEMENT',
  ADD_ADGROUP_LINEITEM: 'ADGROUP.ADD.LINEITEM',
  REMOVE_ADGROUP_LINEITEM: 'ADGROUP.REMOVE.LINEITEM',
  UPDATE_ADGROUP: 'ADGROUP.UPDATE',
  REMOVE_ADGROUP: 'ADGROUP.REMOVE',
  UPDATE_ADGROUP_PLACEMENT: 'ADGROUP.UPDATE.PLACEMENT',
  UPDATE_ADGROUP_PLACEMENT_LOCAL: 'ADGROUP.UPDATE.PLACEMENT.LOCAL'
};

const fetchAll = Actions.create({
  type: terms.FETCH_ADGROUPS,
  args: ['appId'],
  awaits: Api.getAll,
  message: ['fetch', 'adgroups']
});

const removeAdgroup = Actions.create({
  type: terms.REMOVE_ADGROUP,
  args: ['appId', 'adgroupId'],
  awaits: Api._delete,
  message: ['remove', 'adgroup']
});

const update = Actions.create({
  type: terms.UPDATE_ADGROUP,
  args: ['appId', 'adgroup'],
  awaits: Api.update,
  message: ['save', 'adgroup']
});

const updateAdgroupPlacement = Actions.create({
  type: terms.UPDATE_ADGROUP_PLACEMENT,
  args: ['appId', 'adgroup', 'placementId'],
  awaits: Api.update,
  message: ['update', 'adgroup']
});

const updateAdgroupPlacementLocal = Actions.create({
  type: terms.UPDATE_ADGROUP_PLACEMENT_LOCAL,
  args: ['appId', 'adgroup', 'placementId'],
  message: ['update', 'adgroup']
});

const create = Actions.create({
  type: terms.CREATE_ADGROUP,
  args: ['appId', 'adgroup'],
  awaits: Api.create,
  message: ['save', 'adgroup']
});

const createLocal = Actions.create({
  type: terms.CREATE_LOCAL_ADGROUP,
  args: ['placement']
});

const clearTransition = Actions.create({
  type: terms.CLEAR_ADGROUP_TRANSITION,
  args: []
});

const selectAdGroup = Actions.create({
  type: terms.SELECT_ADGROUP,
  args: ['id']
});

const setField = Actions.create({
  type: terms.SET_ADGROUP_FIELD,
  args: ['appId', 'agId', 'name', 'value']
});

const addCountry = Actions.create({
  type: terms.ADD_ADGROUP_COUNTRY,
  args: ['appId', 'agId', 'country']
});

const removeCountry = Actions.create({
  type: terms.REMOVE_ADGROUP_COUNTRY,
  args: ['appId', 'agId', 'country']
});

const addPlacement = Actions.create({
  type: terms.ADD_ADGROUP_PLACEMENT,
  args: ['appId', 'agId', 'placementId']
});

const removePlacement = Actions.create({
  type: terms.REMOVE_ADGROUP_PLACEMENT,
  args: ['appId', 'agId', 'placementId']
});

const addLineItem = Actions.create({
  type: terms.ADD_ADGROUP_LINEITEM,
  args: ['appId', 'agId', 'itemId']
});

const removeLineItem = Actions.create({
  type: terms.REMOVE_ADGROUP_LINEITEM,
  args: ['appId', 'agId', 'itemId']
});

const AdgroupActions = {
  ...terms,
  fetchAll,
  selectAdGroup,
  setField,
  addCountry,
  removeCountry,
  addPlacement,
  removeAdgroup,
  removePlacement,
  clearTransition,
  removeLineItem,
  addLineItem,
  createLocal,
  create,
  update,
  updateAdgroupPlacement,
  updateAdgroupPlacementLocal
};

export default AdgroupActions;
