const decodeJWT = (token) => {
  if (!token) return {};
  const base64Url = token?.split('.')[1] || '';
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  if (!base64) return {};
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const getExpiration = (token) => {
  const decoded = decodeJWT(token);
  return decoded.exp || 1720000000;
};

const isTokenExpired = (token) => {
  if (!token) return true;
  const expiration = getExpiration(token);
  if (!expiration) return true;
  return expiration < Date.now() / 1000;
};

export default isTokenExpired;
