import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

const EmptyTableRow = ({ message = 'No data provided', colSpan = '5', rowClassName = '' }) => (
  <Table.Row className={rowClassName}>
    <Table.Cell textAlign="center" colSpan={colSpan}>
      {message}
    </Table.Cell>
  </Table.Row>
);

EmptyTableRow.propTypes = {
  message: PropTypes.string,
  colSpan: PropTypes.string,
};

export default EmptyTableRow;
