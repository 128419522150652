import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { useDebounce } from '@uidotdev/usehooks';

import Helpers from '../helpers';
import Constants from '../../placement/constants';

const LineItemFilterInput = (props) => {
  const { itemLength, networkFilter, networks, type, updateFilter, supportedPlacementPartners } =
    props;
  const [searchQuery, setSearch] = useState('');
  const debouncedSearchFilter = useDebounce(searchQuery, 600);
  const { waterfall_supported_map } = supportedPlacementPartners || {};

  let availableNetworks = networks
    ? networks.map((network) => {
        const name = network.name;
        const supported = waterfall_supported_map[name] || {};
        return Helpers.NetworkBuilder({ name, supported });
      })
    : [];
  availableNetworks.unshift({ key: 'all', value: 'all', text: 'All Networks' });

  useEffect(() => {
    // only update search after debounced time
    updateFilter('searchFilter', debouncedSearchFilter);
  }, [debouncedSearchFilter]);

  return (
    <div className="filter container">
      <div className="search left">
        <h3>
          {Constants.AD_TYPE_LABELS[type]} line items ({itemLength})
        </h3>
      </div>
      <div className="search right">
        <div className="search dropdown">
          <Form>
            <Form.Dropdown
              inline
              label="Network"
              name="network_id"
              onChange={(_, { value }) => updateFilter('networkFilter', value)}
              options={availableNetworks}
              placeholder="Select Network"
              selection={true}
              value={networkFilter}
            />
          </Form>
        </div>
        <div className="search input">
          <Form>
            <Form.Input
              key="serchfilterintput"
              autoComplete="off"
              inline
              label="Search"
              name="search"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search line items"
              value={searchQuery}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LineItemFilterInput;
