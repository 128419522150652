const AD_TYPES = [
  { key: 'interstitial', text: 'Interstitial', value: 'interstitial' },
  { key: 'rewarded', text: 'Rewarded', value: 'rewarded' },
  { key: 'banner', text: 'Banner', value: 'banner' },
  { key: 'adaptive_banner', text: 'Adaptive Banner', value: 'adaptive_banner' },
  { key: 'rewarded_interstitial', text: 'Rewarded Interstitial', value: 'rewarded_interstitial' },
];

const SORT_OPTION_DEFAULT_STATE = {
  network_id: 'sort',
  name: 'sort',
  partner_placement: 'sort',
  cpm: 'sort',
};

const CREATE_LINEITEM_DEFAULT_STATE = (type) => ({
  auto_cpm: false,
  first_look: false,
  checked: true,
  enabled: true,
  cpm: '0',
  name: '',
  network_id: '',
  partner_placement: '',
  partner_details: {},
  type: type,
});

const COMMON_FIELDS = [
  'auto_cpm',
  'cpm',
  'name',
  'network_id',
  'partner_placement',
  'type',
  'checked',
  'first_look',
];

const Constants = {
  AD_TYPES,
  COMMON_FIELDS,
  CREATE_LINEITEM_DEFAULT_STATE,
  SORT_OPTION_DEFAULT_STATE,
};

export default Constants;
