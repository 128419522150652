import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import GoogleRevokeCredentials from './GoogleRevokeCredentials';
import GoogleLoginContainer from './GoogleLoginContainer';
import GoogleBiddingAdUnitDropdown from '../google_bidding/views/GoogleBiddingAdUnitDropdown';
// Targeting Rules Components
import PredicateInput from '../../lineitem/targetingRules/components/PredicateInput';
import TargetingRulesContainer from '../../lineitem/targetingRules/components/TargetingRulesContainer';
import TargetRuleConstants from '../../lineitem/targetingRules/constants/targetingRules';
import TargetingRulesHelpers from '../../lineitem/targetingRules/helpers';
import NetworkHelpers from '../helpers';
import AmazonHelpers from '../amazon_aps/helpers';

import '../css/credentials.css';
import PricePointsInput from '../amazon_aps/views/PricePointsInput';

const EditCredentialModal = (props) => {
  const {
    appId,
    editCredentials,
    isDisabled,
    source,
    isPlacement,
    setEditShown,
    closePopup,
    placement,
    loading,
    createRule,
    deleteRule,
    negateRule,
    addPredicate,
    deletePredicate,
    editPredicate,
    getPlacementBiddingLineItems,
    shouldFetchPlacementLineItems,
  } = props;
  const [modalOpen, setModal] = useState(false);
  const [credentialState, setCredentialState] = useState({});
  const { line_item_id } = credentialState;

  const [targetingRuleState, setTargetingRuleState] = useState({
    ...TargetRuleConstants.INITIAL_TARGETING_RULES_STATE,
    errors: [],
    keywords: {},
    keywordOptions: [],
  });

  const setKeywords = (customWords, reservedWords) => {
    const filteredReservedWords = TargetingRulesHelpers.Processors.filterKeywords(
      reservedWords,
      source?.id
    );
    const combinedKeywords = { ...customWords, ...filteredReservedWords };
    const options = TargetingRulesHelpers.Processors.keywordsToOptions(combinedKeywords);
    setTargetingRuleState({
      ...targetingRuleState,
      keywords: combinedKeywords,
      keywordOptions: options,
    });
  };

  const toggleAddPredicate = (isAdding, uuid) => {
    if (isAdding) {
      setTargetingRuleState({
        ...targetingRuleState,
        isAddingPredicate: true,
        inputType: 'add',
        addingPredicateId: uuid,
      });
    } else {
      handleResetState();
    }
  };

  const toggleEditPredicate = (isEditing, ruleId, predicate) => {
    if (isEditing) {
      setTargetingRuleState({
        ...targetingRuleState,
        isEditingPredicate: true,
        editingPredicateId: predicate.uuid,
        selectedRuleId: ruleId,
        inputType: 'edit',
        keyword: predicate.keyword.key,
        keywordType: predicate.keyword.keyword_type,
        operation: predicate.operation,
        value: predicate.value,
      });
    } else {
      handleResetState();
    }
  };

  const handleResetState = () => {
    setTargetingRuleState({
      ...targetingRuleState,
      ...TargetRuleConstants.INITIAL_TARGETING_RULES_STATE,
      errors: [],
    });
  };

  const handleSubmitPredicate = () => {
    const errors = TargetingRulesHelpers.Validators.validateKeywordType(
      targetingRuleState.value,
      targetingRuleState.keywordType
    );

    if (errors.length > 0) {
      setTargetingRuleState((prevState) => ({
        ...prevState,
        errors,
      }));
    }

    if (errors.length === 0) {
      const { predicate, predicateId, rule, ruleId, submitType } =
        TargetingRulesHelpers.Processors.processPredicate(targetingRuleState);

      if (submitType === 'add') {
        if (ruleId === 'newRule') {
          createRule({ appId, lineItemId: line_item_id, rule });
        } else {
          addPredicate({ appId, lineItemId: line_item_id, ruleId, predicate });
        }
      } else if (submitType === 'edit') {
        editPredicate({ appId, lineItemId: line_item_id, ruleId, predicateId, predicate });
      }
      handleResetState();
    }
  };

  const handleDeleteRule = (ruleID) => {
    deleteRule(appId, placement?.credentials?.[source?.id]?.line_item_id, ruleID);
  };

  const handleDeletePredicate = (ruleID, predicateId) => {
    deletePredicate(appId, placement?.credentials?.[source?.id]?.line_item_id, ruleID, predicateId);
  };

  const handleNegateRule = (ruleID, isNegated) => {
    const rule = TargetingRulesHelpers.Processors.getRule(
      ruleID,
      placement?.credentials?.[source?.id],
      isNegated
    );
    negateRule(appId, placement?.credentials?.[source?.id]?.line_item_id, ruleID, rule);
  };

  const handleOpen = () => {
    if (setEditShown) {
      setEditShown(true);
    }
    setModal(true);
  };

  const handleClose = () => {
    if (setEditShown && closePopup) {
      setEditShown(false);
      closePopup();
    }
    setModal(false);
    handleResetState();
  };

  const handleSubmit = () => {
    // Remove targeting rules from saving normal placement bidding credentials
    const credentialFields = source.fields.filter(
      (field) => field.name !== 'line_item_id' && field.name !== 'targeting_rules'
    );
    const updatedFields = credentialFields.map((credentialField) => {
      let update = { ...credentialField };
      let { name, value } = credentialField;
      if (credentialState[name] !== value) {
        update = { ...credentialField, value: credentialState[name] };
      }
      return update;
    });
    const updateSource = { ...source, fields: updatedFields };
    editCredentials(updateSource);
    handleClose();
  };

  const setter = (ctx, e) => {
    let value = e.value;
    setCredentialState({
      ...credentialState,
      [e.name]: value,
    });

    if (e.name === 'keyword') {
      const keywordType = TargetingRulesHelpers.Processors.getKeywordType(
        value,
        targetingRuleState.keywords
      );
      setTargetingRuleState({
        ...targetingRuleState,
        keyword: value,
        keywordType,
        operation: '',
        value: '',
        errors: [],
      });
    } else {
      setTargetingRuleState({
        ...targetingRuleState,
        [e.name]: value,
        errors: [],
      });
    }
  };

  const isModified = () => {
    let result = false;
    source &&
      source.fields &&
      source.fields.forEach((credentialLine) => {
        Object.keys(credentialLine).forEach((key) => {
          if (key === 'value') {
            if (credentialLine[key] !== credentialState[credentialLine.name]) {
              result = true;
            }
          }
        });
      });
    return result;
  };

  const isCSVValid = () => {
    if (!credentialState || !credentialState.price_points) return true;
    return credentialState?.price_points.length > 0;
  };

  const shouldDisableSubmit = !isModified() || !isCSVValid();

  useEffect(() => {
    const initialState =
      source &&
      source.fields &&
      Object.keys(source.fields).reduce((obj, key) => {
        obj[source.fields[key].name] = source.fields[key].value;
        return obj;
      }, {});
    setCredentialState(initialState);
  }, [source]);

  useEffect(() => {
    // Fetch Placement Line Items and set targeting rules
    if (placement && modalOpen && shouldFetchPlacementLineItems === true) {
      getPlacementBiddingLineItems(appId, placement?.id);
    }
  }, [shouldFetchPlacementLineItems, modalOpen]);

  return (
    <Modal
      centered={true}
      className="hl-modal LineItemModal"
      closeIcon
      closeOnDimmerClick={false}
      onClose={handleClose}
      open={modalOpen}
      size="fullscreen"
      trigger={
        isPlacement ? (
          <Button className="removeLineItemButton" onClick={handleOpen} disabled={isDisabled}>
            Edit Bidding Partner
          </Button>
        ) : (
          <Button
            icon="pencil"
            onClick={handleOpen}
            disabled={source.name == 'BidMachine' && placement != undefined ? true : isDisabled}
          />
        )
      }
    >
      <Modal.Header content="Update Network Credential" color="green" />
      <Modal.Content>
        <div className="edit lineItem content">
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '25px' }}>
            <div>
              <Form className="lineItem edit container">
                {source?.fields &&
                  source.fields.map((field) => {
                    // skip showing line item id and targeting rules fields
                    if (
                      field?.name === 'line_item_id' ||
                      field?.name === 'targeting_rules' ||
                      field?.name === 'managed_prebidding'
                    ) {
                      return null;
                    }
                    if (
                      !NetworkHelpers.shouldDisplayCredentialField(
                        source,
                        field,
                        isPlacement,
                        placement
                      )
                    ) {
                      return null;
                    }
                    if (
                      source?.id === 'amazon_aps' &&
                      field?.name === 'price_points' &&
                      (isPlacement || placement?.id === '__new__')
                    ) {
                      return <PricePointsInput setter={setter} credentialState={credentialState} />;
                    }
                    if (
                      source?.id === 'amazon_aps' &&
                      field?.name === 'size' &&
                      (isPlacement || placement?.id === '__new__')
                    ) {
                      return (
                        <Form.Dropdown
                          placeholder="Select Ad Size"
                          fluid={true}
                          selection={true}
                          labelPosition="left"
                          name="size"
                          value={field.value}
                          label="Ad Size"
                          options={AmazonHelpers.getSizes(placement)}
                          onChange={setter}
                        />
                      );
                    }
                    if (
                      source?.id === 'google_googlebidding' &&
                      isPlacement &&
                      field?.name === 'ad_unit_id'
                    ) {
                      return (
                        <>
                          <Form.Input
                            autoComplete="off"
                            label={field.label}
                            name="ad_unit_id"
                            onChange={setter}
                            value={
                              credentialState &&
                              credentialState['ad_unit_id'] &&
                              credentialState['ad_unit_id']
                            }
                          />
                          <div style={{ width: '300px' }}>
                            <>Or select from existing Ad Units </>
                            <GoogleBiddingAdUnitDropdown placement={placement} edit={setter} />
                          </div>
                        </>
                      );
                    }
                    return (
                      <Form.Input
                        disabled={source.id === 'chartboost' || field.disabled}
                        autoComplete="off"
                        label={field.label}
                        name={field.name}
                        onChange={setter}
                        value={
                          credentialState &&
                          credentialState[field.name] &&
                          credentialState[field.name]
                        }
                      />
                    );
                  })}
                {!isPlacement && NetworkHelpers.hasGoogleSSOField(source) && (
                  <div className="edit-google-container">
                    {NetworkHelpers.shoudDisplayRevokeGoogleSSO(source) && (
                      <GoogleRevokeCredentials appId={appId} network={source?.id} />
                    )}
                    {NetworkHelpers.shoudDisplayLoginGoogleSSO(source) && (
                      <GoogleLoginContainer
                        appId={appId}
                        isEdit={true}
                        networkAppId={
                          credentialState?.admob_app_id ||
                          credentialState?.google_googlebidding_app_id
                        }
                        network={source?.id}
                      />
                    )}
                  </div>
                )}
              </Form>
            </div>
            <div>
              {placement && (
                <TargetingRulesContainer
                  addingPredicateId={targetingRuleState.addingPredicateId}
                  editingPredicateId={targetingRuleState.editingPredicateId}
                  handleDeletePredicate={handleDeletePredicate}
                  handleDeleteRule={handleDeleteRule}
                  handleNegateRule={handleNegateRule}
                  isAddingPredicate={targetingRuleState.isAddingPredicate}
                  isEditingPredicate={targetingRuleState.isEditingPredicate}
                  isLoading={loading}
                  keyword={targetingRuleState.keyword}
                  lineItem={placement?.credentials?.[source?.id] || {}}
                  operation={targetingRuleState.operation}
                  selectedRuleId={targetingRuleState.selectedRuleId}
                  setKeywords={setKeywords}
                  setter={setter}
                  toggleAddPredicate={toggleAddPredicate}
                  toggleEditPredicate={toggleEditPredicate}
                  value={targetingRuleState.value}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        {(targetingRuleState.isAddingPredicate || targetingRuleState.isEditingPredicate) && (
          <PredicateInput
            errors={targetingRuleState.errors}
            handleSubmitPredicate={handleSubmitPredicate}
            inputType={targetingRuleState.inputType}
            keyword={targetingRuleState.keyword}
            keywordOptions={targetingRuleState.keywordOptions}
            keywordType={targetingRuleState.keywordType}
            operation={targetingRuleState.operation}
            setter={setter}
            toggleAddPredicate={toggleAddPredicate}
            toggleEditPredicate={toggleEditPredicate}
            value={targetingRuleState.value}
          />
        )}
        {!targetingRuleState.isAddingPredicate && !targetingRuleState.isEditingPredicate && (
          <div>
            <Button onClick={handleSubmit} disabled={shouldDisableSubmit}>
              <Icon name="add" /> Submit Changes
            </Button>

            <Button onClick={handleClose}>
              <Icon name="remove" /> Cancel
            </Button>
          </div>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default EditCredentialModal;
