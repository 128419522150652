import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// views
import { Button, Message, Segment, Transition } from 'semantic-ui-react';
import { AppLayout, DualHeader, PageHeader, RouteLeavingGuard } from 'core/app/views';
import AdGroupForm from './AdGroupForm.jsx';

// modules
import AdGroup from 'apps/adgroup';
import App from 'apps/app';
import LineItem from 'apps/lineitem';
import Placement from 'apps/placement';
import User from 'core/user';

import Helpers from '../helpers';

class CreateAdgroup extends AdGroupForm {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitClicked: false,
      saved: false,
    };
  }

  componentDidMount() {
    this.props.createLocal(this.props.preset);
    if (this.props.shouldFetchPlacements) {
      this.props.fetchPlacements(this.props.appId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldFetchPlacements) {
      this.props.fetchPlacements(this.props.appId);
    }
    // preset the adgroup with placement
    if (
      nextProps.preset &&
      this.props.preset !== nextProps.preset &&
      this.props.pid === '__new__'
    ) {
      this.props.createLocal(nextProps.preset);
    }
  }

  handleSubmit = () => {
    const { save, appId, adgroup, warnings } = this.props;
    if (warnings.length > 0) {
      this.setState({ isSubmitClicked: true });
    } else {
      this.setState({ isSubmitClicked: false, saved: true });
      save(appId, adgroup);
    }
  };

  render() {
    const { app, warnings, madeChanges } = this.props;
    const { isSubmitClicked, saved } = this.state;

    const isDirty = madeChanges && !saved;

    const context = {
      app: app,
      adgroups: true,
      extra: {
        url: `/apps/${app.id}/createAdGroup`,
        label: 'New',
      },
    };

    return (
      <AppLayout bundle="apps" context={context}>
        <RouteLeavingGuard when={isDirty} shouldBlockNavigation={() => true} />
        <Segment padded textAlign="left" className="pt-10 pb-10">
          {warnings.length > 0 && isSubmitClicked ? (
            <Transition animation="bounce" transitionOnMount>
              <Message
                content={warnings.map((warning, idx) => (
                  <li key={idx}>{warning}</li>
                ))}
                color="red"
              />
            </Transition>
          ) : null}
          <DualHeader>
            <PageHeader content="Create Ad Group" img={true} />
            <Button className="positive" content="Save Ad Group" onClick={this.handleSubmit} />
          </DualHeader>
          {this.renderForm()}
        </Segment>
      </AppLayout>
    );
  }
}

const props = (state, ownProps) => {
  const appId = ownProps.match.params.id;
  const app = App.Store.getApp(state, appId) || {};
  const adgroup = AdGroup.Store.getNew(state);
  const warnings = Helpers.isValid(adgroup);

  let preset = false;
  let pid = ownProps.match.params.pid;

  // Get the AdGroup Presets
  switch (pid) {
    case 'interstitial':
      preset = { type: 'interstitial' };
      break;
    case 'rewarded':
      preset = { type: 'rewarded' };
      break;
    case 'banner':
      preset = { type: 'banner' };
      break;
    case 'adaptive_banner':
      preset = { type: 'adaptive_banner' };
      break;
    case 'rewarded_interstitial':
      preset = { type: 'rewarded_interstitial' };
      break;
    case '__new__':
      preset = Placement.Store.getOne(state, appId, Placement.Store.getNewPlacementId(state));
      break;
    default:
      preset = pid ? Placement.Store.getOne(state, appId, pid) : false;
      break;
  }

  return {
    adgroup: adgroup,
    app: app,
    appId: appId,
    isBetaTestAccount: User.Store.isBetaTestAccount(state),
    loading: AdGroup.Store.getLoading(state),
    madeChanges: App.Store.hasMadeChanges(state),
    pid: pid,
    preset: preset,
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    shouldFetchLineItems: LineItem.Store.shouldFetch(state),
    shouldFetchPlacements: Placement.Store.getShouldFetchPlacements(state),
    shouldTransition: AdGroup.Store.shouldTransition(state),
    warnings: warnings,
  };
};

const dispatches = {
  addCountry: AdGroup.Actions.addCountry,
  addPlacement: AdGroup.Actions.addPlacement,
  createLocal: AdGroup.Actions.createLocal,
  fetchAdGroups: AdGroup.Actions.fetchAll,
  fetchLineItems: LineItem.Actions.fetchAll,
  fetchPlacements: Placement.Actions.fetchAll,
  removeCountry: AdGroup.Actions.removeCountry,
  removePlacement: AdGroup.Actions.removePlacement,
  save: AdGroup.Actions.create,
  setField: AdGroup.Actions.setField,
};

export default withRouter(connect(props, dispatches)(CreateAdgroup));
