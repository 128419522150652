import Api from 'core/framework/api';
import Actions from 'core/framework/actions';

/***********************
 * USER LEVEL ACTIONS
 **********************/

const terms = {
  LOGIN: 'USER.LOGIN',
  LOGOUT: 'USER.LOGOUT',
  FETCH_EXPERIENCE: 'USER.FETCH_EXPERIENCE',
  FETCH_TERMS: 'USER.FETCH_TERMS',
  ACCEPT_TERMS: 'USER.ACCEPT_TERMS',
  FETCH_IS_BETA_TEST_ACCOUNT: 'USER.FETCH_IS_BETA_TEST_ACCOUNT',
  FETCH_IS_TEST_ACCOUNT: 'USER.FETCH_IS_TEST_ACCOUNT',
};

const login = Actions.create({
  type: terms.LOGIN,
  args: ['data'],
  awaits: (data) => Api.xhr(`${Api.DASH_API}/login`, data, { encode: true }),
});

const logout = Actions.create({
  type: terms.LOGOUT,
  args: [],
  awaits: () => Api.xhr(`${Api.DASH_API}/logout`),
});

const fetchExperience = Actions.create({
  type: terms.FETCH_EXPERIENCE,
  args: [],
  awaits: () => Api.xhr(`${Api.DASH_API}/experience`),
});

const fetchTerms = Actions.create({
  type: terms.FETCH_TERMS,
  args: [],
  awaits: () => Api.get(`${Api.HELIUM_API}/terms-of-service`, null, { token: true }),
  message: ['fetch-failure', 'terms of service'],
});

const acceptTerms = Actions.create({
  type: terms.ACCEPT_TERMS,
  args: [],
  awaits: () =>
    Api.post(
      `${Api.HELIUM_API}/terms-of-service`,
      { accepted: true },
      { token: true, method: 'POST' }
    ),
  message: ['save-failure', 'terms of service'],
});

const fetchIsBetaTestAccount = Actions.create({
  type: terms.FETCH_IS_BETA_TEST_ACCOUNT,
  args: [],
  awaits: () => Api.get(`${Api.HELIUM_API}/is-beta-company`, null, { token: true, method: 'GET' }),
});

const fetchIsTestAccount = Actions.create({
  type: terms.FETCH_IS_TEST_ACCOUNT,
  args: [],
  awaits: () => Api.get(`${Api.HELIUM_API}/is-test-account`, null, { token: true, method: 'GET' }),
});

const UserActions = {
  ...terms,
  login,
  logout,
  fetchExperience,
  fetchTerms,
  acceptTerms,
  fetchIsBetaTestAccount,
  fetchIsTestAccount,
};

export default UserActions;
