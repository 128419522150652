import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Input, Button} from 'semantic-ui-react';


const CopyableInput = ({value, className}) => (
    <div className={className}>
        <Input value={value} disabled />
        <CopyToClipboard text={value}>
            <Button icon="copy" content="copy" className="positive" />
        </CopyToClipboard>
    </div>
);

export default CopyableInput;