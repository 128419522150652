import React from 'react';
import GoogleLoginContainer from '../../views/GoogleLoginContainer';
import { Grid, Message, Form, Divider } from 'semantic-ui-react';

const GoogleBiddingForm = (props) => {
  const { fields, appId } = props;

  const google_bidding_app_id =
    fields &&
    fields[0] &&
    fields[0].props &&
    fields[0].props.children &&
    fields[0].props.children.props &&
    fields[0].props.children.props.value;

  const messageStyle =
    google_bidding_app_id && google_bidding_app_id.length ? 'sso-message-hidden' : 'sso-message';

  return (
    <div style={{ position: 'relative' }}>
      <Grid columns={2}>
        <Grid.Column>
          <Form>{fields}</Form>
        </Grid.Column>
        <Grid.Column>
          <Form>{fields[0]}</Form>
          <Message color="yellow" size="mini" className={messageStyle}>
            <p>Google bidding App ID required for google sign in</p>
          </Message>
          <GoogleLoginContainer appId={appId} network="gbid" networkAppId={google_bidding_app_id} />
        </Grid.Column>
      </Grid>
      <Divider vertical>OR</Divider>
    </div>
  );
};

export default GoogleBiddingForm;
