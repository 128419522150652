import Actions from 'core/framework/actions';
import Api from './api';

const terms = {
  FETCH_NETWORK_SCHEMA: 'NETWORK.SCHEMA.FETCH',
  FETCH_SUPPORTED_APP_PARTNERS: 'NETWORK.APP.PARTNERS',
  FETCH_SUPPORTED_PLACEMENT_PARTNERS: 'NETWORK.PLACEMENT.PARTNERS',
  FETCH_SUPPORTED_PLACEMENT_TYPES: 'NETWORK.PLACEMENT.TYPES',
};

const fetchNetworkSchema = Actions.create({
  type: terms.FETCH_NETWORK_SCHEMA,
  args: [],
  awaits: Api.getNetworkSchema,
  message: ['fetch-failure', 'network credentials schema'],
});

const fetchSupportedAppPartners = Actions.create({
  type: terms.FETCH_SUPPORTED_APP_PARTNERS,
  args: ['platform', 'network_type'],
  awaits: Api.getSupportedAppPartners,
  message: ['fetch-failure', 'network app partner'],
});

const fetchSupportedPlacementPartners = Actions.create({
  type: terms.FETCH_SUPPORTED_PLACEMENT_PARTNERS,
  args: ['platform', 'network_type', 'placement_type'],
  awaits: Api.getSupportedPlacementPartners,
  message: ['fetch-failure', 'network placement partner'],
});

const fetchSupportedPlacementTypes = Actions.create({
  type: terms.FETCH_SUPPORTED_PLACEMENT_TYPES,
  args: ['platform'],
  awaits: Api.getSupportedPlacementTypes,
  message: ['fetch-failure', 'network placement type'],
});

const NetworkActions = {
  ...terms,
  fetchNetworkSchema,
  fetchSupportedAppPartners,
  fetchSupportedPlacementPartners,
  fetchSupportedPlacementTypes,
};

export default NetworkActions;
