import React from 'react';
import { Checkbox, Image, Popup, Table } from 'semantic-ui-react';

import Network from 'apps/network';
import WarningPopup from 'core/app/views/WarningPopup';
import EditCredentialModal from './EditCredentialModal';
import CredentialField from './CredentialField';
import CredentialPopupField from './CredentialPopupField';
import AmazonApsCredentialField from '../amazon_aps/views/AmazonApsCredentialField';

const CredentialsRow = ({
  appId,
  abtest,
  editCredentials,
  onlySource = false,
  removeSource,
  setSource,
  source = { active: true, fields: [] },
  networkType,
  placement,
}) => {
  const Fields = (source) => {
    if (source && source.fields) {
      switch (source.id) {
        case 'amazon_aps':
          if (placement) {
            return source.fields.map((attributes) => AmazonApsCredentialField(attributes));
          } else {
            const amazonAppID = source.fields.find((field) => field?.name === 'application_id');
            return CredentialField(amazonAppID);
          }
        default:
          return source.fields.map((attributes) => CredentialField(attributes));
      }
    }
  };

  const Compact = (source) => {
    const markup = <CredentialPopupField source={source} />;
    const details = <span className="cred-details"> Credential Details </span>;
    return (
      <Popup trigger={details} content={markup} position="top center" pinned="true" wide="very" />
    );
  };

  const shouldDisableEdit = () => {
    if (placement) return true; // SG-1782: remove ablitity to edit credentials on creation, only allow deletion
    return false;
  };

  const shouldDisableDelete = () => {
    if (networkType === 'mediation' && source.id === 'chartboost') return false;
    // Always allow removing a bidding credential for adaptive banner or rewarded interstitial
    if (placement?.type === 'adaptive_banner' || placement?.type === 'rewarded_interstitial')
      return false;
    if (onlySource || source.id === 'chartboost') {
      return true;
    }
    return false;
  };

  return (
    <Table.Row>
      <Table.Cell className="bidding-network-active">
        <Checkbox
          checked={source.active}
          name="active"
          onClick={setSource}
          toggle
          value={String(!source.active)}
        />
      </Table.Cell>
      <Table.Cell>
        <Image src={Network.Constants.NETWORK_LOGOS[source.id]} avatar className={source.id} />
        <span>{source.name}</span>
      </Table.Cell>
      <Table.Cell>{abtest ? Compact(source) : Fields(source)}</Table.Cell>
      <Table.Cell>
        <EditCredentialModal
          appId={appId}
          editCredentials={editCredentials}
          isDisabled={shouldDisableEdit()}
          source={source}
          placement={placement}
        />
      </Table.Cell>
      <Table.Cell className="center-align">
        <WarningPopup
          action={removeSource}
          actionName="Delete"
          disabled={shouldDisableDelete()}
          name={source.name}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default CredentialsRow;
