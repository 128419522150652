import React from 'react';
import { Button, Card } from 'semantic-ui-react';
import AppCard from 'apps/app/views/AppCard';

class ChartboostAppCard extends AppCard {
  constructor(props) {
    super(props);
    this.type = 'chartboost-card';
  }

  renderDescription = () => {
    const app = this.props.app;
    const existing = this.props.importedApps[app.key];
    const importer = e => this.props.importFunction(app.key);
    const buttonText = existing ? 'Already Imported' : 'Import';
    return (
      <Card.Description>
        <Button
          icon="plus circle"
          className={existing ? '' : 'positive'}
          content={buttonText}
          onClick={importer}
          disabled={existing}
        />
      </Card.Description>
    );
  };
}

export default ChartboostAppCard;
