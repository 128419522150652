import React from 'react';

import MetricHelpers from '../helpers';
import MetricsConstants from '../constants';

const ToolTipCalculated = ({ selectedView, value }) => {
  if (value) {
    value = MetricHelpers.formatValue({ value, selectedView });
  }
  const calculated = MetricHelpers.isCalculatedMetric(selectedView) ? 'Average' : 'Total';
  return (
    <div className="metrics-tooltip-calculated">
      <b>
        {calculated} {MetricsConstants.FIELD_LABELS[selectedView]}:
      </b>
      <div>{value}</div>
    </div>
  );
};

export default ToolTipCalculated;
