import Network from 'apps/network';
import Utils from 'core/framework/utils';
import Constants from './constants';
import TestAccountWarning from '../../apps/network/views/TestAccountWarning';

const NewLineItem = () => ({
  name: '',
  network: 'chartboost',
  value: '',
});

const isPartnerField = (name) => Constants.COMMON_FIELDS.indexOf(name) === -1;

const _getOne = (items, id) => items.find((l) => l.id === id);

const _getSome = (items, ids) => ids.map((id) => _getOne(items, id));

const get = (items, ids) => {
  const existingLineItems = Array.isArray(ids) ? _getSome(items, ids) : _getOne(items, ids);
  return existingLineItems.filter((lineItem) => lineItem !== undefined);
};

const filterLineItems = (lineItems, type) => {
  return lineItems.filter((lineItem) => lineItem.type === type);
};

const toApiLineItem = (item) => {
  const payload = {
    auto_cpm: false,
    first_look: item.first_look,
    cpm: item.cpm,
    name: item.name,
    network_id: item.network_id,
    partner_placement: item.partner_placement,
    type: item.type,
    enabled: item.enabled,
  };
  if (typeof item.partner_details === 'object') {
    if (Object.keys(item.partner_details).length > 0) {
      payload.partner_details = item.partner_details;
    }
  }
  return payload;
};

const labelDeletedLineItems = (items) => {
  if (!Array.isArray(items)) {
    return [];
  }
  return items.map((item) => ({
    ...item,
    name: !item.deleted ? item.name : item.name + '-[Deleted]',
  }));
};

const NetworkBuilder = ({ name, supported }) => ({
  key: name,
  value: name,
  text: (
    <span>
      {Network.Helpers.getName(name)} {!supported && <TestAccountWarning />}
    </span>
  ),
  image: Network.Helpers.getLogo(name),
});

const sortLineItems = (name, sortBy, items) => {
  if (sortBy === 'sort') {
    return items;
  }
  return items.sort((a, b) => {
    if (sortBy === 'sort ascending') {
      if (name === 'cpm') return b[name] - a[name];
      const orderBool = b[name].toLowerCase() > a[name].toLowerCase();
      return orderBool ? 1 : -1;
    }

    if (name === 'cpm') return a[name] - b[name];
    const orderBool = b[name].toLowerCase() < a[name].toLowerCase();
    return orderBool ? 1 : -1;
  });
};

const getNextSortValue = (currentValue) => {
  let nextSortValue = 'sort';
  if (currentValue === 'sort') {
    nextSortValue = 'sort descending';
  } else if (currentValue === 'sort descending') {
    nextSortValue = 'sort ascending';
  } else if (currentValue === 'sort ascending') {
    nextSortValue = 'sort';
  }
  return nextSortValue;
};

const getActive = (lineItems) => {
  if (Utils.isNonZeroArray(lineItems)) {
    if (isNaN(lineItems[0])) {
      return lineItems.filter((line) => !line.deleted);
    }
  }
  return lineItems;
};

const filterSearchLineItems = (network, query, items) => {
  let queryFilter = items.filter((item) => {
    const { name, partner_placement } = item;
    let matchesFilter = false;
    if (
      (name && name.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
      (partner_placement && partner_placement.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    ) {
      matchesFilter = true;
    }
    return matchesFilter;
  });
  let combinedFilter = [];
  if (network === '' || network === 'all') {
    return queryFilter;
  } else {
    combinedFilter = queryFilter.filter((item) => {
      const { network_id } = item;
      let matchesFilter = false;
      if (network_id !== undefined && network_id === network) {
        matchesFilter = true;
      }
      return matchesFilter;
    });
  }
  return combinedFilter;
};

const isLineItemStateValid = (state) => {
  const { cpm, network_id, name, partner_placement, partner_details } = state;
  let isValid = false;
  if (
    network_id?.trim() !== '' &&
    name?.trim() !== '' &&
    partner_placement?.trim() !== '' &&
    cpm !== ''
  ) {
    isValid = true;
    if (network_id === 'mintegral') {
      isValid = false;
      if (
        partner_details?.mintegral_unit_id !== undefined &&
        partner_details?.mintegral_unit_id?.trim() !== ''
      ) {
        isValid = true;
      }
    }
  }
  return isValid;
};

const Helpers = {
  filterLineItems,
  filterSearchLineItems,
  labelDeletedLineItems,
  get,
  getActive,
  getNextSortValue,
  NetworkBuilder,
  NewLineItem,
  sortLineItems,
  toApiLineItem,
  isPartnerField,
  isLineItemStateValid,
};

export default Helpers;
