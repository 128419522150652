const AD_SIZES = {
  INTERSTITIAL: [
    {
      key: 'interstitial',
      text: 'interstitial',
      value: 'interstitial',
    },
  ],
  BANNER: [
    {
      key: '320x50',
      text: '320x50',
      value: '320x50',
    },
    {
      key: '300x250',
      text: '300x250',
      value: '300x250',
    },
    {
      key: '728x90',
      text: '728x90',
      value: '728x90',
    },
  ],
  REWARDED: [
    {
      key: 'rewarded',
      text: 'rewarded',
      value: 'rewarded',
    },
  ],
  ADAPTIVE_BANNER: [
    {
      key: '320x50',
      text: '320x50',
      value: '320x50',
    },
    {
      key: '300x250',
      text: '300x250',
      value: '300x250',
    },
    {
      key: '728x90',
      text: '728x90',
      value: '728x90',
    },
  ],
};

const INDEXED_HEADERS = {
  'Slot Name': 'slot_name',
  'Price Points': 'price_point',
  'Encoded Price Points': 'encoded',
  'Slot Size': 'slot_size',
  'Create Date': 'create_date',
  cpm: 'cpm',
};

const Constants = {
  AD_SIZES,
  INDEXED_HEADERS,
};

export default Constants;
