import React from 'react';

import adcolony from './images/adcolony.png';
import admob from './images/_admob.png';
import applovin from './images/applovin.png';
import chartboost from './images/chartboost.svg';
import facebook from './images/facebook.png';
import ironsource from './images/ironsource.jpeg';
import tapjoy from './images/tapjoy.png';
import unity from './images/unity.png';
import vungle from './images/vungle.png';
import mintegral from './images/mintegral.png';
import inmobi from './images/inmobi.png';
import pangle from './images/pangle.png';
import yahoo from './images/yahoo.png';
import digital_turbine from './images/digital_turbine.png';
import amazon_aps from './images/amazon_aps.png';
import google from './images/google.svg';
import hyprmx from './images/hyprmx.png';
import verve from './images/verve.svg';
import mobilefuse from './images/mobilefuse.png';
import bidmachine from './images/bidMachine.svg';

const NETWORK_FIELDS = {
  adcolony_adc_app_id: 'AdColony App ID',
  adcolony_adc_zone_id: 'AdColony Zone ID',
  adcolony_api_key: 'API Key',
  admob_admob_app_id: 'AdMob App ID',
  admob_api_key: 'API Key',
  admob_client_id: 'Client ID',
  admob_client_secret: 'Client Secret',
  admob_refresh_token: 'Refresh Token',
  amazon_aps_ad_slot_name: 'Ad Slot Name',
  amazon_aps_ad_slot_uuid: 'Ad Slot UUID',
  amazon_aps_application_id: 'Amazon App ID',
  amazon_aps_price_points: 'Price Points',
  amazon_aps_size: 'Ad Size',
  applovin_app_id: 'AppLovin App ID',
  applovin_package_name: 'Package Name',
  applovin_report_key: 'Report Key',
  applovin_sdk_key: 'SDK Key',
  bidmachine_source_id: 'Source ID',
  chartboost_ad_location: 'Chartboost Ad Location',
  chartboost_app_id: 'Chartboost App ID',
  chartboost_app_signature: 'Chartboost App Signature',
  facebook_applicationid: 'Meta Audience Network App ID',
  facebook_placement_name: 'Meta Audience Network Placement ID',
  fyber_app_id: 'Digital Turbine App ID',
  fyber_consumer_key: 'Consumer Key',
  fyber_consumer_secret: 'Consumer Secret',
  fyber_publisher_id: 'Publisher ID',
  fyber_spot_id: 'Spot ID',
  google_googlebidding_ad_unit_id: 'Ad Unit ID',
  google_googlebidding_api_key: 'Api Key',
  google_googlebidding_app_id: 'Google bidding App ID',
  google_googlebidding_client_id: 'Client ID',
  google_googlebidding_client_secret: 'Client Secret',
  google_googlebidding_refresh_token: 'Refresh Token',
  hyprmx_api_key: 'API Key',
  hyprmx_distributor_id: 'Distributor ID',
  inmobi_account_id: 'Account ID',
  inmobi_api_key: 'API Key',
  inmobi_user_name: 'User Name',
  inmobi_placement_id: 'InMobi Placement ID',
  ironsource_app_id: 'ironSource App ID',
  ironsource_app_key: 'App Key',
  ironsource_instance_id: 'Instance ID',
  ironsource_refresh_token: 'Refresh Token',
  ironsource_secret_key: 'Secret Key',
  ironsource_user_name: 'User Name',
  mintegral_app_id: 'Mintegral App ID',
  mintegral_app_key: 'Mintegral App Key',
  mintegral_placement_id: 'Placement ID',
  mintegral_secret: 'Secret',
  mintegral_skey: 'SKey',
  mintegral_unit_id: 'Unit ID',
  mobilefuse_api_key: 'API Key',
  mobilefuse_tag_id: 'Tag ID',
  pangle_application_id: 'Application ID',
  pangle_role_id: 'Role ID',
  pangle_secure_key: 'Secure Key',
  pangle_user_id: 'User ID',
  tapjoy_placement_name: 'Tapjoy Placement Name',
  tapjoy_pub_oauth_key: 'Publisher OAuth Key',
  tapjoy_reporting_key: 'Mediation Reporting API Key',
  tapjoy_sdk_key: 'Tapjoy SDK Key',
  unity_app_id: 'Unity App ID',
  unity_game_id: 'Game ID',
  unity_monetization_api_key: 'Monetization API Key',
  unity_org_core_id: 'Organization Core ID',
  verve_account_token: 'Account Token',
  verve_app_token: 'App Token',
  verve_zone_id: 'Verve Zone ID',
  vungle_placement_reference_id: 'Placement Reference ID',
  vungle_reporting_api_key: 'Reporting API Key',
  vungle_vungle_app_id: 'Liftoff Monetize App ID',
  yahoo_client_id: 'Client ID',
  yahoo_client_secret: 'Client Secret',
  yahoo_organization_id: 'Organization ID',
  yahoo_site_id: 'Site ID',
};

const PARTNER_DETAIL_MAP = {
  mintegral: 'mintegral_unit_id',
};

const NETWORK_LABELS = {
  adcolony: 'AdColony',
  admob: 'AdMob',
  applovin: 'AppLovin',
  chartboost: 'Chartboost',
  facebook: 'Meta Audience Network',
  ironsource: 'ironSource',
  tapjoy: 'Tapjoy',
  unity: 'Unity',
  vungle: 'Liftoff Monetize',
  mintegral: 'Mintegral',
  inmobi: 'InMobi',
  pangle: 'Pangle',
  yahoo: 'Yahoo',
  fyber: 'Digital Turbine',
  amazon_aps: 'Amazon APS',
  google_googlebidding: 'Google bidding',
  hyprmx: 'HyprMX',
  verve: 'Verve',
  mobilefuse: 'MobileFuse',
  bidmachine: 'BidMachine',
};

const NETWORK_LOGOS = {
  adcolony: adcolony,
  admob: admob,
  applovin: applovin,
  chartboost: chartboost,
  default: chartboost,
  facebook: facebook,
  ironsource: ironsource,
  tapjoy: tapjoy,
  unity: unity,
  vungle: vungle,
  mintegral: mintegral,
  inmobi: inmobi,
  pangle: pangle,
  yahoo: yahoo,
  fyber: digital_turbine,
  amazon_aps: amazon_aps,
  google_googlebidding: google,
  hyprmx: hyprmx,
  verve: verve,
  mobilefuse: mobilefuse,
  bidmachine: bidmachine,
};

const TOOLTIPS = {
  app_credentials:
    "Specific credentials provided by an ad network for your app. These credentials are used to activate the partner SDK whenever they win an auction for your app's impressions",
  chartboost_app_id:
    'Your Chartboost app id and app signature are automatically populated when you import an app from the main dashboard.',
  facebook_applicationid:
    'Copy and paste your Meta Audience Network App id from your Meta Audience Network account.',
  tapjoy_placement_name:
    'Copy and paste the Tapjoy placement name associated with this placement from your Tapjoy account',
  tapjoy_sdk_key: (
    <span>
      The Tapjoy SDK Key can be found by navigating to <b>Settings {'>'} App Settings</b> from your
      main Tapjoy Dashboard in your app. Keep in mind each app/platform combination has its own SDK
      Key.
    </span>
  ),
};

const NETWORK_TYPE_LABELS = {
  bidding: 'Bidding',
  interstitial: 'Interstitial',
  banner: 'Banner',
  rewarded_interstitial: 'Rewarded Interstitial',
  adaptive_banner: 'Adaptive Banner',
};

const BIDDING_DEMAND_SOURCES = [
  { name: 'adcolony', label: 'AdColony', logo: adcolony },
  { name: 'chartboost', label: 'Chartboost', logo: chartboost },
  { name: 'facebook', label: 'Meta Audience Network', logo: facebook },
  { name: 'tapjoy', label: 'Tapjoy', logo: tapjoy },
  { name: 'vungle', label: 'Liftoff Monetize', logo: vungle },
  { name: 'mintegral', label: 'Mintegral', logo: mintegral },
  { name: 'verve', label: 'Verve', logo: verve },
  { name: 'google_googlebidding', label: 'Google bidding', logo: google },
  { name: 'mobilefuse', label: 'MobileFuse', logo: mobilefuse },
  { name: 'bidmachine', label: 'BidMachine', logo: bidmachine },
];

const MEDIATION_DEMAND_SOURCES = [
  { name: 'adcolony', label: 'AdColony', logo: adcolony },
  { name: 'admob', label: 'AdMob', logo: admob },
  { name: 'applovin', label: 'AppLovin', logo: applovin },
  { name: 'chartboost', label: 'Chartboost', logo: chartboost },
  { name: 'facebook', label: 'Meta Audience Network', logo: facebook },
  { name: 'tapjoy', label: 'Tapjoy', logo: tapjoy },
  { name: 'vungle', label: 'Liftoff Monetize', logo: vungle },
  { name: 'ironsource', label: 'ironSource', logo: ironsource },
  { name: 'unity', label: 'Unity', logo: unity },
  { name: 'mintegral', label: 'Mintegral', logo: mintegral },
  { name: 'inmobi', label: 'InMobi', logo: inmobi },
  { name: 'fyber', label: 'Digital Turbine', logo: digital_turbine },
  { name: 'yahoo', label: 'Yahoo', logo: yahoo },
  { name: 'hyprmx', label: 'HyprMX', logo: hyprmx },
  { name: 'verve', label: 'Verve', logo: verve },
  { name: 'bidmachine', label: 'BidMachine', logo: bidmachine },
];

/*
   These networks used to be supported for (bidding/mediation) but are currently deprecated.
   For example Tapjoy has temporarily disabled bidding, Facebook no longer supports mediation.
   We keep track of them here so we can hide them from appearing as an option when creating
   new network credentials on placements or line-items until their status changes again.
*/

const DEPRECATED_BIDDING_NETWORKS = {
  tapjoy: true,
};

const DEPRECATED_MEDIATION_NETWORKS = {
  facebook: true,
};

const getDemandSources = (menuType) =>
  menuType === 'Bidding' ? BIDDING_DEMAND_SOURCES : MEDIATION_DEMAND_SOURCES;

const NON_SUPPORTED_SOURCES = ['pangle', 'amazon_aps', 'verve', 'bidmachine'];

const Constants = {
  BIDDING_DEMAND_SOURCES,
  DEPRECATED_BIDDING_NETWORKS,
  DEPRECATED_MEDIATION_NETWORKS,
  getDemandSources,
  MEDIATION_DEMAND_SOURCES,
  NETWORK_FIELDS,
  NETWORK_LABELS,
  NETWORK_LOGOS,
  NETWORK_TYPE_LABELS,
  NON_SUPPORTED_SOURCES,
  PARTNER_DETAIL_MAP,
  TOOLTIPS,
};

export default Constants;
