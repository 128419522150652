import React from 'react';

const ToolTipDate = ({ date }) => {
  // Add hour distinction so JS will not use UTC to convert to a possible previous day
  const dateWithHours = `${date}T00:00:00`;
  const formattedDate = new Date(dateWithHours).toLocaleDateString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const [day, month, year] = formattedDate.split(',');

  return (
    <div className="metrics-tooltip-date">
      <div>
        <h4>{`${day}`}</h4>
      </div>
      <div>
        <h4>{`${month}, ${year} `}</h4>
      </div>
    </div>
  );
};

export default ToolTipDate;
