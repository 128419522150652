import Actions from 'core/framework/actions';

import {
  getBlockedAdomains,
  removeDomainBlocking,
  updateDomainBlocking,
  getAdomainReports,
  postAdomainReport,
  deleteAdomainReport,
  getCompany,
  resetRewardedSecret,
  getReservedKeywords,
  getCustomKeywords,
  postKeyword,
  delKeyword,
  putKeyword
} from './api';

export const FETCH_BLOCKED_ADOMAINS = 'BLOCKED.ADOMAINS.FETCH';
export const fetchBlockedDomains = Actions.create({
  type: FETCH_BLOCKED_ADOMAINS,
  args: [],
  awaits: getBlockedAdomains,
  message: ['fetch-failure', 'a list of blocked adomains']
});

export const SAVE_DOMAIN_BLOCKING = 'DOMAIN.BLOCKING.SAVE';
export const saveBlockedDomains = Actions.create({
  type: SAVE_DOMAIN_BLOCKING,
  args: ['appId', 'domains'],
  awaits: updateDomainBlocking,
  message: ['save', 'adomain blockings']
});

export const DELETE_DOMAIN_BLOCKING = 'DOMAIN.BLOCKING.DELETE';
export const deleteExistingBlocking = Actions.create({
  type: DELETE_DOMAIN_BLOCKING,
  args: ['appId'],
  awaits: removeDomainBlocking,
  message: ['delete', 'adomain blockings']
});

export const FETCH_ADOMAIN_REPORTS = 'ADOMAIN.REPORTS.FETCH';
export const fetchAdomainReports = Actions.create({
  type: FETCH_ADOMAIN_REPORTS,
  args: [],
  awaits: getAdomainReports,
  message: ['fetch', 'adomain reports']
});

export const SUBMIT_REPORT = 'ADOMAIN.REPORTS.SUBMIT';
export const submitReport = Actions.create({
  type: SUBMIT_REPORT,
  args: ['report'],
  awaits: report => postAdomainReport(report),
  message: ['fetch-failure', 'adomain report']
});

export const DELETE_REPORT = 'ADOMAIN.REPORTS.DELETE';
export const deleteReport = Actions.create({
  type: DELETE_REPORT,
  args: ['reportId'],
  awaits: reportId => deleteAdomainReport(reportId),
  message: ['delete-failure', 'adomain report']
});

export const RESET_REWARDED_SECRET = 'SETTINGS.RESET.REWARDED.SECRET';
export const resetRewardedSecretKey = async dispatch => {
  dispatch({ type: Actions.started(RESET_REWARDED_SECRET) });
  try {
    const response = await resetRewardedSecret();
    dispatch({ type: Actions.success(RESET_REWARDED_SECRET), payload: response });
  } catch (e) {
    dispatch({ type: Actions.failure(RESET_REWARDED_SECRET) });
  }
};

export const FETCH_COMPANY = 'SETTINGS.FETCH.COMPANY';
export const fetchCompany = async dispatch => {
  dispatch({ type: Actions.started(FETCH_COMPANY) });
  try {
    const response = await getCompany();
    dispatch({ type: Actions.success(FETCH_COMPANY), payload: response });
  } catch (e) {
    dispatch({ type: Actions.failure(FETCH_COMPANY) });
  }
};

export const FETCH_RESERVED_KEYWORDS = 'KEYWORDS.FETCH.RESERVED_KEYWORDS';
export const fetchReservedKeywords = () => async dispatch => {
  dispatch({ type: Actions.started(FETCH_RESERVED_KEYWORDS) });
  try {
    const { reserved_keywords } = await getReservedKeywords();
    dispatch({ type: Actions.success(FETCH_RESERVED_KEYWORDS), payload: reserved_keywords });
  } catch (e) {
    dispatch({ type: Actions.failure(FETCH_RESERVED_KEYWORDS) });
  }
};

export const FETCH_KEYWORDS = 'KEYWORDS.FETCH.KEYWORDS';
export const fetchKeywords = () => async dispatch => {
  dispatch({ type: Actions.started(FETCH_KEYWORDS) });
  try {
    const { custom_keywords } = await getCustomKeywords();
    dispatch({ type: Actions.success(FETCH_KEYWORDS), payload: custom_keywords });
  } catch (e) {
    dispatch({ type: Actions.failure(FETCH_KEYWORDS) });
  }
};

export const CREATE_KEYWORD = 'KEYWORDS.CREATE';
export const createKeyword = keyword => async dispatch => {
  dispatch({ type: Actions.started(CREATE_KEYWORD) });
  try {
    const payload = await postKeyword(keyword);
    dispatch({
      type: Actions.success(CREATE_KEYWORD),
      payload,
      message: ['save-success', `${keyword.key} (${keyword.keyword_type})`]
    });
  } catch (e) {
    const isDuplicated = e && e.message === 'Duplicate Entry';
    dispatch({
      type: Actions.failure(CREATE_KEYWORD),
      payload: {},
      error: true,
      meta: {
        messages: {
          failure: ['save-failure', `${isDuplicated ? `: ${keyword.key} already exists` : ''}`]
        }
      }
    });
  }
};

export const DELETE_KEYWORD = 'KEYWORDS.DELETE';
export const deleteKeyword = keywordId => async dispatch => {
  dispatch({ type: Actions.started(DELETE_KEYWORD) });
  try {
    const payload = await delKeyword(keywordId);
    dispatch({
      type: Actions.success(DELETE_KEYWORD),
      payload,
      message: ['delete-success', `${payload.key}`]
    });
  } catch (e) {
    dispatch({
      type: Actions.failure(DELETE_KEYWORD),
      payload: {},
      error: true,
      meta: {
        messages: {
          failure: 'delete-failure'
        }
      }
    });
  }
};

export const EDIT_KEYWORD = 'KEYWORDS.EDIT';
export const editKeyword = (keywordId, keyword) => async dispatch => {
  dispatch({ type: Actions.started(EDIT_KEYWORD) });
  try {
    const payload = await putKeyword(keywordId, keyword);
    dispatch({
      type: Actions.success(EDIT_KEYWORD),
      payload,
      message: ['save-success', `${payload.key}`]
    });
  } catch (e) {
    dispatch({
      type: Actions.failure(EDIT_KEYWORD),
      payload: {},
      error: true,
      meta: {
        messages: {
          failure: 'save-failure'
        }
      }
    });
  }
};
