const ERROR_LABELS = {
  invalid_request: 'Google SSO Invalid',
  invalid_grant: 'Google Grant Credentials Invalid',
  400: 'Error fetching/creating Ad Unit',
  401: 'Authentication Error',
};

const Constants = {
  ERROR_LABELS,
};

export default Constants;
