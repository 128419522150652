import Actions from 'core/framework/actions';
import { LOADED, LOADING, UNLOADED } from 'core/app/constants/readyStates';

import LineItemActions from './actions';
import AppHelpers from '../app/helpers';
import Helpers from './helpers';

const updateState = (state, app) => ({
  ...state,
  madeChanges: false,
  entities: AppHelpers.addOrReplaceApp(state.entities, app),
});

const setLineItems = (state, { appId, response }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  const line_items = response.lineitems;
  if (app) {
    app.line_items = line_items;
    state.lineItemsState = LOADED;
    return updateState(state, app);
  }
  return state;
};

const setCompanyLineItems = (state, { response }) => {
  const lineItems = Helpers.labelDeletedLineItems(response.lineitems);
  return {
    ...state,
    company: {
      ...state.company,
      lineItemsState: LOADED,
      lineItems,
    },
  };
};

const setCompanyLineItemNames = (state, { response }) => {
  const lineItemNames = response.line_item_names || [];
  return {
    ...state,
    company: {
      ...state.company,
      lineItemsState: LOADED,
      lineItemNames: lineItemNames.map((lineItemName) => ({
        name: lineItemName,
        value: lineItemName,
      })),
    },
  };
};

const create = (state) => ({
  ...state,
  newLineItem: {
    auto_cpm: false,
    first_look: false,
    network_id: '',
    name: '',
    partner_placement: '',
    partner_details: {},
    cpm: 0,
    type: '',
    enabled: true,
  },
});

const setField = (state, payload) => {
  const newItem = state.newLineItem;
  if (Helpers.isPartnerField(payload.name)) {
    newItem.partner_details[payload.name] = payload.value;
  } else {
    newItem[payload.name] = payload.value;
  }
  return { ...state, newLineItem: newItem };
};

const deleteLineItem = (state, { response }) => {
  return {
    ...state,
    lineItemsState: UNLOADED,
  };
};

const CREATE_LINE_ITEM_SUCCESS = (state, { item, response }) => ({
  ...state,
  lineItemsState: UNLOADED,
  selectedLineItem: item.checked ? response.id : false,
});

const apply = (state, type, payload) => {
  switch (type) {
    case Actions.started(LineItemActions.FETCH_LINE_ITEMS):
      return { ...state, lineItemsState: LOADING };

    case Actions.failure(LineItemActions.FETCH_LINE_ITEMS):
      return { ...state, lineItemsState: LOADED };

    case Actions.success(LineItemActions.FETCH_LINE_ITEMS):
      return setLineItems(state, payload);

    case Actions.started(LineItemActions.FETCH_COMPANY_LINE_ITEMS):
      return { ...state, placementsState: LOADING };

    case Actions.failure(LineItemActions.FETCH_COMPANY_LINE_ITEMS):
      return { ...state, placementsState: LOADED };

    case Actions.success(LineItemActions.FETCH_COMPANY_LINE_ITEMS):
      return setCompanyLineItems(state, payload);

    case Actions.started(LineItemActions.FETCH_COMPANY_LINE_ITEM_NAMES):
      return { ...state, placementsState: LOADING };

    case Actions.failure(LineItemActions.FETCH_COMPANY_LINE_ITEM_NAMES):
      return { ...state, placementsState: LOADED };

    case Actions.success(LineItemActions.FETCH_COMPANY_LINE_ITEM_NAMES):
      return setCompanyLineItemNames(state, payload);

    case Actions.started(LineItemActions.CREATE_LINE_ITEM):
      return { ...state, lineItemsState: LOADING };

    case Actions.failure(LineItemActions.CREATE_LINE_ITEM):
      return { ...state, lineItemsState: LOADED };

    case Actions.success(LineItemActions.CREATE_LINE_ITEM):
      return CREATE_LINE_ITEM_SUCCESS(state, payload);

    case Actions.started(LineItemActions.DELETE_LINE_ITEM):
      return { ...state, lineItemsState: LOADING };

    case Actions.failure(LineItemActions.DELETE_LINE_ITEM):
      return { ...state, lineItemsState: UNLOADED };

    case Actions.success(LineItemActions.DELETE_LINE_ITEM):
      return deleteLineItem(state, payload);

    case Actions.started(LineItemActions.EDIT_LINE_ITEM):
      return { ...state, lineItemsState: LOADING };

    case Actions.failure(LineItemActions.EDIT_LINE_ITEM):
      return { ...state, lineItemsState: LOADED };

    case Actions.success(LineItemActions.EDIT_LINE_ITEM):
      return { ...state, lineItemsState: UNLOADED };

    case LineItemActions.SET_LINE_ITEM_FIELD:
      return setField(state, payload);

    case LineItemActions.CREATE_LOCAL_LINE_ITEM:
      return create(state);

    case LineItemActions.CLEAR_SELECTED_LINE_ITEM:
      return { ...state, selectedLineItem: false };

    case Actions.started(LineItemActions.CREATE_RULE):
    case Actions.started(LineItemActions.DELETE_RULE):
    case Actions.started(LineItemActions.NEGATE_RULE):
    case Actions.started(LineItemActions.ADD_PREDICATE):
    case Actions.started(LineItemActions.DELETE_PREDICATE):
    case Actions.started(LineItemActions.EDIT_PREDICATE):
      return {
        ...state,
        lineItemsState: LOADING,
        placementLineItemState: LOADING,
        placementsReadyState: LOADED,
      };

    case Actions.failure(LineItemActions.CREATE_RULE):
    case Actions.failure(LineItemActions.DELETE_RULE):
    case Actions.failure(LineItemActions.NEGATE_RULE):
    case Actions.failure(LineItemActions.ADD_PREDICATE):
    case Actions.failure(LineItemActions.DELETE_PREDICATE):
    case Actions.failure(LineItemActions.EDIT_PREDICATE):
      return {
        ...state,
        lineItemsState: LOADED,
        placementLineItemState: LOADED,
        placementsReadyState: LOADED,
      };

    case Actions.success(LineItemActions.CREATE_RULE):
    case Actions.success(LineItemActions.DELETE_RULE):
    case Actions.success(LineItemActions.NEGATE_RULE):
    case Actions.success(LineItemActions.ADD_PREDICATE):
    case Actions.success(LineItemActions.DELETE_PREDICATE):
    case Actions.success(LineItemActions.EDIT_PREDICATE):
      return {
        ...state,
        lineItemsState: UNLOADED,
        placementLineItemState: UNLOADED,
        placementsReadyState: LOADED,
      };

    default:
      return state;
  }
};

const Reducer = {
  apply,
};

export default Reducer;
