import { datadogRum } from '@datadog/browser-rum';
import GlobalConstants from './constants';
import packageJson from '../../package.json';

interface bootDatadogProps {
  sampleRate: number;
}

interface setUserProps {
  id: string;
  company_id: string;
}

const isProd = process.env.REACT_APP_DOMAIN === '.chartboost.com';
const currentStack = process.env.REACT_APP_STACK;

const bootDatadog = ({ sampleRate }: bootDatadogProps) => {
  if (isProd || currentStack !== 'local') {
    datadogRum.init({
      applicationId: GlobalConstants.DATADOG_APP_ID,
      clientToken: GlobalConstants.DATADOG_CLIENT_ID,
      site: 'datadoghq.com',
      service: 'heliumdashboard',
      env: isProd ? 'production' : currentStack,
      version: packageJson?.version,
      sampleRate: sampleRate,
      sessionReplaySampleRate: sampleRate,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
};

const setUser = ({ id, company_id }: setUserProps) => {
  datadogRum.setUser({
    id,
    company_id,
  });
};

const startSessionReplayRecording = () => {
  datadogRum.startSessionReplayRecording();
};

const stopSessionReplayRecording = () => {
  datadogRum.stopSessionReplayRecording();
};

const DatadogHelpers = {
  boot: bootDatadog,
  setUser,
  recordSessionReplay: startSessionReplayRecording,
  stopSessionReplay: stopSessionReplayRecording,
};

export default DatadogHelpers;
