const INTERCOM_APP_ID = 'q90r1tnf';
const DATADOG_APP_ID = '003a8a92-f576-4502-b7c7-dd1a3a3a5ba7';
const DATADOG_CLIENT_ID = 'pub17ddaf600d3a7a39fadcc1b1f1595d4f';

const GlobalConstants = {
  INTERCOM_APP_ID,
  DATADOG_APP_ID,
  DATADOG_CLIENT_ID,
};

export default GlobalConstants;
