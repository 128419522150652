import React from 'react';
import { Button } from 'semantic-ui-react';

const MetricsAdvancedButton = ({ isAdvancedView, setState }) => {
  return (
    <div className="metrics-advanced-view-button">
      <Button
        size="tiny"
        icon={isAdvancedView ? 'search minus' : 'search plus'}
        circular
        onClick={() =>
          setState((prevState) => {
            return { ...prevState, isAdvancedView: !prevState.isAdvancedView };
          })
        }
      />
    </div>
  );
};

export default MetricsAdvancedButton;
