import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Popup, Table } from 'semantic-ui-react';

import { formatDollars, formatPercent, formatValue } from 'utils/helpers';
import Constants from '../constants';

import '../css/placements.css';

const ABLabel = (enabled) => (
  <span className="ab-label-container">
    <Label
      color="blue"
      content={'A/B Test'}
      className={enabled ? 'ab-label enabled' : 'ab-label disabled'}
    />
  </span>
);

const Status = ({ active }) => {
  const statusColor = Boolean(active) ? 'green' : 'grey';
  return (
    <Popup
      trigger={<Icon color={statusColor} name="dot circle" className="ab-status" />}
      content={active ? 'Active' : 'Inactive'}
      on="hover"
    />
  );
};

const Name = ({ url, name, type, ABEnabled }) => {
  const label = type ? ABLabel(ABEnabled) : null;
  return (
    <Link to={url} className={`test-${type}`}>
      {name} {label}
    </Link>
  );
};

const Placement = ({ ...props }) => {
  const { adPlacement, appId, disabled, override = false, removePlacement } = props;

  const {
    ab_placement_id,
    active,
    has_abtest,
    proportion,
    id,
    name,
    type,
    earnings,
    requests,
    fills,
    impressions,
    ecpm,
    fill_rate,
    show_rate,
    clicks,
    ctr,
  } = adPlacement;

  const placementType = Constants.AD_TYPE_LABELS[type];

  const placementURL =
    ab_placement_id != null
      ? `/apps/${appId}/placements-split/${id}/${ab_placement_id}`
      : `/apps/${appId}/placements/${id}`;

  const handleDelete = (appId, id) => {
    removePlacement(appId, id);
  };

  const ABEnabled = proportion === 0 || proportion === 1 ? false : true;

  const EditPlacementPopup = () => (
    <Popup
      on="click"
      pinned
      position="bottom center"
      trigger={<Button icon="ellipsis vertical" className="editplacementpopup" />}
      content={
        <div style={{ width: '130px' }}>
          <Button as={Link} to={placementURL} override={override} className="editPlacementButton">
            Edit Placement
          </Button>
          <Button
            onClick={() => handleDelete(appId, id)}
            disabled={disabled}
            className="removePlacementButton"
          >
            Delete Placement
          </Button>
        </div>
      }
    />
  );

  return (
    <Table.Row className="placementrow">
      <Table.Cell className="namecolumn">
        <Name
          name={name}
          override={override}
          type={has_abtest ? 'A' : false}
          url={placementURL}
          ABEnabled={ABEnabled}
        />
      </Table.Cell>
      <Table.Cell>
        <Status active={active} />
      </Table.Cell>
      <Table.Cell>{placementType}</Table.Cell>
      <Table.Cell>{earnings !== undefined ? formatDollars(earnings) : '-'}</Table.Cell>
      <Table.Cell>{requests !== undefined ? formatValue(requests) : '-'}</Table.Cell>
      <Table.Cell>{fills !== undefined ? formatValue(fills) : '-'}</Table.Cell>
      <Table.Cell>{impressions !== undefined ? formatValue(impressions) : '-'}</Table.Cell>
      <Table.Cell>{ecpm !== undefined ? formatDollars(ecpm) : '-'}</Table.Cell>
      <Table.Cell>{fill_rate !== undefined ? formatPercent(fill_rate) : '-'}</Table.Cell>
      <Table.Cell>{show_rate !== undefined ? formatPercent(show_rate) : '-'}</Table.Cell>
      <Table.Cell>{clicks !== undefined ? formatValue(clicks) : '-'}</Table.Cell>
      <Table.Cell>{ctr !== undefined ? formatPercent(ctr) : '-'}</Table.Cell>
      <Table.Cell>
        <EditPlacementPopup />
      </Table.Cell>
    </Table.Row>
  );
};

export default Placement;
