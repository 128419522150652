import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { Redirect, Route } from 'react-router-dom';

import User from 'core/user';

export const ProtectedRouteContainer = ({ bundles, name, ...rest }) => {
  if (!bundles.length) {
    return <Loader active />;
  }

  return bundles.map((bundle) => bundle.name).includes(name) ? (
    <Route {...rest} />
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = (state) => ({
  bundles: User.Store.getBundles(state),
  readyState: User.Store.getReadyState(state),
});

export default connect(mapStateToProps)(ProtectedRouteContainer);
