import React from 'react';
import { Form } from 'semantic-ui-react';

const LineItemPartnerForm = (props) => {
  const { createLineitemState, setter } = props;
  const extraFields = [];

  if (createLineitemState.network_id === 'mintegral') {
    let value = '';
    if (typeof createLineitemState.partner_details !== 'undefined') {
      value = createLineitemState.partner_details.mintegral_unit_id;
    }
    const unitIDField = (
      <Form.Input
        required={true}
        autoComplete="off"
        label="Unit ID"
        name="mintegral_unit_id"
        onChange={setter}
        placeholder="Unit ID"
        value={value}
        width={6}
      />
    );
    extraFields.push(unitIDField);
  }

  return (
    <Form className="lineItem create placement">
      <Form.Group>
        <Form.Input
          required={true}
          autoComplete="off"
          label="Partner Placement"
          name="partner_placement"
          onChange={setter}
          placeholder="Partner Placement"
          value={createLineitemState.partner_placement}
          width={8}
        />
        {extraFields}
      </Form.Group>
    </Form>
  );
};

export default LineItemPartnerForm;
