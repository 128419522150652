/**
 * @namespace App/Helpers
 */

import _ from 'lodash';
import Utils from 'core/framework/utils';
import { LOADED, UNLOADED } from 'core/app/constants/readyStates';
import NetworkHelpers from 'apps/network/helpers';
import Constants from './constants';
import { AmazonLogo, AppleLogo, AndroidLogo } from 'apps/app/assets';

export const PLATFORM_LOGO_MAP = {
  1: AppleLogo,
  2: AndroidLogo,
  3: AmazonLogo,
};

/**
 * App object with extra fields allowing it
 * to be displayed in the UI it contains all the
 * fields as {@link APP} but with some extra fields
 * as well as some augmented values as described below:
 * @typedef {Object} UI_APP
 * @property {string} name - name will now contain the value of nickname if it's empty ""
 * @property {string} key - react key prop will have the same value as app id
 * @property {string} keywords - convenient string to help with searching for an app
 * @property {number} page - app gets assigned a page it belongs to for pagination
 * @property {PLACEMENT[]} placements - placement ids will be replaced by actual placements whenever fetches
 * @property {ADGROUP[]} adgroups - adgroup ids will be replaced by actual adgroups whenever api fetches
 */

/**
 * @function
 * @name toUIApp
 * @description adds fields to app object to help in UI display
 * @param {APP} app - app object from API
 * @param {number} index - unique number to use as react key prop
 * @returns {UI_APP} - app object containing extra fields for UI display
 * @memberof App/Helpers
 */

const toUIApp = (app, index) => {
  const appId = app?.id !== undefined ? app.id : '';
  const appName = getAppName(app) || '';
  return {
    ...app,
    name: getAppName(app),
    key: appId,
    keywords: `${appName.toLowerCase()}${String(appId).toLowerCase()}`,
    page: assignPageNumber(index, 9),
    credentials: app?.credentials || { creds: {}, network_states: [] },
  };
};

/**
 * @function
 * @name toUIApps
 * @description maps all app objects from API to UI_APP objects
 * @param {APP[]} apps - list of app objects from API
 * @returns {UI_APP[]} - list of UI_APP objects
 * @memberof App/Helpers
 */

const toUIApps = (apps) =>
  Utils.isNonZeroArray(apps) ? _.sortBy(apps, [getAppName]).map(toUIApp) : [];

/**
 * App level credentials contains network names as keys and credentials as values
 * @typedef {Object} APP_CREDENTIALS
 * @example
 * {
 *   chartboost: {
 *     app_id: "5c37c51476f3910c3cf92534",
 *     app_signature: "e29012de354dca5b190faac05f01820586e40814"
 *   },
 *   ...
 * }
 */

/**
 * @function
 * @name getAppCredentials
 * @description returns the credential values for each network for an app
 * @param {UI_APP} app - app object from UI (react state)
 * @param {string} [networkType=null] - optional filter for mediation / bidding
 * @returns {APP_CREDENTIALS} - app credentials object
 * @memberof App/Helpers
 */

const getAppCredentials = (app, networkType) => {
  let filtered = {};
  let state = false;
  let type = networkType === 'bidding' ? 0 : 1;
  if (!app.credentials || !app.credentials.creds) {
    return {};
  }
  if (!networkType) {
    return app.credentials.creds;
  }
  _.forIn(app.credentials.creds, (value, network) => {
    state = getNetworkState(app, network, type);
    if (state) {
      filtered[network] = {
        ...value,
        active: state.active,
      };
    }
  });
  return filtered;
};

/**
 * @function
 * @name getNetworkState
 * @description returns the state of a network in the app
 * @param {UI_APP} app - app object
 * @param {string} networkType - bidding or mediation
 * @returns {NETWORK_STATES} - object of network fields ( name , active .. etc )
 * @memberof App/Helpers
 */

const getNetworkState = (app, networkName, networkType) => {
  let type = networkType;
  if (isNaN(networkType)) {
    type = networkType === 'bidding' ? 0 : 1;
  }
  if (!app.credentials || !app.credentials.network_states) {
    return false;
  } else {
    return app.credentials.network_states.find((n) => n.name === networkName && n.type === type);
  }
};

/**
 * @function
 * @name getNetworkStates
 * @description returns the state of all networks for an app
 * @param {UI_APP} app - app object from UI (react state)
 * @param {string} [networkType=null] - optional filter for bidding / mediation
 * @returns {NETWORK_STATES} - array of network settings
 * @memberof App/Helpers
 */

const getNetworkStates = (app, networkType) => {
  let type = networkType === 'bidding' ? 0 : 1;
  let states = [];
  let deprecated = NetworkHelpers.getDeprecatedNetworks(networkType);

  if (!app.credentials || !app.credentials.network_states) {
    return states;
  }
  if (!networkType) {
    return app.credentials.network_states;
  } else {
    return app.credentials.network_states.filter((n) => n.type === type && !deprecated[n.name]);
  }
};

/**
 * @function
 * @name getActiveNetworks
 * @description returns list of active networks
 * @param {UI_APP} app - app object from UI (react state)
 * @param {string} [networkType=null] - optional filter for bidding / mediation
 * @returns {NETWORK_STATES} - array of network settings
 * @memberof App/Helpers
 */

const getActiveNetworks = (app, networkType) => {
  let nets = getNetworkStates(app, networkType);
  return nets.filter((n) => n.active === true);
};

const getApp = (apps, appId) => apps.filter((a) => a.id === appId)[0];

const getAppName = (app) => (app.name ? app.name : app.nickname);

const getPlatform = (apps, appId) => {
  const app = getApp(apps, appId);
  return app ? app.platform : null;
};

const getAppIds = (apps) =>
  Utils.isNonZeroArray(apps)
    ? apps.reduce((reduced, app) => ({ ...reduced, [app.id]: true }), {})
    : {};

/* returns new list of apps containing newly updated app */

const addOrReplaceApp = (apps, app) => {
  let found = false;
  let newApps = [];
  if (app !== undefined) {
    for (let i = 0; i < apps.length; i++) {
      if (apps[i].id === app?.id) {
        newApps.push({ ...apps[i], ...Utils.clone(app) });
        found = true;
      } else {
        newApps.push(apps[i]);
      }
    }
    if (!found) {
      newApps.push(toUIApp(app));
    }
  }
  return newApps;
};

/* returns new list of apps containing app with updated fields */

const findAndUpdateFields = (apps, appId, changes) =>
  apps.map((app) => (app.id === appId ? { ...app, ...changes } : app));

/* App Pagination Helpers */

const assignPageNumber = (index, itemsPerPage = 8) => parseInt(index / itemsPerPage, 10) + 1;

const getPagination = (apps) =>
  Utils.isNonZeroArray(apps)
    ? { active: 1, total: apps[apps.length - 1].page || 1 }
    : { active: 1, total: 1 };

/* UI Helpers */

const addImages = (options) =>
  options.map((app) => {
    const platformId = Constants.APP_PLATFORM_MAP[app.description];
    const placeholderImage = PLATFORM_LOGO_MAP[platformId];

    const onImageError = (e) => {
      e.target.src = placeholderImage;
    };

    return {
      ...app,
      image: {
        avatar: true,
        src: app.icon || placeholderImage,
        onError: onImageError,
      },
    };
  });

const getPlacementNames = (apps) => {
  return [];
};

const getChildReadyState = (app, child, readyState) => {
  if (Utils.isNonZeroArray(app[child])) {
    if (!isNaN(app[child][0])) {
      return UNLOADED;
    }
  } else {
    return LOADED;
  }
  return readyState;
};

const toApiCredentials = (credentials) => {
  const networks = Object.keys(credentials.creds);
  networks.forEach((network) => {
    let networkValues = Object.keys(credentials.creds[network]);
    networkValues.forEach((networkValue) => {
      // trims string values for each network
      credentials.creds[network][networkValue] =
        typeof credentials.creds[network][networkValue] === 'string'
          ? credentials.creds[network][networkValue].trim()
          : credentials.creds[network][networkValue];
    });
  });
  return credentials;
};

const Helpers = {
  getChildReadyState,
  assignPageNumber,
  getAppCredentials,
  getAppName,
  getNetworkStates,
  getNetworkState,
  getActiveNetworks,
  getPlacementNames,
  getPlatform,
  getApp,
  getAppIds,
  toApiCredentials,
  toUIApp,
  toUIApps,
  addOrReplaceApp,
  findAndUpdateFields,
  getPagination,
  addImages,
};

export default Helpers;
