import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader, Breadcrumbs } from './';

import App from 'apps/app';
import User from 'core/user';
import Placement from 'apps/placement';
import Network from 'apps/network';
import LineItem from 'apps/lineitem';

class AppLayout extends Component {
  componentDidMount() {
    if (this.props.isBetaTestAccount === null) {
      this.props.fetchIsBetaTestAccount();
      // Allows for fetching of user id and company id as well as setting datadog details
      this.props.fetchTerms();
    }

    if (this.props.isTestAccount === null) {
      this.props.fetchIsTestAccount();
    }

    if (this.props.shouldFetchApps) {
      this.props.fetchApps();
    }

    if (this.props.shouldFetchNetworkSchema) {
      this.props.fetchNetworkSchema();
    }
  }

  render() {
    let { loading, bundle, context, override, isBetaTestAccount } = this.props;

    bundle = bundle || 'apps';
    context = context || {};
    override = override || false;

    return (
      <>
        <Breadcrumbs
          bundle={bundle}
          context={context}
          override={override}
          isBetaTestAccount={isBetaTestAccount}
        />
        <div className="AppLayout">
          <Loader isLoading={loading} />
          {this.props.children}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shouldFetchApps: App.Store.getShouldFetchApps(state),
  shouldFetchNetworkSchema: Network.Store.getShouldFetchSchema(state),
  shouldFetchCompanyPlacements: Placement.Store.getShouldFetchCompanyPlacements(state),
  shouldFetchCompanyLineItems: LineItem.Store.shouldFetchCompanyLineItems(state),
  st: User.Store.isV2(state),
  isBetaTestAccount: User.Store.isBetaTestAccount(state),
  isTestAccount: User.Store.isTestAccount(state),
});

const mapDispatchToProps = {
  fetchApps: App.Actions.fetchApps,
  fetchNetworkSchema: Network.Actions.fetchNetworkSchema,
  fetchCompanyPlacements: Placement.Actions.fetchCompanyPlacements,
  fetchCompanyLineItems: LineItem.Actions.fetchCompanyLineItems,
  fetchIsBetaTestAccount: User.Actions.fetchIsBetaTestAccount,
  fetchIsTestAccount: User.Actions.fetchIsTestAccount,
  fetchTerms: User.Actions.fetchTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
