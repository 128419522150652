import Utils from 'core/framework/utils';
import _ from 'lodash';

const NewAdGroup = () => ({
  name: '',
  type: 'interstitial',
  inclusion_type: 'ALL',
  countries: [],
  placements: [],
  line_items: [],
});

const find = (adgroups, id) => adgroups.find((a) => a.id === id);

const toApiAdGroup = (adgroup) => {
  if (adgroup.id === '__new__') {
    delete adgroup.id;
  }
  return adgroup;
};

const toUIAdGroup = (adgroups) => {
  if (Utils.isNonZeroArray(adgroups)) {
    const sanitized = adgroups.map((adgroup) => {
      if (!adgroup.placements) adgroup.placements = [];
      if (!adgroup.countries) adgroup.countries = [];
      if (!adgroup.line_items) adgroup.line_items = [];
      return adgroup;
    });
    return sanitized;
  }
  return adgroups;
};

const isValid = (adgroup) => {
  const warnings = [];
  if (adgroup && typeof adgroup.name === 'string' && adgroup.name.length === 0) {
    warnings.push('Ad Group must have a name');
  }
  if (
    adgroup &&
    adgroup.countries &&
    adgroup.countries.length === 0 &&
    adgroup.inclusion_type !== 'ALL'
  ) {
    warnings.push('Ad Group must have at least 1 country selected');
  }
  if (adgroup && adgroup.line_items && adgroup.line_items.length === 0) {
    warnings.push('Ad Group must have at least 1 line item');
  }
  return warnings;
};

const removeAdgroupPlacementConnection = (state, appId, placementId, adgroupId, newPlacement) => {
  if (newPlacement) {
    const updatedAdgroups = state.newPlacement.adgroups.filter((adgroup) => adgroup !== adgroupId);
    return {
      ...state,
      newPlacement: {
        ...state.newPlacement,
        adgroups: updatedAdgroups,
      },
    };
  }
  const app = state.entities.find((entity) => entity.id === appId);
  const placement = app.placements.find((placement) => placement.id === placementId);
  const filteredAdgroup = placement.adgroups.filter((adgroup) => adgroup !== adgroupId);
  placement.adgroups = filteredAdgroup;
  return state;
};

const addMediationMetricsLineItem = (metrics, lineitems) => {
  return lineitems.map((lineitem) => {
    const { name } = lineitem;
    if (metrics && metrics[name]) {
      const {
        attempts: attempts,
        earnings: earnings,
        impressions: impressions,
        wins: wins,
        ecpm: ecpm,
        win_rate: winRate,
        show_rate: showRate,
        clicks: clicks,
        ctr: ctr,
      } = metrics[name];

      return {
        ...lineitem,
        attempts,
        earnings,
        impressions,
        wins,
        ecpm,
        winRate,
        showRate,
        clicks,
        ctr,
      };
    }
    return lineitem;
  });
};

const addZeroes = (num) => {
  const dec = String(num).split('.')[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
};

const isArrayEqual = function (x, y) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};

const isLineItemsChanged = (before, after) => {
  if (before.length !== after.length) {
    return true;
  }
  for (let i = 0; i < before.length; i++) {
    if (before[i]?.name !== after[i]?.name) return true;
    if (before[i]?.cpm !== after[i]?.cpm) return true;
    if (before[i]?.partner_placement !== after[i]?.partner_placement) return true;
    if (before[i]?.enabled !== after[i]?.enabled) return true;
    if (before[i]?.first_look !== after[i]?.first_look) return true;

    const isPartnerDetailsChanged = !_.isEqual(
      before[i]?.partner_details,
      after[i]?.partner_details
    );
    const isTargetingRulesChanged = !isArrayEqual(
      before[i]?.targeting_rules,
      after[i]?.targeting_rules
    );

    if (isTargetingRulesChanged || isPartnerDetailsChanged) {
      return true;
    }
  }
  return false;
};

const makeCpmMap = (lineItems) => {
  return lineItems.reduce((cpmMap, lineitem) => {
    if (cpmMap[lineitem.id] === undefined) {
      const formatedRate = addZeroes(lineitem.cpm);
      cpmMap[lineitem.id] = formatedRate;
    }
    return cpmMap;
  }, {});
};

const validateNumber = (value) => {
  const numberRegex = /^[\d]*\.?[\d]*$/;
  return value?.search(numberRegex) === 0;
};

const AdGroupHelpers = {
  NewAdGroup,
  find,
  isValid,
  removeAdgroupPlacementConnection,
  toApiAdGroup,
  toUIAdGroup,
  addMediationMetricsLineItem,
  isLineItemsChanged,
  makeCpmMap,
  validateNumber,
};

export default AdGroupHelpers;
