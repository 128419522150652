import React from 'react';


const CredentialField = ({name, value, label, hidden}) => {
    if (hidden || (label == "undefined")) {
        return null;
    }
    return (
        <div className="credential-container" key={name}>
            <p className="credential-paragraph">
                
                <span className="credential-tag">{label}</span>
                {value}
            </p>
        </div>
    )
}

export default CredentialField;