import { getAuthToken } from 'core/framework/localStorage';
import Helpers from './helpers';
import Constants from './constants';

const setRequestOptions = (body) => {
  const requestTemplate = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
      Accept: 'application/json',
      'Accept-Encoding': 'gzip',
    },
  };
  if (body !== undefined) {
    requestTemplate.method = 'POST';
    requestTemplate.body = JSON.stringify(body);
  }
  return requestTemplate;
};

const onSuccessFetch = async (response, setAdUnitOptions) => {
  const data = await response.json();
  if (!response.ok) {
    const error = (data && data.message) || (data && data.error) || response.status;
    return Promise.reject(error);
  }
  const formatedAdUnits = Helpers.adUnitToOptions(data?.data);
  setAdUnitOptions(formatedAdUnits);
};

const onSuccessPost = async (response, adUnitOptions, setAdUnitOptions, setApiMessage) => {
  const data = await response.json();
  if (!response.ok) {
    const error = (data && data.message) || (data && data.error) || response.status;
    return Promise.reject(error);
  }

  const { message, adUnits } = Helpers.combineAdUnits(data, adUnitOptions);
  const formatedAdUnits = Helpers.adUnitToOptions(adUnits);
  setAdUnitOptions(formatedAdUnits);
  setApiMessage(message);
};

const onFailure = (error, setApiMessage, action) => {
  const errorMessage = error?.code || error.toString();
  setApiMessage(Constants.ERROR_LABELS[errorMessage]);
  console.error(`Error ${action} Ad Units: `, error);
};

const handlePost = (placement, adUnitOptions, setAdUnitOptions, setApiMessage) => {
  const POST_ROUTE = `${process.env.REACT_APP_HELIUM_API}/helium/generate-gbid-ad-unit`;
  const BODY = {
    app_id: placement?.app_id,
    placement_id: placement?.id,
  };
  const requestOptions = setRequestOptions(BODY);

  fetch(POST_ROUTE, requestOptions)
    .then((response) => onSuccessPost(response, adUnitOptions, setAdUnitOptions, setApiMessage))
    .catch((error) => onFailure(error, setApiMessage, 'creating'));
};

const handleGet = (placement, setAdUnitOptions, setApiMessage) => {
  const GET_ROUTE = `${process.env.REACT_APP_HELIUM_API}/helium/gbid-ad-units?app_id=${placement.app_id}&placement_id=${placement.id}`;
  const requestOptions = setRequestOptions();

  fetch(GET_ROUTE, requestOptions)
    .then((response) => onSuccessFetch(response, setAdUnitOptions))
    .catch((error) => onFailure(error, setApiMessage, 'fetching'));
};

const Api = {
  handlePost,
  handleGet,
};

export default Api;
