import countries from 'core/app/constants/country_codes';
import constants from './constants';

/* Constants */

const FLAG_CODES = countries.reduce(
  (acc, { text, value, flag }) => ({ ...acc, [value]: { text: text, flag: flag } }),
  {}
);

const DM_CODES = {
  Facebook: 'Meta Audience Network',
  Vungle: 'Liftoff Monetize',
  Google_googlebidding: 'Google Bidding',
  Amazon_aps: 'Amazon APS',
  Fyber: 'Digital Turbine',
};

/* formats any date to yyyy-mm-dd */

const date = (date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return year + '-' + month + '-' + day;
};

/* formats metric names */

const metric = ({ name }) => {
  if (constants.ANALYTICS_LABEL_MAP[name] !== undefined) {
    return constants.ANALYTICS_LABEL_MAP[name];
  }
  let f = name.split('_');
  f = f.map((n) => n[0].toUpperCase() + n.slice(1));
  return f.join(' ');
};

/* checks if number contains decimals that are not 0 */

const hasDecimal = (num) => Number(num) % 1 !== 0;

/* checks if string is float removes decimal point if remainder is 0 */

const formatFloat = (num) => (hasDecimal(num) ? num : Math.floor(num));

/* converts number to float with precision point */

const toFloat = (x) => {
  if (isNaN(x)) {
    return x;
  }
  return formatFloat(x.toFixed(2));
};

/* Adds commas for numbers */

export const formatNumber = (x) => {
  if (typeof x === 'undefined' || x === null) return 0;
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2);
  }
  return parts.join('.');
};

/* formats numbers in K(thousands) M(millions) B(billions)...etc */

const numberRange = (num) => {
  if (!num) return '';

  num = formatFloat(num.toFixed(2));

  if (num < 1000) {
    return `${num}`;
  }

  if (num < 1000000) {
    num = formatFloat((num / 1000).toFixed(2));
    return `${num}K`;
  }

  if (num < 1000000000) {
    num = formatFloat((num / 1000000).toFixed(2));
    return `${num}M`;
  }

  if (num < 1000000000000) {
    num = formatFloat((num / 1000000000).toFixed(2));
    return `${num}B`;
  }

  num = formatFloat((num / 1000000000000).toFixed(2)); // keep hope alive
  return `${num}T`;
};

const num_metrics = ['impressions', 'ad_requests', 'valid_bids', 'winning_bids', 'ecpm'];

const rate_metrics = ['fill_rate', 'bid_rate', 'win_rate', 'impression_show_rate'];

/* format currency values */
const formatCurrency = (amount) => {
  let currencyValue =
    value != null
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount)
      : 'N/A';

  // Removes decimals if there is no change amount
  if (currencyValue.slice(-2) === '00') {
    currencyValue = currencyValue.slice(0, -3);
  }
  return currencyValue;
};

/* format metric values */

const value = (name, value, length) => {
  let v;
  const floatedValue = value != null ? formatFloat(value.toFixed(2)) : 'N/A';
  const currencyValue = formatCurrency(value);
  if (name === 'earnings' || name === 'ecpm') {
    if (length === 'long') {
      return currencyValue;
    }
    return '$' + numberRange(value);
  }

  if (name === 'bid_earnings') {
    return value.toFixed(2);
  }

  if (num_metrics.indexOf(name) > -1) {
    if (length === 'long') {
      return floatedValue.toLocaleString();
    }
    return numberRange(value);
  }

  if (rate_metrics.indexOf(name) > -1) {
    v = value.toFixed(1);
    return `${v}%`;
  }
  return value ? value.toLocaleString() : 'N/A';
};

/* gets 2 letter country code from 3 letter */

const countryCode3to2 = (c3) => {
  let ret = {
    text: '',
    flag: '',
  };
  if (c3) {
    c3 = c3.toUpperCase();
    const c2 = FLAG_CODES[c3];
    if (c2) {
      ret = c2;
    }
  }
  return ret;
};

/* Formats values for analytics tables */

export const tableValue = (key, label) => {
  if (key === 'earnings' || key === 'ecpm' || key === 'arpdau') {
    return formatCurrency(label);
  }
  if (
    key === 'ad_requests' ||
    key === 'valid_bids' ||
    key === 'winning_bids' ||
    key === 'impressions' ||
    key === 'value' ||
    key === 'responses' ||
    key === 'requests'
  ) {
    return formatNumber(label);
  }
  if (
    key === 'bid_rate' ||
    key === 'win_rate' ||
    key === 'fill_rate' ||
    key === 'rate' ||
    key === 'impression_show_rate'
  ) {
    return `${label && label.toFixed ? label.toFixed(2) : label} %`;
  }
  if (key === 'demand_partner') {
    return DM_CODES[label] || label;
  }
  return label;
};

const Format = {
  countryCode3to2,
  date,
  formatCurrency,
  formatNumber,
  metric,
  tableValue,
  toFloat,
  value,
};

export default Format;
