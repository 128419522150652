import { createElement, Component } from 'react';
import { Loader } from 'semantic-ui-react';

import AppActions from 'core/app/actions';

const loadBundle = (loader, store) => {
  class Bundle extends Component {
    static connect(store) {
      this.store = store;
    }

    static register(bundle) {
      const { name, reducer, router } = bundle;
      this.Router = router;
      this.store.injectReducer(name, reducer);
      this.store.dispatch(AppActions.registerBundle(name));
    }

    componentWillMount() {
      if (Bundle.store && !Bundle.Router) {
        if (store) {
          store.dispatch(AppActions.setBundleLoading(true));
        }
        loader()
          .then(bundle => Bundle.register(bundle))
          .then(() => this.forceUpdate());
      }
    }

    render() {
      return createElement(Bundle.Router || Loader);
    }
  }

  Bundle.Router = null;
  return store => {
    Bundle.connect(store);
    return Bundle;
  };
};

export default loadBundle;
