import { LOADING, UNLOADED } from 'core/app/constants/readyStates';
import { NEW_ENTITY_ID } from 'core/app/constants/helium';

/**
 *  Network states for an app is an array that indicates wethere a
 *  network is active or not, and which type of network is active
 *  where type 0 indicates bidding and type 1 indicates mediation.
 *
 *  So the same network can have two entries one for bidding and one
 *  for mediation if both settings are set.
 *
 *  @typedef {Object} NETWORK_STATES
 *  @example
 *
 *   states: [
 *     {
 *       type: 0,
 *       active: true,
 *       name: "facebook"
 *     },
 *     {
 *       type: 1,
 *       active: true,
 *       name: "facebook"
 *     }
 *   ]
 */

const getState = (state) => state.apps;
const getApps = (state) => getState(state).entities;
const getApp = (state, id) => getState(state).entities.find((app) => String(app.id) === id);
const getAppPlatform = (state, id) => getApp(state, id)?.platform;
const getSelected = (state) => getState(state).selected;
const getShouldFetchApps = (state) => getState(state).readyState === UNLOADED;
const getShouldFetchApp = (state, id) => !getLoading(state) && !getApp(state, id);
const getPagination = (state) => getState(state).pagination;
const getSearchTerm = (state) => getState(state).searchTerm;
const getFilteredApps = (state) => getApps(state).filter(({ id }) => id !== NEW_ENTITY_ID);
const getLoading = (state, type = 'readyState') => getState(state)[type] === LOADING;
const getImportedApps = (state) => getState(state).ids;
const hasMadeChanges = (state) => getState(state).madeChanges;
const getCallbackErrors = (state) => getState(state).callbackErrors;
const getPlacementErrors = (state) => getState(state).placementErrors;
const getDateFilter = (state) => getState(state).dateFilter;
const getTimelineMetrics = (state) => getState(state).timelineMetrics;

const getABTestedApps = (state) => {
  // const abTested = [];
  const apps = getApps(state) || [];
  // Takes too long to fetch all company placements, and then find which apps has ab-tests from placements
  // Refactor to display only apps with ab tests, show all apps for now

  // const allPlacements = state.apps.company.placements;
  // for (let app of apps) {
  //   if (app.placements) {
  //     const placements = app.placements.map((p) =>
  //       isNaN(p) ? p : allPlacements.find((P) => P.id === p)
  //     );
  //     let found = placements.find((p) => p && p.has_abtest === true);
  //     if (found) {
  //       abTested.push(app);
  //     }
  //   }
  // }
  // return abTested;
  return apps;
};

const getAppsMap = (state) => {
  const apps = getApps(state) || [];
  return apps.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: { ...cur },
    }),
    {}
  );
};

const AppStore = {
  getState,
  getApps,
  getSelected,
  getPagination,
  getSearchTerm,
  getApp,
  getAppPlatform,
  getFilteredApps,
  getLoading,
  getABTestedApps,
  getShouldFetchApps,
  getShouldFetchApp,
  getImportedApps,
  hasMadeChanges,
  getAppsMap,
  getCallbackErrors,
  getPlacementErrors,
  getDateFilter,
  getTimelineMetrics,
};

export default AppStore;
