const getState = state => state.app;
const getOnline = state => getState(state).online;
const getMessages = state => getState(state).messages;
const getNavbarVisible = state => getState(state).navbarVisible;
const getBundleLoading = state => getState(state).bundleLoading;

const AppStore = {
  getOnline,
  getMessages,
  getNavbarVisible,
  getBundleLoading
};

export default AppStore;
