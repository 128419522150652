import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Icon } from 'semantic-ui-react';

import Constants from '../constants';

const AppDetail = (props) => {
  const { disabled, icon, items, name, url } = props;

  if (disabled) {
    return null;
  }

  const getName = (quantity, name) => {
    return quantity === 1 ? name.slice(0, name.length - 1) : name;
  };

  return (
    <Card>
      <Card.Content className="header-blue">
        <Card.Header as={Link} to={url}>
          {' '}
          <Icon name={icon} /> {name}{' '}
        </Card.Header>
      </Card.Content>
      <Card.Content className="appDetail container">
        <Card.Description className="appDetail description">
          You have
          <ul>
            {items.map(([quantity, type]) => (
              <li>
                <span>
                  {quantity} {Constants.APP_DETAIL_LABELS[type]}
                </span>{' '}
                {getName(quantity, name)}
              </li>
            ))}
          </ul>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          as={Link}
          className="positive"
          content={`Manage ${name}`}
          id={`manage ${name} button`}
          to={url}
        />
      </Card.Content>
    </Card>
  );
};

export default AppDetail;
