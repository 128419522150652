import React, { Component } from 'react';
import Utils from 'core/framework/utils';
import helium from 'core/app/images/icon-green.png';
import { Grid, Image, Header, Popup, Icon } from 'semantic-ui-react';

/******************
 *   DualHeader
 ******************/

export class DualHeader extends Component {
  render() {
    let left,
      right = null;
    let children = this.props.children;

    if (Utils.isNonZeroArray(children)) {
      left = children[0];
      if (children.length > 1) {
        right = children[1];
      }
    }

    return (
      <Grid className={this.props.className}>
        <Grid.Column width={5}>{left}</Grid.Column>
        <Grid.Column width={6} />
        <Grid.Column width={5} className="right-align">
          {right}
        </Grid.Column>
      </Grid>
    );
  }
}

/******************
 *   MultiHeader
 ******************/

export const MultiHeader = ({ left, right1, right2 }) => (
  <Grid>
    <Grid.Column width={5}>{left}</Grid.Column>
    <Grid.Column width={2} />
    <Grid.Column width={4} className="right-align">
      {right1}
    </Grid.Column>
    <Grid.Column width={5} className="right-align">
      {right2}
    </Grid.Column>
  </Grid>
);

/******************
 *   PageHeader
 ******************/

const Iconed = props => (
  <Header className={`PageHeader logo-header ${props.className}`} as="h2" id="app header">
    <Image src={helium} /> {props.content}
  </Header>
);

const Standard = props => (
  <Header className={`PageHeader ${props.className}`} as="h2" id="app header">
    {props.icon ? <Icon name={props.icon} /> : null}
    {props.content}
  </Header>
);

export const PageHeader = props => (props.img ? <Iconed {...props} /> : <Standard {...props} />);

/********************
 *   SectionHeader
 ********************/

const displayToolTip = text => {
  if (text) {
    return (
      <Popup
        trigger={<Icon name="question circle" className="tooltip" />}
        content={text}
        position="top center"
        wide
      />
    );
  }
  return null;
};

export const SectionHeader = props => (
  <Header className={`SectionHeader ${props.className}`} as={props.as || 'h3'}>
    <Header.Content>{props.content}</Header.Content>
    {displayToolTip(props.tooltip)}
  </Header>
);
