import mopub from '../apps/network/images/mopub.png';
import Placement from '../apps/placement';


export const parseBlocking = entry =>
  Object.keys(entry).reduce(
    ({ name, nickname, id, blocked_adomains }, key) => ({
      name,
      nickname,
      id,
      value: id,
      text: name || nickname,
      blocked_adomains: blocked_adomains || [],
      [key]: entry[key]
    }),
    {}
  );

export const formatNetwork = networks => {
  // TODO: allows for the rest of the networks to display
  // return networks.map(network => {
  //   const copy = { ...network };
  //   copy.image = network.logo;
  //   copy.value = network.name;
  //   copy.text = network.label;
  //   delete copy.label;
  //   return copy;
  // });
  const MOPUB = {
    image: mopub,
    name: 'mopub',
    text: 'MoPub',
    value: 'mopub'
  };
  return [MOPUB];
};

export const apiPreprocessing = ({ selectedAdUnit, lineItems }) => {
  let processedResult = {
    [selectedAdUnit.name]: {
      name: selectedAdUnit.name,
      ad_type: selectedAdUnit.type,
      line_items: []
    }
  };
  for (const lineItem of lineItems) {
    const currentLineItem = {
      name: lineItem['name'],
      cpm: lineItem['bid'],
      network: lineItem['networkType'],
      network_ad_unit_id:
        lineItem['overrideFields'] && lineItem['overrideFields']['network_adunit_id']
    };
    processedResult[selectedAdUnit.name].line_items = processedResult[
      selectedAdUnit.name
    ].line_items.concat(currentLineItem);
  }
  return processedResult;
};

export const findPlacement = (placements, name) => {
  for (let i = 0; i < placements.length; i++) {
    if (placements[i][1].name === name) {
      return {
        placement: placements[i][1],
        name: placements[i][1].name,
        index: i
      };
    }
  }
  return {};
};

export const validatePlacementName = (name, placements, duplicatePlacementNames) => {
  const formErrors = Placement.Helpers.validatePlacementName(name);
  if (name.length === 0) {
    formErrors.push('Name cannot be empty');
  }
  const currentPlacementNames = placements.map(placement => placement[0]);
  if (currentPlacementNames.includes(name)) {
    formErrors.push('Name already exists');
  } else if (Array.isArray(duplicatePlacementNames) && duplicatePlacementNames.includes(name)) {
    formErrors.push('Name Duplicated');
  }
  return formErrors;
};

export const areAllPlacementsValid = (placements, duplicatedNames) =>
  placements.every(
    placement =>
      placement[1].ad_type !== null &&
      !(Array.isArray(duplicatedNames) && duplicatedNames.includes(placement[1].name))
  );

export const parseErrors = error => {
  let errorMessage = '';
  let errorMap = {};
  let duplicatePlacementNames = [];
  if (error.name === 'Not Found') {
    errorMessage = 'App not found';
  } else if (error.name === 'Internal Server Error') {
    errorMessage = 'Internal Server Error';
  } else if (error.reason === 'Duplicate Entry') {
    const message = error.message;
    for (let placement in message) {
      const duplicateEntryError = message[placement];
      const duplicateName = duplicateEntryError.split("'")[1];
      duplicatePlacementNames = duplicatePlacementNames.concat(duplicateName);
      let currentRow = { [placement]: duplicateEntryError };
      errorMap[placement] = currentRow;
    }
  } else if (error.reason === 'Duplicate Line Item Assignment Entry') {
    errorMessage = 'Duplicate Line Item Assignment Entry';
  } else if (error && error.errors) {
    let errors = error.errors;
    for (let errorName in errors) {
      let currentRow = { [errorName]: errors[errorName] };
      errorMap[errorName] = currentRow;
    }
  } else if (error && error.message && error.message.placement) {
    errorMessage = 'Missing Placement field';
  } else {
    const mapLineItemErrors = errors => {
      let map = {};
      let lineItems = errors.message.line_items;
      for (let row in lineItems) {
        const lineItemsKeys = Object.keys(lineItems[row]);
        let currData = [];
        for (let i = 0; i < lineItemsKeys.length; i++) {
          let key = lineItemsKeys[i];
          let rowValue = lineItems[row][key] || [];
          currData = currData.concat({ [key]: rowValue });
        }
        let currentRow = { [row]: currData };
        map[row] = currentRow;
      }
      return map;
    };
    errorMap = mapLineItemErrors(error);
  }
  return { errorMessage, errorMap, duplicatePlacementNames };
};

export const validateApiKey = key => {
  const errors = [];
  if (key.length === 0) {
    errors.push('Api Key cannot be empty');
  }
  let regexp = /^[a-zA-Z0-9-_]+$/;
  if (key.search(regexp) === -1) {
    errors.push('Invalid characters');
  }
  return errors;
};