import React from 'react';
import { Modal } from 'semantic-ui-react'
import PricePointsTable from './PricePointsTable';

const PricePointsField = attributes => {
    if (!attributes || !attributes.value || !attributes.value.length || attributes.hidden) {
        return null;
    }

    let modalTrigger = `See details (${attributes.value.length} rows)`;

    return (
        <div className="credential-container" key={attributes.name}>
            <p className="credential-paragraph">
                <span className="credential-tag">{attributes.label}</span>
                <Modal
                    closeIcon={true}
                    className="aps-pricepoints-modal"
                    header='Amazon APS Price Points'
                    trigger={<a className="ml-5">{modalTrigger}</a>}
                    content={<PricePointsTable values={attributes.value} />}
                    actions={[{ key: 'done', content: 'Done', positive: true }]}
                />
            </p>
        </div>
    )
}

export default PricePointsField;