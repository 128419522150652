import Constants from '../constants/targetingRules';

const isValidBoolean = keyword => {
  const validBooleans = ['true', 'false'];
  return validBooleans.some(boolean => boolean === keyword);
};

const isValidNumber = keyword => {
  const numberRegex = /^(-?\d+\.\d+)$|^(-?\d+)$/;
  return keyword.search(numberRegex) === 0;
};

const isValidVersion = keyword => {
  const versionRegex = /^(\d+\.\d+)((\.\d+){1,5})?(\.\d+)?$/;
  return keyword.search(versionRegex) === 0;
};

const isValidString = keyword => {
  const stringRegex = /^(?!.*("|\\)).*$/;
  return keyword.search(stringRegex) === 0;
};

const isValidStringLength = keyword => keyword.trim().length <= 256;

const isEmptyString = keyword => keyword.trim().length === 0;

const validateKeywordType = (keyword, keywordType) => {
  const errors = [];
  if (keywordType === 'string' && isEmptyString(keyword)) {
    errors.push(Constants.ERRORS.EMPTY_STRING);
  }
  if (keywordType === 'string' && !isValidStringLength(keyword)) {
    errors.push(Constants.ERRORS.MAX_LENGTH);
  }
  if (keywordType === 'string' && !isValidString(keyword)) {
    errors.push(Constants.ERRORS.INVALID_STRING);
  }
  if (keywordType === 'boolean' && !isValidBoolean(keyword)) {
    errors.push(Constants.ERRORS.INVALID_BOOLEAN);
  }
  if (keywordType === 'version' && !isValidVersion(keyword)) {
    errors.push(Constants.ERRORS.INVALID_VERSION);
  }
  if (keywordType === 'number' && !isValidNumber(keyword)) {
    errors.push(Constants.ERRORS.INVALID_NUMBER);
  }
  return errors;
};

const Validators = {
  validateKeywordType,
  isValidVersion,
};

export default Validators;
