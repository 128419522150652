const EQUALS_OPERATIONS = [
  {
    key: 'equals',
    text: '==',
    value: '=='
  },
  {
    key: 'not_equals',
    text: '!=',
    value: '!='
  }
];

const ALL_OPERATIONS = [
  ...EQUALS_OPERATIONS,
  {
    key: 'greater_than',
    text: '>',
    value: '>'
  },
  {
    key: 'less_than',
    text: '<',
    value: '<'
  },
  {
    key: 'greater_than_or_equals_to',
    text: '>=',
    value: '>='
  },
  {
    key: 'less_than_or_equals_to',
    text: '<=',
    value: '<='
  }
];

const OPERATION_MAP = {
  string: EQUALS_OPERATIONS,
  boolean: EQUALS_OPERATIONS,
  number: ALL_OPERATIONS,
  version: ALL_OPERATIONS
};

const BOOLEAN_OPTIONS = [
  {
    key: 'true',
    text: 'true',
    value: 'true'
  },
  {
    key: 'false',
    text: 'false',
    value: 'false'
  }
];

const INITIAL_TARGETING_RULES_STATE = {
  addingPredicateId: '',
  editingPredicateId: '',
  selectedRuleId: '',
  isAddingPredicate: false,
  isEditingPredicate: false,
  inputType: '',
  keyword: '',
  keywordType: '',
  operation: '',
  value: ''
};

const STRINGS = {
  DELETE_DESCRIPTION: 'Deleting this rule will remove all predicates associated with it.'
};

const ERRORS = {
  EMPTY_STRING: 'String must not be empty',
  MAX_LENGTH: 'String length must be under 256 characters',
  INVALID_BOOLEAN: 'Please enter "True" or "False"',
  INVALID_NUMBER: 'Please enter a valid number',
  INVALID_STRING: `Invalid characters found. \\ and " are not allowed`,
  INVALID_VERSION: `Version numbers must be numeric only and delimited by '.' up to 8 numbers. E.g 12.3.4.5`,
};

const Constants = {
  ALL_OPERATIONS,
  BOOLEAN_OPTIONS,
  EQUALS_OPERATIONS,
  ERRORS,
  INITIAL_TARGETING_RULES_STATE,
  OPERATION_MAP,
  STRINGS
};

export default Constants;
