import { COUNTRY_MAP } from 'core/app/constants/helium';

const ITEMS_PER_PAGE = 5;

const CHART_COLORS = [
  '#8ac73a',
  '#737fc5',
  '#ffb000',
  '#66aaf1',
  '#ff7a7a',
  '#79c6ca',
  '#bca7fd',
  '#adadad',
  '#76e4a6',
  '#f5beda'
];

const COLOR_CLASSES = {
  '#8ac73a': 'an col1',
  '#737fc5': 'an col2',
  '#ffb000': 'an col3',
  '#66aaf1': 'an col4',
  '#ff7a7a': 'an col5',
  '#79c6ca': 'an col6',
  '#bca7fd': 'an col7',
  '#adadad': 'an col8',
  '#76e4a6': 'an col9',
  '#f5beda': 'an col10'
};

const BREAKDOWNS = [
  {
    key: 'app_id',
    text: 'App',
    value: 'app_id',
    icon: 'mobile alternate'
  },
  {
    key: 'placement_type',
    text: 'Placement Type',
    value: 'placement_type',
    icon: 'bullhorn',
    list: {}
  },
  {
    key: 'demand_partner',
    text: 'Demand Source',
    value: 'demand_partner',
    icon: 'list',
    list: {}
  },
  {
    key: 'country',
    text: 'Country',
    value: 'country',
    icon: 'flag outline',
    list: COUNTRY_MAP
  },
  {
    key: 'helium_placement',
    text: 'Placement',
    value: 'helium_placement',
    icon: 'bookmark outline',
    list: {}
  },
  {
    key: 'partner_placement',
    text: 'Partner Placement',
    value: 'partner_placement',
    icon: 'globe',
    list: {}
  },
  {
    key: 'line_item_name',
    text: 'Line Items',
    value: 'line_item_name',
    icon: 'globe',
    list: {}
  }
];

const COMBINED_METRICS = {
  simple: ['earnings', 'impressions', 'requests'],
  calculated: ['fill_rate', 'ecpm']
};

const BIDDING_METRICS = {
  simple: ['earnings', 'requests', 'impressions', 'valid_bids', 'winning_bids'],
  calculated: ['fill_rate', 'ecpm', 'bid_rate', 'win_rate', 'impression_show_rate']
};

const MEDIATION_METRICS = {
  simple: ['earnings', 'impressions', 'requests', 'responses'],
  calculated: ['fill_rate', 'ecpm']
};

const BREAKDOWN_OPTIONS = {
  combined: ['app_id', 'helium_placement', 'placement_type', 'demand_partner', 'country'],
  bidding: ['app_id', 'helium_placement', 'placement_type', 'demand_partner', 'country'],
  mediation: [
    'app_id',
    'helium_placement',
    'placement_type',
    'line_item_name',
    'demand_partner',
    'country'
  ]
};


const REJECTED_KEY = "--rejected--";

const MESSAGES = {
  NO_NETWORK: `No demand partner qualified for these requests. This may be due to publisher blocks, or Helium blocks 
  (like embargoed countries). Review the Helium documentation for further details.`
}


const getMetrics = (networkType, metricType) => {
  const type = metricType || 'simple';
  switch (networkType) {
    case 'combined':
      return COMBINED_METRICS[type];
    case 'bidding':
      return BIDDING_METRICS[type];
    case 'mediation':
      return MEDIATION_METRICS[type];
    default:
      return COMBINED_METRICS[type];
  }
};

const ANALYTICS_BREADCRUMB = {
  bidding: 'Bidding',
  combined: 'All',
  mediation: 'Waterfall'
};

const getBreakdowns = networkType => BREAKDOWN_OPTIONS[networkType];

const getBreakdownProps = breakdown => BREAKDOWNS.find(b => b.key === breakdown);

const PerformanceConstants = {
  ANALYTICS_BREADCRUMB,
  BIDDING_METRICS,
  BREAKDOWNS,
  CHART_COLORS,
  COLOR_CLASSES,
  COMBINED_METRICS,
  ITEMS_PER_PAGE,
  MEDIATION_METRICS,
  REJECTED_KEY,
  MESSAGES,
  getBreakdownProps,
  getBreakdowns,
  getMetrics
};

export default PerformanceConstants;
