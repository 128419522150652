import React from 'react';
import { Header, Dropdown } from 'semantic-ui-react';

import Constants from '../constants';

const QueueSize = (props) => {
  return (
    <Header as="h5">
      <span>
        Max Queue Size{' '}
        <Dropdown
          data-testid="max-queue-size-dropdown"
          inline
          name="max_queue_size"
          options={Constants.QUEUE_SIZE_OPTIONS}
          value={props?.maxQueueSize}
          onChange={props?.toggleQueueSize}
        />
      </span>
      <span style={{ marginLeft: '40px' }}>
        Default Queue Size{' '}
        <Dropdown
          data-testid="default-queue-size-dropdown"
          inline
          name="default_queue_size"
          options={Constants.QUEUE_SIZE_OPTIONS}
          value={props?.defaultQueueSize}
          onChange={props?.toggleQueueSize}
        />
      </span>
    </Header>
  );
};

export default QueueSize;
