import React from 'react';

import { Form } from 'semantic-ui-react';

import Helpers from '../helpers';

const PricePointsInput = ({ setter, credentialState }) => {
  const isCSVError = () => {
    if (credentialState?.price_points === undefined) return false;
    if (credentialState?.price_points.length === 0) return true;
  };

  const setPricePoints = async (file) => {
    const pricePoints = await Helpers.processPricePoints(file);
    const e = {
      name: 'price_points',
      value: pricePoints,
    };
    setter({}, e);
  };

  return (
    <Form.Input
      type="file"
      fluid={true}
      accept=".csv"
      id="csvFile"
      style={{ minWidth: '220px' }}
      name="price_points"
      label="Price Points"
      error={isCSVError()}
      onChange={(e) => {
        e.preventDefault();
        setPricePoints(e.target.files[0]);
      }}
    />
  );
};

export default PricePointsInput;
