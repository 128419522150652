import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

const NoContent = () => (
  <Header as="h5">
    No Helium apps found <Link to="/apps/new"> Import App </Link>
  </Header>
);

export default NoContent;
