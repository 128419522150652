import _ from 'lodash';

/**
 *  Capitalizes first letter of word, or the entire string if all is true
 * @function capitalize()
 * @param {string} word
 * @param {boolean} all
 */
export const capitalize = (word, all = false) => {
  if (typeof word !== 'string') return 'undefined';
  if (!word || word.length === 0) return '';
  if (all) {
    return word.toUpperCase();
  }
  return word[0].toUpperCase() + word.slice(1);
};

/**
 *  Formats number to percentage and adds percent symbol to the end
 * @function formatPercent()
 * @param {number}
 */
export const formatPercent = (number) =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

/**
 *  Formats number to dollar and adds dollar symbol to the front
 * @function formatDollars()
 * @param {number}
 */
export const formatDollars = (number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

/**
 *  Formats numbers greater than three digits to include commas
 * @function formatValue()
 * @param {value}
 */
export const formatValue = (value) => {
  if (value === 0) return 0;
  if (!value) return '-';
  return new Intl.NumberFormat().format(value);
};

/**
 *  Sorts an array of objects by value of specific key. Undefined values are placed behind
 *  defined values regardless of direction.
 * @function sortArrayByDirection()
 * @param {Object[]} array
 * @param {string} key
 * @param {string} direction
 */
export const sortArrayByDirection = (array, key, direction = 'ascending') => {
  // Lodash does not support placing undefined/null values after truthy values in descending order
  // Use vanilla js sort() for descending and lodash for ascending
  if (direction === 'descending') {
    return [...array].sort((a, b) => {
      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        return a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1;
      }
      return (b[key] || 0) - (a[key] || 0);
    });
  } else {
    return _.orderBy(array, [key], ['asc']);
  }
};

export const setTitle = (title) => {
  const DEFAULT = 'Helium Dashboard';
  document.title = title || DEFAULT;
};
