const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const Helpers = {
  numberInputOnWheelPreventChange,
};

export default Helpers;
