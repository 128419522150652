import React, { useState } from 'react';
import { Button, Grid, Popup, Segment } from 'semantic-ui-react';

const WarningPopup = (props) => {
  const [isPopupOpen, togglePopup] = useState(false);
  const { action, actionColor, actionName, disabled, element, iconName, modalText, name, size } =
    props;

  const handleAction = () => {
    action();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleOpen = () => {
    // disable popup if disabled
    if (disabled) return;
    togglePopup(true);
  };
  const handleClose = () => {
    togglePopup(false);
  };

  const trigger = element ? (
    element
  ) : (
    <Button
      id={`${name} ${actionName} button`}
      icon={iconName || 'trash'}
      disabled={disabled}
      size={size ? size : 'medium'}
    />
  );
  const color = actionColor ? actionColor : 'red';
  const confirmID = `${name} ${actionName} button confirm`;
  const cancelID = `${name} ${actionName} button cancel`;

  return (
    <Popup
      on="click"
      onClose={handleClose}
      onOpen={handleOpen}
      open={isPopupOpen}
      pinned="true"
      trigger={trigger}
      wide
      position="top right"
      content={
        <div>
          <Segment>
            <div>
              {modalText !== undefined ? (
                modalText
              ) : (
                <h4>
                  {actionName} {name}
                </h4>
              )}
            </div>
          </Segment>
          <Grid columns="equal">
            <Grid.Column>
              <Button color="grey" content="Cancel" fluid onClick={handleCancel} id={cancelID} />
            </Grid.Column>
            <Grid.Column>
              <Button
                color={color}
                content={actionName}
                fluid
                id={confirmID}
                onClick={handleAction}
              />
            </Grid.Column>
          </Grid>
        </div>
      }
    />
  );
};

export default WarningPopup;
