import Actions from 'core/framework/actions';
import NetworkActions from './actions';
import Helpers from './helpers';

const initState = () => ({
  schema: {},
  names: [],
  supported_app_partners: {
    bidding: [],
    bidding_supported: [],
    waterfall: [],
    waterfall_supported: [],
  },
  supported_placement_partners: {
    bidding: [],
    bidding_supported: [],
    waterfall: [],
    waterfall_supported: [],
    waterfall_supported_map: {},
  },
  supported_placement_types: [],
});

const updateState = (state, updates) => ({
  ...state,
  network: {
    ...state.network,
    ...updates,
  },
});

const setNetworkSchema = (state, { response }) => {
  return updateState(state, {
    schema: response,
    names: Object.keys(response),
    bidding: Helpers.filter(response, 'bidding'),
    mediation: Helpers.filter(response, 'mediation'),
  });
};

const setSupportedAppNetworks = (state, { platform, network_type, response }) => {
  let biddingNetworks = [...state.network.supported_app_partners.bidding];
  let waterfallNetworks = [...state.network.supported_app_partners.waterfall];
  let supportedBiddingNetworks = [...state.network.supported_app_partners.bidding_supported];
  let supportedWaterfallNetworks = [...state.network.supported_app_partners.waterfall_supported];
  const formattedAppNetworks = Helpers.formatSupportedPartners(response.data);

  if (network_type === 'bidding') {
    biddingNetworks = [...formattedAppNetworks.partners];
    supportedBiddingNetworks = [...formattedAppNetworks.partnersSupported];
  } else if (network_type === 'waterfall') {
    waterfallNetworks = [...formattedAppNetworks.partners];
    supportedWaterfallNetworks = [...formattedAppNetworks.partnersSupported];
  }

  return updateState(state, {
    ...state,
    supported_app_partners: {
      platform: platform,
      bidding: [...biddingNetworks],
      bidding_supported: [...supportedBiddingNetworks],
      waterfall: [...waterfallNetworks],
      waterfall_supported: [...supportedWaterfallNetworks],
    },
  });
};

const setSupportedPlacementPartnerNetworks = (
  state,
  { placement_type, platform, network_type, response }
) => {
  let biddingNetworks = [...state.network.supported_placement_partners.bidding];
  let waterfallNetworks = [...state.network.supported_placement_partners.waterfall];

  let supportedBiddingNetworks = [...state.network.supported_placement_partners.bidding_supported];
  let supportedWaterfallNetworks = [
    ...state.network.supported_placement_partners.waterfall_supported,
  ];

  let supportedWaterFallMap = {
    ...state.network.supported_placement_partners.waterfall_supported_map,
  };

  const formattedPlacementPartners = Helpers.formatSupportedPartners(response.data);

  if (network_type === 'bidding') {
    biddingNetworks = [...formattedPlacementPartners.partners];
    supportedBiddingNetworks = [...formattedPlacementPartners.partnersSupported];
  } else if (network_type === 'waterfall') {
    waterfallNetworks = [...formattedPlacementPartners.partners];
    supportedWaterfallNetworks = [...formattedPlacementPartners.partnersSupported];
    supportedWaterFallMap = { ...formattedPlacementPartners.partnerSupportedMap };
  }

  return updateState(state, {
    ...state,
    supported_placement_partners: {
      network_type,
      placement_type,
      platform,
      bidding: [...biddingNetworks],
      bidding_supported: [...supportedBiddingNetworks],
      waterfall: [...waterfallNetworks],
      waterfall_supported: [...supportedWaterfallNetworks],
      waterfall_supported_map: { ...supportedWaterFallMap },
    },
  });
};

const setSupportedPlacementTypes = (state, { response }) => {
  const availablePlacementTypes = response.data;
  return updateState(state, {
    ...state,
    supported_placement_types: [...availablePlacementTypes],
  });
};
/* Network Reducer's apply function */

const apply = (state, type, payload) => {
  switch (type) {
    /* FETCH_NETWORK_SCHEMA */

    case Actions.started(NetworkActions.FETCH_NETWORK_SCHEMA):
      return updateState(state, { schema: {} });

    case Actions.failure(NetworkActions.FETCH_NETWORK_SCHEMA):
      return updateState(state, { schema: {} });

    case Actions.success(NetworkActions.FETCH_NETWORK_SCHEMA):
      return setNetworkSchema(state, payload);

    // FETCH_SUPPORTED_APP_PARTNERS
    case Actions.started(NetworkActions.FETCH_SUPPORTED_APP_PARTNERS):
      return { ...state };

    case Actions.failure(NetworkActions.FETCH_SUPPORTED_APP_PARTNERS):
      return {
        ...state,
        supported_app_partners: {
          bidding: [],
          bidding_supported: [],
          waterfall: [],
          waterfall_supported: [],
        },
      };

    case Actions.success(NetworkActions.FETCH_SUPPORTED_APP_PARTNERS):
      return setSupportedAppNetworks(state, payload);

    // FETCH_SUPPORTED_PLACEMENT_PARTNERS
    case Actions.started(NetworkActions.FETCH_SUPPORTED_PLACEMENT_PARTNERS):
      return { ...state };

    case Actions.failure(NetworkActions.FETCH_SUPPORTED_PLACEMENT_PARTNERS):
      return {
        ...state,
        supported_placement_partners: {
          bidding: [],
          bidding_supported: [],
          waterfall: [],
          waterfall_supported: [],
        },
      };

    case Actions.success(NetworkActions.FETCH_SUPPORTED_PLACEMENT_PARTNERS):
      return setSupportedPlacementPartnerNetworks(state, payload);

    // FETCH_SUPPORTED_PLACEMENT_TYPES
    case Actions.started(NetworkActions.FETCH_SUPPORTED_PLACEMENT_TYPES):
      return { ...state };

    case Actions.failure(NetworkActions.FETCH_SUPPORTED_PLACEMENT_TYPES):
      return {
        ...state,
        supported_placement_types: [],
      };

    case Actions.success(NetworkActions.FETCH_SUPPORTED_PLACEMENT_TYPES):
      return setSupportedPlacementTypes(state, payload);

    default:
      return state;
  }
};

const Reducer = {
  initState,
  updateState,
  apply,
};

export default Reducer;
