import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MetricsFilterButtons from './MetricsFilterButtons';
import MetricsAdvancedButton from './MetricsAdvancedButton';
import MetricsLineGraph from './MetricsLineGraph';
import MetricsBarGraph from './MetricsBarGraph';
import Placements from 'apps/app/views/Placements';
import EmptyData from './EmptyData';

import MetricsHelpers from '../helpers';
import MetricsConstants from '../constants';

import Placement from 'apps/placement';
import '../css/metrics.css';

const MetricsGraphContainer = ({ ...props }) => {
  const [state, setState] = useState({
    calculated_totals: {},
    colorMap: {},
    graph_data: [],
    isAdvancedView: false,
    pageView: 'appOverview',
    placementNames: [],
    selectedView: 'earnings',
    shouldDisplayEmpty: false,
  });

  const toggleMetric = (metric) => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedView: metric,
      };
    });
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      shouldDisplayEmpty: false,
      pageView: props.pageView,
    }));

    let graph_data = [];
    let calculated_totals = {};
    let placementNames = [];
    let colorMap = {};

    if (props.pageView === 'allApps') {
      graph_data = props?.timelineMetrics?.graph_data || [];
      calculated_totals = props?.timelineMetrics?.calculated_totals || {};
    }

    if (props.pageView === 'appOverview') {
      graph_data = props?.timelineMetrics?.graph_data || [];
      calculated_totals = props?.timelineMetrics?.calculated_totals || {};
    }

    if (props.pageView === 'placementOverview') {
      graph_data = props?.overviewMetrics?.graph_data || [];
      calculated_totals = props?.overviewMetrics?.calculated_totals || {};
      let tableMetrics = props?.overviewMetrics?.table_metrics || {};
      placementNames = Object.keys(tableMetrics) || [];
      if (graph_data.length > 0) {
        colorMap = placementNames.reduce((acc, cur, idx) => {
          acc[cur] = MetricsConstants.BAR_COLORS[idx];
          return acc;
        }, {});
      }
    }

    if (props.pageView === 'singlePlacement') {
      graph_data = props?.placementNetworkGraphData || [];
      calculated_totals = props?.placementCalculatedTotal || [];
    }

    if (graph_data && graph_data.length > 0) {
      setState((prevState) => {
        return {
          ...prevState,
          shouldDisplayEmpty: false,
          graph_data,
          calculated_totals,
          placementNames,
          colorMap,
        };
      });
    }

    if (graph_data && graph_data.length === 0) {
      setState((prevState) => {
        return {
          ...prevState,
          shouldDisplayEmpty: true,
          graph_data,
          calculated_totals,
          placementNames,
        };
      });
    }
  }, [
    props.biddingMetrics,
    props.mediationMetrics,
    state.selectedView,
    props.pageView,
    props.timelineMetrics,
    props.overviewMetrics,
  ]);

  const hasData = state.graph_data && state.graph_data.length > 0;
  const shouldDisplayBar = !MetricsHelpers.isCalculatedMetric(state.selectedView);

  return (
    <div className="metrics-container">
      <MetricsFilterButtons
        pageView={state.pageView}
        selectedView={state.selectedView}
        toggleMetric={toggleMetric}
      />
      {props.pageView === 'placementOverview' && !state.shouldDisplayEmpty && (
        <MetricsAdvancedButton isAdvancedView={state.isAdvancedView} setState={setState} />
      )}

      {!state.shouldDisplayEmpty && (
        <div className="metrics-graph-container">
          {hasData && shouldDisplayBar && (
            <MetricsBarGraph
              colorMap={state.colorMap}
              graph_data={state.graph_data}
              isAdvancedView={state.isAdvancedView}
              pageView={state.pageView}
              placementNames={state.placementNames}
              selectedView={state.selectedView}
            />
          )}
          {hasData && !shouldDisplayBar && (
            <MetricsLineGraph
              colorMap={state.colorMap}
              data={state.graph_data}
              isAdvancedView={state.isAdvancedView}
              pageView={state.pageView}
              placementNames={state.placementNames}
              selectedView={state.selectedView}
            />
          )}
        </div>
      )}
      {!state.shouldDisplayEmpty && (
        <Placements calculated_totals={state.calculated_totals} pageView={state.pageView} />
      )}
      {state.shouldDisplayEmpty && !props.loading && <EmptyData />}
    </div>
  );
};

const mapStateToProps = (state, _ownProps) => {
  return {
    loading: Placement.Store.getLoading(state),
    biddingMetrics: Placement.Store.getBiddingMetrics(state),
    mediationMetrics: Placement.Store.getMediationMetrics(state),
    placementNetworkGraphData: Placement.Store.getMetricsGraphData(state),
    placementCalculatedTotal: Placement.Store.getCalculatedTotalsMetrics(state),
  };
};

export default withRouter(connect(mapStateToProps, {})(MetricsGraphContainer));
