import React, { useState, useEffect } from 'react';

import { Button, Icon, Modal, Popup, Segment } from 'semantic-ui-react';

import LineItemFilterFooter from './LineItemFilterFooter';
import LineItemFilterInput from './LineItemFilterInput';
import LineItemFilterTable from './LineItemFilterTable';

import Constants from '../constants';
import Helpers from '../helpers';

import '../css/Modal.css';

const LineItemModal = (props) => {
  const {
    add,
    clear,
    compact,
    create,
    deleteLineItem,
    items,
    loading,
    networks,
    selected,
    type,
    isBetaTestAccount,
    isTestAccount,
    platform,
    getSupportedPlacementPartners,
    supportedPlacementPartners,
  } = props;

  const [currentLineItems, setLineItems] = useState([]);
  const [modalOpen, setModal] = useState(false);
  const [networkFilter, setNetworkFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedLines, setSelectedLines] = useState({});

  const [sortOptions, setSortOptions] = useState(Constants.SORT_OPTION_DEFAULT_STATE);

  const toggleSortOption = (option) => {
    const name = option;
    const lineItems = [...currentLineItems];
    let nextSortOption = Helpers.getNextSortValue(sortOptions[name]);
    let sortedLines = [];
    if (name !== undefined) {
      sortedLines = Helpers.sortLineItems(name, nextSortOption, lineItems);
      setSortOptions({
        ...sortOptions,
        [name]: nextSortOption,
      });
      setLineItems(sortedLines);
    }
  };

  const toggleFilter = (network, query, items) => {
    return Helpers.filterSearchLineItems(network, query, items);
  };

  const updateField = (lineItemId) => {
    const originalValue = selectedLines[lineItemId];
    if (selectedLines[lineItemId] === true) {
      delete selectedLines[lineItemId];
      setSelectedLines({ ...selectedLines });
    } else {
      setSelectedLines({
        ...selectedLines,
        [lineItemId]: !originalValue,
      });
    }
  };

  const numOfSelected = Object.keys(selectedLines).length;

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    clear();
    setSelectedLines({});
  };

  const handleSubmit = () => {
    if (numOfSelected > 0) {
      add(Object.keys(selectedLines));
    }
    handleClose();
  };

  const updateFilter = (filterType, filterVal) => {
    if (filterType === 'searchFilter') {
      setSearchFilter(filterVal);
    } else if (filterType === 'networkFilter') {
      setNetworkFilter(filterVal);
    }
  };

  useEffect(() => {
    if (selected && !selectedLines[selected]) {
      setSelectedLines({ ...selectedLines, [selected]: true });
    }
    const filteredLineItems = Helpers.filterLineItems(items, type);
    const searchFilteredItems = toggleFilter(networkFilter, searchFilter, filteredLineItems);
    setLineItems(searchFilteredItems);
  }, [items, searchFilter, networkFilter]);

  useEffect(() => {
    if (type && platform) {
      getSupportedPlacementPartners(platform.toLowerCase(), 'waterfall', type);
    }
  }, [platform, type]);

  const ModalButtonProps = {
    className: 'positive',
    content: 'Add a line item',
    icon: 'plus',
    labelPosition: 'left',
    onClick: handleOpen,
    size: compact ? 'tiny' : 'medium',
  };

  const AddButton = (
    <Button onClick={handleSubmit} className={numOfSelected > 0 ? 'btn-darker' : ''}>
      <Icon name="add" /> Add Selected Items ({numOfSelected})
    </Button>
  );

  return (
    <Modal
      centered={true}
      className="hl-modal LineItemModal"
      closeIcon
      closeOnDimmerClick={false}
      onClose={handleClose}
      open={modalOpen}
      size="fullscreen"
      trigger={<Button {...ModalButtonProps} />}
    >
      <Modal.Header content={`Create a new line item`} color="green" />
      <Modal.Content>
        <Segment loading={loading}>
          <LineItemFilterInput
            itemLength={currentLineItems.length}
            networkFilter={networkFilter}
            networks={networks}
            searchFilter={searchFilter}
            type={type}
            updateFilter={updateFilter}
            supportedPlacementPartners={supportedPlacementPartners}
          />
          <LineItemFilterTable
            deleteLineItem={deleteLineItem}
            items={currentLineItems}
            selectedLines={selectedLines}
            sortOptions={sortOptions}
            toggleSortOption={toggleSortOption}
            updateField={updateField}
            isBetaTestAccount={isBetaTestAccount}
            isTestAccount={isTestAccount}
          />
          <LineItemFilterFooter
            networks={networks}
            create={create}
            type={type}
            isBetaTestAccount={isBetaTestAccount}
            isTestAccount={isTestAccount}
            supportedPlacementPartners={supportedPlacementPartners}
          />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          className="warningpopup"
          content="Click here to confirm adding the selected Line Item(s) to your Ad Group"
          on="click"
          open={numOfSelected > 0}
          position="left center"
          trigger={AddButton}
        />
        <Button onClick={handleClose}>
          <Icon name="remove" /> Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LineItemModal;
