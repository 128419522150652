import React, { useEffect, useState } from 'react';
import { AppLayout, DualHeader, PageHeader, RouteLeavingGuard } from 'core/app/views';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// modules
import App from 'apps/app';
import UserStore from 'core/user/store';
import Placement from 'apps/placement';
import AdGroup from 'apps/adgroup';
import Utils from 'core/framework/utils';

// views
import ABEditPlacement from './ABEditPlacement';
import CreativeTypes from 'apps/placement/views/CreativeTypes';
import PlacementOverview from 'apps/placement/views/PlacementOverview';
import BannerRefresh from 'apps/placement/views/BannerRefresh';
import ABTestToggle from 'apps/placement/views/ABTestToggle';
import RewardedCallbackSettings from 'apps/placement/views/RewardedCallbackSettings';

import { setTitle } from 'utils/helpers';

import '../css/splitplacements.css';

const SplitPlacements = (props) => {
  const {
    app,
    clearRedirect,
    defaultNames,
    editPlacement,
    fetchAdGroups,
    fetchAll,
    loading,
    madeChangePlacement,
    madeChanges,
    placementA,
    placementB,
    proportionA,
    proportionB,
    save,
    selectApp,
    setProportion,
    shouldFetchAdGroups,
    shouldFetchPlacements,
    enableBannerRefresh,
    disableBannerRefresh,
    setBannerRefresh,
    setRewardedCallback,
    callbackErrors,
    placementErrors,
  } = props;
  const [defaultNamesAddition, setDefaultNamesAddition] = useState(defaultNames);
  const [isSaved, setIsSaved] = useState(false);
  const set = Utils.handleFormEvent(editPlacement, app.id, placementA.id);
  const isBanner = placementA.type === 'banner' || placementA.type === 'adaptive_banner';
  const isRewarded = placementA.type === 'rewarded';

  const urlParams = window.location.pathname.split('/');
  const urlAppId = urlParams[2];
  const appId = app.id || urlAppId;

  const saver = () => {
    // sg-1838: Copy placement A active state to placement B
    placementB.active = placementA.active;
    save(placementA, app.id);
    save(placementB, app.id);

    setIsSaved(true);
  };

  const saveButtonProps = {
    onClick: () => {
      saver();
    },
    disabled: placementErrors.length > 0 || !placementA.name,
    content: 'Save Placement',
    className: 'positive',
  };

  const handleAddition = () => {
    let name = placementA ? placementA.name : null;
    let defaultNamesAddition = defaultNames || [];
    let isCustomName = name && !defaultNamesAddition.find(({ value }) => value === name);
    if (isCustomName) {
      defaultNamesAddition.push(Placement.Helpers.makePlacementOptionRow(name));
      setDefaultNamesAddition(defaultNamesAddition);
    }
  };

  useEffect(() => {
    clearRedirect();
    handleAddition();

    if (app.id !== urlAppId && placementA?.has_abtest !== undefined) {
      fetchAll(appId);
      selectApp(appId);
      setIsSaved(true);
    }
    if (shouldFetchAdGroups || app.id !== urlAppId) {
      fetchAdGroups(appId);
    }
    if (placementA.name) {
      setTitle(placementA.name);
    }
  }, [shouldFetchPlacements, shouldFetchAdGroups, placementA.name]);

  useEffect(() => {
    // refetch placements when user clicks on activate A/B test to populate data
    if (
      (app.id === urlAppId && placementA?.has_abtest === undefined) ||
      placementB?.has_abtest === undefined
    ) {
      fetchAll(appId);
      selectApp(appId);
    }
  }, [placementA.has_abtest]);

  // Need to have both placement proportion values to determine if the control or test is active
  const isControlActive = placementA.proportion > 0;
  const isTestActive = placementB.proportion > 0;
  return (
    <AppLayout bundle="apps" context={{ app: app, placement: placementA, placements: true }}>
      <RouteLeavingGuard
        when={(madeChanges || madeChangePlacement) && !isSaved}
        shouldBlockNavigation={() => true}
      />
      <Segment className="splitPlacementContainer">
        <Segment>
          <DualHeader>
            <PageHeader content={placementA.name || 'New Placement'} />
            <Button {...saveButtonProps} />
          </DualHeader>

          <PlacementOverview
            onAddName={handleAddition}
            defaultNames={defaultNamesAddition}
            errors={placementErrors}
            placement={placementA}
            loading={loading}
            appId={app.id}
            set={set}
            abtest={true}
            abtesttype={'A'}
          />

          <PageHeader content="Advanced Settings" />
          <Segment padded textAlign="left">
            <Grid>
              <Grid.Row className="pb-0">
                <Grid.Column width="4">
                  <CreativeTypes placement={placementA} set={set} />
                </Grid.Column>
                <Grid.Column width="4">
                  <ABTestToggle disabled={true} onClick={() => {}} />
                </Grid.Column>
                <Grid.Column width="4" className={isBanner ? 'visible' : 'hidden'}>
                  <BannerRefresh
                    placement={placementA}
                    enable={() => enableBannerRefresh(app.id, placementA.id)}
                    disable={() => disableBannerRefresh(app.id, placementA.id)}
                    set={(val) => setBannerRefresh(app.id, placementA.id, val)}
                    max={Placement.Constants.BANNER_MAX_REFRESH_RATE}
                    min={Placement.Constants.BANNER_MIN_REFRESH_RATE}
                  />
                </Grid.Column>
                <Grid.Column width="8" className={isRewarded ? 'visible' : 'hidden'}>
                  <RewardedCallbackSettings
                    appId={app.id}
                    placement={placementA}
                    set={setRewardedCallback}
                    errors={callbackErrors}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <PageHeader content="A/B Test Settings" className="pb-20" />
          <Grid columns={2}>
            <Grid.Column>
              <ABEditPlacement
                abtest={true}
                abtesttype={'A'}
                abtestid={placementA.id}
                placementName={placementA.name}
                saver={saver}
                proportion={proportionA}
                setProportion={setProportion}
                isControlActive={isControlActive}
                isTestActive={isTestActive}
              />
            </Grid.Column>
            <Grid.Column>
              <ABEditPlacement
                abtest={true}
                abtesttype={'B'}
                abtestid={placementB.id}
                placementName={placementB.name}
                saver={saver}
                proportion={proportionB}
                setProportion={setProportion}
                isControlActive={isControlActive}
                isTestActive={isTestActive}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment>
    </AppLayout>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id: appId, pid } = ownProps.match.params;
  const placementId = parseInt(pid, 10);

  const params = window.location.pathname.split('/');
  const abtestone = params[params.length - 2];
  const abtesttwo = params[params.length - 1];

  const placementA = Placement.Store.getOne(state, appId, abtestone) || { adgroups: [] };
  const placementB = Placement.Store.getOne(state, appId, abtesttwo) || { adgroups: [] };

  const app = App.Store.getApp(state, appId) || { credentials: [], placements: [] };
  const existingNames = Placement.Helpers.getExistingNames(app, placementId);

  const proportionA = typeof placementA.proportion === 'undefined' ? 0 : placementA.proportion;
  const proportionB = typeof placementB.proportion === 'undefined' ? 0 : placementB.proportion;

  return {
    app,
    shouldFetchPlacements: Placement.Store.getShouldFetchPlacements(state),
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    defaultNames: Placement.Store.getDefaults(state, existingNames),
    existingNames,
    loading: Placement.Store.getLoading(state),
    madeChanges: App.Store.hasMadeChanges(state),
    madeChangePlacement: Placement.Store.hasMadeChanges(state),
    placementA: placementA || { credentials: [], floor_active: false, prioritize_bidding: false },
    placementB: placementB || { credentials: [], floor_active: false, prioritize_bidding: false },
    placementId,
    proportionA,
    proportionB,
    callbackErrors: App.Store.getCallbackErrors(state),
    placementErrors: App.Store.getPlacementErrors(state),
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
  };
};

const mapDispatchToProps = {
  fetchAll: Placement.Actions.fetchAll,
  fetchAdGroups: AdGroup.Actions.fetchAll,
  clearRedirect: Placement.Actions.clearRedirect,
  editPlacement: Placement.Actions.editPlacement,
  setProportion: Placement.Actions.setProportion,
  selectApp: App.Actions.selectApp,
  save: Placement.Actions.update,
  enableBannerRefresh: Placement.Actions.enableBannerRefresh,
  disableBannerRefresh: Placement.Actions.disableBannerRefresh,
  setBannerRefresh: Placement.Actions.setBannerRefresh,
  setRewardedCallback: Placement.Actions.setRewardedCallback,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SplitPlacements));
