import React from 'react';
import { Input, Label, Form, Dropdown, Message } from 'semantic-ui-react';

import Helpers from '../helpers';

const AmazonApsForm = (props) => {
  const { fields = [], placement, edit } = props;
  const placementType = placement?.type;

  const getValue = (name) => {
    const field = fields.find((f) => f.name === name);
    return field.value;
  };

  const setPricePoints = async (file) => {
    const pricePoints = await Helpers.processPricePoints(file);
    edit(null, { name: 'price_points', value: pricePoints });
  };

  const isCSVError = () => {
    const pricePoint = fields.find((field) => field.name === 'price_points');
    if (pricePoint?.value === undefined) return false;
    if (pricePoint?.value.length === 0) return true;
  };

  if (placementType === 'rewarded_interstitial') {
    return (
      <Message warning icon="warning">
        Rewarded Interstitial Ads are not currently supported for Amazon Aps
      </Message>
    );
  }
  return (
    <Form>
      <Form.Field key="application_id">
        <Input
          autoComplete="off"
          fluid={true}
          labelPosition="left"
          name="application_id"
          value={getValue('application_id')}
        >
          <Label pointing="right" id="application_id label">
            Amazon Application Id
          </Label>
          <input disabled={true} />
        </Input>
      </Form.Field>

      <Form.Field key="size" className="amazon-aps-size-field">
        <Label pointing="right" id="size label">
          Ad Size
        </Label>
        <Dropdown
          placeholder="Select Ad Size"
          fluid={true}
          selection={true}
          labelPosition="left"
          name="size"
          value={getValue('size')}
          label="Ad Size"
          options={Helpers.getSizes(placement)}
          onChange={edit}
        />
      </Form.Field>

      <Form.Field key="ad_slot_uuid">
        <Input
          autoComplete="off"
          fluid={true}
          labelPosition="left"
          name="ad_slot_uuid"
          value={getValue('ad_slot_uuid')}
          onChange={edit}
          type="string"
        >
          <Label pointing="right" id="ad_slot_uuid label">
            Ad Slot UUID
          </Label>
          <input />
        </Input>
      </Form.Field>

      <Form.Field key="price_points" className="amazon-aps-pricepoints-field" error={isCSVError()}>
        <Label pointing="right" id="price_points label">
          Price Points
        </Label>
        <Input
          type="file"
          fluid={true}
          accept=".csv"
          id="csvFile"
          name="price_points"
          onChange={(e) => {
            e.preventDefault();
            setPricePoints(e.target.files[0]);
          }}
        />
      </Form.Field>
    </Form>
  );
};

export default AmazonApsForm;
