import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';

import WarningPopup from 'core/app/views/WarningPopup';
import Constants from '../../placement/constants';

const NameLink = ({ url, name, override = false }) => {
  if (override) {
    return (
      <a onClick={() => override(url)} link="true">
        {name}
      </a>
    );
  }
  return <Link to={url}>{name}</Link>;
};

const EditLink = ({ url, override = false }) => {
  if (override) {
    return <Button onClick={() => override(url)} icon="pencil" />;
  }
  return <Button as={Link} to={url} icon="pencil" />;
};

const AdGroup = ({ adGroup, appId, disabled, override = false, removeAdgroup }) => {
  const { name, id, type } = adGroup;

  const adType = Constants.AD_TYPE_LABELS[type];

  const handleDelete = (appId, id) => {
    removeAdgroup(appId, id);
  };

  return (
    <Table.Row>
      <Table.Cell>
        <NameLink url={`/apps/${appId}/adgroups/${id}`} override={override} name={name} />
      </Table.Cell>
      <Table.Cell textAlign="center">{adType}</Table.Cell>
      <Table.Cell textAlign="center">
        <EditLink url={`/apps/${appId}/adgroups/${id}`} override={override} />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <WarningPopup
          action={() => handleDelete(appId, id)}
          actionName="Delete"
          disabled={disabled}
          name={name}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default AdGroup;
