import React, { Component } from 'react';
import { connect } from 'react-redux';

//views
import AdGroups from './AdGroups';
import AppSelector from 'apps/app/views/AppSelector';
import AdTypeDropdown from 'apps/common/AdTypeDropdown';
import { Segment, Label } from 'semantic-ui-react';
import { PageHeader, AppLayout, DualHeader } from 'core/app/views';

// models
import App from 'apps/app';
import AdGroup from '../../adgroup';
import LineItem from 'apps/lineitem';
import Placement from 'apps/placement';
import UserStore from 'core/user/store';
import Network from 'apps/network';
import { setTitle } from 'utils/helpers';

class ManageAdGroups extends Component {
  componentDidMount() {
    if (this.props.appId !== 'unselected') {
      this.props.fetchApp(this.props.appId);
      this.props.fetchAdGroups(this.props.appId);
      this.props.selectApp(this.props.appId);
      const platform = this.props.app.platform;
      if (platform) {
        this.props.getSupportedPlacementTypes(platform.toLowerCase());
      }
    }
    this.props.clearTransition();
  }

  componentDidUpdate(prevProps) {
    const isAppChanged = this.props.appId !== prevProps.appId;
    const platform = this.props.app?.platform;

    if (prevProps.appId !== 'unselected' && this.props.appId !== 'unselected') {
      setTitle(this.props?.app?.nickname || this.props?.app?.name);
      if (isAppChanged) {
        this.props.fetchApp(this.props.appId);
      }
      if (
        (prevProps.shouldFetchLineItems === false && this.props.shouldFetchLineItems) ||
        isAppChanged
      ) {
        this.props.fetchLineItems(this.props.appId);
      }
      if (
        (prevProps.shouldFetchAdGroups === false && this.props.shouldFetchAdGroups) ||
        isAppChanged
      ) {
        this.props.fetchAdGroups(this.props.appId);
      }
      if (
        (prevProps.shouldFetchPlacements === false && this.props.shouldFetchPlacements) ||
        isAppChanged
      ) {
        this.props.fetchPlacements(this.props.appId);
      }
      if (platform !== prevProps.app.platform && platform) {
        this.props.getSupportedPlacementTypes(platform.toLowerCase());
      }
    }
  }

  render = () => {
    const { all, app, appId, loading, removeAdgroup, supportedPlacementTypes } = this.props;

    const default_adgroup_ids = [
      app.default_banner_adgroup,
      app.default_interstitial_adgroup,
      app.default_rewarded_adgroup,
      app.default_rewarded_interstitial_adgroup,
      app.default_adaptive_banner_adgroup,
    ];

    const context = {
      app: app,
      adgroups: true,
    };

    return (
      <AppLayout bundle="apps" context={context}>
        <Segment padded textAlign="left">
          <DualHeader>
            <PageHeader content="Manage Ad Groups" className="pt-10 pb-10" img={true} />
            <AppSelector id={appId} suffix="adgroups" />
          </DualHeader>
          <div className="content-padding manage-tab">
            <Segment padded loading={loading} className="smooth-table">
              <Label attached="top" size="large">
                <AdTypeDropdown
                  appId={app.id}
                  supportedPlacementTypes={supportedPlacementTypes}
                  dropdownType="adgroup"
                />
              </Label>
              <AdGroups
                adGroups={all}
                appId={appId}
                defaultIds={default_adgroup_ids}
                loading={loading}
                removeAdgroup={removeAdgroup}
                type="all"
              />
            </Segment>
          </div>
        </Segment>
      </AppLayout>
    );
  };
}

const props = (state, ownProps) => {
  const appId = ownProps.match.params.id;
  const app = App.Store.getApp(state, appId) || {};

  return {
    shouldFetchLineItems: LineItem.Store.shouldFetch(state),
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    shouldFetchPlacements: Placement.Store.getShouldFetchPlacements(state),
    loading: AdGroup.Store.getLoading(state),
    all: AdGroup.Store.getAll(state, appId),
    appId: appId,
    app: app,
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
    isTestAccount: UserStore.isTestAccount(state),
    supportedPlacementTypes: Network.Store.getSupportedPlacementTypes(state),
  };
};

const dispatches = {
  fetchAdGroups: AdGroup.Actions.fetchAll,
  removeAdgroup: AdGroup.Actions.removeAdgroup,
  clearTransition: AdGroup.Actions.clearTransition,
  fetchLineItems: LineItem.Actions.fetchAll,
  fetchPlacements: Placement.Actions.fetchAll,
  fetchApp: App.Actions.fetchApp,
  selectApp: App.Actions.selectApp,
  getSupportedPlacementTypes: Network.Actions.fetchSupportedPlacementTypes,
};

export default connect(props, dispatches)(ManageAdGroups);
