import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Pagination, Label, Icon, Input, Grid, Segment, Card, Button } from 'semantic-ui-react';

import { PageHeader, AppLayout } from 'core/app/views';

import HeliumCard from './HeliumCard';
import NoContent from './NoContent';
import MetricsGraphContainer from 'apps/common/metrics/views/MetricsGraphContainer';
import DateFilter from 'analytics/common/views/DateFilter';
import CommonHelpers from 'analytics/common/helpers';
import Format from 'analytics/common/format';

import Store from '../store';
import Actions from '../actions';

class Overview extends Component {
  componentDidMount() {
    const { max, min } = this.props.getDateFilter;
    if (this.props.shouldFetchHeliumApps) {
      this.props.fetchAppsTimeline(min, max);
      this.props.fetchApps();
    }
  }

  componentDidUpdate(prevProps) {
    let curDateFilter = this.props.getDateFilter;
    let lastDateFilter = prevProps.getDateFilter;
    if (curDateFilter.min !== lastDateFilter.min || curDateFilter.max !== lastDateFilter.max) {
      this.props.fetchAppsTimeline(curDateFilter.min, curDateFilter.max);
    }
    document.title = 'Helium Dashboard';
  }

  componentWillMount() {
    this.props.clearSearch();
  }

  handlePageChange(e, p) {
    this.props.setPage(p.activePage);
  }

  clearSearch() {
    this.props.clearSearch();
  }

  filterApps(term) {
    this.props.searchApps(term);
  }

  renderAppCards = (apps) => {
    let Apps = <NoContent />;
    let Cards = [];
    if (apps && apps.length > 0) {
      Cards = apps.map((heliumApp) => (
        <HeliumCard key={heliumApp.id} app={heliumApp} toggleFunction={this.props.saveApp} />
      ));
      Apps = (
        <Card.Group centered itemsPerRow={3}>
          {Cards}
        </Card.Group>
      );
    }
    return Apps;
  };

  updateDateFilter = (start, end, selectedKey) => {
    const min = Format.date(start || CommonHelpers.timePeriod.week);
    const max = Format.date(end || CommonHelpers.timePeriod.today);
    this.props.setDateFilter({}, { min, max, type: selectedKey });
  };

  render() {
    const { heliumApps, loading, pagination, getDateFilter, searchTerm } = this.props;
    const { min, max, type } = getDateFilter;
    const total = heliumApps.length;
    const apps = heliumApps.filter(({ page }) => page === pagination.active);
    let searchLabel;

    if (searchTerm !== '') {
      searchLabel = (
        <Label className="filter-label">
          <Icon name="close" onClick={(e) => this.clearSearch()} /> {searchTerm}
          <Label.Detail>
            <Icon name="filter" />
          </Label.Detail>
        </Label>
      );
    }

    return (
      <AppLayout bundle="apps">
        <Segment>
          <Segment basic loading={loading} textAlign="left">
            <Grid>
              <Grid.Column width={5}>
                <PageHeader content="Helium Apps" img={true} />
              </Grid.Column>
              <Grid.Column width={6} />
              <Grid.Column width={5}>
                <Button
                  as={Link}
                  content="Add Helium App"
                  floated="right"
                  icon="plus"
                  labelPosition="left"
                  className="positive"
                  to="/apps/new"
                />
              </Grid.Column>
            </Grid>
            <div className="metrics-filter" style={{ marginTop: '30px' }}>
              <DateFilter
                date_key={type}
                date_from={min}
                date_to={max}
                onSelect={(_e) => null}
                onApply={(_e, endDate, startDate, selectedKey) =>
                  this.updateDateFilter(startDate, endDate, selectedKey)
                }
              />
            </div>
            <Segment loading={loading} style={{ marginTop: '-10px', marginBottom: '30px' }}>
              <MetricsGraphContainer
                timelineMetrics={this.props.timelineMetrics}
                pageView="allApps"
              />
            </Segment>
            <Segment basic>
              <div className="search-separator">
                <div className="mb-10">Search your Helium apps</div>
                <Input
                  fluid
                  value={searchTerm}
                  icon="search"
                  placeholder="Search..."
                  onChange={(e) => this.filterApps(e.target.value)}
                />
              </div>
              <div className="search-results-labels">
                <Label>
                  <Icon name="search" />
                  Results<Label.Detail>{total}</Label.Detail>
                </Label>
                {searchLabel}
              </div>
              {this.renderAppCards(apps)}
            </Segment>
            <div className="center-align">
              <Pagination
                boundaryRange={0}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                activePage={pagination.active}
                totalPages={pagination.total}
                onPageChange={this.handlePageChange.bind(this)}
              />
            </div>
          </Segment>
        </Segment>
      </AppLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  heliumApps: Store.getFilteredApps(state),
  loading: Store.getLoading(state),
  shouldFetchHeliumApps: Store.getShouldFetchApps(state),
  pagination: Store.getPagination(state),
  searchTerm: Store.getSearchTerm(state),
  getDateFilter: Store.getDateFilter(state),
  timelineMetrics: Store.getTimelineMetrics(state),
});

const mapDispatchToProps = {
  ...Actions,
  setDateFilter: Actions.setDateFilter,
  fetchAppsTimeline: Actions.fetchAppsTimeline,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
