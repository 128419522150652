import Actions from 'core/framework/actions';
import Api from './api';
import Helpers from './helpers';

/* Actions */

const terms = {
  FETCH_APP: 'APP.FETCH',
  FETCH_APPS: 'APPS.FETCH',
  FETCH_APPS_TIMELINE: 'APPS.TIMELINE.FETCH',
  EDIT_APP: 'APP.EDIT',
  SAVE_APP: 'APP.SAVE',
  CLEAR_CHANGES: 'APP.CLEAR',
  SEARCH_APPS: 'APPS.SEARCH',
  CLEAR_SEARCH: 'CLEAR.SEARCH',
  SET_PAGE: 'PAGE.SET',
  ADD_CREDENTIAL: 'CREDENTIAL.ADD',
  EDIT_CREDENTIAL: 'CREDENTIAL.EDIT',
  TOGGLE_CREDENTIAL: 'CREDENTIAL.TOGGLE',
  REMOVE_CREDENTIAL: 'CREDENTIAL.REMOVE',
  SAVE_CREDENTIALS: 'CREDENTIALS.SAVE',
  SAVE_CREDENTIAL_REMOVAL: 'CREDENTIALS.REMOVE.SAVE',
  SELECT_APP: 'APP.SELECT',
  SET_DATE_FILTER: 'APP.SET.DATEFILTER',
};

/* Async Definitions */

const fetchApp = Actions.create({
  type: terms.FETCH_APP,
  args: ['appId'],
  awaits: Api.getOne,
  message: ['fetch-failure', 'helium app'],
});

const fetchApps = Actions.create({
  type: terms.FETCH_APPS,
  args: [],
  awaits: Api.getAll,
  message: ['fetch-failure', 'helium apps'],
});

const fetchAppsTimeline = Actions.create({
  type: terms.FETCH_APPS_TIMELINE,
  args: ['date_min', 'date_max'],
  awaits: (date_min, date_max) => Api.getAllTimeline(date_min, date_max),
  message: ['fetch-failure', 'helium apps timeline'],
});

const save = Actions.create({
  type: terms.SAVE_APP,
  args: ['heliumApp'],
  awaits: (heliumApp) => Api.update(heliumApp),
  message: ['save', 'helium app'],
});

const saveApp = (heliumApp, name, value) => (dispatch, getState) => {
  dispatch(editApp(heliumApp.id, name, value));
  //TODO: update this to use correct state
  const updatedHeliumApp = getState().apps.entities.find(({ id }) => id === heliumApp.id);
  dispatch(save(updatedHeliumApp));
};

const createAppCredentials = (appId, source, networkType) => (dispatch, getState) => {
  const local = Actions.create({
    type: terms.ADD_CREDENTIAL,
    args: ['appId', 'source', 'networkType'],
  });

  const async = Actions.create({
    type: terms.SAVE_CREDENTIALS,
    args: ['appId', 'credentials'],
    awaits: Api.saveCredentials,
  });

  dispatch(local(appId, source, networkType));
  const app = Helpers.getApp(getState().apps.entities, appId);
  dispatch(async(appId, app.credentials));
};

const editAppCredentials = (appId, source, networkType) => (dispatch, getState) => {
  const local = Actions.create({
    type: terms.EDIT_CREDENTIAL,
    args: ['appId', 'source', 'networkType'],
  });

  const async = Actions.create({
    type: terms.SAVE_CREDENTIALS,
    args: ['appId', 'credentials'],
    awaits: Api.saveCredentials,
  });

  dispatch(local(appId, source, networkType));
  const app = Helpers.getApp(getState().apps.entities, appId);
  dispatch(async(appId, app.credentials));
};

const deleteAppCredentials = (appId, networkId, networkType) => (dispatch) => {
  const type = networkType === 'bidding' ? 0 : 1;

  const local = Actions.create({
    type: terms.REMOVE_CREDENTIAL,
    args: ['appId', 'networkId', 'networkType'],
  });

  const async = Actions.create({
    type: terms.SAVE_CREDENTIAL_REMOVAL,
    args: ['appId', 'networkState'],
    awaits: Api.deleteCredential,
  });

  dispatch(local(appId, networkId, networkType));
  dispatch(async(appId, { type: type, name: networkId }));
};

const toggleAppCredentials = (appId, networkId, networkType, value) => (dispatch, getState) => {
  const local = Actions.create({
    type: terms.TOGGLE_CREDENTIAL,
    args: ['appId', 'networkId', 'networkType', 'value'],
  });

  const async = Actions.create({
    type: terms.SAVE_CREDENTIALS,
    args: ['appId', 'credentials'],
    awaits: Api.saveCredentials,
  });

  dispatch(local(appId, networkId, networkType, value));
  const app = Helpers.getApp(getState().apps.entities, appId);
  dispatch(async(appId, app.credentials));
};

/* Sync Definitions */

const searchApps = Actions.create({ type: terms.SEARCH_APPS, args: ['value'] });

const setPage = Actions.create({ type: terms.SET_PAGE, args: ['value'] });

const editApp = Actions.create({ type: terms.EDIT_APP, args: ['appId', 'name', 'value'] });

const clearChanges = Actions.create({ type: terms.CLEAR_CHANGES });

const clearSearch = Actions.create({ type: terms.CLEAR_SEARCH });

const setDateFilter = Actions.create({
  type: terms.SET_DATE_FILTER,
  args: ['heliumApp', 'dateFilter'],
});

const selectApp = Actions.create({ type: terms.SELECT_APP, args: ['id'] });

/* Exports */

const AppActions = {
  ...terms,
  fetchApp,
  fetchApps,
  fetchAppsTimeline,
  save,
  selectApp,
  editApp,
  createAppCredentials,
  deleteAppCredentials,
  editAppCredentials,
  toggleAppCredentials,
  searchApps,
  setPage,
  saveApp,
  clearChanges,
  clearSearch,
  setDateFilter,
};

export default AppActions;
