import { formatDollars, formatPercent } from 'utils/helpers';

const yAxisFormatter = ({ ...options }) => {
  let baseValue = '';
  let { view, tick } = options;
  tick = parseFloat(tick).toFixed(2);
  if (tick >= 1000000000) {
    baseValue = (tick / 1000000000).toString() + 'B';
  } else if (tick >= 1000000) {
    baseValue = (tick / 1000000).toString() + 'M';
  } else if (tick >= 1000) {
    baseValue = (tick / 1000).toString() + 'K';
  } else {
    baseValue = tick.toString();
  }
  if (view === 'earnings' || view === 'ecpm') {
    baseValue = `$${baseValue}`;
  }
  if (view === 'fill_rate' || view === 'win_rate' || view == 'show_rate') {
    return `${tick * 100}%`;
  }
  if (baseValue.slice(-3) === '.00') {
    baseValue = baseValue.slice(0, -3);
  }
  return baseValue;
};

const xAxisFormatter = (date) => {
  if (typeof date === 'string' && date.length === 10) {
    let [_year, month, day] = date.split('-');
    if (['10', '11', '12'].some((str) => str === month)) {
      return `${month}/${day}`;
    }
    return `${month[1]}/${day}`;
  }
  return date;
};

const formatValue = ({ value, selectedView }) => {
  if (value !== undefined) {
    if (['earnings', 'ecpm'].includes(selectedView)) {
      value = formatDollars(value);
    } else if (['show_rate', 'win_rate', 'fill_rate', 'ctr'].includes(selectedView)) {
      value = formatPercent(value);
    } else {
      value = value.toLocaleString('en-US');
    }
  }
  return value;
};

const isCalculatedMetric = (selectedView) =>
  ['ecpm', 'win_rate', 'show_rate', 'fill_rate', 'ctr'].includes(selectedView);

const Helpers = {
  formatValue,
  isCalculatedMetric,
  yAxisFormatter,
  xAxisFormatter,
};

export default Helpers;
