import { LOADED, LOADING, UNLOADED } from 'core/app/constants/readyStates';
// import { setAuthToken } from 'core/framework/localStorage';
import { deleteAuthToken } from 'core/framework/localStorage';

import Actions from 'core/framework/actions';
import UserActions from 'core/user/actions';
import IntercomHelpers from 'utils/intercom';
import DatadogHelpers from 'utils/datadog';

const initState = {
  features: [],
  isLoggedIn: true,
  readyState: UNLOADED,
  roles: [],
  settings: {},
  acceptedTerms: true,
  wasShownTerms: false,
  user_id: null,
  isBetaTestAccount: null,
  isTestAccount: null,
  company_id: null,
};

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    // Login / Logout

    case Actions.started(UserActions.LOGIN):
      return { ...state, isLoggedIn: false, loginError: false, readyState: UNLOADED };

    case Actions.success(UserActions.LOGIN):
      return { ...state, isLoggedIn: true, loginError: false };

    case Actions.failure(UserActions.LOGIN):
      return { ...state, isLoggedIn: false, loginError: true };

    case Actions.success(UserActions.LOGOUT):
      IntercomHelpers.shutdown();
      DatadogHelpers.stopSessionReplay();
      deleteAuthToken();
      return { ...initState, isLoggedIn: false, loginError: false };

    // Fetch terms

    case Actions.success(UserActions.LOGOUT):
      deleteAuthToken();
      return { ...initState, isLoggedIn: false, loginError: false };

    case Actions.failure(UserActions.LOGOUT):
      deleteAuthToken();
      return { ...initState, isLoggedIn: false, loginError: false };

    case Actions.started(UserActions.FETCH_TERMS):
      return { ...state, readyState: LOADING, wasShownTerms: true };

    case Actions.success(UserActions.FETCH_TERMS):
      let accepted = false;
      let user_id = null;
      let company_id = null;
      if (action.payload && action.payload.response) {
        accepted = action.payload.response.accepted;
        user_id = action.payload.response.user_id;
        company_id = action.payload.response.company_id;
      }

      if (user_id !== null && accepted) {
        IntercomHelpers.boot({ user_id });
        DatadogHelpers.boot({ sampleRate: 100 });
        DatadogHelpers.setUser({ id: user_id, company_id });
        DatadogHelpers.recordSessionReplay();
      }

      return {
        ...state,
        readyState: LOADED,
        acceptedTerms: accepted,
        wasShownTerms: true,
        user_id,
        company_id,
      };

    case Actions.failure(UserActions.FETCH_TERMS):
      return { ...state, readyState: LOADED, wasShownTerms: true };

    // Beta Test Account

    case Actions.started(UserActions.FETCH_IS_BETA_TEST_ACCOUNT):
      return { ...state, readyState: LOADING };

    case Actions.success(UserActions.FETCH_IS_BETA_TEST_ACCOUNT):
      let isBetaCompany = null;
      if (action.payload && action.payload.response) isBetaCompany = action.payload.response.data;
      return { ...state, readyState: LOADED, isBetaTestAccount: isBetaCompany };

    case Actions.failure(UserActions.FETCH_IS_BETA_TEST_ACCOUNT):
      return { ...state, readyState: LOADED, isBetaTestAccount: false };

    // Test Account

    case Actions.started(UserActions.FETCH_IS_TEST_ACCOUNT):
      return { ...state, readyState: LOADING };

    case Actions.success(UserActions.FETCH_IS_TEST_ACCOUNT):
      let isTestCompany = null;
      if (action.payload && action.payload.response) isTestCompany = action.payload.response.data;
      return { ...state, readyState: LOADED, isTestAccount: isTestCompany };

    case Actions.failure(UserActions.FETCH_IS_TEST_ACCOUNT):
      return { ...state, readyState: LOADED, isTestAccount: false };

    default:
      return state;
  }
};

export default reducer;
