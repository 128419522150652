import Actions from 'core/framework/actions';
import { LOADED, LOADING, UNLOADED } from 'core/app/constants/readyStates';
import { SAVE_DOMAIN_BLOCKING } from 'settings/actions';
import Utils from 'core/framework/utils';

import AppActions from './actions';
import Helpers from './helpers';
import NetworkHelpers from '../network/helpers';
import CommonHelpers from 'analytics/common/helpers';
import Format from 'analytics/common/format';

const initState = () => ({
  ids: {},
  entities: [],
  newApp: {},
  newPlacement: false,
  newPlacementId: false,
  newLineItem: {},
  newAdGroup: {},
  shouldTransitionAdGroup: false,
  selected: false,
  selectedAdGroup: false,
  selectedLineItem: false,
  redirectToAB: false,
  readyState: UNLOADED,
  madeChanges: false,
  searchTerm: '',
  pagination: {
    total: 0,
    active: 0,
  },
  dateFilter: {
    min: Format.date(CommonHelpers.timePeriod.week),
    max: Format.date(CommonHelpers.timePeriod.today),
    type: 'last-7-days',
  },
  placementCredState: UNLOADED,
  placementLineItemState: UNLOADED,
  appCredState: UNLOADED,
  adgroupsState: UNLOADED,
  lineItemsState: UNLOADED,
  placementErrors: [],
  callbackErrors: [],
});

/***************************************
 *       Setters for Helium Apps
 ****************************************/

const setApps = (state, apps, overrides = {}) => {
  // If 1 app has been fetched, set it and populate the rest after
  let appCopy = apps ? [...apps] : [];

  if (state.entities.length > 0) {
    const alreadyFetchedApp = state.entities[0];
    appCopy = appCopy.map((app) => (alreadyFetchedApp.id === app.id ? alreadyFetchedApp : app));
  }
  const _apps = Helpers.toUIApps(appCopy) || [];
  const _copy = Helpers.toUIApps(appCopy) || [];

  let selected = state.selected;
  if (state.selected === false && _apps && _apps.length > 0) {
    selected = appCopy[0].id;
  }
  return {
    ...state,
    entities: _apps,
    unfiltered: _copy,
    pagination: Helpers.getPagination(_apps),
    ids: Helpers.getAppIds(_apps),
    searchTerm: '',
    readyState: LOADED,
    selected: selected,
    ...overrides,
  };
};

const setAppsTimeline = (state, payload) => {
  return {
    ...state,
    timelineMetrics: payload,
    readyState: LOADED,
  };
};

const setApp = (state, payload) => {
  return {
    ...state,
    entities: Helpers.toUIApps(Helpers.addOrReplaceApp(state.entities, payload.response)),
    madeChanges: false,
    readyState: UNLOADED,
  };
};

const selectApp = (state, { id }) => ({
  ...state,
  selected: id,
  placementsReadyState: UNLOADED,
  adgroupsState: UNLOADED,
  lineItemsState: UNLOADED,
});

const setField = (state, { appId, name, value }) => {
  const app = Helpers.getApp(state.entities, appId);
  app[name] = value;
  return {
    ...state,
    madeChanges: true,
    entities: state.entities.map((a) => a),
  };
};

const setAdomain = (state, { response, appId }) => ({
  ...state,
  madeChanges: false,
  entities: Helpers.findAndUpdateFields(state.entities, appId, {
    blocked_adomains: response[appId],
  }),
});

const search = (state, { value }) => {
  let unfiltered = state.unfiltered || [];
  let filtered = unfiltered.filter((a) => Utils.matchString(a.keywords, value));
  let overrides = { unfiltered: unfiltered, searchTerm: value };
  if (value === '') filtered = unfiltered;
  return setApps(state, filtered, overrides);
};

const clearSearch = (state) => setApps(state, state.unfiltered);

const clearChanges = (state) => ({
  ...state,
  madeChanges: false,
  placementErrors: [],
  callbackErrors: [],
});

const setImportedApp = (state, { response, chartboostId }) => {
  const newApps = Helpers.addOrReplaceApp(state.entities, response);
  return {
    ...state,
    chartboost: {
      ...state.chartboost,
      imported: chartboostId,
      importState: LOADED,
    },
    entities: Helpers.toUIApps(newApps),
    ids: Helpers.getAppIds(newApps),
    readyState: UNLOADED,
    placementsReadyState: UNLOADED,
  };
};

const clearImportedApp = (state) => {
  return {
    ...state,
    chartboost: {
      ...state.chartboost,
      imported: null,
      importState: LOADED,
    },
  };
};

const setDateFilter = (state, payload) => {
  const { dateFilter } = payload || {};
  const min = dateFilter.min || CommonHelpers.timePeriod.week;
  const max = dateFilter.max || CommonHelpers.timePeriod.today;
  return { ...state, dateFilter: { min, max, type: dateFilter.type } };
};

const FETCH_APP_SUCCESS = (state, payload) => {
  const app = (payload && payload.response) || {};
  const appCopy = { ...app };
  if (state.entities.length) {
    // Set current app crendentials to entities array
    const currentApp = state.entities.filter((entityApp) => entityApp.id === app.id)[0];
    currentApp.max_queue_size = app.max_queue_size;
    currentApp.default_queue_size = app.default_queue_size;

    // Delete current empty credentials and replace with fetched app creds
    delete currentApp.credentials;
    currentApp.credentials = app.credentials;

    // Set default catch all adgroups from fetched app request
    currentApp.default_banner_adgroup = app.default_banner_adgroup;
    currentApp.default_interstitial_adgroup = app.default_interstitial_adgroup;
    currentApp.default_rewarded_adgroup = app.default_rewarded_adgroup;
    currentApp.default_rewarded_interstitial_adgroup = app.default_rewarded_interstitial_adgroup;
    currentApp.default_adaptive_banner_adgroup = app.default_adaptive_banner_adgroup;
  } else if (state.entities.length === 0) {
    // Set app in the entities for quicker loading of data
    state.entities = [...state.entities, appCopy];
  }
  // Cause of multiple refetches throughout the dashboard
  // TODO: Check if this is still neccessary
  // placementsReadyState = Helpers.getChildReadyState(app, 'placements', placementsReadyState);
  // adgroupsState = Helpers.getChildReadyState(app, 'adgroups', adgroupsState);
  // lineItemsState = Helpers.getChildReadyState(app, 'line_items', lineItemsState);

  return { ...state };
};

/***********************************************
 *                CREDENTIALS
 ***********************************************/

const removeCredential = (state, { appId, networkId, networkType }) => {
  const type = networkType === 'bidding' ? 0 : 1;
  const app = Helpers.getApp(state.entities, appId);
  const networkStates = Helpers.getNetworkStates(app);
  app.credentials.network_states = networkStates.filter(
    (n) => !(n.name === networkId && n.type === type)
  );
  return {
    ...state,
    madeChanges: true,
    entities: Helpers.addOrReplaceApp(state.entities, app),
  };
};

const addCredential = (state, { appId, source, networkType }) => {
  const app = Helpers.getApp(state.entities, appId);
  const credential = NetworkHelpers.toApiCredentials(source);
  const networkState = Helpers.getNetworkState(app, source.id, networkType);
  app.credentials.creds[source.id] = credential;
  if (!networkState) {
    app.credentials.network_states.push({
      type: networkType === 'bidding' ? 0 : 1,
      name: source.id,
      active: true,
    });
  }
  return {
    ...state,
    madeChanges: true,
    entities: Helpers.addOrReplaceApp(state.entities, app),
  };
};

const editCredential = (state, payload) => {
  const { appId, source, networkType } = payload;
  const app = Helpers.getApp(state.entities, appId);
  const credential = NetworkHelpers.toApiCredentials(source);
  const networkState = Helpers.getNetworkState(app, source.id, networkType);
  app.credentials.creds[source.id] = credential;
  if (!networkState) {
    app.credentials.network_states.push({
      type: networkType === 'bidding' ? 0 : 1,
      name: source.id,
      active: true,
    });
  }
  return {
    ...state,
    madeChanges: true,
    entities: Helpers.addOrReplaceApp(state.entities, app),
  };
};

const toggleCredential = (state, payload) => {
  const { appId, networkId, networkType, value } = payload;
  const app = Helpers.getApp(state.entities, appId);
  const networkState = Helpers.getNetworkState(app, networkId, networkType);
  if (networkState) {
    networkState.active = value;
  }
  return {
    ...state,
    madeChanges: true,
    entities: Helpers.addOrReplaceApp(state.entities, app),
  };
};

const apply = (state, type, payload) => {
  switch (type) {
    /*********************
         FETCH_APPS
    **********************/

    case Actions.started(AppActions.FETCH_APPS):
      return { ...state, readyState: LOADING };

    case Actions.failure(AppActions.FETCH_APPS):
      return { ...state, readyState: LOADED };

    case Actions.success(AppActions.FETCH_APPS):
      return setApps(state, payload.response.apps);

    /*********************
         FETCH_APPS_TIMELINE
    **********************/

    case Actions.started(AppActions.FETCH_APPS_TIMELINE):
      return { ...state, readyState: LOADING };

    case Actions.failure(AppActions.FETCH_APPS_TIMELINE):
      return { ...state, readyState: LOADED };

    case Actions.success(AppActions.FETCH_APPS_TIMELINE):
      return setAppsTimeline(state, payload.response.data);

    /*********************
         FETCH_APP
    **********************/

    case Actions.success(AppActions.FETCH_APP):
      return FETCH_APP_SUCCESS(state, payload);

    /*********************
       SAVE_APP
    **********************/

    case Actions.started(AppActions.SAVE_APP):
      return { ...state, readyState: LOADING };

    case Actions.failure(AppActions.SAVE_APP):
      return { ...state, readyState: LOADED };

    case Actions.success(AppActions.SAVE_APP):
      return setApp(state, payload);

    /***************************
     *      SYNCH HANDLERS
     ***************************/

    case AppActions.SET_PAGE:
      let hpag = state.pagination;
      return { ...state, pagination: { total: hpag.total, active: payload.value } };

    case AppActions.SEARCH_APPS:
      return search(state, payload);

    case AppActions.SET_DATE_FILTER:
      return setDateFilter(state, payload);

    case AppActions.CLEAR_SEARCH:
      return clearSearch(state, payload);

    case AppActions.EDIT_APP:
      return setField(state, payload);

    case AppActions.CLEAR_CHANGES:
      return clearChanges(state, payload);

    case AppActions.REMOVE_CREDENTIAL:
      return removeCredential(state, payload);

    case AppActions.TOGGLE_CREDENTIAL:
      return toggleCredential(state, payload);

    case AppActions.ADD_CREDENTIAL:
      return addCredential(state, payload);

    case AppActions.EDIT_CREDENTIAL:
      return editCredential(state, payload);

    case AppActions.SELECT_APP:
      return selectApp(state, payload);

    /***************************
     *      SAVE ADOMAIN
     ***************************/

    case Actions.success(SAVE_DOMAIN_BLOCKING):
      return setAdomain(state, payload);

    default:
      return state;
  }
};

/**********************************************
 *                  EXPORTS
 ***********************************************/

const Reducer = {
  initState,
  apply,
  setImportedApp,
  clearImportedApp,
  setApps,
  setDateFilter,
};

export default Reducer;
