import Actions from 'core/framework/actions';
import { LOADED, LOADING, UNLOADED } from 'core/app/constants/readyStates';

import {
  DELETE_DOMAIN_BLOCKING,
  DELETE_REPORT,
  FETCH_ADOMAIN_REPORTS,
  FETCH_BLOCKED_ADOMAINS,
  FETCH_RESERVED_KEYWORDS,
  FETCH_KEYWORDS,
  CREATE_KEYWORD,
  DELETE_KEYWORD,
  EDIT_KEYWORD,
  FETCH_COMPANY,
  SAVE_DOMAIN_BLOCKING,
  SUBMIT_REPORT,
  RESET_REWARDED_SECRET,
} from './actions';

import AppActions from 'apps/app/actions';
import Utils from 'core/framework/utils';
import { parseBlocking } from './helpers';

export const initState = {
  appList: [],
  blockedAdomains: [],
  blockedAdomainsState: UNLOADED,
  domainList: [],
  company: { rewardedSecretKey: '' },
  companyState: UNLOADED,
  domainList: [],
  entities: [],
  keywords: {
    reserved: {},
    custom: {},
  },
  keywordsState: UNLOADED,
  newReport: { emails: [] },
  readyState: UNLOADED,
  reports: [],
  reportsState: UNLOADED,
  savingState: LOADED,
  submitted: false,
};

const setCompany = (state, payload) => {
  return {
    ...state,
    companyState: LOADED,
    company: {
      rewardedSecretKey: payload.rewarded_secret_key,
    },
  };
};

export default (state = initState, { payload, type }) => {
  switch (type) {
    case Actions.started(DELETE_DOMAIN_BLOCKING):
    case Actions.started(SAVE_DOMAIN_BLOCKING):
      return { ...state, savingState: LOADING };

    case Actions.success(DELETE_DOMAIN_BLOCKING): {
      return {
        ...state,
        entities: state.entities.filter(({ id }) => id !== payload.appId),
        savingState: UNLOADED,
        blockedAdomainsState: UNLOADED,
      };
    }

    case Actions.success(SAVE_DOMAIN_BLOCKING): {
      return {
        ...state,
        savingState: LOADED,
        blockedAdomainsState: UNLOADED,
      };
    }

    case Actions.failure(DELETE_DOMAIN_BLOCKING):
    case Actions.failure(SAVE_DOMAIN_BLOCKING):
      return { ...state, savingState: LOADED };

    case Actions.started(FETCH_BLOCKED_ADOMAINS):
      return { ...state, readyState: LOADING };

    case Actions.started(FETCH_ADOMAIN_REPORTS):
      return { ...state, reportsState: LOADING };

    case Actions.success(FETCH_BLOCKED_ADOMAINS):
      const adomainList = payload?.response?.adomains || [];
      const domainSet = new Set();

      adomainList.forEach((app) => {
        const domains = app.adomains.split(',');
        domains.forEach((domain) => {
          domainSet.add(domain);
        });
      });

      // Populate the admoain list with existing adomain options
      const domainListToOption = Array.from(domainSet).map((domain) => ({
        key: domain,
        value: domain,
        text: domain,
      }));

      return {
        ...state,
        blockedAdomains: payload?.response?.adomains,
        domainList: [...domainListToOption],
        readyState: LOADED,
      };

    case Actions.success(FETCH_ADOMAIN_REPORTS):
      return {
        ...state,
        reports: payload.response.adomainreports,
        reportsState: LOADED,
      };

    case Actions.success(AppActions.SAVE_APP): {
      const {
        response: { id: appId, name, nickname },
      } = payload;

      return {
        ...state,
        appList: state.appList.map((heliumApp) => {
          if (heliumApp.id !== appId) {
            return heliumApp;
          }
          return { ...heliumApp, text: name || nickname };
        }),
        entities: state.entities.map((heliumApp) => {
          if (heliumApp.id !== appId) {
            return heliumApp;
          }
          return { ...heliumApp, name: name || nickname };
        }),
      };
    }

    case Actions.success(AppActions.IMPORT_CHARTBOOST_APP): {
      const { id, name, nickname } = payload.response;

      const existingHeliumApp = state.appList.find(({ id: appId }) => appId === id);
      const newHeliumApp = { id, value: id, text: name || nickname };

      return {
        ...state,
        appList: existingHeliumApp
          ? state.appList.map((heliumApp) =>
              heliumApp.id === id ? { ...heliumApp, ...newHeliumApp } : heliumApp
            )
          : [...state.appList, newHeliumApp],
      };
    }

    case Actions.success(AppActions.FETCH_APPS):
      let allApps = [];
      if (Utils.isNonZeroArray(payload.response.apps)) {
        allApps = payload.response.apps.map((heliumApp) => parseBlocking(heliumApp));
      }
      return {
        ...state,
        appList: allApps.map(({ id, text, value }) => ({ id, text, value })),
        entities: allApps.filter(({ blocked_adomains }) => blocked_adomains.length),
        readyState: LOADED,
      };

    case Actions.failure(FETCH_BLOCKED_ADOMAINS):
      return { ...state, readyState: LOADED };

    case Actions.failure(FETCH_ADOMAIN_REPORTS):
      return { ...state, reportsState: LOADED };

    case Actions.started(SUBMIT_REPORT):
      return {
        ...state,
        newReport: { emails: [] },
        submitted: true,
      };

    case Actions.success(SUBMIT_REPORT):
      return {
        ...state,
        reportsState: UNLOADED,
      };

    case Actions.started(DELETE_REPORT):
      return {
        ...state,
        reportsState: LOADING,
      };

    case Actions.success(DELETE_REPORT):
      return {
        ...state,
        reportsState: UNLOADED,
      };

    case Actions.failure(DELETE_REPORT):
      return {
        ...state,
        reportsState: LOADED,
      };

    case Actions.started(FETCH_COMPANY):
      return { ...state, companyState: LOADING };

    case Actions.failure(FETCH_COMPANY):
      return { ...state, companyState: LOADED };

    case Actions.success(FETCH_COMPANY):
      return setCompany(state, payload);

    case Actions.started(RESET_REWARDED_SECRET):
      return { ...state, companyState: LOADING };

    case Actions.success(RESET_REWARDED_SECRET):
      return { ...state, companyState: UNLOADED };

    case Actions.failure(RESET_REWARDED_SECRET):
      return { ...state, companyState: LOADED };

    case Actions.started(FETCH_RESERVED_KEYWORDS):
      return { ...state, keywordsState: LOADING };

    case Actions.failure(FETCH_RESERVED_KEYWORDS):
      return { ...state, keywordsState: LOADED };

    case Actions.success(FETCH_RESERVED_KEYWORDS):
      return {
        ...state,
        keywordsState: LOADED,
        keywords: {
          ...state.keywords,
          reserved: payload,
        },
      };

    case Actions.started(FETCH_KEYWORDS):
      return { ...state, keywordsState: LOADING };

    case Actions.failure(FETCH_KEYWORDS):
      return { ...state, keywordsState: LOADED };

    case Actions.success(FETCH_KEYWORDS):
      return {
        ...state,
        keywordsState: LOADED,
        keywords: {
          ...state.keywords,
          custom: payload,
        },
      };

    case Actions.started(CREATE_KEYWORD):
      return { ...state, keywordsState: LOADING };

    case Actions.failure(CREATE_KEYWORD):
      return { ...state, keywordsState: LOADED };

    case Actions.success(CREATE_KEYWORD):
      const keywordText = payload.key;
      const updatedKeywords = { ...state.keywords.custom, [keywordText]: payload };
      return {
        ...state,
        keywordsState: LOADED,
        keywords: {
          ...state.keywords,
          custom: updatedKeywords,
        },
      };

    case Actions.started(DELETE_KEYWORD):
      return { ...state, keywordsState: LOADING };

    case Actions.failure(DELETE_KEYWORD):
      return { ...state, keywordsState: LOADED };

    case Actions.success(DELETE_KEYWORD):
      const deletedKeyword = payload.key;
      const keywordsAfterDelete = { ...state.keywords.custom };
      delete keywordsAfterDelete[deletedKeyword];
      return {
        ...state,
        keywordsState: LOADED,
        keywords: {
          ...state.keywords,
          custom: keywordsAfterDelete,
        },
      };

    case Actions.started(EDIT_KEYWORD):
      return { ...state, keywordsState: LOADING };

    case Actions.failure(EDIT_KEYWORD):
      return { ...state, keywordsState: LOADED };

    case Actions.success(EDIT_KEYWORD):
      const editedKeyword = payload.key;
      const keywordCopy = { ...state.keywords.custom };

      const getKeyword = (uuid) => {
        const keys = Object.keys(keywordCopy);
        for (let i = 0; i < keys.length; i++) {
          const keyword = keys[i];
          if (keywordCopy[keyword].uuid === uuid) {
            return keyword;
          }
        }
      };

      const oldKeyword = getKeyword(payload.uuid);
      delete keywordCopy[oldKeyword];
      keywordCopy[editedKeyword] = payload;
      return {
        ...state,
        keywordsState: LOADED,
        keywords: {
          ...state.keywords,
          custom: keywordCopy,
        },
      };

    default:
      return state;
  }
};

const getAdvancedSettings = (state) => state.settings;

export const getSettingsLoading = (state) => getAdvancedSettings(state).readyState === LOADING;

export const getAdomainLoading = (state) => getAdvancedSettings(state).reportsState === LOADING;

export const getAppList = (state) => getAdvancedSettings(state).appList;

export const getDomainList = (state) => getAdvancedSettings(state).domainList;

export const getFilteredBlockedDomains = (state) => getAdvancedSettings(state).blockedAdomains;

export const getShouldFetchBlockedDomains = (state) =>
  getAdvancedSettings(state).readyState === UNLOADED ||
  getAdvancedSettings(state).blockedAdomainsState === UNLOADED;

export const getShouldFetchAdomainReports = (state) =>
  getAdvancedSettings(state).reportsState === UNLOADED;

export const getAdomainReports = (state) => getAdvancedSettings(state).reports;

export const getNewReport = (state) => getAdvancedSettings(state).newReport;

export const getSubmission = (state) => getAdvancedSettings(state).submitted;

export const getKeywordsLoading = (state) => getAdvancedSettings(state).keywordsState === LOADING;

export const getShouldFetchKeywords = (state) =>
  getAdvancedSettings(state).keywordsState === UNLOADED;

export const getReservedKeywords = (state) => getAdvancedSettings(state).keywords.reserved;

export const getCustomKeywords = (state) => getAdvancedSettings(state).keywords.custom;
