import React from 'react';
import { Button } from 'semantic-ui-react';
import Predicate from './Predicate';
import PredicatePlaceholder from './PredicatePlaceholder';
import DeleteRuleButton from './DeleteRuleButton';
import ToggleRule from './ToggleRule';
import Processors from '../helpers/processors';

const TargetingSingleRule = ({
  addingPredicateId,
  editingPredicateId,
  handleDeletePredicate,
  handleDeleteRule,
  handleNegateRule,
  isEditingPredicate,
  isAddingPredicate,
  keyword,
  operation,
  reservedKeywords,
  rule,
  selectedRuleId,
  toggleAddPredicate,
  toggleEditPredicate,
  value,
}) => {
  const { predicates, is_negated: isNegated } = rule;
  const isKeywordReserved = reservedKeywords[keyword] !== undefined;
  const shouldDisplayAddingPredicate =
    (addingPredicateId && addingPredicateId === rule.uuid) ||
    (addingPredicateId === undefined && rule.uuid === undefined);
  const shouldDisableActions = isAddingPredicate || isEditingPredicate;

  const reserveKeywordDisplayMap = Processors.createKeywordDisplayMap(reservedKeywords);
  return (
    <div className={`targeting-rules-single-container${isNegated ? '-negated' : ''}`}>
      <ToggleRule
        handleNegateRule={handleNegateRule}
        isNegated={isNegated}
        rule={rule}
        shouldDisableActions={shouldDisableActions}
      />
      <div className="targeting-rules-predicates-group">
        {predicates &&
          predicates.map((predicate) => (
            <Predicate
              editingPredicateId={editingPredicateId}
              handleDeletePredicate={handleDeletePredicate}
              isEditingPredicate={isEditingPredicate}
              key={predicate.uuid}
              predicate={predicate}
              ruleId={rule.uuid}
              selectedRuleId={selectedRuleId}
              shouldDisableActions={shouldDisableActions}
              toggleEditPredicate={toggleEditPredicate}
              reserveKeywordDisplayMap={reserveKeywordDisplayMap}
            />
          ))}
        {shouldDisplayAddingPredicate && (
          <PredicatePlaceholder
            isReserved={isKeywordReserved}
            reserveKeywordDisplayMap={reserveKeywordDisplayMap}
            keyword={keyword}
            operation={operation}
            value={value}
          />
        )}
      </div>
      <div className="predicate-options-container">
        <Button
          className="positive"
          disabled={shouldDisableActions}
          onClick={() => toggleAddPredicate(true, rule.uuid)}
        >
          Add Predicate
        </Button>

        <DeleteRuleButton
          handleDeleteRule={handleDeleteRule}
          rule={rule}
          shouldDisableActions={shouldDisableActions}
        />
      </div>
    </div>
  );
};

export default TargetingSingleRule;
