import Actions from 'core/framework/actions';
import { LOADED, LOADING, UNLOADED } from 'core/app/constants/readyStates';

import AdGroupActions from './actions';
import AppHelpers from '../app/helpers';
import Helpers from './helpers';

const updateState = ({ state, app, changes }) => {
  return {
    ...state,
    madeChanges: false || changes === true,
    entities: AppHelpers.addOrReplaceApp(state.entities, app)
  };
};

const setAdGroups = (state, { appId, response }) => {
  const app = AppHelpers.getApp(state.entities, appId);
  state.adgroupsState = LOADED;
  if (app) {
    app.adgroups = Helpers.toUIAdGroup(response.adgroups);
    return updateState({ state, app, changes: false });
  }
  return { ...state, madeChanges: false };
};

const removeAdgroupPlacement = (state, { appId, response, placementId }) => {
  const removedAdgroupId = response.id;
  state.adgroupsState = LOADED;
  const newState = Helpers.removeAdgroupPlacementConnection(
    state,
    appId,
    placementId,
    removedAdgroupId
  );
  return { ...newState, adgroupsState: UNLOADED, madeChanges: true };
};

const removePlacementLocal = (state, { appId, placementId, adgroup }) => {
  const adgroupId = adgroup.id;
  state.adgroupsState = LOADED;
  const newState = Helpers.removeAdgroupPlacementConnection(
    state,
    appId,
    placementId,
    adgroupId,
    true
  );
  return { ...newState, adgroupsState: UNLOADED, madeChanges: true };
};

const selectAdGroup = (state, { id }) => ({
  ...state,
  selectedAdGroup: id
});

const create = (state, { placement }) => ({
  ...state,
  newAdGroup: {
    id: '__new__',
    name: '',
    type: placement ? placement.type : 'interstitial',
    inclusion_type: 'SOME',
    countries: [],
    placements: placement && placement.id ? [placement.id] : [],
    line_items: []
  }
});

const findAppAndAdGroup = (state, payload) => {
  const app = AppHelpers.getApp(state.entities, payload.appId);
  const adgroup =
    payload.agId === '__new__' ? state.newAdGroup : Helpers.find(app.adgroups, payload.agId);
  return { app, adgroup };
};

const setField = (state, payload) => {
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  adgroup[payload.name] = payload.value;
  return updateState({ state, app, changes: true });
};

const addCountry = (state, payload) => {
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  const index = adgroup.countries.indexOf(payload.country);
  if (index < 0) {
    adgroup.countries.push(payload.country);
  }
  return updateState({ state, app, changes: true });
};

const removeCountry = (state, payload) => {
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  const index = adgroup.countries.indexOf(payload.country);
  if (index > -1) {
    adgroup.countries.splice(index, 1);
  }
  return updateState({ state, app, changes: true });
};

const addPlacement = (state, payload) => {
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  const index = adgroup.placements.indexOf(payload.placementId);
  if (index < 0) {
    adgroup.placements.push(payload.placementId);
  }
  return updateState({ state, app, changes: true });
};

const removePlacement = (state, payload) => {
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  const index = adgroup.placements.indexOf(payload.placementId);
  if (index > -1) {
    adgroup.placements.splice(index, 1);
  }
  return updateState({ state, app, changes: true });
};

const addLineItem = (state, payload) => {
  const itemId = payload.itemId;
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  const idArray = Array.isArray(itemId) ? itemId : [itemId];
  for (let id of idArray) {
    const index = adgroup.line_items.indexOf(parseInt(id, 10));
    if (index < 0) {
      adgroup.line_items.push(parseInt(id, 10));
    }
  }
  state.selectedLineItem = false;
  return updateState({ state, app, changes: true });
};

const removeLineItem = (state, payload) => {
  const { app, adgroup } = findAppAndAdGroup(state, payload);
  const index = adgroup.line_items.indexOf(payload.itemId);
  if (index > -1) {
    adgroup.line_items.splice(index, 1);
  }
  return updateState({ state, app, changes: true });
};

const apply = (state, type, payload) => {
  switch (type) {
    case Actions.started(AdGroupActions.FETCH_ADGROUPS):
      return { ...state, adgroupsState: LOADING };

    case Actions.failure(AdGroupActions.FETCH_ADGROUPS):
      return { ...state, adgroupsState: LOADED };

    case Actions.success(AdGroupActions.FETCH_ADGROUPS):
      return setAdGroups(state, payload);

    case Actions.started(AdGroupActions.REMOVE_ADGROUP):
      return { ...state, adgroupsState: LOADING };

    case Actions.failure(AdGroupActions.REMOVE_ADGROUP):
      return { ...state, adgroupsState: LOADED };

    case Actions.success(AdGroupActions.REMOVE_ADGROUP):
      return { ...state, adgroupsState: UNLOADED };

    case AdGroupActions.SELECT_ADGROUP:
      return selectAdGroup(state, payload);

    case AdGroupActions.SET_ADGROUP_FIELD:
      return setField(state, payload);

    case AdGroupActions.ADD_ADGROUP_COUNTRY:
      return addCountry(state, payload);

    case AdGroupActions.REMOVE_ADGROUP_COUNTRY:
      return removeCountry(state, payload);

    case AdGroupActions.ADD_ADGROUP_PLACEMENT:
      return addPlacement(state, payload);

    case AdGroupActions.REMOVE_ADGROUP_PLACEMENT:
      return removePlacement(state, payload);

    case AdGroupActions.ADD_ADGROUP_LINEITEM:
      return addLineItem(state, payload);

    case AdGroupActions.REMOVE_ADGROUP_LINEITEM:
      return removeLineItem(state, payload);

    case AdGroupActions.UPDATE_ADGROUP_PLACEMENT_LOCAL:
      return removePlacementLocal(state, payload);

    case Actions.started(AdGroupActions.CREATE_ADGROUP):
    case Actions.started(AdGroupActions.UPDATE_ADGROUP):
      return { ...state, adgroupsState: LOADING };

    case Actions.failure(AdGroupActions.CREATE_ADGROUP):
    case Actions.failure(AdGroupActions.UPDATE_ADGROUP):
      return { ...state, adgroupsState: LOADED };

    case Actions.success(AdGroupActions.UPDATE_ADGROUP):
      return { ...state, adgroupsState: UNLOADED };

    case Actions.started(AdGroupActions.UPDATE_ADGROUP_PLACEMENT):
      return { ...state, adgroupsState: LOADING };

    case Actions.failure(AdGroupActions.UPDATE_ADGROUP_PLACEMENT):
      return { ...state, adgroupsState: LOADED };

    case Actions.success(AdGroupActions.UPDATE_ADGROUP_PLACEMENT):
      return removeAdgroupPlacement(state, payload);

    case Actions.success(AdGroupActions.CREATE_ADGROUP):
      return { ...state, shouldTransitionAdGroup: true, adgroupsState: UNLOADED };

    case AdGroupActions.CREATE_LOCAL_ADGROUP:
      return create(state, payload);

    case AdGroupActions.CLEAR_ADGROUP_TRANSITION:
      return { ...state, shouldTransitionAdGroup: false };

    default:
      return state;
  }
};

const AdgroupReducer = {
  apply
};

export default AdgroupReducer;
