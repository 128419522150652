/**
 * @namespace Network/Store
 */

import _ from 'lodash';
import AppHelpers from '../app/helpers';
import Constants from './constants';
import User from 'core/user';

const getState = (state) => state.apps.network;

/**
 * @function getSchema
 * @description returns all network schemas
 * @param {string} [type=null] - optional filter for bidding / mediation
 * @returns {NETWORK_SCHEMA} - Object containing network names as
 * keys and credentials schema as values
 * @memberof Network/Store
 */

const getSchema = (state, type) => (type ? getState(state)[type] : getState(state).schema);

/**
 * @function getShouldFetchSchema
 * @description returns true if schema needs to be fetched
 * @returns {boolean}
 * @memberof Network/Store
 */

const getShouldFetchSchema = (state) => Object.keys(getState(state).schema).length === 0;

/**
 * @function getNetworkSchema
 * @description returns a network's app or placement credentials schema
 * @param {string} networkId - string id of network ex "chartboost"
 * @param {string} [credentialType=null] - type of credentials to return "app" or "placement"
 * @returns {NETWORK_SCHEMA} - One network's schema
 * keys and credentials schema as values
 * @memberof Network/Store
 */

const getNetworkSchema = (state, networkId, credentialType) => {
  const schema = getSchema(state)[networkId];
  if (credentialType) {
    return schema[`${credentialType}_credentials`];
  }
  return schema;
};

/**
 * @function getNetworks
 * @description returns array of network names from state
 * @param {string} [type=null] - filter for network type
 * @returns {string[]} - array of network names
 * @memberof Network/Store
 */

const getNetworks = (state, type) => {
  let networks = [];
  const isBetaTestAccount = User.Store.isBetaTestAccount(state);
  if (!type) {
    return getState(state).names;
  }
  _.forIn(getSchema(state, type), (value, name) => {
    if (isBetaTestAccount) {
      networks.push(name);
    } else if (Constants.NON_SUPPORTED_SOURCES.indexOf(name) === -1) {
      networks.push(name);
    }
  });
  return networks;
};

const filterSupportedNetworkUsed = (supportedNetworks, usedNetworks) => {
  const filteredSupportedNetworks = supportedNetworks.filter((network) => {
    return !usedNetworks.includes(network.option);
  });
  return filteredSupportedNetworks;
};

const createMap = (supportList) => {
  return supportList.reduce((acc, cur) => {
    acc[cur.option] = cur.supported;
    return acc;
  }, {});
};

/**
 * @function getUnusedNetworks
 * @param {Object} state - react state
 * @param {APP} app - app to get credential options for
 * @param {string} [networkType=null] - optional filter by network type
 * @returns {string[]} - Array of unused network names
 * @memberof Network/Store
 */

const getUnusedNetworks = (state, app = {}, networkType) => {
  const { bidding, waterfall, bidding_supported, waterfall_supported } =
    getSupportedAppPartners(state);

  let supportedNetworks = networkType === 'bidding' ? bidding : waterfall;

  let used = AppHelpers.getNetworkStates(app, networkType).map((n) => n.name);
  let unused = _.difference(supportedNetworks, used);
  let supported_bid = [];
  let supported_water = [];

  if (networkType === 'bidding') {
    supported_bid = filterSupportedNetworkUsed(bidding_supported, used);
  } else if (networkType === 'mediation') {
    supported_water = filterSupportedNetworkUsed(waterfall_supported, used);
  }

  return {
    unused,
    bidding_supported: createMap(supported_bid),
    waterfall_supported: createMap(supported_water),
  };
};

/**
 * @function getUnusedNetworksForPlacement
 * @param {Object} state - react state
 * @param {APP} app - app that contains placement
 * @param {PLACEMENT} placement - placement to get networks for
 * @returns {string[]} - Array of unused network names
 * @memberof Network/Store
 */

const getUnusedNetworksForPlacement = (state, app = {}, placement = {}) => {
  // Refactor to allow setting supported waterfall partners
  const { bidding_supported } = getSupportedPlacementPartners(state);
  let used = Object.keys(placement.credentials || {});
  let available = AppHelpers.getActiveNetworks(app, 'bidding').map((n) => n.name);
  let unused = _.difference(available, used);
  let supported_bid = [];
  supported_bid = filterSupportedNetworkUsed(bidding_supported, used);

  return {
    unused,
    bidding_supported: createMap(supported_bid),
  };
};

const getSupportedAppPartners = (state) => {
  const supportedAppPartners = getState(state).supported_app_partners;
  return supportedAppPartners;
};

const getSupportedPlacementPartners = (state) => {
  const supportedPlacementPartners = getState(state).supported_placement_partners;
  return supportedPlacementPartners;
};

const getSupportedPlacementTypes = (state) => {
  return getState(state).supported_placement_types;
};

const Store = {
  getSchema,
  getNetworks,
  getNetworkSchema,
  getUnusedNetworks,
  getShouldFetchSchema,
  getUnusedNetworksForPlacement,
  getSupportedAppPartners,
  getSupportedPlacementTypes,
  getSupportedPlacementPartners,
};

export default Store;
