import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from 'core/routes';
import Store from 'core/framework/store.js';

import 'semantic-ui-css/semantic.min.css';
import 'core/app/css/header.css';
import 'core/app/css/sidebar.css';
import 'core/app/css/theme.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(createElement(Router, {}, createElement(Routes, { store: Store })));
