/**
 * @namespace Analytics/Helpers
 */

import { COUNTRY_MAP } from 'core/app/constants/helium';
import NetworkConstants from '../../apps/network/constants';
import Utils from 'core/framework/utils';
import Constants from '../performance/constants';
import { capitalize } from 'utils/helpers';

import TestAccountWarning from 'apps/network/views/TestAccountWarning';

const today = new Date();
let yesterday = new Date(today);
let week = new Date(today);

yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
week = new Date(yesterday.getTime() - 6 * 24 * 60 * 60 * 1000);

/* Calculated properties */

const calc_ecpm = (x, prefix) => {
  prefix = prefix || '';
  if (x[prefix + 'impressions'] === 0) return 0;
  return (x[prefix + 'earnings'] / x[prefix + 'impressions']) * 1000;
};

/* Survival Rate */

const calc_impression_show_rate = (x, prefix) => {
  prefix = prefix || '';
  if (x[prefix + 'winning_bids'] === 0) return 0;
  return (x[prefix + 'impressions'] / x[prefix + 'winning_bids']) * 100;
};

/* Bid Rate */

const calc_bid_rate = (x, prefix) => {
  prefix = prefix || '';
  if (x[prefix + 'requests'] === 0) return 0;
  return (x[prefix + 'valid_bids'] / x[prefix + 'requests']) * 100;
};

/* Win Rate */

const calc_win_rate = (x, prefix) => {
  prefix = prefix || '';
  if (x[prefix + 'valid_bids'] === 0) return 0;
  return (x[prefix + 'winning_bids'] / x[prefix + 'valid_bids']) * 100;
};

/* Fill Rate */

const calc_fill_rate = (x, prefix) => {
  prefix = prefix || '';
  if (x[prefix + 'requests'] === 0) return 0;
  return (x[prefix + 'impressions'] / x[prefix + 'requests']) * 100;
};

const calculateMetric = (x, metricName, prefix) => {
  switch (metricName) {
    case 'ecpm':
      return calc_ecpm(x, prefix);
    case 'impression_show_rate':
      return calc_impression_show_rate(x, prefix);
    case 'bid_rate':
      return calc_bid_rate(x, prefix);
    case 'win_rate':
      return calc_win_rate(x, prefix);
    case 'fill_rate':
      return calc_fill_rate(x, prefix);
    default:
      return 0;
  }
};

/**
 * @function sortDates
 * @description helper sort function that sorts array of date strings
 * @memberof Analytics/Helpers
 */

const sortDates = (previous, current) => {
  if (typeof previous === 'string' && typeof current === 'string') {
    return Date.parse(previous) - Date.parse(current);
  }
  return 0;
};

/**
 * @function increment
 * @description if the given key does not
 * exist, it is initialized with value val,
 * if it does exist it gets incremented by value val
 * the updated value is returned
 * @param {Object} obj
 * @param {string} key
 * @param {number} val
 * @returns {number} updated value
 * @memberof Analytics/Helpers
 */

const increment = (obj, key, val) => {
  if (obj[key] == null) {
    obj[key] = val;
  } else {
    obj[key] += val;
  }
  return val;
};

/**
 * @function sumAllItems
 * @description adds the totals for every metric of every item
 * @param {object} totals
 * @param {string[]} simpleMetrics array of metric names to add
 * @param {string[]} calculatedMetrics array of metric names to calculate
 * @memberof Analytics/Helpers
 */

const sumAllItems = (totals, simpleMetrics, calculatedMetrics) => {
  let all = {};
  for (let line of Object.keys(totals)) {
    for (let metric of simpleMetrics) {
      if (!all[metric]) {
        all[metric] = totals[line][metric];
      } else {
        all[metric] += totals[line][metric];
      }
    }
  }
  for (let metric of calculatedMetrics) {
    all[metric] = calculateMetric(all, metric);
  }
  totals.all = all;
  return totals;
};

/**
 * @function toLineChartData
 * @description takes raw API analytics data and converts
 * it to data that can be used to plot a line chart and
 * display an aggregate totals table with.
 * @param {ANALYTICS_DATA} data
 * @param {string} breakdown
 * @param {string} metric
 * @memberof Analytics/Helpers
 */

const toLineChartData = (data, breakdown, metric, network_type) => {
  breakdown = breakdown || 'app_id';

  if (data && !Utils.isNonZeroArray(data.data)) return false;

  const final = {};
  const dates = {};
  const totals = {};
  let dataPoint;
  let dailyAggregate;
  let item;

  let lines = Object.keys(data.indexes[breakdown]);
  const simpleMetrics = Constants.getMetrics(network_type, 'simple');
  const calculatedMetrics = Constants.getMetrics(network_type, 'calculated');

  for (let itemName of lines) {
    if (!totals[itemName]) {
      totals[itemName] = {
        color: Constants.CHART_COLORS[0],
      };
      item = totals[itemName];
    }

    for (let dataPointIndex of data.indexes[breakdown][itemName]) {
      dataPoint = data.data[dataPointIndex];
      dailyAggregate = dates[dataPoint.dt];

      if (!dailyAggregate) {
        dates[dataPoint.dt] = { dt: dataPoint.dt };
        dailyAggregate = dates[dataPoint.dt];
      }

      for (let metric_name of simpleMetrics) {
        const prefixed = `${itemName}__${metric_name}`;
        const dailyValue = increment(dailyAggregate, prefixed, dataPoint[metric_name]);
        increment(item, metric_name, dailyValue);
      }

      for (let metric_name of calculatedMetrics) {
        const prefixed = `${itemName}__${metric_name}`;
        const calculated = calculateMetric(dailyAggregate, metric_name, `${itemName}__`);
        dailyAggregate[prefixed] = calculated;
      }
    }

    for (let metric_name of calculatedMetrics) {
      item[metric_name] = calculateMetric(item, metric_name);
    }
  }

  let sorted = Object.keys(dates).sort(sortDates);
  final.lines = lines;
  final.points = sorted.map((d) => dates[d]);
  final.totals = sumAllItems(totals, simpleMetrics, calculatedMetrics);
  lines.sort((a, b) => final.totals[b][metric] - final.totals[a][metric]);
  return final;
};

/*
  update labels of demand partners
  for display in Analyitcs tables and Charts
*/

const sanitizeDemandPartnersForAnalytics = (values) => {
  if (values[Constants.REJECTED_KEY]) {
    values[Constants.REJECTED_KEY].label = 'No Network';
    values[Constants.REJECTED_KEY].info = Constants.MESSAGES.NO_NETWORK;
  }
  return values;
};

/**
 * @function toChartDictionary
 * @description takes array of strings and converts it
 * to json object with each string as key as such
 * "string_1": { value: "string_1", label: "String_1" }
 * @memberof Analytics/Helpers
 */

const toChartDictionary = (list) =>
  list.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        value: cur,
        label: capitalize(cur),
      },
    }),
    {}
  );

/**
 * @function getBreakdownItems
 * @description returns list of breakdown items found in the data
 * @memberof Analytics/Helpers
 */

const getBreakdownItems = (lines, breakdown, apps) => {
  let dictionary = {};
  let label_key;
  let value_key;

  if (breakdown === 'app_id') {
    dictionary = apps;
    label_key = 'name';
    value_key = 'id';
  } else if (breakdown === 'country') {
    dictionary = COUNTRY_MAP;
    label_key = 'text';
    value_key = 'value';
  } else if (breakdown === 'demand_partner') {
    dictionary = sanitizeDemandPartnersForAnalytics(toChartDictionary(lines));
    label_key = 'label';
    value_key = 'value';
  } else {
    dictionary = toChartDictionary(lines);
    label_key = 'label';
    value_key = 'value';
  }

  const list = lines.map((item, i) => {
    if (dictionary && dictionary[item]) {
      dictionary[item].color = Constants.CHART_COLORS[i % Constants.CHART_COLORS.length];
      const platform = breakdown === 'app_id' ? dictionary[item].platform : '';
      return {
        color: dictionary[item].color,
        label: dictionary[item][label_key],
        value: dictionary[item][value_key],
        info: dictionary[item].info,
        platform,
      };
    }
    return {};
  });

  return list;
};

const filterSources = ({ allItems, name, searchQuery }) => {
  const filterMap = {
    Apps: ['name', 'id'],
    Placements: ['name'],
    Sources: ['name'],
    Countries: ['text'],
    'Line Items': ['name'],
  };
  const availableKeys = filterMap[name] || [];
  return allItems.filter((item) => {
    const temp = availableKeys.map((key) => item[key] && item[key].toLowerCase()).join('');
    return temp.includes(searchQuery.toLowerCase());
  });
};

const moveArrayElementPosition = (array, fromIndex, toIndex) => {
  const element = array.splice(fromIndex, 1)[0];
  array.splice(toIndex, 0, element);
};

const formatTextAdtype = (option) => {
  const replacedUnderscore = option.split('_');
  const upperCaseWord = replacedUnderscore.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return upperCaseWord.join(' ');
};

const formatSupportedAdTypes = (options) => {
  if (options) {
    return options.map(({ option, supported }) => {
      const displayText = (
        <span>
          {formatTextAdtype(option)} {supported === false && <TestAccountWarning />}
        </span>
      );
      return {
        id: option,
        label: option,
        name: option,
        key: option,
        value: option,
        text: displayText,
      };
    });
  }
  return [];
};

const formatSupportedPartners = (options) => {
  if (options) {
    return options.map(({ option, supported }) => {
      const displayText = (
        <span>
          {NetworkConstants.NETWORK_LABELS[option]} {supported === false && <TestAccountWarning />}
        </span>
      );
      return {
        id: option,
        label: displayText,
        name: option,
        key: option,
        value: option,
        logo: NetworkConstants.NETWORK_LOGOS[option],
      };
    });
  }
  return [];
};

const CommonHelpers = {
  calc_ecpm,
  calc_impression_show_rate,
  calc_bid_rate,
  calc_win_rate,
  calc_fill_rate,
  calculateMetric,
  timePeriod: {
    week,
    today,
    yesterday,
  },
  toLineChartData,
  getBreakdownItems,
  filterSources,
  moveArrayElementPosition,
  formatSupportedAdTypes,
  formatSupportedPartners,
};

export default CommonHelpers;
