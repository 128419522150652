import React from 'react';
import { Icon, Modal, Button } from 'semantic-ui-react';

import Network from 'apps/network';
import Constants from '../../network/constants';
import PartnerPlacementDetails from './PartnerPlacementDetails';

import '../css/Selector.css';

const LineItemRows = ({
  cpm,
  id,
  name,
  network_id,
  partner_placement,
  first_look,
  partner_details,
  isSelected,
  updateField,
  isBetaTestAccount,
  openModal,
}) => {
  return (
    <tr
      className={isSelected ? 'lineItem-createTable-row selected' : 'lineItem-createTable-row'}
      key={name}
    >
      <td className="lineItem-createTable-network" key={`${name}-network`}>
        <div className="flex">
          <img
            className="lineItem-createTable-platform-icon"
            src={Constants.NETWORK_LOGOS[network_id] || Constants.NETWORK_LOGOS['chartboost']}
            key={`${name}-network-icon`}
          />
          <span>{Network.Helpers.getName(network_id)}</span>
        </div>
      </td>
      <td className="lineItem-createTable-name" key={`${name}-name`}>
        {name}
      </td>
      <td className="lineItem-createTable-partner" key={`${name}-partdetails`}>
        <PartnerPlacementDetails
          network={network_id}
          partnerDetails={partner_details}
          partnerPlacementName={partner_placement}
        />
      </td>
      {isBetaTestAccount && (
        <td className="lineItem-createTable-firstlook" key={`${name}-firstlook`}>
          <input
            className="lineItem-add-to-adgroup-first-look"
            type="checkbox"
            disabled
            checked={first_look}
          />
        </td>
      )}
      <td className="lineItem-createTable-cpm" key={`${name}-cpm`}>
        <div className="lineItem-createTable-cpm-details">
          <Icon />
          {cpm}
        </div>
      </td>
      <td className="lineItem-createTable-add" key={`${name}-add`}>
        <input
          className="lineItem-add-to-adgroup-button"
          type="checkbox"
          checked={isSelected}
          onClick={() => updateField(id)}
        />
      </td>
      <td className="lineItem-createTable-delete" key={`${name}-del`}>
        <Button
          size="tiny"
          onClick={() => {
            openModal(id, name);
          }}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

const LineItemFilterTable = (props) => {
  const {
    deleteLineItem,
    items,
    selectedLines,
    sortOptions,
    toggleSortOption,
    updateField,
    isBetaTestAccount,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [selectedForDelete, setToDelete] = React.useState({ id: '', name: '' });

  const handleOpenModal = (id, name) => {
    setOpen(true);
    setToDelete({ id: id, name: name });
  };
  const handleCloseDeleteLineItem = () => {
    setOpen(false);
    setToDelete({ id: '', name: '' });
  };

  const handleDeleteLineItem = () => {
    deleteLineItem(selectedForDelete.id);
    setOpen(false);
    setToDelete({ id: '', name: '' });
  };

  const sortIcon = (positition) => <Icon name={positition} />;

  return (
    <>
      <Modal onClose={() => handleCloseDeleteLineItem()} open={open} size="tiny">
        <Modal.Content>
          <Modal.Description>
            <h4>Delete {selectedForDelete?.name} ?</h4>
            <p>Are you sure you want to delete this line item?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button size="tiny" onClick={() => handleCloseDeleteLineItem()}>
            Cancel
          </Button>
          <Button size="tiny" color="red" onClick={() => handleDeleteLineItem()}>
            Delete Line Item
          </Button>
        </Modal.Actions>
      </Modal>
      <table className="lineItem-createTable">
        <tbody>
          <tr className="lineItem-createTable-header">
            <th className="lineItem-createTable-network">
              <button className="lineItem-createTable-sort">
                <span onClick={() => toggleSortOption('network_id')}>
                  Network {sortIcon(sortOptions.network_id)}
                </span>
              </button>
            </th>
            <th className="lineItem-createTable-name">
              <button className="lineItem-createTable-sort">
                <span onClick={() => toggleSortOption('name')}>
                  Name {sortIcon(sortOptions.name)}
                </span>
              </button>
            </th>
            <th className="lineItem-createTable-partner">
              <button className="lineItem-createTable-sort">
                <span onClick={() => toggleSortOption('partner_placement')}>
                  Partner Placement {sortIcon(sortOptions.partner_placement)}
                </span>
              </button>
            </th>
            {isBetaTestAccount && <th className="lineItem-createTable-firstlook">First Look</th>}
            <th className="lineItem-createTable-cpm">
              <button className="lineItem-createTable-sort">
                <span onClick={() => toggleSortOption('cpm')}>CPM {sortIcon(sortOptions.cpm)}</span>
              </button>
            </th>
            <th className="lineItem-createTable-add">Add to Ad Group</th>
            <th className="lineItem-createTable-delete">Delete</th>
          </tr>
          {items && items.length ? (
            items.map((item) => (
              <LineItemRows
                cpm={item.cpm}
                id={item.id}
                name={item.name}
                network_id={item.network_id}
                partner_placement={item.partner_placement}
                first_look={item.first_look}
                partner_details={item?.partner_details}
                isSelected={selectedLines[item?.id]}
                updateField={updateField}
                isBetaTestAccount={isBetaTestAccount}
                itemLength={items.length}
                openModal={(id) => {
                  handleOpenModal(id, item.name);
                }}
              />
            ))
          ) : (
            <tr>
              <td className="lineItem-createTable-none" colSpan="6">
                No line items
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default LineItemFilterTable;
