import React, { useState } from 'react';
import { Button, Dropdown, Menu, Radio, Popup, Icon } from 'semantic-ui-react';
import useComponentVisible from './UseComponentVisible';

import Constants from '../constants';
import { TOOLTIPS } from 'core/app/constants/helium';
import Format from '../format';
import DateRange from './DateRange';

const DateFilter = ({ date_key, date_from, date_to, onSelect, onApply }) => {
  let defaultTime = date_key || 'last-7-days';

  const [temp, setTemp] = useState({
    to: new Date(date_to),
    from: new Date(date_from),
    selected: Constants.DEFAULT_PERIODS.find((i) => i.key === defaultTime),
  });

  const handleOutsideClick = () => {
    setTo(temp.to);
    setFrom(temp.from);
    setSelected(temp.selected);
  };

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(
    false,
    handleOutsideClick
  );

  let [to, setTo] = useState(new Date(date_to));
  let [from, setFrom] = useState(new Date(date_from));

  let [allItems] = useState(Constants.DEFAULT_PERIODS);
  let [selected, setSelected] = useState(
    Constants.DEFAULT_PERIODS.find((i) => i.key === defaultTime)
  );

  const toggle = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const apply = () => {
    if (onApply) {
      onApply(selected, to, from, selected.key);
    }
    setTemp({ to, from, selected });
    setIsComponentVisible(false);
  };

  const selectDate = (dt) => {
    const { key, offset } = dt;
    let max = new Date();
    let min = new Date();
    if (key === 'today') {
      min = max;
      setFrom(min);
      setTo(max);
      onSelect(key, min, max);
    } else if (key === 'yesterday') {
      min = new Date(max.getTime() - 1 * 24 * 60 * 60 * 1000);
      max = min;
      setFrom(min);
      setTo(max);
      onSelect(key, min, max);
    } else if (key === 'custom') {
      max = to;
      min = from;
    } else {
      max = new Date(max.getTime() - 1 * 24 * 60 * 60 * 1000);
      min = new Date(max.getTime() - offset * 24 * 60 * 60 * 1000);
      setFrom(min);
      setTo(max);
      onSelect(key, min, max);
    }
    setSelected(dt);
  };

  const selectCustomMin = (min) => {
    setFrom(min);
    if (min > to) {
      setTo(min);
      onSelect(selected.key, min, min);
    } else {
      onSelect(selected.key, min, to);
    }
  };

  const selectCustomMax = (max) => {
    setTo(max);
    onSelect(selected.key, from, max);
  };

  const renderCustomDateRange = () => (
    <div className="daterange-group">
      <DateRange
        label="From"
        name="start_date"
        value={from}
        onChange={selectCustomMin}
        minDate={from}
        endDate={to}
        type="start"
      />
      <DateRange
        label="To"
        name="end_date"
        value={to}
        onChange={selectCustomMax}
        minDate={from}
        endDate={to}
        type="end"
      />
    </div>
  );

  const renderDateOption = (dt, i) => {
    let dater = null;
    let classname = 'regular';
    if (dt.key === 'custom') {
      dater = renderCustomDateRange();
      classname = 'special-filter';
    }
    return (
      <Menu.Item className={`${classname}`} onClick={() => selectDate(dt)}>
        <div className="filter-item dates">
          <div className="check">
            <Radio name="date-group" value={dt.key} checked={selected.key === dt.key} />
          </div>
          <div className="details">
            <div className="name"> {dt.label} </div>
          </div>
          {dater}
        </div>
      </Menu.Item>
    );
  };

  let label = (
    <span>
      {selected.label} <i>▾</i>
    </span>
  );
  const cssVisible = isComponentVisible ? 'visible' : '';
  if (selected.key === 'custom') {
    label = (
      <span>
        {Format.date(from)} to {Format.date(to)} <i>▾</i>
      </span>
    );
  }

  return (
    <div className="filter dates" ref={ref}>
      <Dropdown
        icon="calendar alternate outline"
        className="icon"
        labeled
        button
        text={label}
        onClick={toggle}
        basic
      />
      {isComponentVisible && (
        <Menu size="tiny" fluid vertical className={`filter-menu ${cssVisible} shadow datefilter`}>
          <Menu.Item className="bottom-border special-header">
            <Menu.Header
              className="mb-0"
              icon="mobile"
              content={
                <span>
                  <span>Filter by Date </span>
                  <Popup
                    trigger={<Icon name="question circle" className="tooltip" />}
                    content={TOOLTIPS.analytics_timezone}
                    position="top center"
                    wide
                  />
                </span>
              }
            />
          </Menu.Item>
          <div className="list">{allItems.map(renderDateOption)}</div>
          <Menu.Item className="hl-gray-bg">
            <div className="right-align">
              <Button onClick={apply} className="positive">
                Apply
              </Button>
            </div>
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

export default DateFilter;
