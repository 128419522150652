import AppActions from 'core/app/actions';

let nextIndex = 0;
const dismissInterval = 4000;

const messages = {
  'save-success': {
    content: 'Successfully saved {0}. Changes may take up to 15 minutes to propagate',
    format: 'success',
    args: ['your data'],
  },
  'save-failure': {
    content: 'Unable to save {0}.',
    format: 'error',
    args: ['your data'],
  },
  'edit-success': {
    content: 'Successfully updated {0}. Changes may take up to 15 minutes to propagate',
    format: 'success',
    args: ['your data'],
  },
  'edit-failure': {
    content: 'Unable to update {0}',
    format: 'error',
    args: ['your data'],
  },
  'fetch-failure': {
    content: 'Unable to load {0}.',
    format: 'error',
    args: ['your data'],
  },
  'delete-success': {
    content: 'Successfully deleted {0}.',
    format: 'success',
    args: ['your data'],
  },
  'delete-failure': {
    content: 'Unable to delete {0}.',
    format: 'error',
    args: ['your data'],
  },
};

const formatMessage = (message, args = []) => {
  nextIndex += 1;
  const content = message.content.replace(
    /{(\d+)}/g,
    (match, index) => args[index] || message.args[index]
  );
  return { ...message, content, index: nextIndex };
};

const getMessage = (message = '') => {
  const [key, ...args] = typeof message === 'string' ? [message] : message;
  return messages[key] ? formatMessage(messages[key], args) : null;
};

const messaging = (store) => (next) => (action) => {
  let message = null;
  if (action.message) {
    message = getMessage(action.message);
  }
  if (action.meta && action.meta.messages) {
    const { success, failure } = action.meta.messages;
    message = action.error ? getMessage(failure) : getMessage(success);
    // override with api message
    if (action.meta.custom && action.error) {
      message.content = action.meta.custom;
      message.args = [];
    }
  }
  if (message) {
    store.dispatch(AppActions.showMessage(message));
    setTimeout(() => store.dispatch(AppActions.dismissMessage(message.index)), dismissInterval);
  }
  return next(action);
};

export default messaging;
