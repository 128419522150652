/**
 * @namespace Network/Api
 */

import Api from 'core/framework/api';

/**
 * Api response object with network names as
 * keys and credential schema as values
 * @typedef {Object} NETWORK_SCHEMA
 * @example
 * {
 *   chartboost: {
 *     type: "bidding",
 *     app_credentials: [
 *       {
 *         name: "app_id",
 *         type: "string"
 *       },
 *       {
 *         name: "app_signature",
 *         type: "string"
 *       }
 *     ],
 *     placement_credentials: [
 *       {
 *         name: "placement_id",
 *         type: "string"
 *       }
 *     ]
 *   }
 *   ...
 * }
 */

/**
 * @function
 * @name getNetworkSchema
 * @description fires a GET request to /public/credentials
 * @returns {NETWORK_SCHEMA} - Object containing network names as
 * keys and credentials schema as values
 * @memberof Network/Api
 */
const getNetworkSchema = () =>
  Api.get(`${Api.HELIUM_API}/public/credentials`, null, { token: true });

const getSupportedAppPartners = (platform, network_type) =>
  Api.get(
    `${Api.HELIUM_API}/supported-app-partners?platform=${platform}&network_type=${network_type}`,
    null,
    {
      token: true,
    }
  );

const getSupportedPlacementPartners = (platform, network_type, placement_type) =>
  Api.get(
    `${Api.HELIUM_API}/supported-placement-partners?platform=${platform}&network_type=${network_type}&placement_type=${placement_type}`,
    null,
    {
      token: true,
    }
  );

const getSupportedPlacementTypes = (platform) =>
  Api.get(`${Api.HELIUM_API}/supported-placement-types?platform=${platform}`, null, {
    token: true,
  });

const NetworkApi = {
  getNetworkSchema,
  getSupportedAppPartners,
  getSupportedPlacementPartners,
  getSupportedPlacementTypes,
};

export default NetworkApi;
