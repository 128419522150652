import React from 'react';

const CredentialPopupField = ({ source }) => {
  return (
    <div className="popup-container">
      {source &&
        source.fields &&
        source.fields.map((field) => {
          if (field.hidden) return null;
          return (
            <div className="popup-container field" key={field.name}>
              <div className="popup-container label">{field.label}</div>
              <div className="popup-container value">{field.value}</div>
            </div>
          );
        })}
    </div>
  );
};

export default CredentialPopupField;
