// views
import { Table } from 'semantic-ui-react';

// modules
import Placement from './Placement';
import MetricContants from '../../common/metrics/constants';

import '../css/placement.css';

const Placements = ({ calculated_totals, pageView }) => {
  return (
    <Table singleLine structured unstackable className="placements-table">
      <div className="table-contents">
        <Table.Header>
          <Table.Row>
            {pageView === 'singlePlacement' &&
              MetricContants.SINGLE_PLACEMENT_METRICS_FIELDS.map((metric) => {
                return (
                  <Table.HeaderCell className="column md">
                    {MetricContants.FIELD_LABELS[metric]}
                  </Table.HeaderCell>
                );
              })}
            {pageView !== 'singlePlacement' &&
              MetricContants.APP_OVERVIEW_METRICS_FIELDS.map((metric) => {
                return (
                  <Table.HeaderCell className="column md">
                    {MetricContants.FIELD_LABELS[metric]}
                  </Table.HeaderCell>
                );
              })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Placement
            calculated_totals={calculated_totals ? calculated_totals : {}}
            pageView={pageView}
          />
        </Table.Body>
      </div>
    </Table>
  );
};

export default Placements;
