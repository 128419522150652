import React from 'react';
import { Button } from 'semantic-ui-react';
import MetricsConstants from '../constants';

const MetricsFilterButtons = ({ pageView, selectedView, toggleMetric }) => {
  return (
    <div className="metrics-filter-buttons">
      <Button.Group fluid>
        {MetricsConstants.PAGE_VIEW_MAP[pageView].map((field) => {
          return (
            <Button active={selectedView === field} onClick={() => toggleMetric(field)}>
              {MetricsConstants.FIELD_LABELS[field]}
            </Button>
          );
        })}
      </Button.Group>
    </div>
  );
};

export default MetricsFilterButtons;
