import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Grid, Message } from 'semantic-ui-react';

import Api from '../api';

const GoogleBiddingAdUnitDropdown = (props) => {
  const { placement, edit } = props;
  const isNewPlacement = placement?.id === '__new__';
  const [adUnitOptions, setAdUnitOptions] = useState([]);
  const [apiMessage, setApiMessage] = useState('');
  const [selectedAdUnit, setSelectedAdUnit] = useState('');

  const handleChange = (e, data) => {
    setSelectedAdUnit(data?.value);
    edit(e, { name: 'ad_unit_id', value: data?.value });
  };

  const handleDismiss = () => {
    setApiMessage('');
  };

  useEffect(() => {
    // Only fetches ad units with exisiting placements
    if (!isNewPlacement) {
      Api.handleGet(placement, setAdUnitOptions, setApiMessage);
    }
  }, [placement?.id]);

  return (
    <Grid.Column>
      {/* New placements cannot create ad units */}
      {isNewPlacement && <Message content="Create Placement first to generate Ad Unit" />}
      {!isNewPlacement && (
        <>
          <Dropdown
            placeholder="+ Create Ad Unit"
            clearable
            fluid
            search
            selection
            options={[
              <Button
                onClick={() =>
                  Api.handlePost(placement, adUnitOptions, setAdUnitOptions, setApiMessage)
                }
                fluid
              >
                + Generate Ad Unit
              </Button>,
              ...adUnitOptions,
            ]}
            onChange={handleChange}
            name="ad_unit_id"
            value={selectedAdUnit}
            text={selectedAdUnit}
          />
          {typeof apiMessage === 'string' && apiMessage.length > 0 && (
            <Message onDismiss={handleDismiss}>{apiMessage}</Message>
          )}
        </>
      )}
    </Grid.Column>
  );
};

export default GoogleBiddingAdUnitDropdown;
