import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import isTokenExpired from 'core/helpers';

import loadBundle from './framework/loadBundle';
import Shell from './app/views/Shell';
import Logout from './user/views/Logout';
import { getAuthToken } from './framework/localStorage';

import AuthLoginNew from './user/views/AuthLoginNew';

import Unauthorized from './user/views/Unauthorized';
import TermsOfService from './user/views/TermsOfService';
import GoogleLoading from '../apps/network/views/GoogleLoading';
import { ProtectedRoute } from './app/views';

const CoreRoutes = ({ store }) => {
  const Apps = loadBundle(() => import('apps'), store);
  const Resources = loadBundle(() => import('resources'), store);
  const Analytics = loadBundle(() => import('analytics'), store);
  const Settings = loadBundle(() => import('settings'), store);

  const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const token = getAuthToken();
  const isValidToken = !isTokenExpired(token);

  return (
    <Auth0Provider
      domain={auth0_domain || 'chartboost-dev.us.auth0.com'}
      clientId={auth0_client_id || 'DbE0tn4X4jTBVlT7DmTpnKGAMjGBe1Xl'}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/login`,
        audience: auth0_audience || 'https://api.gateway.chartboost.com',
      }}
    >
      <Provider store={store}>
        <Switch>
          <Route exact path="/login">
            <AuthLoginNew />
          </Route>

          {!isValidToken && (
            <Route exact path="/apps">
              <Redirect to="/login" />
            </Route>
          )}
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/unauthorized">
            <Unauthorized />
          </Route>
          <Route exact path="/terms-of-service">
            <TermsOfService />
          </Route>
          {!isValidToken && (
            <Route>
              {/* When not logged in, redirect to login */}
              <Redirect to="/login" />
            </Route>
          )}
          <Shell>
            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route path="/google_code" component={GoogleLoading} />
              <ProtectedRoute component={Apps(store)} name="apps" path="/apps" />
              <ProtectedRoute component={Resources(store)} name="resources" path="/resources" />
              <ProtectedRoute component={Analytics(store)} name="analytics" path="/analytics" />
              <ProtectedRoute component={Settings(store)} name="settings" path="/settings" />
              <Route>
                <Redirect to="/apps" />
              </Route>
            </Switch>
          </Shell>
        </Switch>
      </Provider>
    </Auth0Provider>
  );
};

export default CoreRoutes;
