import React, { useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import { getAuthToken } from 'core/framework/localStorage';
import isTokenExpired from 'core/helpers';
import { deleteAuthToken } from '../../framework/localStorage';

import chartboost from 'core/app/images/chartboost.svg';
import AuthZeroClient from 'utils/auth0';

import 'core/user/css/login.css';

const AuthLoginNew = () => {
  const { loginWithRedirect, isAuthenticated, getToken, signup } = AuthZeroClient();
  const authTokenCookie = getAuthToken();
  const isValidToken = !isTokenExpired(authTokenCookie);
  if (!isValidToken) {
    // Token is not valid, delete token
    deleteAuthToken();
  }
  if (authTokenCookie) {
    // Has existing token but is expired, get new token and update token cookie
    getToken();
  }

  useEffect(() => {
    if (isAuthenticated) {
      // Get new token and redirect to Apps overview
      getToken();
      window.location = `/apps`;
      return <></>;
    }
  }, [isAuthenticated]);

  //   Get Auth0 and display to user
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const auth0Error = urlParams.get('error');

  return (
    <div className="newauth container">
      {isAuthenticated && isValidToken ? (
        <></>
      ) : (
        <div className="newauth content">
          <div className="newauth heading">
            <Image src={chartboost} style={{ height: '30px', marginTop: '-5px' }} />
            <div className="newauth heading-title">Chartboost</div>
          </div>
          <div>
            <div className="newauth heading-welcome">Welcome Back!</div>
          </div>
          {!isAuthenticated && !auth0Error && (
            <div className="newauth heading-detail">Log in to Helium using your credentials</div>
          )}
          {auth0Error === 'access_denied' && (
            <div className="newauth heading-error">Please verify your email first to Log in</div>
          )}
          <div className="newauth actions">
            <>
              <div className="newauth login" onClick={() => loginWithRedirect()}>
                <p>Log In</p>
              </div>
              <div>
                <div style={{ display: 'flex', gap: ' 10px' }}>
                  <div>Dont't have an account?</div>
                  <div className="newauth signup" onClick={() => signup()}>
                    Sign up
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthLoginNew;
