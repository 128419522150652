import React, { useEffect, useState } from 'react';

// views
import { Table, Icon } from 'semantic-ui-react';
import { EmptyRow } from 'core/app/views';

// modules
import Placement from './Placement';
import Utils from 'core/framework/utils';
import Helpers from '../helpers';
import { sortArrayByDirection } from 'utils/helpers';

import '../css/placements.css';

const Placements = ({ ...props }) => {
  const {
    adPlacements,
    appId,
    removePlacement,
    override = false,
    preview = false,
    placementsOverview,
  } = props;
  const [sortingState, setSortingState] = useState({
    column: '',
    data: [],
    direction: '',
  });

  const showAll = !preview && Utils.isNonZeroArray(adPlacements) && adPlacements.length > 4;
  const placements = showAll ? adPlacements : adPlacements.slice(0, 4);

  // Combine the A and B data and create one row for each A/B Test Placement
  const nonBtestPlacements = placements.filter((placement) => placement['ab_type'] !== 'B');
  const nonBtestPlacementsAndMetrics = Helpers.combineNonBAndOverview(
    nonBtestPlacements,
    placementsOverview
  );
  const combinedData = Helpers.combineABTestData(nonBtestPlacementsAndMetrics, placementsOverview);
  useEffect(() => {
    setSortingState({
      ...sortingState,
      data: combinedData,
    });
  }, [adPlacements]);

  const changeSort = (column) => {
    if (column === sortingState.column) {
      const newDirection = sortingState.direction === 'ascending' ? 'descending' : 'ascending';
      const sortedData = sortArrayByDirection(sortingState.data, column, newDirection);
      setSortingState({
        ...sortingState,
        data: sortedData,
        direction: newDirection,
      });
    } else {
      const newDirection = 'descending';
      const sortedData = sortArrayByDirection(sortingState.data, column, newDirection);
      setSortingState({
        column: column,
        data: sortedData,
        direction: newDirection,
      });
    }
  };

  const displaySort = (isSorted) =>
    isSorted ? (
      <Icon name={`sort ${sortingState.direction}`} />
    ) : (
      <Icon name="sort" style={{ color: 'rgba(0,0,0,0.3' }} />
    );

  const itemsToRender = [];
  for (let placement of sortingState.data) {
    itemsToRender.push(
      <Placement
        adPlacement={placement}
        appId={appId}
        disabled={adPlacements.length <= 1}
        key={placement.id}
        override={override}
        removePlacement={removePlacement}
      />
    );
  }

  return (
    <Table singleLine selectable structured unstackable className="placementsTable">
      <div className="table-contents">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="column lg" onClick={() => changeSort('name')}>
              Name {displaySort(sortingState.column === 'name')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column xs" onClick={() => changeSort('active')}>
              Status {displaySort(sortingState.column === 'active')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column sm" onClick={() => changeSort('type')}>
              Type {displaySort(sortingState.column === 'type')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column md" onClick={() => changeSort('earnings')}>
              Earnings {displaySort(sortingState.column === 'earnings')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column md" onClick={() => changeSort('requests')}>
              Requests {displaySort(sortingState.column === 'requests')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column md" onClick={() => changeSort('fills')}>
              Fills {displaySort(sortingState.column === 'fills')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column md" onClick={() => changeSort('impressions')}>
              Impressions {displaySort(sortingState.column === 'impressions')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column sm" onClick={() => changeSort('ecpm')}>
              eCPM {displaySort(sortingState.column === 'ecpm')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column sm" onClick={() => changeSort('fill_rate')}>
              Fill Rate {displaySort(sortingState.column === 'fill_rate')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column sm" onClick={() => changeSort('show_rate')}>
              Show Rate {displaySort(sortingState.column === 'show_rate')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column sm" onClick={() => changeSort('clicks')}>
              Clicks {displaySort(sortingState.column === 'clicks')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column sm" onClick={() => changeSort('ctr')}>
              CTR {displaySort(sortingState.column === 'ctr')}
            </Table.HeaderCell>
            <Table.HeaderCell className="column xs"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {itemsToRender.length === 0 ? (
            <EmptyRow message="No Ad Placements" colSpan="3" rowClassName="emptyRow" />
          ) : (
            itemsToRender
          )}
        </Table.Body>
      </div>
    </Table>
  );
};

export default Placements;
