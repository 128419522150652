import React from 'react';
import MetricHelpers from '../helpers';

import ToolTipDate from './ToolTipDate';
import ToolTipDetails from './ToolTipDetails';
import ToolTipCalculated from './ToolTipCalculated';
import '../css/metrics.css';

const MetricsTooltip = ({
  active,
  colorMap,
  isAdvanced,
  label: date,
  pageView,
  payload,
  selectedView,
}) => {
  const shouldShowDetails =
    pageView === 'singlePlacement' || (pageView === 'placementOverview' && isAdvanced);

  let totalsKey = '';
  let totalValue = '';
  let metricData = payload && payload[0] && payload[0]?.payload;

  if (pageView === 'appOverview' && metricData) {
    totalValue = metricData[selectedView];
  }

  if ((pageView === 'placementOverview' || pageView === 'allApps') && metricData) {
    totalValue = metricData.totals[selectedView];
  }

  if (pageView === 'singlePlacement' && metricData) {
    // TODO convert flat Data structure to nested
    if (MetricHelpers.isCalculatedMetric(selectedView)) {
      totalsKey = `average_${selectedView}`;
    } else {
      totalsKey = `total_${selectedView}`;
    }
    totalValue = metricData[totalsKey];
  }

  if (active && payload && payload.length) {
    return (
      <div className="metrics-tooltip">
        <ToolTipDate date={date} />
        {shouldShowDetails &&
          payload &&
          payload
            .sort((a, b) => a.value - b.value)
            .map((metric) => {
              return (
                <ToolTipDetails
                  metric={metric}
                  colorMap={colorMap}
                  pageView={pageView}
                  selectedView={selectedView}
                  isAdvanced={isAdvanced}
                />
              );
            })}
        <ToolTipCalculated selectedView={selectedView} value={totalValue} />
      </div>
    );
  }

  return null;
};

export default MetricsTooltip;
