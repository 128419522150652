import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

const ExternalLink = ({ url }) => (
  <Button basic color="red" inverted as="a" href={url}>
    <Icon name="remove" /> Leave page
  </Button>
);

const InternalLink = ({ url }) => (
  <Button basic color="red" inverted as={Link} to={url}>
    <Icon name="remove" /> Leave page
  </Button>
);

const DiscardLink = ({ url }) => {
  if (url.indexOf('https://') === 0) {
    return <ExternalLink url={url} />;
  }
  return <InternalLink url={url} />;
};

const SaveModal = ({ saver, discardUrl, visible }) => (
  <Modal open={visible} basic size="small">
    <Header icon="archive" content="Save changes first ?" />
    <Modal.Content>
      <p>Would you like to save your changes before navigating away ?</p>
    </Modal.Content>
    <Modal.Actions>
      <DiscardLink url={discardUrl} />
      <Button color="green" onClick={saver} inverted>
        <Icon name="checkmark" /> Save
      </Button>
    </Modal.Actions>
  </Modal>
);

export default SaveModal;
