import React, { useState } from 'react';
import { Button, Checkbox, Image, Popup, Table } from 'semantic-ui-react';

import Network from 'apps/network';
import EditCredentialModal from './EditCredentialModal';
import { formatDollars, formatPercent, formatValue } from 'utils/helpers';

const CredentialsRowPlacements = ({
  addPredicate,
  appId,
  createRule,
  deletePredicate,
  deleteRule,
  editCredentials,
  editPredicate,
  getPlacementBiddingLineItems,
  loading,
  negateRule,
  onlySource = false,
  placement,
  removeSource,
  rowData,
  setSource,
  shouldFetchPlacementLineItems,
  source = { active: true, fields: [] },
}) => {
  const [state, setState] = useState({
    popupId: '',
    isEditShown: false,
    selectedRow: '',
  });

  const togglePopup = (network) => {
    setState((prevState) => ({ ...prevState, popupId: network, selectedRow: network }));
  };

  const toggleEditShown = (bool) => {
    setState((prevState) => ({ ...prevState, isEditShown: bool }));
  };

  const {
    earnings,
    attempts,
    bids,
    wins,
    impressions,
    ecpm,
    bidRate,
    winRate,
    showRate,
    clicks,
    ctr,
  } = rowData;

  const shouldDisableDelete = () => {
    if (placement?.type !== 'adaptive_banner' && placement?.type !== 'rewarded_interstitial') {
      if (onlySource || source.id === 'chartboost') {
        return true;
      }
    }
    return false;
  };

  return (
    <Table.Row
      key={source.id}
      className={`biddingitemrow ${source.id === state.selectedRow ? 'selected' : ''}`}
    >
      <Table.Cell className="bidding-network-active">
        <Checkbox
          checked={source.active}
          name="active"
          onClick={setSource}
          toggle
          value={String(!source.active)}
        />
      </Table.Cell>
      <Table.Cell>
        <Image src={Network.Constants.NETWORK_LOGOS[source.id]} avatar className={source.id} />
        <span>{source.name}</span>
      </Table.Cell>
      <Table.Cell className="bidding-table row">
        {earnings !== undefined ? formatDollars(earnings) : '-'}
      </Table.Cell>
      <Table.Cell className="bidding-table row">{formatValue(attempts)}</Table.Cell>
      <Table.Cell className="bidding-table row">{formatValue(bids)}</Table.Cell>
      <Table.Cell className="bidding-table row">{formatValue(wins)}</Table.Cell>
      <Table.Cell className="bidding-table row">{formatValue(impressions)}</Table.Cell>
      <Table.Cell className="bidding-table row">
        {ecpm !== undefined ? formatDollars(ecpm) : '-'}
      </Table.Cell>
      <Table.Cell className="bidding-table row">
        {bidRate !== undefined ? formatPercent(bidRate) : '-'}
      </Table.Cell>
      <Table.Cell className="bidding-table row">
        {winRate !== undefined ? formatPercent(winRate) : '-'}
      </Table.Cell>
      <Table.Cell className="bidding-table row">
        {showRate !== undefined ? formatPercent(showRate) : '-'}
      </Table.Cell>
      <Table.Cell className="bidding-table row">
        {clicks !== undefined ? formatValue(clicks) : '-'}
      </Table.Cell>
      <Table.Cell className="bidding-table row">
        {ctr !== undefined ? formatPercent(ctr) : '-'}
      </Table.Cell>

      <Table.Cell>
        <Popup
          on="click"
          open={state.popupId === source.id}
          pinned
          position="left"
          onClose={() => {
            if (state.isEditShown === false) {
              togglePopup('');
            }
          }}
          trigger={
            <Button
              icon="ellipsis vertical"
              className="editlineitempopup"
              onClick={() => togglePopup(source.id)}
            />
          }
        >
          <div style={{ width: '165px' }}>
            <EditCredentialModal
              addPredicate={addPredicate}
              appId={appId}
              closePopup={() => togglePopup('')}
              createRule={createRule}
              deletePredicate={deletePredicate}
              deleteRule={deleteRule}
              editCredentials={editCredentials}
              editPredicate={editPredicate}
              getPlacementBiddingLineItems={getPlacementBiddingLineItems}
              isDisabled={false}
              isPlacement={true}
              loading={loading}
              negateRule={negateRule}
              placement={placement}
              setEditShown={(bool) => toggleEditShown(bool)}
              shouldFetchPlacementLineItems={shouldFetchPlacementLineItems}
              source={source}
            />
            <Button
              className="removeLineItemButton"
              disabled={shouldDisableDelete()}
              onClick={() => removeSource()}
            >
              Remove Bidding Partner
            </Button>
          </div>
        </Popup>
      </Table.Cell>
    </Table.Row>
  );
};

export default CredentialsRowPlacements;
