import React from 'react';
import { Header, Label, Segment, Tab, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// views
import AdGroupEditor from './AdGroupEditor';
import Bidfloors from 'apps/placement/views/Bidfloors';
import CredentialsPlacements from 'apps/network/views/CredentialsPlacements';
import PlacementForm from './PlacementForm';
import WarningPopup from 'core/app/views/WarningPopup';

// modules
import AdGroup from 'apps/adgroup';
import App from 'apps/app';
import Constants from '../constants';
import LineItem from '../../lineitem';
import Network from 'apps/network';
import Placement from 'apps/placement';
import UserStore from 'core/user/store';
import Utils from 'core/framework/utils';

class ABEditPlacement extends PlacementForm {
  componentDidMount() {
    const {
      app,
      fetchApp,
      placementName,
      getPlacementMetrics,
      getBTestPlacementMetrics,
      getDateFilter,
      placement,
      selectApp,
    } = this.props;
    const urlParams = window.location.pathname.split('/');
    const urlAppId = urlParams[2];
    const appId = app.id || urlAppId;

    const { max, min } = getDateFilter;

    if (placementName) {
      let plcmntName = this.props.placementName;
      if (plcmntName.endsWith('_B')) {
        plcmntName = plcmntName.slice(0, -2) + '::B';
        getBTestPlacementMetrics(appId, plcmntName, min, max);
      } else {
        getPlacementMetrics(appId, plcmntName, min, max);
      }

      if (this.props.shouldFetchAdGroups) {
        this.props.fetchAdgroups(appId);
      }
      if (this.props.shouldFetchLineItems) {
        this.props.fetchLineItems(appId);
      }
      if (app.id !== urlAppId) {
        selectApp(urlAppId);
        fetchApp(appId);
      }
    }
    const platform = app?.platform && app?.platform.toLowerCase();
    const placementType = placement?.type && placement?.type;
    // fetch supported bidding networks to populate dropdowns
    if (platform && placementType) {
      this.props.getSupportedPlacementPartners(platform, 'bidding', placementType);
    }
  }

  componentDidUpdate(prevProps) {
    const urlParams = window.location.pathname.split('/');
    const urlAppId = urlParams[2];

    if (prevProps?.app?.id === undefined && this.props?.app?.id !== undefined) {
      this.props.fetchApp(urlAppId);
    }
    if (prevProps.placementName !== this.props.placementName) {
      let plcmntName = this.props.placementName;
      if (plcmntName) {
        if (plcmntName.endsWith('_B')) {
          plcmntName = plcmntName.slice(0, -2) + '::B';
          this.props.getBTestPlacementMetrics(
            urlAppId,
            plcmntName,
            this.props.getDateFilter.min,
            this.props.getDateFilter.max
          );
        } else {
          this.props.getPlacementMetrics(
            urlAppId,
            plcmntName,
            this.props.getDateFilter.min,
            this.props.getDateFilter.max
          );
        }
      }
    }
  }

  renderBidding() {
    const {
      app,
      createPlacementCredentials,
      credsLoading,
      deletePlacementCredentials,
      editPlacement,
      networkOptions,
      placement,
      removeFloor,
      setFloor,
      togglePlacementCredentials,
      biddingMetrics,
      abtesttype,
    } = this.props;

    const set = Utils.handleFormEvent(editPlacement, app.id, placement.id);

    return (
      <Segment loading={credsLoading} textAlign="left" basic>
        <Bidfloors
          abtest={true}
          appId={app.id}
          placement={placement}
          removeFloor={removeFloor}
          set={set}
          setFloor={setFloor}
        />
        <Header as="h4" content="Bidding Networks" />
        <CredentialsPlacements
          abtest={true}
          app={app}
          create={createPlacementCredentials}
          delete={deletePlacementCredentials}
          edit={togglePlacementCredentials}
          options={networkOptions}
          placement={placement}
          biddingMetrics={biddingMetrics}
          abtesttype={abtesttype}
          type="placement_credentials"
        />
      </Segment>
    );
  }

  renderWaterfall() {
    const {
      app,
      isV2,
      adgroups,
      addAdgroup,
      placement,
      saver,
      updateAdgroupPlacement,
      mediationMetrics,
      mediationBTestMetrics,
      abtesttype,
    } = this.props;
    const {
      default_interstitial_adgroup,
      default_rewarded_adgroup,
      default_rewarded_interstitial_adgroup,
    } = app;
    const display = { display: isV2 ? 'block' : 'none' };

    return (
      <div className="AdGroupEditorContainer" style={display}>
        <AdGroupEditor
          abtest={true}
          addAdgroup={addAdgroup}
          adgroups={adgroups}
          app={app}
          catchallIds={[
            default_interstitial_adgroup,
            default_rewarded_adgroup,
            default_rewarded_interstitial_adgroup,
          ]}
          compact={true}
          placement={placement}
          saveOnExit={saver}
          updateAdgroupPlacement={updateAdgroupPlacement}
          mediationMetrics={abtesttype === 'B' ? mediationBTestMetrics : mediationMetrics}
        />
      </div>
    );
  }

  render() {
    const {
      abtesttype,
      app,
      isControlActive,
      isTestActive,
      placementId,
      proportion,
      setProportion,
    } = this.props;
    const { a, b } = Constants.AB_TEST_COLORS;
    const ABtestHeader = abtesttype === 'A' ? 'Control Group (A)' : 'Test Group (B)';
    const ABtestColor = abtesttype === 'A' ? a : b;
    const isChecked = proportion > 0;

    const panes = [
      {
        menuItem: 'Bidding',
        render: () => <Tab.Pane attached={false}> {this.renderBidding()} </Tab.Pane>,
      },
      {
        menuItem: 'Waterfall',
        render: () => <Tab.Pane attached={false}>{this.renderWaterfall()}</Tab.Pane>,
      },
    ];

    const onToggle = () => {
      isChecked ? setProportion(app.id, placementId, 0) : setProportion(app.id, placementId, 1);
    };

    const handleDisableToggle = (testType, controlActive, testActive) => {
      if (controlActive && testActive) {
        // When both are active, dont disable.
        return false;
      }
      if (testType === 'A') {
        return testActive;
      }
      if (testType === 'B') {
        return controlActive;
      }
    };
    const toggle = (
      <Checkbox
        checked={isChecked}
        className="ab-toggle"
        label={isChecked ? 'Active' : 'Disabled'}
        disabled={handleDisableToggle(abtesttype, isControlActive, isTestActive)}
        toggle
      />
    );
    return (
      <Segment className="ab-test-form">
        <Label size="large" attached="top" color={ABtestColor}>
          {ABtestHeader}
          <WarningPopup
            action={onToggle}
            actionName={isChecked ? 'Disable' : 'Enable'}
            name={ABtestHeader}
            disabled={handleDisableToggle(abtesttype, isControlActive, isTestActive)}
            element={toggle}
            actionColor={isChecked ? 'red' : 'green'}
          />
        </Label>
        <Tab attached="top" panes={panes} />
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: appId, pid } = ownProps.match.params;
  const { abtestid } = ownProps;
  const parsedPlacementId = parseInt(pid, 10);
  const placementId = abtestid != null ? parseInt(abtestid, 10) : parsedPlacementId;
  const placement = Placement.Store.getOne(state, appId, placementId) || { adgroups: [] };
  const app = App.Store.getApp(state, appId) || { credentials: [], placements: [] };
  const existingNames = Placement.Helpers.getExistingNames(app, placementId);
  const adgroups = placement.adgroups
    ? placement.adgroups.map((agid) => AdGroup.Store.getOne(state, appId, agid) || {})
    : [];

  return {
    abtestid,
    adgroups,
    app,
    biddingBTestMetrics: Placement.Store.getBTestBiddingMetrics(state),
    biddingMetrics: Placement.Store.getBiddingMetrics(state),
    callbackErrors: App.Store.getCallbackErrors(state),
    credsLoading: Placement.Store.getCredsLoading(state),
    defaultNames: Placement.Store.getDefaults(state, existingNames),
    existingNames,
    getDateFilter: App.Store.getDateFilter(state),
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
    isV2: UserStore.isV2(state),
    loading: Placement.Store.getLoading(state),
    madeChanges: App.Store.hasMadeChanges(state),
    mediationBTestMetrics: Placement.Store.getBTestMediationMetrics(state),
    mediationMetrics: Placement.Store.getMediationMetrics(state),
    networkOptions: Network.Store.getUnusedNetworksForPlacement(state, app, placement),
    placement: placement || { credentials: [], floor_active: false, prioritize_bidding: false },
    placementErrors: App.Store.getPlacementErrors(state),
    placementId,
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    shouldFetchLineItems: LineItem.Store.shouldFetch(state),
    shouldTransition: Placement.Store.getShouldFetchPlacements(state),
  };
};

const mapDispatchToProps = {
  addAdgroup: Placement.Actions.addAdgroup,
  clearChanges: App.Actions.clearChanges,
  createPlacementCredentials: Placement.Actions.createPlacementCredentials,
  deletePlacementCredentials: Placement.Actions.deletePlacementCredentials,
  disableBannerRefresh: Placement.Actions.disableBannerRefresh,
  editPlacement: Placement.Actions.editPlacement,
  enableBannerRefresh: Placement.Actions.enableBannerRefresh,
  fetchAdgroups: AdGroup.Actions.fetchAll,
  fetchLineItems: LineItem.Actions.fetchAll,
  fetchApp: App.Actions.fetchApp,
  fetchOne: Placement.Actions.fetchOne,
  getBTestPlacementMetrics: Placement.Actions.fetchBTestMetrics,
  getPlacementMetrics: Placement.Actions.fetchMetrics,
  getSupportedPlacementPartners: Network.Actions.fetchSupportedPlacementPartners,
  removeFloor: Placement.Actions.removeFloor,
  save: Placement.Actions.update,
  selectApp: App.Actions.selectApp,
  setBannerRefresh: Placement.Actions.setBannerRefresh,
  setFloor: Placement.Actions.setFloor,
  setRewardedCallback: Placement.Actions.setRewardedCallback,
  togglePlacementCredentials: Placement.Actions.togglePlacementCredentials,
  updateAdgroupPlacement: AdGroup.Actions.updateAdgroupPlacement,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ABEditPlacement));
