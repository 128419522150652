const ALL_APPS_METRICS_FIELDS = [
  'earnings',
  'requests',
  'fills',
  'impressions',
  'ecpm',
  'fill_rate',
  'show_rate',
  'clicks',
  'ctr',
];

const APP_OVERVIEW_METRICS_FIELDS = [
  'earnings',
  'requests',
  'fills',
  'impressions',
  'ecpm',
  'fill_rate',
  'show_rate',
  'clicks',
  'ctr',
];

const SINGLE_PLACEMENT_METRICS_FIELDS = [
  'earnings',
  'attempts',
  'wins',
  'impressions',
  'ecpm',
  'win_rate',
  'show_rate',
  'clicks',
  'ctr',
];

const PLACEMENTS_OVERVIEW_METRIC_FIELDS = [
  'earnings',
  'requests',
  'fills',
  'impressions',
  'ecpm',
  'fill_rate',
  'show_rate',
  'clicks',
  'ctr',
];

const BAR_COLORS = [
  '#54bebe',
  '#76c8c8',
  '#98d1d1',
  '#badbdb',
  '#dedad2',
  '#e4bcad',
  '#df979e',
  '#d7658b',
  '#c80064',
  // Additional Colors
  '#2e222f',
  '#3e3546',
  '#625565',
  '#966c6c',
  '#ab947a',
  '#694f62',
  '#7f708a',
  '#9babb2',
  '#c7dcd0',
  '#6e2727',
  '#b33831',
  '#ea4f36',
  '#f57d4a',
  '#ae2334',
  '#e83b3b',
  '#fb6b1d',
  '#f79617',
  '#f9c22b',
  '#7a3045',
  '#9e4539',
  '#cd683d',
  '#e6904e',
  '#fbb954',
  '#4c3e24',
  '#676633',
  '#a2a947',
  '#d5e04b',
  '#fbff86',
  '#165a4c',
  '#239063',
  '#1ebc73',
  '#91db69',
  '#cddf6c',
  '#374e4a',
  '#547e64',
  '#92a984',
  '#b2ba90',
  '#0b5e65',
  '#0b8a8f',
  '#0eaf9b',
  '#30e1b9',
  '#8ff8e2',
  '#323353',
  '#484a77',
  '#4d65b4',
  '#4d9be6',
  '#8fd3ff',
  '#45293f',
  '#6b3e75',
  '#905ea9',
  '#a884f3',
  '#eaaded',
  '#753c54',
  '#a24b6f',
  '#cf657f',
  '#ed8099',
  '#831c5d',
  '#c32454',
  '#f04f78',
  '#f68181',
  '#fca790',
  '#fdcbb0',
];

const COMMON_COLORS = {
  basic: '#84c342',
  bidding: '#84c342',
  waterfall: '#055c9d',
};

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const FIELD_LABELS = {
  clicks: 'Clicks',
  ctr: 'CTR',
  bidding_ctr: 'Bidding',
  waterfall_ctr: 'Waterfall',
  attempts: 'Attempts',
  earnings: 'Earnings',
  ecpm: 'eCPM',
  fill_rate: 'Fill Rate',
  fills: 'Fills',
  impressions: 'Impressions',
  requests: 'Requests',
  show_rate: 'Show Rate',
  win_rate: 'Fill Rate',
  wins: 'Fills',
  Bidding: 'Bidding',
  Waterfall: 'Waterfall',
  bidding_ecpm: 'Bidding',
  bidding_show_rate: 'Bidding',
  bidding_win_rate: 'Bidding',
  waterfall_ecpm: 'Waterfall',
  waterfall_show_rate: 'Waterfall',
  waterfall_win_rate: 'Waterfall',
};

const PAGE_VIEW_MAP = {
  appOverview: APP_OVERVIEW_METRICS_FIELDS,
  placementOverview: PLACEMENTS_OVERVIEW_METRIC_FIELDS,
  singlePlacement: SINGLE_PLACEMENT_METRICS_FIELDS,
  allApps: ALL_APPS_METRICS_FIELDS,
};

const Constants = {
  ALL_APPS_METRICS_FIELDS,
  APP_OVERVIEW_METRICS_FIELDS,
  BAR_COLORS,
  COMMON_COLORS,
  FIELD_LABELS,
  PAGE_VIEW_MAP,
  PLACEMENTS_OVERVIEW_METRIC_FIELDS,
  SINGLE_PLACEMENT_METRICS_FIELDS,
  WEEKDAYS,
};

export default Constants;
