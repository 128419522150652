import React from 'react';
import { Grid, Form } from 'semantic-ui-react';

import GoogleBiddingAdUnitDropdown from './GoogleBiddingAdUnitDropdown';
import GoogleBiddingHelpers from '../helpers';

const GoogleBiddingPlacementForm = (props) => {
  const { fields, placement, edit } = props;
  const field = GoogleBiddingHelpers.filterPlacementFields(fields);

  return (
    <div style={{ position: 'relative' }}>
      <Grid columns={2}>
        <Grid.Column>
          <Form>{field}</Form>
        </Grid.Column>
        <GoogleBiddingAdUnitDropdown placement={placement} edit={edit} />
      </Grid>
    </div>
  );
};

export default GoogleBiddingPlacementForm;
