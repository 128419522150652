import GlobalConstants from './constants';

const HELIUM_PRODUCT_TOUR_ID = 176045;
const HELIUM_KEYWORD_TOUR_ID = 364449;

declare global {
    interface Window {
        Intercom:any;
    }
}

interface bootIntercomProps {
    user_id: string,
}

const bootIntercom = ({ user_id }: bootIntercomProps) => {
    window.Intercom('boot', {
        app_id: GlobalConstants.INTERCOM_APP_ID,
        user_id,
     });
}

const shutdownIntercom = () => {
    window.Intercom('shutdown');
}

const showProductTour = () => {
    window.Intercom('startTour', HELIUM_PRODUCT_TOUR_ID);
}

const showKeywordTour = () => {
    window.Intercom('startTour', HELIUM_KEYWORD_TOUR_ID);
}

const IntercomHelpers = {
    boot: bootIntercom,
    shutdown: shutdownIntercom,
    showProductTour,
    showKeywordTour,
}

export default IntercomHelpers;
