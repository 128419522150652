import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import TargetingSingleRule from './TargetingSingleRule';
import '../css/targeting-rules.css';

import { getReservedKeywords, getCustomKeywords } from '../../../../settings/reducer';
import { fetchKeywords, fetchReservedKeywords } from '../../../../settings/actions';

const TargetingRulesContainer = ({
  addingPredicateId,
  customKeywords,
  editingPredicateId,
  fetchKeywords,
  fetchReservedKeywords,
  handleDeletePredicate,
  handleDeleteRule,
  handleNegateRule,
  isAddingPredicate,
  isEditingPredicate,
  isLoading,
  keyword,
  lineItem,
  operation,
  reservedKeywords,
  selectedRuleId,
  setKeywords,
  setter,
  toggleAddPredicate,
  toggleEditPredicate,
  value,
}) => {
  const [rulesState, setRulesState] = useState({ ...lineItem });
  const { targeting_rules } = rulesState;

  const addNewRule = () => {
    const ruleSkeleton = {
      is_negated: false,
      predicates: [],
    };

    const updatedTargetingRules = () => {
      if (rulesState && rulesState.targeting_rules && rulesState.targeting_rules.length) {
        const copy = [...rulesState.targeting_rules].concat(ruleSkeleton);
        return copy;
      }
      return [{}];
    };
    setRulesState((prevState) => ({
      ...prevState,
      targeting_rules: updatedTargetingRules(),
    }));
    toggleAddPredicate(true, undefined);
  };

  useEffect(() => {
    fetchReservedKeywords();
    fetchKeywords();
  }, []);

  useEffect(() => {
    setKeywords(customKeywords, reservedKeywords);
  }, [customKeywords, reservedKeywords]);

  useEffect(() => {
    setRulesState((prevState) => ({
      ...prevState,
      targeting_rules: lineItem.targeting_rules,
    }));
  }, [lineItem]);

  return (
    <div>
      <Segment loading={isLoading}>
        <Header as="h3" content="Targeting Rules" />
        <div className="targeting-rules-container">
          {targeting_rules && targeting_rules.length ? (
            targeting_rules.map((rule, idx) => {
              const isLastRule = idx === targeting_rules.length - 1;
              const isNewRule = rule.uuid === undefined;
              return (
                <div>
                  <TargetingSingleRule
                    addingPredicateId={addingPredicateId}
                    handleDeletePredicate={handleDeletePredicate}
                    handleDeleteRule={handleDeleteRule}
                    handleNegateRule={handleNegateRule}
                    isAddingPredicate={isAddingPredicate}
                    isEditingPredicate={isEditingPredicate}
                    editingPredicateId={editingPredicateId}
                    keyword={keyword}
                    operation={operation}
                    reservedKeywords={reservedKeywords}
                    rule={rule}
                    selectedRuleId={selectedRuleId}
                    setter={setter}
                    toggleAddPredicate={toggleAddPredicate}
                    toggleEditPredicate={toggleEditPredicate}
                    value={value}
                  />
                  {!isLastRule && <Divider horizontal>Or</Divider>}
                  {!isNewRule && isLastRule && (
                    <div>
                      <Divider horizontal>Or</Divider>
                      <div className="flex center">
                        <Button
                          className="positive"
                          disabled={isAddingPredicate || isEditingPredicate}
                          icon="add"
                          onClick={() => addNewRule()}
                        >
                          Add new rule
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="empty-rules-container">
              <div>
                <p>No Targeting Rules Set</p>
                <Button className="positive" onClick={() => addNewRule()}>
                  Add new rule
                </Button>
              </div>
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    reservedKeywords: getReservedKeywords(state),
    customKeywords: getCustomKeywords(state),
  };
};

const mapDispatchToProps = {
  fetchReservedKeywords,
  fetchKeywords,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetingRulesContainer);
