import Store from './store';
import Reducer from './reducer';
import Actions from './actions';

const User = {
  Store,
  Reducer,
  Actions
};

export default User;
