import React from 'react';
import { Loader } from 'semantic-ui-react';

import AuthZeroClient from 'utils/auth0';
import { deleteAuthToken } from '../../framework/localStorage';

export const Logout = () => {
  const { forceLogout } = AuthZeroClient();
  deleteAuthToken();
  forceLogout();
  return <Loader />;
};

export default Logout;
