import React, { useEffect, useState } from 'react';
import { Button, Modal, Segment } from 'semantic-ui-react';
import { withRouter, Prompt } from 'react-router-dom';

const RouteLeavingGuard = withRouter(({ when, shouldBlockNavigation, history }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(
    () => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname);
      }
    },
    [confirmedNavigation, lastLocation]
  );

  return (
    <div>
      <Prompt when={when} message={handleBlockedNavigation} />

      <Modal
        open={modalVisible}
        onClose={closeModal}
        centered={true}
        className="hl-modal LineItemModal"
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Quit Editing?</Modal.Header>
        <Modal.Content>
          <Segment>
            <p>You have unsaved changes on this page. Changes you made so far will not be saved.</p>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Keep Editing</Button>
          <Button className="positive" onClick={handleConfirmNavigationClick}>
            Yes, Quit Editing
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
});

export default RouteLeavingGuard;
