import Api from 'core/framework/api';

const getApps = () => Api.get(`${Api.HELIUM_API}/apps/chartboost`, null, { token: true });

const importApp = chartboostId =>
  Api.post(`${Api.HELIUM_API}/apps/import/${chartboostId}`, null, { token: true, method: 'POST' });

const ChartboostApi = {
  getApps,
  importApp
};

export default ChartboostApi;
