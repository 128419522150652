import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';

import Helpers from '../helpers';
import LineItemCPMField from './LineItemCPMField';
import LineItemPartnerForm from './LineItemPartnerForm';

import Constants from '../constants';

const LineItemFilterFooter = (props) => {
  const { networks, type, isBetaTestAccount, create, supportedPlacementPartners } = props;
  const [createLineitemState, updateCreateLineitemState] = useState({});

  const setter = (_ctx, e) => {
    let value = e.value;
    if (e.name === 'checked' || e.name === 'first_look') {
      value = !value;
    }
    if (Helpers.isPartnerField(e.name)) {
      updateCreateLineitemState({
        ...createLineitemState,
        partner_details: {
          ...createLineitemState.partner_details,
          [e.name]: value,
        },
      });
    } else {
      updateCreateLineitemState({
        ...createLineitemState,
        [e.name]: value,
      });
    }
  };

  const handleCreate = () => {
    create(createLineitemState);
    updateCreateLineitemState(Constants.CREATE_LINEITEM_DEFAULT_STATE(type));
  };

  // Update Line item dropdown using product support matrix and available networks
  const { waterfall_supported } = supportedPlacementPartners || {};

  const availablePartnerNames = networks.map((network) => network.name);
  let availableNetworks = waterfall_supported
    ? waterfall_supported.flatMap((network) => {
        if (!availablePartnerNames.includes(network.option)) {
          return [];
        }
        const { option: name, supported } = network;
        return Helpers.NetworkBuilder({ name, supported });
      })
    : [];

  const isCreateButtonDisabled = (state) => {
    return !Helpers.isLineItemStateValid(state);
  };

  const isValidated = isCreateButtonDisabled(createLineitemState);

  useEffect(() => {
    //Set type at mount
    if (props.type !== undefined) {
      updateCreateLineitemState(Constants.CREATE_LINEITEM_DEFAULT_STATE(props.type));
    }
  }, [props.type]);

  return (
    <div className="lineItem footer">
      <div className="lineItem create container">
        <Form className="lineItem create network">
          <Form.Dropdown
            label="Network"
            name="network_id"
            onChange={setter}
            options={availableNetworks}
            placeholder="Select Network"
            selection={true}
            required={true}
            value={createLineitemState.network_id}
          />
        </Form>
        <Form className="lineItem create name">
          <Form.Input
            autoComplete="off"
            label="Name"
            name="name"
            onChange={setter}
            placeholder="Name"
            required={true}
            value={createLineitemState.name}
            width={14}
          />
        </Form>
        <LineItemPartnerForm createLineitemState={createLineitemState} setter={setter} />
        {isBetaTestAccount && (
          <Checkbox
            checked={createLineitemState.first_look}
            className="lineItem create firstlook"
            label="First Look"
            name="first_look"
            onChange={setter}
            value={createLineitemState.first_look}
          />
        )}
        <Form className="lineItem create cpm">
          <LineItemCPMField cpm={createLineitemState.cpm} setter={setter} />
        </Form>
        <Checkbox
          checked={createLineitemState.checked}
          className="lineItem create checkbox"
          label="Add to Ad Group"
          name="checked"
          onChange={setter}
          value={createLineitemState.checked}
        />
      </div>
      <Button
        disabled={isValidated}
        className="positive lineItem create new"
        content="Create New"
        onClick={() => {
          handleCreate();
        }}
      />
    </div>
  );
};

export default LineItemFilterFooter;
