import Utils from '../framework/utils';

// const NAMESPACE = 'cbpro';
// Use cookie to store auth0 token instead of JWT

const removeAllCookies = () => {
  const DOMAIN = process.env.REACT_APP_DOMAIN || '.chartboost.com';

  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${DOMAIN}`);
  });
};

export const setAuthToken = (token) => {
  try {
    Utils.setCookie('token', token);
    // return localStorage.setItem(`${NAMESPACE}.jwt`, token);
  } catch (err) {
    return false;
  }
};

export const getAuthToken = () => {
  try {
    return Utils.getCookie('token');
    // return localStorage.getItem(`${NAMESPACE}.jwt`);
  } catch (err) {
    return undefined;
  }
};

export const deleteAuthToken = () => {
  try {
    removeAllCookies();
    // localStorage.removeItem(`${NAMESPACE}.jwt`);
    return '';
  } catch (err) {
    return '';
  }
};
