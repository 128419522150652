/**
 * @namespace Placement/Actions
 */

import Actions from 'core/framework/actions';
import Api from './api';
import AppHelpers from '../app/helpers';
import Helpers from './helpers';
import Network from 'apps/network';

/* Actions */

const terms = {
  FETCH_PLACEMENT: 'AD.PLACEMENT.FETCH',
  FETCH_PLACEMENTS: 'AD.PLACEMENTS.FETCH',
  CREATE_PLACEMENT: 'AD.PLACEMENT.ADD',
  DISCARD_PLACEMENT: 'AD.PLACEMENT.DISCARD',
  SET_PLACEMENT_FIELD: 'AD.PLACEMENT.SET',
  SAVE_PLACEMENT: 'AD.PLACEMENT.SAVE',
  SAVE_NEW_PLACEMENT: 'PLACEMENT.NEW.SAVED',
  SET_FLOOR: 'FLOOR.ACTIVE.SET',
  REMOVE_FLOOR: 'FLOOR.ACTIVE.REMOVE',
  ADD_PLACEMENT_CREDENTIAL: 'PLACEMENT.CREDENTIAL.ADD',
  FETCH_COMPANY_PLACEMENTS: 'AD.COMPANY.PLACEMENTS.FETCH',
  FETCH_COMPANY_PLACEMENT_NAMES: 'AD.COMPANY.PLACEMENT.NAMES.FETCH',
  SAVE_PLACEMENT_CREDENTIALS: 'PLACEMENT.CREDENTIALS.SAVE',
  TOGGLE_PLACEMENT_CREDENTIAL: 'PLACEMENT.CREDENTIAL.TOGGLE',
  REMOVE_PLACEMENT_CREDENTIAL: 'PLACEMENT.CREDENTIAL.REMOVE',
  SAVE_DELETED_PLACEMENT_CREDENTIALS: 'PLACEMENT.CREDENTIAL.REMOVE.SAVE',
  REMOVE_PLACEMENT: 'PLACEMENT.REMOVE',
  CREATE_AB_TEST: 'PLACEMENT.CREATE.ABTEST',
  ADD_ADGROUP: 'PLACEMENT.ADD.ADGROUP',
  CLEAR_REDIRECT: 'PLACEMENT.CLEAR.REDIRECT',
  SET_LOCAL_PROPORTION: 'PLACEMENT.SET.LOCAL.PROPORTION',
  DISABLE_BANNER_REFRESH: 'PLACEMENT.DISABLE.BANNER.REFRESH',
  ENABLE_BANNER_REFRESH: 'PLACEMENT.ENABLE.BANNER.REFRESH',
  SET_BANNER_REFRESH: 'PLACEMENT.SET.BANNER.REFRESH',
  SET_REWARDED_CALLBACK: 'PLACEMENT.SET.REWARDED_CALLBACK',
  SET_PLACEMENT_QUEUE_SIZE: 'PLACEMENT.SET.QUEUE.SIZE',
  FETCH_PLACEMENT_METRICS: 'PLACEMENT.METRICS.FETCH',
  FETCH_B_TEST_PLACEMENT_METRICS: 'PLACEMENT.B.TEST.METRICS.FETCH',
  FETCH_PLACEMENT_OVERVIEW_METRICS: 'PLACEMENT.METRICS_OVERVIEW.FETCH',
  FETCH_APP_OVERVIEW_TIMELINE_METRICS: 'APP.OVERVIEW_TIMELINE.FETCH',
  FETCH_PLACEMENT_BIDDING_LINE_ITEMS: 'PLACEMENT.BIDDING.LINEITEMS.FETCH',
};

/* Async Definitions */

const fetchOne = Actions.create({
  type: terms.FETCH_PLACEMENT,
  args: ['appId', 'placementId'],
  awaits: Api.getOne,
  message: ['fetch-failure', 'helium placements'],
});

const removePlacement = Actions.create({
  type: terms.REMOVE_PLACEMENT,
  args: ['appId', 'placementId'],
  awaits: Api._delete,
  message: ['remove', 'placement'],
});

const fetchAll = Actions.create({
  type: terms.FETCH_PLACEMENTS,
  args: ['appId'],
  awaits: Api.getAll,
  message: ['fetch-failure', 'helium placements'],
});

const fetchCompanyPlacements = Actions.create({
  type: terms.FETCH_COMPANY_PLACEMENTS,
  args: [],
  awaits: Api.getCompanyPlacements,
  message: ['fetch-failure', 'helium placements'],
});

const fetchCompanyPlacementsNames = Actions.create({
  type: terms.FETCH_COMPANY_PLACEMENT_NAMES,
  args: [],
  awaits: Api.getCompanyPlacementNames,
  message: ['fetch-failure', 'helium placements'],
});

const update = Actions.create({
  type: terms.SAVE_PLACEMENT,
  args: ['adPlacement', 'appId'],
  awaits: Api.update,
  message: ['save', 'ad placement'],
});

const fetchPlacementBiddingLineItems = Actions.create({
  type: terms.FETCH_PLACEMENT_BIDDING_LINE_ITEMS,
  args: ['appId', 'placementId'],
  awaits: Api.getPlacementBiddingLineItems,
  message: ['fetch-failure', 'placement bidding lineitems'],
});

const savePlacementCredentials = Actions.create({
  type: terms.SAVE_PLACEMENT_CREDENTIALS,
  args: ['appId', 'placementId', 'credentials'],
  awaits: Api.savePlacementCredentials,
  message: ['save', 'placement credentials'],
});

const saveDeletedPlacementCredentials = Actions.create({
  type: terms.SAVE_DELETED_PLACEMENT_CREDENTIALS,
  args: ['appId', 'placementId', 'credentials'],
  awaits: Api.deletePlacementCredentials,
  message: ['delete', 'placement credentials'],
});

const clearRedirect = Actions.create({
  type: terms.CLEAR_REDIRECT,
  args: [],
});

const setLocalProportion = Actions.create({
  type: terms.SET_LOCAL_PROPORTION,
  args: ['appId', 'placementId', 'value'],
});

const fetchMetrics = Actions.create({
  type: terms.FETCH_PLACEMENT_METRICS,
  args: ['appId', 'placementName', 'date_min', 'date_max'],
  awaits: Api.getPlacementMetrics,
  message: ['fetch-failure', 'helium placements metrics'],
});

const fetchBTestMetrics = Actions.create({
  type: terms.FETCH_B_TEST_PLACEMENT_METRICS,
  args: ['appId', 'placementName', 'date_min', 'date_max'],
  awaits: Api.getBTestPlacementMetrics,
  message: ['fetch-failure', 'helium placements metrics'],
});

const fetchOverviewMetrics = Actions.create({
  type: terms.FETCH_PLACEMENT_OVERVIEW_METRICS,
  args: ['appId', 'date_min', 'date_max'],
  awaits: Api.getPlacementOverviewMetrics,
  message: ['fetch-failure', 'helium placements overview metrics'],
});

const fetchAppOverviewMetrics = Actions.create({
  type: terms.FETCH_APP_OVERVIEW_TIMELINE_METRICS,
  args: ['appId', 'date_min', 'date_max'],
  awaits: Api.getAppOverviewTimelineMetrics,
  message: ['fetch-failure', 'helium app overview timeline metrics'],
});

/**
 * @function saveNewPlacement()
 * @description sends two xhr requests , first one saves a new placement
 * in the API. then when the response comes back we use the new placement's ID
 * to send another xhr request to save the placement's credentials
 * @param {PLACEMENT} adPlacement
 * @param {string} appId
 * @memberof Placement/Actions
 */

const saveNewPlacement = (adPlacement, appId) => async (dispatch, getState) => {
  dispatch({ type: Actions.started(terms.SAVE_NEW_PLACEMENT) });
  try {
    const response = await Api.save(adPlacement, appId);

    // Persist placement credentials
    if (adPlacement.credentials) {
      const schema = Network.Store.getSchema(getState());
      const creds = Helpers.prepareCredentials(adPlacement.credentials, schema);
      await Api.savePlacementCredentials(appId, String(response.id), creds);
    }

    dispatch({ type: Actions.success(terms.SAVE_NEW_PLACEMENT), payload: { response } });
  } catch (e) {
    dispatch({
      type: Actions.failure(terms.SAVE_NEW_PLACEMENT),
      payload: {},
      error: true,
      meta: {
        messages: {
          failure: 'save-failure',
        },
      },
    });
  }
};

/**
 * @function createABTest
 * @description sends two xhr requests, first one saves the placement
 * being edited in case of other changes, second one creates an AB Test
 */

// SG-1955: Creation of AB test removed

// const createABTest = (appId, placement) => async (dispatch) => {
//   dispatch({ type: Actions.started(terms.CREATE_AB_TEST) });
//   try {
//     placement.proportion = 0.5;
//     await Api.update(placement, appId);
//     const abResponse = await Api.createABTest(appId, placement.id);

//     dispatch({
//       type: Actions.success(terms.CREATE_AB_TEST),
//       payload: {
//         appId,
//         placementId: placement.id,
//         response: abResponse,
//       },
//     });
//   } catch (e) {
//     dispatch({
//       type: Actions.failure(terms.CREATE_AB_TEST),
//       payload: {},
//       error: true,
//       meta: {
//         messages: {
//           failure: 'save-failure',
//         },
//       },
//     });
//   }
// };

/* setters */

const createPlacement = Actions.create({
  type: terms.CREATE_PLACEMENT,
  args: ['heliumApp', 'type'],
});
const discardPlacement = Actions.create({ type: terms.DISCARD_PLACEMENT, args: [] });
const editPlacement = Actions.create({
  type: terms.SET_PLACEMENT_FIELD,
  args: ['appId', 'placementId', 'name', 'value'],
});
const addCredential = Actions.create({
  type: terms.ADD_PLACEMENT_CREDENTIAL,
  args: ['appId', 'placementId', 'credentials'],
});
const removeCredential = Actions.create({
  type: terms.REMOVE_PLACEMENT_CREDENTIAL,
  args: ['appId', 'placementId', 'networkId'],
});
const editCredential = Actions.create({
  type: terms.TOGGLE_PLACEMENT_CREDENTIAL,
  args: ['appId', 'placementId', 'networkId', 'value'],
});
const setFloor = Actions.create({
  type: terms.SET_FLOOR,
  args: ['appId', 'placementId', 'country', 'floor'],
});
const removeFloor = Actions.create({
  type: terms.REMOVE_FLOOR,
  args: ['appId', 'placementId', 'country'],
});
const addAdgroup = Actions.create({
  type: terms.ADD_ADGROUP,
  args: ['appId', 'placementId', 'agId'],
});
const disableBannerRefresh = Actions.create({
  type: terms.DISABLE_BANNER_REFRESH,
  args: ['appId', 'placementId'],
});
const enableBannerRefresh = Actions.create({
  type: terms.ENABLE_BANNER_REFRESH,
  args: ['appId', 'placementId'],
});
const setBannerRefresh = Actions.create({
  type: terms.SET_BANNER_REFRESH,
  args: ['appId', 'placementId', 'value'],
});
const setQueueSize = Actions.create({
  type: terms.SET_PLACEMENT_QUEUE_SIZE,
  args: ['appId', 'placementId', 'value'],
});
const setRewardedCallback = Actions.create({
  type: terms.SET_REWARDED_CALLBACK,
  args: ['appId', 'placementId', 'name', 'value'],
});

const togglePlacement = (appId, adPlacement, name, value) => (dispatch, getState) => {
  dispatch(editPlacement(appId, adPlacement.id, name, value));
  const updatedAdPlacement = getState()
    .apps.entities.find(({ id }) => id === appId)
    .placements.find(({ id }) => id === adPlacement.id);
  dispatch(update(updatedAdPlacement, appId));
};

const setProportion = (appId, placementId, value) => (dispatch, getState) => {
  dispatch(setLocalProportion(appId, placementId, value));
  const state = getState().apps;
  const app = AppHelpers.getApp(state.entities, appId);
  const target = Helpers.get(app, placementId, state);
  // we only save proportion value on A placements
  // UPDATE SG-1995 - we now save the proportion value on B placements as well
  if (target.ab_type === 'B') {
    target.proportion = value;
    dispatch(update(target, appId));
  } else {
    dispatch(update(target, appId));
  }
};

/* credential handlers */

const createPlacementCredentials = (appId, placementId, credentials) => (dispatch, getState) => {
  const app = AppHelpers.getApp(getState().apps.entities, appId);
  const placement = Helpers.get(app, placementId);
  dispatch(addCredential(appId, placementId, credentials));
  if (placementId !== '__new__')
    dispatch(savePlacementCredentials(app.id, placement.id, placement.credentials));
};

const editPlacementCredentials = (appId, placementId, credentials) => (dispatch, getState) => {
  const { id, fields } = credentials;
  const app = AppHelpers.getApp(getState().apps.entities, appId);
  const placement = Helpers.get(app, placementId);

  fields.map((field) => {
    const { name, value } = field;
    placement.credentials[id] = { ...placement.credentials[id], [name]: value };
  });
  dispatch(savePlacementCredentials(app.id, placement.id, placement.credentials));
};

const deletePlacementCredentials = (appId, placementId, networkId) => (dispatch, getState) => {
  const app = AppHelpers.getApp(getState().apps.entities, appId);
  const placement = Helpers.get(app, placementId);
  dispatch(removeCredential(appId, placementId, networkId));
  if (placementId !== '__new__')
    dispatch(saveDeletedPlacementCredentials(app.id, placement.id, { name: networkId }));
};

const togglePlacementCredentials =
  (appId, placementId, networkId, value) => (dispatch, getState) => {
    const app = AppHelpers.getApp(getState().apps.entities, appId);
    const placement = Helpers.get(app, placementId);
    dispatch(editCredential(appId, placementId, networkId, value));
    if (placementId !== '__new__')
      dispatch(savePlacementCredentials(app.id, placement.id, placement.credentials));
  };

const PlacementActions = {
  ...terms,
  fetchOne,
  fetchAll,
  fetchPlacementBiddingLineItems,
  update,
  createPlacement,
  discardPlacement,
  editPlacement,
  setFloor,
  removeFloor,
  deletePlacementCredentials,
  createPlacementCredentials,
  togglePlacementCredentials,
  togglePlacement,
  saveNewPlacement,
  removePlacement,
  fetchCompanyPlacements,
  fetchCompanyPlacementsNames,
  addAdgroup,
  clearRedirect,
  setProportion,
  editPlacementCredentials,
  enableBannerRefresh,
  disableBannerRefresh,
  setBannerRefresh,
  setRewardedCallback,
  fetchMetrics,
  fetchBTestMetrics,
  fetchOverviewMetrics,
  fetchAppOverviewMetrics,
  setQueueSize,
};

export default PlacementActions;
