// 3rd Party
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//views
import { AppLayout, SaveModal } from 'core/app/views';
import PlacementForm from './PlacementForm';
import '../../../analytics/common/css/filter.css';
import '../css/placements.css';

// modules
import AdGroup from 'apps/adgroup';
import App from 'apps/app';
import Network from 'apps/network';
import Placement from 'apps/placement';
import UserStore from 'core/user/store';
import { setTitle } from 'utils/helpers';

class EditPlacement extends PlacementForm {
  constructor(props) {
    super(props);
    this.state = {
      showSave: false,
      navigateTo: false,
      saved: false,
    };
  }

  componentDidMount() {
    const {
      app,
      clearChanges,
      fetchOne,
      fetchOneApp,
      fetchAdGroups,
      placementId,
      placementName,
      getPlacementMetrics,
      getDateFilter,
      selectApp,
    } = this.props;
    // if app is not defined, use browser to determine app id
    const urlParams = window.location.pathname.split('/');
    const urlAppId = urlParams[2];

    const { max, min } = getDateFilter;

    if (!app.id) {
      fetchOneApp(urlAppId);
      fetchOne(urlAppId, placementId);
      fetchAdGroups(urlAppId);

      if (placementName) {
        getPlacementMetrics(urlAppId, placementName, min, max);
      }
      selectApp(urlAppId);
    } else {
      fetchOne(app.id, placementId);
      fetchAdGroups(app.id);
      if (placementName) {
        getPlacementMetrics(app.id, placementName, min, max);
      }
      selectApp(app.id);
    }
    clearChanges();
    // fetch available bidding networks
    const platform = this.props.app.platform && this.props.app.platform.toLowerCase();
    const placementType = this.props.placement.type;
    if (platform && placementType) {
      this.props.getSupportedPlacementPartners(platform, 'bidding', placementType);
    }
  }

  componentDidUpdate(prevProps) {
    const urlParams = window.location.pathname.split('/');
    const urlAppId = urlParams[2];
    if (prevProps.shouldFetchPlacement === false && this.props.shouldFetchPlacement === true) {
      if (this.props.app.id) {
        this.props.fetchOne(urlAppId, this.props.placementId);
        selectApp(urlAppId);
      } else {
        this.props.fetchOne(this.props.app.id, this.props.placementId);
        selectApp(this.props.app.id);
      }
    }
    if (prevProps.placementName !== this.props.placementName) {
      const { max, min } = this.props.getDateFilter;
      this.props.getPlacementMetrics(urlAppId, this.props.placementName, min, max);
    }

    if (this.props.appId !== 'unselected') {
      // set tab to placement name for easier UI
      const placementName = this.props?.placementName || 'Helium Dashboard';
      setTitle(placementName);
      if (
        prevProps.shouldFetchAdGroups === false &&
        this.props.shouldFetchAdGroups === true &&
        this.props.isAdgroupLoading === false
      ) {
        if (this.props.app.id) {
          this.props.fetchAdGroups(this.props.app.id);
        } else {
          this.props.fetchAdGroups(urlAppId);
        }
      }
    }
  }

  overrideNavigation = (errors) => {
    if (!errors && this.props.madeChanges) {
      const curriedNavigator = (url) => {
        this.setState({ showSave: true, navigateTo: url });
      };
      return curriedNavigator.bind(this);
    }
    return false;
  };

  render() {
    const { app, placement, save, placementErrors } = this.props;
    this.handleAddition();
    const hasErrors = placementErrors.length > 0;

    return (
      <AppLayout
        bundle="apps"
        override={this.overrideNavigation(hasErrors)}
        context={{ app: app, placement: placement, placements: true }}
      >
        <SaveModal
          discardUrl={this.state.navigateTo}
          saver={() => {
            save(placement, app.id);
            this.setState({ showSave: false });
          }}
          visible={this.state.showSave && !hasErrors}
        />

        {this.renderForm()}
      </AppLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: appId, pid } = ownProps.match.params;
  const placementId = parseInt(pid, 10);
  const placement = Placement.Store.getOne(state, appId, placementId) || { adgroups: [] };
  const placementName = placement?.name;
  const app = App.Store.getApp(state, appId) || { credentials: [], placements: [] };
  const existingNames = Placement.Helpers.getExistingNames(app, placementId);
  const adgroups = placement.adgroups
    ? placement.adgroups.map((agid) => AdGroup.Store.getOne(state, appId, agid) || {})
    : [];

  return {
    adgroups,
    app,
    biddingMetrics: Placement.Store.getBiddingMetrics(state),
    mediationMetrics: Placement.Store.getMediationMetrics(state),
    credsLoading: Placement.Store.getCredsLoading(state),
    defaultNames: Placement.Store.getDefaults(state, existingNames),
    existingNames,
    isAdgroupLoading: AdGroup.Store.getLoading(state),
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
    isTestAccount: UserStore.isTestAccount(state),
    isZynga: UserStore.isZynga(state),
    loading: Placement.Store.getLoading(state),
    madeChanges: App.Store.hasMadeChanges(state),
    madeChangePlacement: Placement.Store.hasMadeChanges(state),
    networkOptions: Network.Store.getUnusedNetworksForPlacement(state, app, placement),
    placement: placement || { credentials: [], floor_active: false, prioritize_bidding: false },
    placementId,
    placementName,
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    shouldRedirectToAB: Placement.Store.getShouldRedirectToAB(state),
    showABTestOption: true,
    callbackErrors: App.Store.getCallbackErrors(state),
    placementErrors: App.Store.getPlacementErrors(state),
    getDateFilter: App.Store.getDateFilter(state),
  };
};

const mapDispatchToProps = {
  addAdgroup: Placement.Actions.addAdgroup,
  clearChanges: App.Actions.clearChanges,
  createABTest: Placement.Actions.createABTest,
  createPlacementCredentials: Placement.Actions.createPlacementCredentials,
  deletePlacementCredentials: Placement.Actions.deletePlacementCredentials,
  disableBannerRefresh: Placement.Actions.disableBannerRefresh,
  editPlacement: Placement.Actions.editPlacement,
  enableBannerRefresh: Placement.Actions.enableBannerRefresh,
  fetchAdGroups: AdGroup.Actions.fetchAll,
  fetchOne: Placement.Actions.fetchOne,
  fetchOneApp: App.Actions.fetchApp,
  getPlacementMetrics: Placement.Actions.fetchMetrics,
  getSupportedPlacementPartners: Network.Actions.fetchSupportedPlacementPartners,
  removeFloor: Placement.Actions.removeFloor,
  save: Placement.Actions.update,
  selectApp: App.Actions.selectApp,
  setBannerRefresh: Placement.Actions.setBannerRefresh,
  setQueueSize: Placement.Actions.setQueueSize,
  setDateFilter: App.Actions.setDateFilter,
  setFloor: Placement.Actions.setFloor,
  setRewardedCallback: Placement.Actions.setRewardedCallback,
  togglePlacementCredentials: Placement.Actions.togglePlacementCredentials,
  updateAdgroupPlacement: AdGroup.Actions.updateAdgroupPlacement,
  updateAdgroupPlacementLocal: AdGroup.Actions.updateAdgroupPlacement,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPlacement));
