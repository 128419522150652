import React from 'react';
import { Label } from 'semantic-ui-react';

const PredicatePlaceholder = ({
  keyword,
  operation,
  value,
  isReserved,
  reserveKeywordDisplayMap,
}) => {
  return (
    <div className="flex ">
      <div className="predicate-adding">
        {isReserved ? <div className="reserved-predicate-text">reserved keyword</div> : null}
        <Label key={keyword}>
          <div className="keyword-chip-text flex">
            <div className="keyword-chip-text-keyword">
              {isReserved ? reserveKeywordDisplayMap[keyword] : keyword}
            </div>
            <div className="keyword-chip-text-operation">{operation}</div>
            <div className="keyword-chip-text-value">{value}</div>
          </div>
        </Label>
      </div>
    </div>
  );
};

export default PredicatePlaceholder;
