import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import Constants from '../constants/targetingRules';
import { capitalize } from 'utils/helpers';

const PredicateInput = ({
  inputType,
  keyword,
  keywordOptions,
  keywordType,
  operation,
  setter,
  toggleAddPredicate,
  toggleEditPredicate,
  handleSubmitPredicate,
  errors,
  value,
}) => {
  const isValueSelected = keyword !== undefined && keyword !== '';
  const isOperationSelected = operation !== undefined && operation !== '';
  const isActionDisabled =
    !isValueSelected || !isOperationSelected || (value !== undefined && value === '');

  const PredicateActionButton = () => (
    <Button
      className="positive"
      disabled={isActionDisabled}
      onClick={() => {
        handleSubmitPredicate();
      }}
    >
      {capitalize(inputType)}
    </Button>
  );

  const handleClickCancel = () => {
    if (inputType === 'add') {
      toggleAddPredicate(false, '');
    } else {
      toggleEditPredicate(false, '', '');
    }
  };

  return (
    <div className="predicate-input-container">
      <Form>
        <Form.Group className="predicate-input-group">
          <Form.Dropdown
            autoComplete="off"
            label="Keyword"
            fluid
            className="predicate-keyword-input"
            name="keyword"
            onChange={setter}
            options={keywordOptions}
            placeholder="Device OS"
            search
            selection
            value={keyword}
          />
          <Form.Dropdown
            autoComplete="off"
            label="Operation"
            name="operation"
            fluid
            disabled={!isValueSelected}
            className="predicate-opertation-input"
            onChange={setter}
            options={Constants.OPERATION_MAP[keywordType]}
            placeholder="=="
            selection
            value={operation}
          />
          {(keywordType === 'boolean' && (
            <Form.Dropdown
              autoComplete="off"
              label="Value"
              name="value"
              fluid
              disabled={!isOperationSelected}
              className="predicate-value-input"
              onChange={setter}
              options={Constants.BOOLEAN_OPTIONS}
              placeholder="true"
              selection
              value={value}
            />
          )) || (
            <Form.Input
              autoComplete="off"
              label="Value"
              error={
                errors &&
                errors.length > 0 && {
                  content: errors[0],
                  pointing: 'above',
                }
              }
              name="value"
              disabled={!isOperationSelected}
              className="predicate-value-input"
              fluid
              onChange={setter}
              placeholder="Input Value.."
              value={value}
            />
          )}
        </Form.Group>
      </Form>
      <div className="predicate-input-container-actions">
        <PredicateActionButton />
        <Button onClick={handleClickCancel}>Cancel</Button>
      </div>
    </div>
  );
};

export default PredicateInput;
