import { LOADING, UNLOADED, LOADED } from 'core/app/constants/readyStates';
import AppStore from '../app/store';

const getState = (state, appId) => {
  const app = AppStore.getApp(state, appId) || {};
  return app.adgroups || [];
};

const getAll = (state, appId) => getState(state, appId);

const getOne = (state, appId, adgroupId) =>
  getState(state, appId).find(({ id }) => id === adgroupId);

const getByType = (state, appId, type) => {
  const all = getAll(state, appId);
  return all.filter(a => a.type === type);
};

const getSelected = state => state.apps.selectedAdGroup;

const getNew = state => state.apps.newAdGroup;

const shouldFetch = state => state.apps.adgroupsState !== LOADED;

const getLoading = state =>
  state.apps.adgroupsState === LOADING || state.apps.placementsReadyState === LOADING;

const shouldTransition = state => state.apps.shouldTransitionAdGroup;

const AdgroupStore = {
  getNew,
  getOne,
  getAll,
  getSelected,
  shouldFetch,
  getLoading,
  getByType,
  shouldTransition
};

export default AdgroupStore;
