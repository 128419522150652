import Store from './store';
import Reducer from './reducer';
import Actions from './actions';

const CoreApp = {
  Store,
  Reducer,
  Actions
};

export default CoreApp;
