/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Grid } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';

import { Messages, Loader, Sidebar } from './';

import Welcome from 'core/user/views/Welcome';
import App from 'core/app';
import User from 'core/user';
import AppStore from 'apps/app/store';

class Shell extends Component {
  componentDidMount() {
    const { appOnline, appResize } = this.props;
    window.addEventListener('online', () => appOnline(true), { capture: false, passive: true });
    window.addEventListener('offline', () => appOnline(false), { capture: false, passive: true });
    window.addEventListener('resize', appResize, { capture: false, passive: true });
    // if (online && shouldFetch) {
    //   fetchExperience();
    // }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.shouldFetchTerms) {
  //     nextProps.fetchTerms();
  //   }
  // }

  render() {
    const {
      acceptedTerms,
      bundleLoading,
      children,
      dismissMessage,
      hasActiveRole,
      isBetaTestAccount,
      isLoggedIn,
      isTestAccount,
      isV2Publisher,
      loading,
      logout,
      messages,
      // logout,
      online,
      selectedApp,
    } = this.props;
    let modal;
    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }
    if (loading === 0 && !hasActiveRole) {
      window.location = '//dashboard.chartboost.com';
    }
    if (!acceptedTerms) {
      modal = <Welcome />;
    }

    const redirectLogout = () => {
      window.location = '/logout';
    };

    return (
      <div className="Shell">
        {!online ? <Dimmer active>You appear to be offline.</Dimmer> : null}
        <Grid columns={2} className="full-height">
          <Grid.Column width={2}>
            <Sidebar
              logout={redirectLogout}
              isV2Publisher={isV2Publisher}
              selectedApp={selectedApp}
            />
          </Grid.Column>
          <div className="main-column fourteen wide column">
            <div loading="true">
              <Messages dismissMessage={dismissMessage} messages={messages} />
              <Loader isLoading={bundleLoading} />
              {children}
              {modal}
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}

Shell.defaultProps = {
  appOnline: () => {},
  appResize: () => {},
  bundles: [],
  closeNavbar: () => {},
  dismissMessage: () => {},
  fetchExperience: () => {},
  fetchTerms: () => {},
  hasActiveRole: true,
  isLoggedIn: true,
  loading: false,
  location: { pathname: '/' },
  logout: () => {},
  messages: [],
  navbarVisible: false,
  online: true,
  shouldFetch: false,
  toggleNavbar: () => {},
  shouldFetchTerms: false,
  bundleLoading: true,
};

const mapStateToProps = (state) => ({
  bundles: User.Store.getBundles(state),
  hasActiveRole: User.Store.getHasActiveRole(state),
  isLoggedIn: User.Store.getLoggedIn(state),
  isFetching: User.Store.getIsFetching(state),
  messages: App.Store.getMessages(state),
  navbarVisible: App.Store.getNavbarVisible(state),
  online: App.Store.getOnline(state),
  shouldFetch: User.Store.getShouldFetch(state),
  shouldFetchTerms: User.Store.getShouldFetchTerms(state),
  acceptedTerms: User.Store.getHasAcceptedTerms(state),
  bundleLoading: App.Store.getBundleLoading(state),
  isV2Publisher: User.Store.isV2(state),
  isTestAccount: User.Store.isTestAccount(state),
  selectedApp: AppStore.getSelected(state),
  isBetaTestAccount: User.Store.isBetaTestAccount(state),
});

const mapDispatchToProps = {
  appOnline: App.Actions.appOnline,
  appResize: App.Actions.appResize,
  closeNavbar: App.Actions.toggleNavbar,
  dismissMessage: App.Actions.dismissMessage,
  fetchExperience: User.Actions.fetchExperience,
  fetchTerms: User.Actions.fetchTerms,
  logout: User.Actions.logout,
  toggleNavbar: App.Actions.toggleNavbar,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shell));
