import React from 'react';
import { Checkbox, Header } from 'semantic-ui-react';

const ToggleRule = ({ isNegated, handleNegateRule, rule, shouldDisableActions }) => {
  if (rule && rule.uuid) {
    return (
      <div className="flex-col">
        <Header as="h5" color={isNegated ? 'red' : ''}>
          {isNegated ? 'Negated' : 'Active'}
        </Header>
        <Checkbox
          checked={!isNegated}
          disabled={shouldDisableActions}
          onClick={() => {
            if (!shouldDisableActions) {
              handleNegateRule(rule.uuid, !isNegated);
            }
          }}
          toggle
        />
      </div>
    );
  }
  return null;
};

export default ToggleRule;
