// 3rd-Party
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Icon, Card } from 'semantic-ui-react';
import { PageHeader, AppLayout } from 'core/app/views';

// Views
import AppDetail from './AppDetail';
import AppDetailCard from './AppDetailCard';
import QueueSize from './QueueSize';
import MetricsGraphContainer from 'apps/common/metrics/views/MetricsGraphContainer';

// Modules
import Actions from '../actions';
import Store from '../store';
import Helpers from '../helpers';
import Placement from 'apps/placement';
import AdGroup from 'apps/adgroup';
import LineItem from 'apps/lineitem';
import UserStore from 'core/user/store';

import DateFilter from 'analytics/common/views/DateFilter';
import Format from 'analytics/common/format';
import CommonHelpers from 'analytics/common/helpers';
import { setTitle } from '../../../utils/helpers';

class EditApp extends Component {
  componentDidMount() {
    const { max, min } = this.props.getDateFilter;
    if (this.props.appId !== 'unselected') {
      this.props.fetch(this.props.appId);
      this.props.selectApp(this.props.appId);
      this.props.fetchAppOverviewTimelineMetrics(this.props.appId, min, max);
      this.props.fetchPlacements(this.props.appId);
      this.props.fetchAdGroups(this.props.appId);
      // set tab to app name for easier UI
      const appName = this.props?.heliumApp?.nickname || this.props?.heliumApp?.name;
      setTitle(appName);
    }
  }

  componentDidUpdate(prevProp) {
    let curDateFilter = this.props.getDateFilter;
    let prevDateFilter = prevProp.getDateFilter;
    if (curDateFilter.min !== prevDateFilter.min || curDateFilter.max !== prevDateFilter.max) {
      this.props.fetchAppOverviewTimelineMetrics(
        this.props.appId,
        curDateFilter.min,
        curDateFilter.max
      );
    }
    if (this.props.appId !== 'unselected') {
      if (
        prevProp.shouldFetchPlacements === false &&
        this.props.shouldFetchPlacements === true &&
        this.props.placementLoading === false
      ) {
        this.props.fetchPlacements(this.props.appId);
      }
      if (prevProp.shouldFetchAdGroups === false && this.props.shouldFetchAdGroups === true) {
        this.props.fetchAdGroups(this.props.appId);
      }
      if (prevProp.shouldFetchLineItems === false && this.props.shouldFetchLineItems === true) {
        this.props.fetchLineItems(this.props.appId);
      }

      const appChanged = this.props.appId !== prevProp.appId;
      if (appChanged) {
        this.props.fetchApp(this.props.appId);
      }
    }

    // set tab to app name for easier UI
    const appName = this.props?.heliumApp?.nickname || this.props?.heliumApp?.name;
    setTitle(appName);
  }

  render() {
    const {
      appId,
      toggle,
      heliumApp = { credentials: [] },
      getDateFilter,
      setDateFilter,
      isTestAccount,
    } = this.props;

    const { min, max, type } = getDateFilter || {};

    const toggleActive = (_e, { name, value }) => {
      const val = value === 'true' ? true : false;
      toggle(heliumApp, name, val);
    };

    const toggleQueueSize = (_e, { name, value }) => {
      toggle(heliumApp, name, value);
    };

    const detailsLoading = this.props.adgroupLoading || this.props.placementLoading;

    const placementDetails = [
      [this.props.interstitialPlacements.length, 'interstitial'],
      [this.props.rewardedPlacements.length, 'rewarded'],
      [this.props.bannerPlacements.length, 'banner'],
      [this.props.adaptiveBannerPlacements.length, 'adaptive_banner'],
      [this.props.rewardedInterstitialPlacements.length, 'rewarded_interstitial'],
    ];

    const adgroupDetails = [
      [this.props.interstitialAdGroups.length, 'interstitial'],
      [this.props.rewardedAdGroups.length, 'rewarded'],
      [this.props.bannerAdGroups.length, 'banner'],
      [this.props.adaptiveBannerAdGroups.length, 'adaptive_banner'],
      [this.props.rewardedInterstitialAgroups.length, 'rewarded_interstitial'],
    ];

    const NetworkDetails = {
      url: `/apps/${appId}/networks`,
      appName: heliumApp.name,
      name: 'Networks',
      icon: 'globe',
      items: [
        [this.props.mediationNetworks.length, 'mediation'],
        [this.props.biddingNetworks.length, 'bidding'],
      ],
    };

    const PlacementDetails = {
      url: `/apps/${appId}/placements`,
      appName: heliumApp.name,
      name: 'Placements',
      icon: 'bookmark outline',
      items: [...placementDetails],
    };

    const AdGroupDetails = {
      url: `/apps/${appId}/adgroups`,
      appName: heliumApp.name,
      name: 'Ad Groups',
      icon: 'bullseye',
      items: [...adgroupDetails],
    };

    const updateDateFilter = (start, end, selectedKey) => {
      const min = Format.date(start || CommonHelpers.timePeriod.week);
      const max = Format.date(end || CommonHelpers.timePeriod.today);
      setDateFilter(heliumApp, { min, max, type: selectedKey });
    };

    const title = (app) => {
      let iconName = 'apple';
      if (app.platform === 'Android') {
        iconName = 'android';
      } else if (app.platform === 'Amazon') {
        iconName = 'amazon';
      }
      return (
        <span className="platform">
          <Icon name={iconName} />
          {heliumApp.name}
        </span>
      );
    };

    return (
      <AppLayout bundle="apps" context={{ app: heliumApp }}>
        <Segment attached="bottom">
          <Segment basic>
            <PageHeader content={title(heliumApp)} className="pb-15" />
            <div>
              <AppDetailCard heliumApp={heliumApp} appId={appId} toggleActive={toggleActive} />
              <QueueSize
                maxQueueSize={heliumApp?.max_queue_size}
                defaultQueueSize={heliumApp?.default_queue_size}
                toggleQueueSize={toggleQueueSize}
              />
            </div>
            <div className="metrics-filter">
              <DateFilter
                date_key={type}
                date_from={min}
                date_to={max}
                onSelect={(_e) => null}
                onApply={(_e, endDate, startDate, selectedKey) =>
                  updateDateFilter(startDate, endDate, selectedKey)
                }
              />
            </div>

            <Segment loading={detailsLoading} style={{ marginTop: '-10px', marginBottom: '-20px' }}>
              <MetricsGraphContainer
                timelineMetrics={this.props.timelineMetrics}
                pageView="appOverview"
              />
            </Segment>

            <Segment basic loading={detailsLoading}>
              <Card.Group className="app-detail-card" itemsPerRow={3}>
                <AppDetail {...NetworkDetails} />
                <AppDetail {...PlacementDetails} />
                <AppDetail {...AdGroupDetails} />
              </Card.Group>
            </Segment>
          </Segment>
        </Segment>
      </AppLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: appId } = ownProps.match.params;
  const heliumApp = Store.getApp(state, appId) || {};
  return {
    appId,
    heliumApp,
    mediationNetworks: Helpers.getNetworkStates(heliumApp, 'mediation'),
    biddingNetworks: Helpers.getNetworkStates(heliumApp, 'bidding'),
    bannerAdGroups: AdGroup.Store.getByType(state, appId, 'banner'),
    interstitialAdGroups: AdGroup.Store.getByType(state, appId, 'interstitial'),
    rewardedAdGroups: AdGroup.Store.getByType(state, appId, 'rewarded'),
    rewardedInterstitialAgroups: AdGroup.Store.getByType(state, appId, 'rewarded_interstitial'),
    adaptiveBannerAdGroups: AdGroup.Store.getByType(state, appId, 'adaptive_banner'),
    bannerPlacements: Placement.Store.getByType(state, appId, 'banner'),
    interstitialPlacements: Placement.Store.getByType(state, appId, 'interstitial'),
    rewardedPlacements: Placement.Store.getByType(state, appId, 'rewarded'),
    rewardedInterstitialPlacements: Placement.Store.getByType(
      state,
      appId,
      'rewarded_interstitial'
    ),
    adaptiveBannerPlacements: Placement.Store.getByType(state, appId, 'adaptive_banner'),
    loading: Store.getLoading(state),
    placementLoading: Placement.Store.getLoading(state),
    adgroupLoading: AdGroup.Store.getLoading(state),
    shouldFetchPlacements: Placement.Store.getShouldFetchPlacements(state),
    shouldFetchAdGroups: AdGroup.Store.shouldFetch(state),
    shouldFetchLineItems: LineItem.Store.shouldFetch(state),
    isBetaTestAccount: UserStore.isBetaTestAccount(state),
    isTestAccount: UserStore.isTestAccount(state),
    timelineMetrics: Placement.Store.getTimelineMetrics(state),
    getDateFilter: Store.getDateFilter(state),
  };
};

const mapDispatchToProps = {
  fetch: Actions.fetchApp,
  toggle: Actions.saveApp,
  selectApp: Actions.selectApp,
  fetchPlacements: Placement.Actions.fetchAll,
  fetchAdGroups: AdGroup.Actions.fetchAll,
  fetchLineItems: LineItem.Actions.fetchAll,
  setDateFilter: Actions.setDateFilter,
  fetchAppOverviewTimelineMetrics: Placement.Actions.fetchAppOverviewMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditApp);
