import React from 'react';
import { Table } from 'semantic-ui-react';

const PricePointsTable = ({ values }) => {

    if (!values) {
        return null;
    }

    return (
        <div className="aps-table-container">
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Price Point</Table.HeaderCell>
                        <Table.HeaderCell>Encoded</Table.HeaderCell>
                        <Table.HeaderCell>CPM</Table.HeaderCell>
                        <Table.HeaderCell>Created Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        values.map(p => (
                            <Table.Row>
                                <Table.Cell>{p.price_point}</Table.Cell>
                                <Table.Cell>{p.encoded}</Table.Cell>
                                <Table.Cell>{p.cpm}</Table.Cell>
                                <Table.Cell>{p.create_date}</Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
        </div>
    )

}

export default PricePointsTable;