import React, { Component } from 'react';
import { Card, Icon } from 'semantic-ui-react';
import AppIcon from './AppIcon';

class AppCard extends Component {
  renderHeader = (name) => <Card.Header> {name} </Card.Header>;

  renderIcon = (app) => (
    <AppIcon appId={app.key} appIcon={app.icon} platform={app.platform} floated="right" />
  );

  renderMeta = (app) => {
    let icon = 'amazon';
    if (app.platform === 'iOS') {
      icon = 'apple';
    } else if (app.platform === 'Android') {
      icon = 'android';
    }
    return (
      <Card.Meta>
        <span>
          <b>{app.key}</b>
        </span>
        <div className="platform">
          <Icon name={icon} />
          <span>{app.platform}</span>
        </div>
      </Card.Meta>
    );
  };

  renderDescription = () => {};

  renderExtra = () => {};

  render() {
    const app = this.props.app;
    return (
      <Card className={this.type}>
        <Card.Content>
          {this.renderIcon(app)}
          {this.renderHeader(app.name)}
          {this.renderMeta(app)}
          {this.renderDescription()}
        </Card.Content>
        {this.renderExtra()}
      </Card>
    );
  }
}

export default AppCard;
