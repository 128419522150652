const APP_FIELDS = [
  'active',
  'bundle',
  'company_id',
  'company_name',
  'credentials',
  'default_queue_size',
  'id',
  'itunes_id',
  'logging_level',
  'max_queue_size',
  'name',
  'nickname',
  'placements',
  'platform',
  'signature',
  'url',
];

const APP_DETAIL_LABELS = {
  mediation: 'Mediation',
  bidding: 'Bidding',
  banner: 'Banner',
  adaptive_banner: 'Adaptive Banner',
  interstitial: 'Interstitial',
  rewarded: 'Rewarded',
  rewarded_interstitial: 'Rewarded Interstitial',
};

const APP_PLATFORM_MAP = {
  iOS: 1,
  Android: 2,
  Amazon: 3,
};

const QUEUE_SIZE_OPTIONS = [
  { key: '1', value: 1, text: '1' },
  { key: '2', value: 2, text: '2' },
  { key: '3', value: 3, text: '3' },
  { key: '4', value: 4, text: '4' },
  { key: '5', value: 5, text: '5' },
];

const PLACEMENT_QUEUE_SIZE_OPTIONS = [
  { key: 'none', value: null, text: 'App Queue Size' },
  ...QUEUE_SIZE_OPTIONS,
];

const Constants = {
  APP_FIELDS,
  APP_DETAIL_LABELS,
  APP_PLATFORM_MAP,
  PLACEMENT_QUEUE_SIZE_OPTIONS,
  QUEUE_SIZE_OPTIONS,
};

export default Constants;
