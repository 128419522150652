import Actions from 'core/framework/actions';

const terms = {
  APP_ONLINE: 'APP.ONLINE',
  APP_RESIZE: 'APP.RESIZE',
  SHOW_MESSAGE: 'APP.MESSAGE.SHOW',
  DISMISS_MESSAGE: 'APP.MESSAGE.DISMISS',
  TOGGLE_NAVBAR: 'APP.NAVBAR',
  REGISTER_BUNDLE: 'APP.REGISTER_BUNDLE',
  REGISTER_BUNDLE_STARTED: 'APP.REGISTER_BUNDLE.STARTED'
};

const appOnline = Actions.create({ type: terms.APP_ONLINE, args: ['status'] });
const registerBundle = Actions.create({ type: terms.REGISTER_BUNDLE, args: ['name'] });
const toggleNavbar = Actions.create({ type: terms.TOGGLE_NAVBAR, args: ['visible'] });
const showMessage = Actions.create({ type: terms.SHOW_MESSAGE, args: ['message'] });
const dismissMessage = Actions.create({ type: terms.DISMISS_MESSAGE, args: ['index'] });
const setBundleLoading = Actions.create({
  type: terms.REGISTER_BUNDLE_STARTED,
  args: ['isLoading']
});
const appResize = () => ({
  type: terms.APP_RESIZE,
  payload: { dimensions: [window.innerHeight, window.innerWidth] }
});

const AppActions = {
  ...terms,
  appOnline,
  appResize,
  registerBundle,
  toggleNavbar,
  showMessage,
  dismissMessage,
  setBundleLoading
};

export default AppActions;
