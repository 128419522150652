import React from 'react';
import CredentialField from '../../views/CredentialField';
import PricePointsField from './PricePointsField';


const AmazonApsCredentialField = attributes => {
    if (attributes.hidden) {
        return null;
    }
    switch (attributes.name) {
        case 'price_points':
            return (<PricePointsField {...attributes} />)
        case 'ad_slot_name':
            return null;
        default:
            return (<CredentialField {...attributes} />)
    }
}

export default AmazonApsCredentialField;
