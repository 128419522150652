import React from 'react';
import { Button, Label } from 'semantic-ui-react';

const Predicate = ({
  editingPredicateId,
  handleDeletePredicate,
  isEditingPredicate,
  predicate,
  ruleId,
  selectedRuleId,
  shouldDisableActions,
  toggleEditPredicate,
  reserveKeywordDisplayMap,
}) => {
  const { keyword } = predicate;
  const isSelectedPredicateEditing =
    isEditingPredicate && editingPredicateId === predicate.uuid && selectedRuleId === ruleId;

  const isReserved = reserveKeywordDisplayMap[keyword.key] !== undefined;
  const displayKeyword = isReserved ? reserveKeywordDisplayMap[keyword.key] : keyword.key;
  return (
    <div className="predicate-container">
      {keyword && keyword.is_reserved ? (
        <div className="reserved-predicate-text">reserved keyword</div>
      ) : null}
      <Label
        key={predicate.key}
        className={`targeting-rule-predicate${keyword && keyword.is_reserved ? '-reserved' : ''}${
          isSelectedPredicateEditing ? ' editing' : ''
        }`}
      >
        <div className="center-vertical">
          <Button
            disabled={shouldDisableActions}
            fitted
            icon="pencil"
            onClick={() => toggleEditPredicate(true, ruleId, predicate)}
          />
        </div>
        <div className="flex">
          <div className="keyword-chip-text-keyword">{displayKeyword}</div>
          <div className="keyword-chip-text-operation">{predicate.operation}</div>
          <div className="keyword-chip-text-value">{predicate.value}</div>
        </div>
        <div className="center-vertical">
          <Button
            disabled={shouldDisableActions}
            fitted
            icon="cancel"
            onClick={() => handleDeletePredicate(ruleId, predicate.uuid)}
          />
        </div>
      </Label>
    </div>
  );
};

export default Predicate;
